import React, { useEffect } from "react";

import BrandFilterPortfolioAdConnectPage from "~/pages/marketplaceConnectors/brandFilterPortfolioAdConnectPage";
import CriteoAdvertisingProfilePage from "~/pages/marketplaceConnectors/criteoAdvertisingProfilePage";
import GoogleAdvertisingProfilePage from "~/pages/marketplaceConnectors/googleAdvertisingProfilePage";
import MetaAdvertisingProfilePage from "~/pages/marketplaceConnectors/metaAdvertisingProfilePage";
import StandaloneSelectProfile from "~/pages/marketplaceConnectors/standaloneSelectProfile";
import TikTokAdvertisingProfilePage from "~/pages/marketplaceConnectors/tikTokAdvertisingProfilePage";

type StandaloneAdvertisingProfilePageProps = {
  merchantId?: string;
  marketplace: string;
  countryCode?: string;
  selectProfile: (
    mid: string,
    profileId?: string,
    entityId?: string,
    countryCode?: string
  ) => void;
  onSuccess: () => void;
  onConnectionComplete: (
    goToNextStage?: boolean,
    payload?: Record<string, any>,
    finalStep?: boolean
  ) => void;
};

const StandaloneAdvertisingProfilePage = ({
  merchantId,
  marketplace,
  countryCode,
  selectProfile,
  onSuccess,
  onConnectionComplete,
}: StandaloneAdvertisingProfilePageProps) => {
  switch (marketplace) {
    case "amazon":
    case "amazon_vendor":
    case "dsp":
      return countryCode ? (
        <StandaloneSelectProfile
          marketplaceType={marketplace}
          countryCode={countryCode}
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      ) : (
        <></>
      );
    case "criteo":
      return (
        <CriteoAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    case "tiktok":
      return (
        <TikTokAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    case "google":
      return (
        <GoogleAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    case "amazon_portfolio_filtered":
    case "amazon_vendor_portfolio_filtered":
      return (
        <BrandFilterPortfolioAdConnectPage
          storeParams={{
            mid: merchantId || "",
            marketplaceType: marketplace,
            marketplaceSubtype: marketplace,
            marketplaceCountry: countryCode || "",
          }}
          onSuccess={onConnectionComplete}
        />
      );
    case "meta":
      return (
        <MetaAdvertisingProfilePage
          selectProfile={selectProfile}
          onSuccess={onSuccess}
        />
      );
    default:
      return <DummyProfilePage onSuccess={onSuccess} />;
  }
};

type DummyProfilePageProps = {
  onSuccess: () => void;
};
const DummyProfilePage = ({ onSuccess }: DummyProfilePageProps) => {
  useEffect(() => {
    onSuccess();
  }, []);
  return <></>;
};

export default StandaloneAdvertisingProfilePage;
