import { IconButton, Menu, Tab } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  clearCustomOverridesConfig,
  fetchCustomOverridesConfig,
} from "~/store/customOverrides.redux";

import { Settings as SettingsCog } from "@material-ui/icons";
import { SettingsMenu } from "~/components/appSettings/menu";
import VendorRevenueMetricOption from "~/modules/widgets/amazonVendor/vendorRevenueMetricOption";
import cookies from "browser-cookies";
import { getPreferredColorScheme } from "~/utils/browserUtils";
import { hasAnyAmazonVendorStores } from "~/utils/marketplaceUtils";
import { isUndefined } from "lodash";
import styled from "styled-components";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SettingsIcon = styled(SettingsCog)`
  fill: ${({ theme }) =>
    !isUndefined(theme.palette.topToolbar)
      ? theme.palette.topToolbar.contrastText
      : theme.palette.secondary.contrastText};
`;

const SmallTab = styled(Tab)`
  min-width: 0px;
  padding: 0; // IconButton has padding
`;

const mmThemeDomains = [
  "mm.merchantspring.io",
  "mm-dev.merchantspring.io",
  "mm-staging.merchantspring.io",
];

const Settings = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [oldTheme, setOldTheme] = React.useState(false);
  const open = Boolean(anchorEl);
  const originalDomain = cookies.get("originalDomain");
  const authSource = cookies.get("authSource");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const vendorRevenueType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
      "orderedRevenue"
  );
  const vendorDefaultRevenueCheck = vendorRevenueType == "shippedRevenue";
  const colorScheme = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.colorScheme ||
      getPreferredColorScheme()
  );
  const user = useTypedSelector((state) => state.user);
  const stores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showVendorRevenueToggle = hasAnyAmazonVendorStores(stores);

  const showDarkModeToggle = useMemo(() => {
    if (user.agency) {
      if (user.agency !== "ms") {
        return false;
      }
    } else if (originalDomain && !mmThemeDomains.includes(originalDomain)) {
      return false;
    }
    return true;
  }, [user]);

  const setTax = (newValue: boolean) =>
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            includeTax: newValue,
          },
        },
        user?.isDemoMode ? false : true
      )
    );

  const setDarkMode = (newValue: boolean) => {
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            colorScheme: newValue ? "dark" : "light",
          },
        },
        user?.isDemoMode ? false : true
      )
    );
    dispatch(
      fetchCustomOverridesConfig({
        originalDomain,
        authSource,
        colorScheme: newValue ? "dark" : "light",
      })
    );
  };

  const setVendorRevenue = (newValue: boolean) => {
    dispatch(
      upsertPersistentAppSettings(
        {
          organisationId: user.organisationId,
          userId: user._id,
          settings: {
            vendorRevenueType: newValue ? "shippedRevenue" : "orderedRevenue",
          },
        },
        user?.isDemoMode ? false : true
      )
    );
  };

  const setToOldTheme = (newValue: boolean) => {
    setOldTheme(newValue);
    if (newValue) {
      dispatch(clearCustomOverridesConfig());
    } else {
      dispatch(
        fetchCustomOverridesConfig({ originalDomain, authSource, colorScheme })
      );
    }
  };

  return (
    <>
      <SmallTab
        value="settings"
        id="settings"
        onClick={handleClick}
        label={
          <IconButton component="div" aria-label="settings" color="inherit">
            <SettingsIcon />
          </IconButton>
        }
      />
      <Menu
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <SettingsMenu
          menuItems={[
            {
              title: t("globalSettings.includeTax"),
              checked: includeTax,
              setChecked: setTax,
              tooltip: t("includeTax.tooltip"),
            },
            ...(showVendorRevenueToggle
              ? [
                  {
                    title: t("globalSettings.vendorRevenueMetricDashboard"),
                    checked: vendorDefaultRevenueCheck,
                    setChecked: setVendorRevenue,
                    tooltip: t("vendorRevenueMetric.tooltip"),
                    customComponent: VendorRevenueMetricOption,
                  },
                ]
              : []),
            ...(showDarkModeToggle
              ? [
                  {
                    title: t("globalSettings.darkMode"),
                    checked: colorScheme === "dark",
                    setChecked: setDarkMode,
                    tooltip: t("darkMode.tooltip"),
                  },
                  {
                    title: t("globalSettings.oldTheme"),
                    checked: oldTheme,
                    setChecked: setToOldTheme,
                    tooltip: t("oldTheme.tooltip"),
                  },
                ]
              : []),
          ]}
        />
      </Menu>
    </>
  );
};

export default Settings;
