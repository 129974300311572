import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import Panel, { FooterLink } from "~/components/panel/panel";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  formatCurrency,
  formatCurrencyRounded,
  getCurrencyByCountryCode,
  intFormatterRounded,
} from "~/utils/currencyUtils";

import BannerWithLink from "~/components/alert/bannerWithLink";
import { Box } from "@material-ui/core";
import { ChangeCell } from "~/components/table/cells/changeCell";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PaginationArgs } from "~/typedef/pagination";
import PercentageAndGrowthCell from "~/components/table/cells/percentageAndGrowthCell";
import { Range } from "~/typedef/store";
import RetailAnalyticsByProductTableChart from "./retailAnalyticsTableChart";
import { ShowChart } from "@material-ui/icons";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useRetailAnalyticsByProductQuery } from "~/store/mystore/vendor.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

export const StyledTableCell = styled.td`
  max-width: 0px;
  overflow-x: visible;
  position: sticky;
  z-index: 3;
  left: 0px;
  padding-left: 16px;
`;

export const InlineBlockDiv = styled.div`
  display: inline-block;
`;

const PAGE_SIZE = 25;

interface RetailAnalyticsByProductProps {
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  sellerId?: string;
  countryCode: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: FooterLink;
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  searchText?: string;
  includeNoInventory: boolean;
  pageSize?: number;
  groupId?: string | null;
  timezone?: string;
  conditionalFormatting?: boolean;
  includeTax: boolean;
  isSourcingView: boolean;
}

interface FormattedRetailAnalyticRows {
  productId: string;
  title: {
    value: string;
    secondRowValue: string;
    link: string;
    image: string;
    target: string;
  };
  price: string;
  glanceViews: number;
  orderedRevenue: number;
  orderedUnits: number;
  unitsPerView: number;
  shippedCogs: number;
  shippedUnits: number;
  shippedCogsManufacturing: number;
  priorGlanceViews: number;
  priorOrderedRevenue: number;
  priorOrderedUnits: number;
  priorPrice: number;
  priorShippedCogs: number;
  priorShippedCogsManufacturing: number;
  priorShippedUnits: number;
  priorUnitsPerView: number;
  shippedUnitsManufacturing: number;
  priorShippedUnitsManufacturing: number;
}

const RetailAnalyticsByProductTable = memo<RetailAnalyticsByProductProps>(
  function RetailAnalyticsByProductTable({
    mid,
    marketplace,
    marketplaceSubtype,
    sellerId,
    countryCode,
    currentPeriod,
    currentRange,
    currentCurrency,
    footerLink,
    actions,
    searchText,
    includeNoInventory,
    report,
    pageSize,
    groupId,
    conditionalFormatting,
    includeTax,
    isSourcingView,
  }) {
    const { t } = useTranslation();
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const userInfo = useTypedSelector((state) => state.user);

    const { selectedTimezone } = useDateRangeFilters();

    const contentRef = React.useRef<HTMLHeadingElement>(null);
    const [myColumns, setMyColumns] = useState<Column[]>([] as any[]);
    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: pageSize ?? PAGE_SIZE,
      pageIndex: 0,
      sortKey: "glanceViews",
      sortOrder: "desc",
    });

    const {
      retailAnalyticsByProductData,
      retailAnalyticsByProductCount,
      retailAnalyticsByProductFetching,
    } = useRetailAnalyticsByProductQuery(
      {
        mid,
        marketplace,
        marketplaceSubtype,
        sellerId,
        countryCode,
        currentRange,
        searchText,
        includeNoInventory,
        includeTax,
        groupId,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          retailAnalyticsByProductData: data?.rows || [],
          retailAnalyticsByProductCount: data?.count || 0,
          retailAnalyticsByProductFetching: isFetching,
        }),
      }
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy?.[0]?.id || "glanceViews",
        sortOrder:
          sortBy && sortBy.length ? (sortBy[0].desc ? "desc" : "asc") : "desc",
        pageIndex,
        pageSize,
      });
    }, []);

    const data = useMemo<FormattedRetailAnalyticRows[]>(
      () =>
        retailAnalyticsByProductData
          ? retailAnalyticsByProductData.map((productSales) => {
              const { current, prior } = productSales;

              return {
                title: {
                  value: current.title,
                  secondRowValue: `ASIN: ${current.productSku} | VPI: ${current.sku}`,
                  link: current.linkUrl,
                  image: current.imageUrl,
                  target: "_blank",
                },
                price: current.retailPrice,
                productId: current.productId,
                glanceViews: current.glanceViews,
                orderedRevenue: current.orderedRevenue,
                orderedUnits: current.orderedUnits,
                unitsPerView: current.unitsPerView,
                shippedCogs: current.shippedCogs,
                shippedUnits: current.shippedUnits,
                shippedCogsManufacturing: current.shippedCogsManufacturing,
                priorGlanceViews: prior.priorGlanceViews,
                priorOrderedRevenue: prior.priorOrderedRevenue,
                priorOrderedUnits: prior.priorOrderedUnits,
                priorPrice: prior.priorPrice,
                priorShippedCogs: prior.priorShippedCogs,
                priorShippedCogsManufacturing:
                  prior.priorShippedCogsManufacturing,
                priorShippedUnits: prior.priorShippedUnits,
                priorUnitsPerView: prior.priorUnitsPerView,
                shippedUnitsManufacturing: current.shippedUnitsManufacturing,
                priorShippedUnitsManufacturing:
                  prior.priorShippedUnitsManufacturing,
              };
            })
          : [],
      [t, retailAnalyticsByProductData, currencyRates, currentCurrency]
    );

    //Create productrevenue report table columns
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const productRevenueColumns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.retailAnalyticsByProduct.productColumn"),
          id: "title",
          accessor: (row: FormattedRetailAnalyticRows) => ({
            value: row.title.value,
            secondRowValue: row.title.secondRowValue,
            image: row.title.image,
            link: row.title.link,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
              icon?: ReactChild;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          sticky: "left",
        },
        {
          Header: t("trafficAndConversion.actionsColumn"),
          accessor: () => {},
          id: "actions",
          Cell: (cell: ReactTableCell) => {
            const actions = [
              {
                icon: ShowChart,
                text: cell.row.isExpanded
                  ? t("generic.hide")
                  : t("generic.show"),
                action: () => {
                  const isExpanded = cell.row.isExpanded;
                  cell.toggleAllRowsExpanded(false);
                  const { onClick } = cell.row.getToggleRowExpandedProps();
                  if (!isExpanded) onClick();
                },
              },
            ];
            return <ActionCell actions={actions} />;
          },
          isVisible: true,
          disableSortBy: true,
        },
        {
          Header: t("customVendorGroups.glanceViewsColumn"),
          accessor: (row: {
            glanceViews: number;
            priorGlanceViews: number;
          }) => {
            return {
              value:
                row.glanceViews !== undefined
                  ? numberWithCommas(row.glanceViews.toFixed(0))
                  : "-",
              growth: row.priorGlanceViews
                ? getPercentageDifference(row.glanceViews, row.priorGlanceViews)
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "glanceViews",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.orderedRevenueColumn"),
          id: "orderedRevenue",
          accessor: (row: {
            orderedRevenue: number;
            priorOrderedRevenue: number;
          }) => {
            return {
              value:
                row.orderedRevenue !== undefined
                  ? formatCurrencyRounded(
                      row.orderedRevenue,
                      currencyRates,
                      homeCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorOrderedRevenue
                ? getPercentageDifference(
                    row.orderedRevenue,
                    row.priorOrderedRevenue
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          align: "right",
        },
        {
          Header: t("customVendorGroups.unitsColumn"),
          accessor: (row: {
            orderedUnits: number;
            priorOrderedUnits: number;
          }) => {
            return {
              value:
                row.orderedUnits !== undefined
                  ? numberWithCommas(row.orderedUnits.toFixed(0))
                  : "-",
              growth: row.priorOrderedUnits
                ? getPercentageDifference(
                    row.orderedUnits,
                    row.priorOrderedUnits
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "orderedUnits",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.unitsPerViewColumn"),
          accessor: (row: {
            unitsPerView: number;
            priorUnitsPerView: number;
          }) => {
            return {
              value:
                row.unitsPerView !== undefined
                  ? row.unitsPerView.toFixed(1)
                  : "-",
              growth: row.priorUnitsPerView
                ? (row.unitsPerView - row.priorUnitsPerView).toFixed(1)
                : "N/A",
            };
          },
          id: "unitsPerView",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.priceColumn"),
          accessor: (row: { price: number; priorPrice: number }) => {
            return {
              value: row.price
                ? formatCurrency(
                    row.price,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth:
                row.priorPrice !== undefined
                  ? getPercentageDifference(row.price, row.priorPrice)
                  : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "retailPrice",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
          divideRight: true,
        },
        {
          Header: t("customVendorGroups.shippedCogsColumn", {
            type: isSourcingView ? "(SRC)" : "(MFG)",
          }),
          wrapHeaderText: Boolean,
          accessor: (row: FormattedRetailAnalyticRows) => {
            const shippedCogs = isSourcingView
              ? row.shippedCogs
              : row.shippedCogsManufacturing;
            const priorShippedCogs = isSourcingView
              ? row.priorShippedCogs
              : row.priorShippedCogsManufacturing;
            return {
              value:
                shippedCogs === 0.0
                  ? "-"
                  : shippedCogs !== undefined
                  ? formatCurrencyRounded(
                      shippedCogs,
                      currencyRates,
                      homeCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: priorShippedCogs
                ? getPercentageDifference(shippedCogs, priorShippedCogs)
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "shippedCogs",
          Cell: ValueAndGrowthCell,
          align: "center",
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.deltaColumn"),
          id: "delta",
          disableSortBy: true,
          accessor: (row: {
            shippedCogs: number;
            priorShippedCogs: number;
            shippedCogsManufacturing: number;
            priorShippedCogsManufacturing: number;
          }) => {
            const shippedCogs = isSourcingView
              ? row.shippedCogs
              : row.shippedCogsManufacturing;
            const priorShippedCogs = isSourcingView
              ? row.priorShippedCogs
              : row.priorShippedCogsManufacturing;
            const delta = Math.abs(shippedCogs - priorShippedCogs);
            return {
              value:
                shippedCogs && priorShippedCogs
                  ? formatCurrencyRounded(
                      delta,
                      currencyRates,
                      homeCurrency,
                      currentCurrency
                    )
                  : "-",
              conditionalFormatting,
              positiveGrowth: shippedCogs > priorShippedCogs,
            };
          },
          align: "center",
          Cell: ChangeCell,
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.shippedUnitsColumn"),
          accessor: (row: FormattedRetailAnalyticRows) => {
            const toggledShippedUnits = isSourcingView
              ? row.shippedUnits
              : row.shippedUnitsManufacturing;
            const toggledPriorShippedUnits = isSourcingView
              ? row.priorShippedUnits
              : row.priorShippedUnitsManufacturing;
            return {
              value:
                toggledShippedUnits === 0.0
                  ? "-"
                  : toggledShippedUnits !== undefined
                  ? intFormatterRounded.format(toggledShippedUnits)
                  : "-",
              growth: toggledPriorShippedUnits
                ? getPercentageDifference(
                    toggledShippedUnits,
                    toggledPriorShippedUnits
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "shippedUnits",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "xl" : "sm",
          divideRight: true,
          isVisible: true,
        },
      ],
      [mid, currentRange, currentPeriod, currentCurrency, isSourcingView]
    );

    const renderChartComponent = ({ row }: any) => {
      const tableRenderedWidth = Number(contentRef.current?.clientWidth) - 20;
      return (
        // eslint-disable-next-line no-magic-numbers
        <tr>
          <StyledTableCell>
            <div
              style={{
                width: tableRenderedWidth,
              }}
            >
              <Box>
                <RetailAnalyticsByProductTableChart
                  mid={mid}
                  productId={row.original?.productId}
                  currentRange={currentRange}
                  currentPeriod={currentPeriod}
                  currentCurrency={currentCurrency}
                  timezone={selectedTimezone}
                />
              </Box>
            </div>
          </StyledTableCell>
        </tr>
      );
    };

    useEffect(() => {
      setMyColumns(productRevenueColumns);
    }, [productRevenueColumns]);

    return (
      <>
        {groupId && (
          <Box pb={2}>
            <BannerWithLink
              type="info"
              isOpen={true}
              message={t("generic.customGroupFilter")}
              link={marketplaceLink(marketplace, mid, "productrevenue")}
              linkText={t("generic.viewAllLink")}
            />
          </Box>
        )}
        <Panel
          id="widget-top-sellers"
          title={t(`retailAnalyticByProduct.mainTitle`)}
          tooltip={undefined}
          footerLink={footerLink}
          content={
            <>
              <InlineBlockDiv ref={contentRef} />
              <Table
                {...{
                  columns: myColumns,
                  data: data,
                  fetchData,
                  sorting: true,
                  pagination: true,
                  pageSize: pageSize ?? PAGE_SIZE,
                  pageCount: Math.ceil(
                    retailAnalyticsByProductCount / (pageSize ?? PAGE_SIZE)
                  ),
                  loading: retailAnalyticsByProductFetching,
                  renderRowSubComponent: renderChartComponent,
                }}
              />
            </>
          }
          actions={
            report ? undefined : (
              <>
                {actions}
                <Box pl={2}>
                  <ColumnSelect
                    {...{ columns: myColumns, setColumns: setMyColumns }}
                  />
                </Box>
              </>
            )
          }
        />
      </>
    );
  }
);

export default RetailAnalyticsByProductTable;
