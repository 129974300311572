import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { CurrentStore, Range } from "~/typedef/store";
import { Grid, Tooltip } from "@material-ui/core";
import ProductTable, {
  ProductEventRow,
} from "~/modules/profitLossProductTable/productTable";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  fetchProfitabilityCategories,
  useFetchProductProfitabilityQuery,
} from "~/store/mystore/profitability.redux";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";

import { AvailabilityCell } from "~/components/table/cells/availabilityCell";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import EditCell from "~/components/table/cells/editCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PERSISTENT_TABLE_IDS } from "~/utils/persistentTableIdsEnum";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import PrimeIcon from "../../../img/amazon_prime.png";
import ProductLinkCell from "~/modules/profitLossProductTable/productLinkCell";
import ProfitLossTable from "~/modules/profitLossTable/table";
import SearchFilter from "~/components/adTable/searchFilter";
import SidePanel from "~/components/drawerPanel/sidePanel";
import SmallButton from "~/components/buttons/smallButton";
import StatusText from "~/components/typography/status";
import { TFunction } from "react-i18next";
import { TableCellProp } from "~/components/table/cellProps";
import TemplateDialog from "~/modules/profitLossProductTable/templateDialog";
import { User } from "~/typedef/user";
import { ValueCell } from "~/components/table/cells/valueCell";
import get from "lodash/get";
import { getStatusFromProfit } from "~/modules/profitLossTable/categoryUtils";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { usePersistentTableColumns } from "~/utils/usePersistentTableColumns";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledButton = styled(SmallButton)`
  margin-right: 8px;
`;

const PAGE_SIZE = 25;

interface ProfitabilityProductProps {
  includeTax: boolean;
  currentCurrency: string;
  currentRange: Range;
  store: CurrentStore;
  exchangeRate: number;
  user: User;
  setSyncDialogOpen?: (open: boolean) => void;
  handleCogsEdit?: (product: ProductEventRow) => void;
  report?: boolean;
  pageSize?: number;
  missingCogs?: boolean;
  setMissingCogs?: (missingCogs: boolean) => void;
}

export type HandleListingSelectFunc = (listing: any) => void;

const DEFAULT_SORT_ORDER = "desc";
const DEFAULT_SORT_KEY = "totalSales";

const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;
export const generateColumns = (
  t: TFunction<"translation">,
  currencyFormatter: (value: number) => string,
  handleListingSelect: HandleListingSelectFunc,
  handleCogsEdit?: (product: ProductEventRow) => void,
  daysCoverLimit?: number,
  report?: boolean
) => [
  {
    Header: t(`profitability.column.product`),
    id: "title",
    accessor: (row: ProductEventRow) => ({
      value: row.title,
      secondRowValue: `ASIN: ${row.productSku} | SKU: ${row.sellerSku}`,
      image: row.imageUrl,
      link: row.linkUrl,
      target: "_blank",
      icon:
        row.fulfilledBy.toUpperCase() !== "MERCHANT" ? (
          <Tooltip title={t("generic.fulfilledByAmazon") as string}>
            <SmallIcon src={PrimeIcon} />
          </Tooltip>
        ) : null,
    }),
    Cell: (props: any) => (
      <LinkAndImageCell
        {...props}
        colorVariant="external"
        maxWidth={report ? "15rem" : "25rem"}
        expandable={!report}
      />
    ),
    isLocked: !report,
    isVisible: true,
    sticky: report ? "unset" : "left",
  },
  {
    Header: t("profitability.productSalesLabel"),
    id: "totalSales",
    accessor: ({ totalSales }: ProductEventRow) =>
      currencyFormatter(totalSales),
    align: "right",
    Cell: ValueCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.refundedSalesLabel"),
    id: "totalRefunds",
    accessor: ({ totalRefunds }: ProductEventRow) =>
      currencyFormatter(totalRefunds),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.reimbursementsLabel"),
    id: "totalReimbursements",
    accessor: ({ totalReimbursements }: ProductEventRow) =>
      currencyFormatter(totalReimbursements),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.promotionsLabel"),
    id: "totalPromotions",
    accessor: ({ totalPromotions }: ProductEventRow) =>
      currencyFormatter(totalPromotions),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.otherIncomeLabel"),
    id: "totalOtherIncome",
    accessor: ({ totalOtherIncome }: ProductEventRow) =>
      currencyFormatter(totalOtherIncome),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  ...(report
    ? []
    : [
        {
          Header: t("profitability.advertisingLabel"),
          id: "totalAdvertising",
          accessor: ({ totalAdvertising }: ProductEventRow) =>
            currencyFormatter(totalAdvertising),
          align: "right",
          Cell: ValueCell,
          isVisible: true,
          disableSortBy: true,
        },
      ]),
  {
    Header: t("profitability.sellingFeesLabel"),
    id: "totalSellingFees",
    accessor: ({ totalSellingFees }: ProductEventRow) =>
      currencyFormatter(totalSellingFees),
    align: "right",
    Cell: ValueCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.fulfilmentAndShippingLabel"),
    id: "totalShippingFees",
    accessor: ({ totalShippingFees }: ProductEventRow) =>
      currencyFormatter(totalShippingFees),
    align: "right",
    Cell: ValueCell,
    isVisible: true,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.refundsAndReturnsLabel"),
    id: "totalRefundsAndReturns",
    accessor: ({ totalRefundsAndReturns }: ProductEventRow) =>
      currencyFormatter(totalRefundsAndReturns),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.costOfGoodsLabel"),
    id: "totalCogs",
    accessor: ({ totalCogs }: ProductEventRow) => currencyFormatter(totalCogs),
    align: "right",
    Cell: (props: any) => {
      return (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            <ValueCell {...props} />
          </Grid>
          {handleCogsEdit && (
            <EditCell
              {...props}
              onEdit={({ value }) => handleCogsEdit(value)}
            />
          )}
        </Grid>
      );
    },
    isVisible: !report,
    sortDescFirst: true,
  },
  {
    Header: t("profitability.otherExpensesLabel"),
    id: "totalOtherExpenses",
    accessor: ({ totalOtherExpenses }: ProductEventRow) =>
      currencyFormatter(totalOtherExpenses),
    align: "right",
    Cell: ValueCell,
    sortDescFirst: true,
  },
  {
    Header: t(`profitability.profitLabel`),
    id: "totalProfit",
    accessor: (row: ProductEventRow) => {
      const totalProfit =
        row.totalRevenue - row.totalExpenses - row.totalAdvertising;
      const grossMargin = (100 * totalProfit) / row.totalRevenue;
      return {
        value: currencyFormatter(totalProfit),
        status: getStatusFromProfit(grossMargin),
      };
    },
    Cell: ({
      cell: {
        value: { value, status },
      },
    }: TableCellProp<{ value: string; status: string }>) => (
      <StatusText
        variant="body2"
        status={status}
        align="right"
        justify="flex-end"
      >
        {value === "-" ? value : `${value}`}
      </StatusText>
    ),
    align: "right",
    isVisible: true,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.availableQuantityLabel`),
    id: "availableQuantity",
    accessor: (row: ProductEventRow) => {
      return {
        availableValue: row.availableQuantity,
        compareValue: row.daysCover,
        threshold: daysCoverLimit,
      };
    },
    Cell: AvailabilityCell,
    align: "right",
    isVisible: !report,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.unitCostOfGoodsLabel`),
    id: "unitCogs",
    accessor: (row: ProductEventRow) => {
      const unitCogs = row.totalCogs / row.data.meta.unitCount;
      return currencyFormatter(unitCogs);
    },
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.inventoryValueLabel`),
    id: "inventoryValue",
    accessor: (row: ProductEventRow) => {
      const inventoryValue =
        (row.availableQuantity * row.totalCogs) / row.data.meta.unitCount;
      return currencyFormatter(inventoryValue);
    },
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.ordersLabel`),
    id: "orderCount",
    accessor: (row: ProductEventRow) => row.data.meta.orderCount,
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.unitsLabel`),
    id: "unitCount",
    accessor: (row: ProductEventRow) => row.data.meta.unitCount,
    Cell: ValueCell,
    align: "right",
    isVisible: report,
    disableSortBy: true,
  },
  {
    Header: t(`profitability.unitsRefundedLabel`),
    id: "refundedUnitCount",
    accessor: (row: ProductEventRow) => row.data.meta.refundedUnitCount,
    Cell: ValueCell,
    align: "right",
    isVisible: false,
    disableSortBy: true,
  },
  ...(report
    ? []
    : [
        {
          Header: t(`profitability.column.action`),
          id: "action",
          accessor: "productSku",
          Cell: ProductLinkCell,
          align: "right",
          getProps: () => ({ handleListingSelect }),
          isLocked: true,
          isVisible: true,
          disableSortBy: true,
        },
      ]),
];

const allHaveCogs = (rows: ProductEventRow[]) =>
  rows.every((row) => {
    const {
      data: { events },
    } = row;
    return (
      // a row should have either no Principal event, or have both Principal and COGS events
      !events.some((event) => event.label === "Principal") ||
      events.some((event) => event.label === "COGS")
    );
  });

const ProfitabilityProduct = memo<ProfitabilityProductProps>(
  function ProfitabilityProduct({
    includeTax,
    currentCurrency,
    currentRange,
    store,
    exchangeRate,
    user,
    setSyncDialogOpen,
    handleCogsEdit,
    report,
    pageSize,
    missingCogs,
    setMissingCogs,
  }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const marketplaceName = useMarketplace();
    const [selectedListing, setSelectedListing] = useState<any>();
    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const categoriesData = useTypedSelector((state) =>
      get(state.profitability, "categories.data")
    );

    const homeCurrency = getCurrencyByCountryCode[store.marketplaceCountry];

    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const handleListingSelect = useCallback(
      (listing) => {
        setSelectedListing(listing);
        setDrawerOpen(true);
      },
      [setDrawerOpen]
    );

    const currencyFormatter = (value: number) =>
      isNaN(value) || value === 0.0
        ? "-"
        : isEmpty(currencyRates)
        ? `$${intFormatter.format(value)}` // only used in storybook
        : formatCurrency(value, currencyRates, homeCurrency, currentCurrency);

    const daysCoverLimit = useTypedSelector(
      (state) => state.notifications.daysCoverLimits.data
    )?.find((d) => d.mid === store.merchantId)?.daysCoverLimit;

    const getInitialColumns = useCallback(
      () =>
        categoriesData
          ? generateColumns(
              t,
              currencyFormatter,
              handleListingSelect,
              handleCogsEdit,
              daysCoverLimit,
              report
            )
          : [],
      [
        categoriesData,
        t,
        currencyFormatter,
        handleListingSelect,
        handleCogsEdit,
        daysCoverLimit,
        report,
      ]
    );

    const {
      isFetching: isColumnsFetching,
      isUpdating: isColumnUpdating,
      myColumns,
      setMyColumns,
    } = usePersistentTableColumns({
      tableId: PERSISTENT_TABLE_IDS.PROFITABILITY_PRODUCT,
      initialColumns: getInitialColumns(),
      report: report ?? false,
    });

    useEffect(() => {
      dispatch(
        fetchProfitabilityCategories({
          mid: store.merchantId,
          includeTax,
        })
      );
    }, [store.merchantId, includeTax]);

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: pageSize ?? PAGE_SIZE,
      pageIndex: 0,
      sortOrder: DEFAULT_SORT_ORDER,
      sortKey: DEFAULT_SORT_KEY,
    });

    const { isLoading, data } = useFetchProductProfitabilityQuery(
      {
        mid: store.merchantId,
        includeTax,
        currentRange,
        searchText,
        ...paginationParams,
      },
      {
        selectFromResult: (result) => {
          return {
            isLoading: result.isFetching,
            data: result.data || {
              count: 0,
              rows: [],
            },
          };
        },
      }
    );

    useEffect(() => {
      const rows = data.rows;
      if (rows.length > 0 && setMissingCogs) {
        const newMissingCogs = !allHaveCogs(rows);
        if (missingCogs !== newMissingCogs) {
          setMissingCogs(newMissingCogs);
        }
      }
    }, [data]);

    const fetchProductData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        pageSize,
        pageIndex,
        sortOrder:
          sortBy.length > 0
            ? sortBy[0].desc
              ? "desc"
              : "asc"
            : DEFAULT_SORT_ORDER,
        sortKey: sortBy.length > 0 ? sortBy[0].id : DEFAULT_SORT_KEY,
      });
    }, []);
    return (
      <DrawerPanel
        open={drawerOpen}
        setOpen={setDrawerOpen}
        containerId={"drawer-container"}
        sidePanel={
          selectedListing ? (
            <SidePanel
              title={selectedListing.title}
              displayImage={true}
              image={selectedListing.imageUrl}
              content={
                <ProfitLossTable
                  countryCode={store.marketplaceCountry}
                  currentRange={currentRange}
                  currentCurrency={currentCurrency}
                  categories={categoriesData}
                  data={get(selectedListing, "data", [])}
                  isProductReport={true}
                  shopName={store.storeName}
                  marketplaceName={marketplaceName}
                  report={!!report}
                />
              }
              handleClose={() => setDrawerOpen(false)}
            />
          ) : (
            <Grid />
          )
        }
        mainPanel={
          <Panel
            id="product-profitability-panel"
            title={t("myStoresWidget.productProfit.mainTitle")}
            tooltip={t("myStoresWidget.productProfit.mainTooltip")}
            minOpenHeight="1100px"
            drawerOpen={drawerOpen}
            content={
              <>
                <TemplateDialog
                  mid={store.merchantId}
                  open={templateDialogOpen}
                  onClose={() => {
                    setTemplateDialogOpen(false);
                  }}
                />
                <ProductTable
                  store={store}
                  currency={currentCurrency}
                  categories={categoriesData}
                  loading={isLoading || isColumnsFetching}
                  data={data}
                  fetchData={fetchProductData}
                  pageSize={PAGE_SIZE}
                  columns={myColumns}
                  report={report}
                />
              </>
            }
            actions={
              !report ? (
                <>
                  <StyledButton
                    variant="outlined"
                    color="info"
                    onClick={() => setTemplateDialogOpen(true)}
                  >
                    {t("profitability.downloadButtonLabel")}
                  </StyledButton>
                  {user.isDemoMode && !hasFilteredSuffix(store.marketplace) ? (
                    <DemoTooltip
                      arrow
                      placement="top"
                      open
                      title={t("generic.notAvailableDemoMode")}
                    >
                      <span>
                        <StyledButton
                          variant="contained"
                          color="info"
                          disabled={true}
                        >
                          {t("profitability.uploadButtonLabel")}
                        </StyledButton>
                      </span>
                    </DemoTooltip>
                  ) : !hasFilteredSuffix(store.marketplace) ? (
                    setSyncDialogOpen && (
                      <StyledButton
                        variant="contained"
                        color="info"
                        onClick={() => setSyncDialogOpen(true)}
                      >
                        {t("profitability.uploadButtonLabel")}
                      </StyledButton>
                    )
                  ) : null}
                  <SearchFilter setSearchText={setSearchText} />
                  <ColumnSelect
                    columns={myColumns}
                    isColumnUpdating={isColumnUpdating}
                    setColumns={(columns) => {
                      setMyColumns(columns);
                    }}
                  />
                  <DownloadCsv
                    mid={store.merchantId}
                    reportType={"profitabilityProducts"}
                    path={"/api/generic/profitability/products"}
                    params={{
                      ...currentRange,
                      searchText,
                      includeTax,
                      shopName: store.storeName,
                      marketplaceName,
                      countryCode: store.marketplaceCountry,
                      currentCurrency,
                      exchangeRate,
                    }}
                  />
                </>
              ) : undefined
            }
          />
        }
      />
    );
  }
);

export default ProfitabilityProduct;
