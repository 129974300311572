import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "@store/utils/dateTimeUtils";
import React, { memo, useEffect } from "react";

import Bold from "@components/typography/bold";
import GenericTotal from "~/components/totals/genericTotal";
import LoadingIndicator from "@components/loadingIndicator/loadingIndicator";
import Panel from "@components/panel/panel";
import PieChart from "@components/charts/pieChart/pieChart";
import { ReactChild } from "react";
import { fetchStoreProfitabilitySplit } from "@store/mystore/profitability.redux";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface IPieChartData {
  name: string;
  value: number;
  color: string;
  type: string;
  tooltipContent?: string;
  legendContent?: ReactChild;
}

interface IProfitabilitySpltLegendItem {
  name: string;
  value: number;
  piePercentage?: number;
  type?: string;
}

const ProfitabilitySpltLegendItem: React.FC<IProfitabilitySpltLegendItem> = ({
  name,
  value,
  piePercentage,
  type,
}) => {
  const { t } = useTranslation();
  const piePercentageText = Number.isNaN(piePercentage)
    ? "N/A"
    : `${piePercentage}%`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      // eslint-disable-next-line no-magic-numbers
      minWidth={0}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        overflow="hidden"
      >
        <Tooltip title={t(`profit-split-tooltip-${type}`) || ""}>
          <Typography variant="body1" noWrap style={{ minWidth: 0 }}>
            {name}
          </Typography>
        </Tooltip>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box display="flex">
          <Bold variant="body2">{value}</Bold>
        </Box>
        <Box display="flex" ml={1}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 300 }}
          >
            ({piePercentageText})
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface ProfitabilitySplitProps {
  singleStore?: boolean;
  condensed?: boolean;
}

const ProfitabilitySplit = memo<ProfitabilitySplitProps>(
  function ProfitabilitySplit({ singleStore, condensed }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );
    const store = singleStore
      ? useTypedSelector((state) =>
          get(state, "persistentAppSettings.setting.data.currentStore")
        )
      : undefined;
    const theme = useTheme();

    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );
    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );
    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    const currentFilter = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter")
    );

    const chartData = useTypedSelector((state) =>
      get(state.profitability, "split.data", {})
    );
    const chartDataLoading = useTypedSelector((state) =>
      get(state.profitability, "split.fetching")
    );

    useEffect(() => {
      dispatch(
        fetchStoreProfitabilitySplit({
          includeTax,
          mid: store?.merchantId,
          currentRange,
          filter: currentFilter,
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, currentRange, store?.merchantId, includeTax]);

    const { profitable, breakeven, loss, total } = chartData;
    const pieChartData: IPieChartData[] = [
      {
        name: t("productProfitSplit.onProfit"),
        value: profitable,
        color: theme.palette.success.main,
        type: "onProfit",
      },
      {
        name: t("productProfitSplit.onBreakdown"),
        value: breakeven,
        color: theme.palette.warning.main,
        type: "onBreakdown",
      },
      {
        name: t("productProfitSplit.onLoss"),
        value: loss,
        color: theme.palette.error.main,
        type: "onLoss",
      },
    ];
    pieChartData.forEach((pieData: IPieChartData) => {
      const piePercentage = Math.round((pieData.value / total) * 100);
      pieData.tooltipContent = `${pieData.name}: ${pieData.value} (${piePercentage}%)`;
      pieData.legendContent = (
        <>
          <ProfitabilitySpltLegendItem
            name={pieData.name}
            value={pieData.value}
            piePercentage={piePercentage}
            type={pieData.type}
          />
        </>
      );
    });

    const footerLink = {
      url: marketplaceLink(
        store?.marketplace || "amazon",
        store?.merchantId || "",
        "profitabilityProduct"
      ),
      label: t("generic.viewAllLink"),
    };

    return (
      <Panel
        id="product-profitability-chart"
        title={t(
          store
            ? "myStoresWidget.productProfit.portfolioTitle"
            : "dashboard.storeProfit.portfolioTitle"
        )}
        tooltip={t(
          store
            ? "myStoresWidget.productProfit.portfolioTooltip"
            : "dashboard.storeProfit.portfolioTooltip"
        )}
        footerLink={condensed ? footerLink : undefined}
        content={
          chartDataLoading ? (
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <Grid container item xs={12} alignItems="center">
              <PieChart
                {...{
                  data: pieChartData,
                  legendHeader: (
                    <GenericTotal
                      number={chartData.total}
                      title={
                        store
                          ? t(`profitability.totalProducts`)
                          : t(`profitability.totalStores`)
                      }
                      condensed={true}
                    />
                  ),
                }}
              />
            </Grid>
          )
        }
      />
    );
  }
);

export default ProfitabilitySplit;
