import React, { memo } from "react";

import CurrencyDisplay from "./currencyDisplay";
import { MenuItem } from "@material-ui/core";
import ToolbarSelect from "../../select/toolbarSelect";
import { distinctCurrenciesByCountryCode } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CurrencyMenuItem = styled(MenuItem)`
  width: ${({ width }: { width?: number | null }) => width}px;
`;

interface CurrencyMenuProps {
  switchCurrency: (currency: string, isDemoMode?: boolean) => void;
  currentCurrency: string;
  maxWidth?: number;
  fullWidth?: boolean;
  disabled?: boolean;
}
const CurrencyMenu = memo<CurrencyMenuProps>(function CurrencyMenu({
  switchCurrency,
  currentCurrency,
  maxWidth,
  fullWidth,
  disabled,
}) {
  const currencies = distinctCurrenciesByCountryCode;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = React.useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const changeCurrency = (currencyCode: string) => {
    setAnchorEl(null);
    switchCurrency(currencyCode);
  };

  const renderOptions = () => {
    const currencyKeys = Object.keys(currencies) as (keyof typeof currencies)[];
    return currencyKeys.map((countryCode, index) => {
      const currencyCode = currencies[countryCode] as string;
      return (
        <CurrencyMenuItem key={index} width={menuWidth}>
          <CurrencyDisplay
            activeLink
            countryCode={countryCode}
            currencyCode={currencyCode}
            changeCurrency={changeCurrency}
          />
        </CurrencyMenuItem>
      );
    });
  };

  const currencyKeys = Object.keys(currencies) as (keyof typeof currencies)[];

  return (
    <ToolbarSelect
      id="currency-select"
      title={t("dashboard.selectCurrencyTitle")}
      tooltip={t("dashboard.selectCurrencyTooltip")}
      setMenuWidth={setMenuWidth}
      renderOptions={renderOptions}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      fullWidth={fullWidth}
      maxWidth={maxWidth || (fullWidth ? undefined : 150)}
      minWidth={95}
      alignItems="flex-end"
      disabled={disabled}
      displayComponent={
        <CurrencyDisplay
          activeLink={false}
          countryCode={
            currencyKeys.find((key) => currencies[key] === currentCurrency) ||
            ""
          }
          currencyCode={currentCurrency}
          changeCurrency={switchCurrency}
        />
      }
    />
  );
});

export default CurrencyMenu;
