import { Grid, GridSize } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { get, isEmpty } from "lodash";
import {
  hasAnyAdvertisingStores,
  hasAnyAmazonStores,
  hasAnyAmazonVendorStores,
  hasAnyWalmartStores,
  hasDemoStore,
  hasOnlyDemoStores,
  hasOnlyVendorStores,
} from "~/utils/marketplaceUtils";

import AccountHealthSummary from "~/modules/widgets/overview/accountHealthSummary";
import AverageSalesByDay from "~/modules/widgets/overview/averageSalesByDay";
import ConnectionProgress from "~/components/connectionProgress/connectionProgress";
import CrossChannelProductPerformance from "~/modules/widgets/overview/crossChannelProductPerformance/crossChannelProductPerformance";
import DemoStoreAlert from "~/components/alert/demoStoreAlert";
import DemoUserAlert from "~/components/alert/demoUserAlert";
import DisconnectedStoresList from "~/modules/overview/disconnectedStoresList";
import DispatchStatus from "~/modules/widgets/overview/dispatchStatus";
import InfoAlert from "~/components/alert/infoAlert";
import InventorySummary from "~/pages/multiChannel/inventorySummary";
import MarketingCampaignSummary from "~/pages/multiChannel/marketingCampaignSummary";
import MarketplaceOverview from "~/pages/multiChannel/marketplaceOverview";
import OverdueOrderSummary from "~/modules/widgets/overview/overdueOrderSummary";
import OverviewChartTotalWrapper from "~/modules/marketing/overviewChartTotalWrapper";
import { OverviewToolbar } from "~/components/toolbars/overviewToolbar/overviewToolbar";
import PageBlock from "~/components/containers/pageBlock";
import ProfitAndLossStatement from "./profitAndLossStatement";
import ProfitabilitySummary from "~/pages/multiChannel/profitabilitySummary";
import PurchaseOrdersPerformance from "~/modules/widgets/amazonVendor/purchaseOrdersPerformance";
import ReportingCommentsDrawer from "~/modules/reportingCommentsDrawer";
import SalesByDay from "~/modules/widgets/salesByDay";
import SalesMixPieChart from "~/modules/widgets/overview/salesMixPieChart";
import SalesPerformance from "~/modules/overview/salesPerformance";
import ShippedRevenueByStores from "./shippedRevenueByStores";
import ShippedRevenueTrendWithTotals from "~/modules/vendor/shippedRevenueTrendWithTotals";
import { StoreState } from "~/typedef/store";
import TrackVisibility from "react-on-screen";
import VendorOverview from "~/pages/multiChannel/vendorOverview";
import { fetchConnectionStatus } from "../../store/connections/connectionStatus.redux";
import { fetchCustomLayoutConfig } from "~/store/customLayout.redux";
import { fetchMinDate } from "../../store/overview/minDate.redux";
import { getFriendlyShortUserName } from "~/store/utils/displayUtils";
import moment from "moment-timezone";
import { useAdvertisingOnly } from "~/hooks/useAdvertisingOnly";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Dashboard = memo(function Dashboard() {
  const { t } = useTranslation();
  const location: any = useLocation();
  const user = useTypedSelector((state) => state.user);
  const currentFilter = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentFilter")
  );
  const connections = useTypedSelector(
    (state) => state.connections.connectionStatus
  );
  const [disconnected, setDisconnected] = useState<any[] | null>(null);
  const [showDisconnected, setShowDisconnected] = useState(true);
  const [showingDemoData, setShowingDemoData] = useState(false);

  const filteredStoresFetching = useTypedSelector(
    (state) => state.mystore.filteredStores.fetching
  );
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );
  const disconnectedStores = useTypedSelector(
    (state) => state.connections?.disconnectedStores?.stores || []
  );
  const allStores = useTypedSelector(
    (state) => state.mystore?.allStores?.stores || []
  );
  const allStoresFetching = useTypedSelector(
    (state) => state.mystore?.allStores?.fetching || false
  );

  const hasAmazonStores = hasAnyAmazonStores(filteredStores);
  const hasWalmartStores = hasAnyWalmartStores(filteredStores);
  const hasAmazonVendorStores = hasAnyAmazonVendorStores(filteredStores);
  const hasAdvertisingStores = hasAnyAdvertisingStores(filteredStores);
  const hasAmazonVendorStoresOnly = hasOnlyVendorStores(filteredStores);
  const isAdvertisingOnly = useAdvertisingOnly();
  const customWidgetNames = useTypedSelector(
    (state) => state.customLayout.layoutConfig?.dashboard?.widgets
  );

  const customLayoutEnabled = Boolean(
    useTypedSelector((state) => state.customLayout?.layoutConfig?.enabled)
  );

  /* Hack to work around the fact that React useEffect hooks don't interact
   * nicely with error boundaries yet */
  const [, setError] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const dispatchFetchMinDate = () =>
      dispatch(fetchMinDate(user, "", currentFilter));
    dispatchFetchMinDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);

  useEffect(() => {
    const dispatchFetchConnectionStatus = (userInfo: StoreState["user"]) =>
      dispatch(fetchConnectionStatus({ user: userInfo }));
    const fetchData = async () => {
      dispatchFetchConnectionStatus(user);
    };
    if (!connections.fetching) {
      const fetchConnections = () => {
        fetchData().catch((err) =>
          setError(() => {
            throw err;
          })
        );
      };
      fetchConnections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  useEffect(() => {
    const primaryDisconnectedStores = disconnectedStores.filter((s) =>
      s.credentials.some((c) => c.type === "primary")
    );
    if (primaryDisconnectedStores.length > 0) {
      setDisconnected(primaryDisconnectedStores);
    }
  }, [disconnectedStores]);

  useEffect(() => {
    const isShowingDemoData = hasDemoStore(filteredStores);
    setShowingDemoData(isShowingDemoData);
  }, [filteredStores]);

  // Used on various widgets to decide if they need to hide drilldowns
  useEffect(() => {
    dispatch(fetchCustomLayoutConfig());
  }, [user._id]);

  const getGreetingTime = () => {
    const currentTime = moment();
    if (!currentTime || !currentTime.isValid()) {
      return "Hello";
    }

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = parseFloat(currentTime.format("HH"));

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return t("greeting.goodAfternoon");
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return t("greeting.goodEvening");
    }
    // Between dawn and noon
    return t("greeting.goodMorning");
  };

  const getCurrentPageGreeting = () => {
    const greeting = getGreetingTime();
    const personalisation = getFriendlyShortUserName(user);
    return greeting + personalisation + t("greeting.greetingMessage");
  };

  const hideDisconnected = () => setShowDisconnected(false);

  const profitabilityWidgets = [
    {
      id: "profitSummary",
      Component: ProfitAndLossStatement,
      props: { showComparison: true, condensed: true },
      xs: 12,
      md: 6,
    },
    {
      id: "profitSummaryByStore",
      Component: ProfitabilitySummary,
      props: {},
      xs: 12,
      md: 6,
    },
  ];

  const amazonVendorWidgets = [
    {
      id: "purchaseOrdersPerformance",
      Component: PurchaseOrdersPerformance,
      props: {
        market: "overview",
        store: {},
      },
      xs: 12,
      md: 6,
    },
    {
      id: "vendorOverview",
      Component: VendorOverview,
      props: {},
      xs: 12,
      md: 6,
    },
    {
      id: "shippedRevenueTrend",
      Component: ShippedRevenueTrendWithTotals,
      props: {},
      xs: 12,
      md: 6,
    },
    {
      id: "shippedRevenueByStoresTable",
      Component: ShippedRevenueByStores,
      props: {},
      xs: 12,
      md: 6,
    },
  ];

  const advertisingWidgets = [
    {
      id: "advertisingTotals",
      Component: OverviewChartTotalWrapper,
      props: { showTotals: true },
      xs: 12,
      md: 6,
    },
    {
      id: "advertisingSummary",
      Component: MarketingCampaignSummary,
      props: {},
      xs: 12,
      md: 6,
    },
  ];

  const nonAmazonVendorWidgets = [
    {
      id: "inventorySummary",
      Component: InventorySummary,
      props: {},
      xs: 12,
      md: 8,
    },
    {
      id: "healthSummary",
      Component: AccountHealthSummary,
      props: {},
      xs: 12,
      md: 4,
    },
  ];

  const allWidgets = [
    {
      id: "salesPerformance",
      Component: SalesPerformance,
      props: {
        market: "overview",
        store: {},
      },
      xs: 12,
      md: 8,
    },
    {
      id: "salesMix",
      Component: SalesMixPieChart,
      props: {},
      xs: 12,
      md: 4,
    },
    {
      id: "marketOverview",
      Component: MarketplaceOverview,
      props: {},
      xs: 12,
      md: 8,
    },
    {
      id: "salesByDay",
      Component: SalesByDay,
      props: {},
      xs: 12,
      md: 4,
    },
    ...(hasAmazonStores || hasWalmartStores ? profitabilityWidgets : []),
    ...(hasAmazonVendorStores ? amazonVendorWidgets : []),
    ...(hasAdvertisingStores ? advertisingWidgets : []),
    ...(hasAmazonVendorStoresOnly ? [] : nonAmazonVendorWidgets),
    {
      id: "topProducts",
      Component: CrossChannelProductPerformance,
      props: {
        market: "overview",
      },
      xs: 12,
      md: 8,
    },
    {
      id: "dispatchStatus",
      Component: DispatchStatus,
      props: {},
      xs: 12,
      md: 4,
    },
    {
      id: "heatmap",
      Component: AverageSalesByDay,
      props: {},
      xs: 12,
      md: 8,
    },
    {
      id: "overdueOrderSummary",
      Component: OverdueOrderSummary,
      props: {},
      xs: 12,
      md: 4,
    },
  ];

  const customWidgets =
    customLayoutEnabled && customWidgetNames?.length
      ? (
          customWidgetNames
            .map((widgetName) => ({
              ...allWidgets.find((w) => w.id === widgetName),
            })) // destructure to copy
            .filter((w) => w !== undefined) as NonNullable<typeof allWidgets>
        ).filter((w) => w.Component !== undefined)
      : [];

  // logic to fill gaps in dashboard
  for (let i = 0; i < customWidgets.length; ) {
    if (
      customWidgets[i]?.md === 8 &&
      (customWidgets[i + 1]?.md === 8 || !customWidgets[i + 1]?.md)
    ) {
      // two large widgets in a row (8 + 8) or last widget with no second widget:
      // expand first widget to fill whole row
      customWidgets[i].md = 12;
      i = i + 1;
    } else if (customWidgets[i]?.md === 4 && customWidgets[i + 1]?.md === 4) {
      // two small widgets in a row (4 + 4): expand both
      customWidgets[i].md = 6;
      customWidgets[i + 1].md = 6;
      i = i + 2;
    } else {
      // 8 + 4 or 4 + 8: no action
      i = i + 2;
    }
  }

  const widgets = customWidgets.length
    ? customWidgets
    : isAdvertisingOnly
    ? advertisingWidgets
    : allWidgets;

  const renderWidgets = () =>
    widgets.map((widget, i) => {
      const Component = widget.Component;
      return (
        <Grid
          key={i}
          item
          xs={widget.xs as GridSize}
          md={widget.md as GridSize}
        >
          <TrackVisibility
            once
            partialVisibility
            offset={300}
            style={{ height: "100%" }}
          >
            {({ isVisible }) =>
              isVisible && <Component {...(widget.props as any)} />
            }
          </TrackVisibility>
        </Grid>
      );
    });

  if (
    (allStores?.length &&
      !allStoresFetching &&
      !user?.isDemoMode &&
      hasOnlyDemoStores(allStores) &&
      !location.pathname.includes("subscription")) ||
    (!isEmpty(user) &&
      !user?.isDemoMode &&
      location.state?.from === "registrationPage")
  ) {
    return <Redirect to="/connect" />;
  }

  const hasResults = !isEmpty(filteredStores);

  return (
    <PageBlock id="dashboard-screen">
      <OverviewToolbar
        selectedItem={{
          currentPage: getCurrentPageGreeting(),
          breadcrumb: [{ text: t("nav.mainPage") }],
        }}
      />
      {connections &&
        connections.connections &&
        !isEmpty(connections.connections) &&
        connections.connections.map((connection, i) => (
          <ConnectionProgress
            {...{
              key: i,
              user: user,
              filteredStores,
              connection: connection,
              percentage: get(connection, "percentage") || 0,
            }}
          />
        ))}
      {showingDemoData && !user.isDemoMode && <DemoStoreAlert />}
      {user.isDemoMode && <DemoUserAlert />}
      {showDisconnected && disconnected?.length && (
        <DisconnectedStoresList
          disconnected={disconnected}
          hideDisconnected={hideDisconnected}
        />
      )}
      {hasResults ? (
        <Grid container spacing={2} alignItems="stretch">
          {renderWidgets()}
        </Grid>
      ) : !filteredStoresFetching ? (
        <InfoAlert message="filteredStores.noResults" />
      ) : null}
      {user.reportsOn && <ReportingCommentsDrawer isMulti />}
    </PageBlock>
  );
});

export default Dashboard;
