import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export interface AdAccount {
  id: string;
  name: string;
  currency: string;
  business_name: string;
  account_status: number;
}

interface ValidateMetaAdvertisingRequest {
  authCode: string;
}

interface ValidateMetaAdvertisingResponse {
  errMsg?: string;
  code?: number;
  adAccounts: AdAccount[] | null;
}

interface UpdateMetaAdAccountRequest {
  adAccountId: AdAccount["id"];
  countryCode: string;
}

interface UpdateMetaAdAccountResponse {
  errMsg?: string;
  code?: number;
  store?: {
    marketplaceType: string;
    marketplaceSubtype: string;
    mid: string;
    countryCode: string;
    shopName: string;
  };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    validateMetaAdvertisingAuth: build.mutation<
      ValidateMetaAdvertisingResponse,
      ValidateMetaAdvertisingRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/meta/advertising/connect`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("Meta Advertising connect", true),
    }),
    updateMetaAdAccount: build.mutation<
      UpdateMetaAdAccountResponse,
      UpdateMetaAdAccountRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/meta/advertising/connect/profile`,
          method: "POST",
          data: params,
        };
      },
    }),
  }),
});

export const {
  useValidateMetaAdvertisingAuthMutation,
  useUpdateMetaAdAccountMutation,
} = extendedApiSlice;
