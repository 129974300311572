import React, { ReactElement } from "react";

import AlertCard from "./alertCard";
import { AlertIcon } from "./messageAlert";
import Bold from "../typography/bold";
import { Box } from "@material-ui/core";
import Medium from "../typography/medium";
import { PriorityHigh } from "@material-ui/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const IconWrapper = styled(AlertIcon)<{ type: "info" | "error" }>`
  background-color: ${({ theme, type }) =>
    type === "error" ? theme.palette.error.light : theme.palette.info.light};
`;
const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface InfoAlertProps {
  message: string;
  actions?: ReactElement;
  onClose?: () => void;
  isOpen?: boolean;
  typographyWeight?: "bold" | "medium";
  type?: "info" | "error";
}

const InfoAlert: React.FC<InfoAlertProps> = ({
  message,
  actions,
  typographyWeight = "bold",
  onClose,
  isOpen = true,
  type = "info",
}) => {
  const { t } = useTranslation();

  return (
    <AlertCard isOpen={isOpen} type={type} onClose={onClose}>
      <IconWrapper type={type}>
        <PriorityHigh fontSize="small" />
      </IconWrapper>
      <Wrapper>
        {typographyWeight === "bold" ? (
          <Bold variant="body1">{t(message)}</Bold>
        ) : (
          <Medium variant="body1">{t(message)}</Medium>
        )}
        {actions}
      </Wrapper>
    </AlertCard>
  );
};

export default InfoAlert;
