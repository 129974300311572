import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Filter, Store } from "~/typedef/store";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchOverviewProfitability,
  fetchProfitabilityCategories,
} from "~/store/mystore/profitability.redux";

import { Column } from "~/components/adTable/columnSelect";
import { LinkCell } from "~/components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import NoScrollTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import ProfitCell from "../../../components/table/cells/profitCell";
import { Range } from "~/typedef/store";
import Table from "~/components/table/table";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "../../../components/table/cells/valueCell";
import { formatData } from "./profitabilityUtils";
import { get } from "lodash";
import { getCurrencySymbol } from "~/utils/currencyUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DISPLAY_TYPE = {
  percentage: true,
  sales: false,
};

function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

interface ProfitabilityProps {
  pageSize: number;
  report?: boolean;
  currentCurrency: string;
  userInfo: User;
  currentRange: Range;
  includeTax: boolean;
  currentFilter?: Filter;
  filteredStores: Store[];
  conditionalFormatting?: boolean;
  condensed?: boolean;
}

const ProfitabilitySummary = memo<ProfitabilityProps>(
  function ProfitabilitySummary({
    pageSize,
    report,
    currentCurrency,
    userInfo,
    currentRange,
    includeTax,
    currentFilter,
    filteredStores,
    conditionalFormatting,
    condensed,
  }) {
    const { t } = useTranslation();
    const CONDENSED_ROWS = 5;
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const params = useTypedSelector(
      (state) => get(state, "profitability.overview.params") || {}
    );
    const profitability = useTypedSelector(
      (state) => get(state, "profitability.overview.data") || []
    );

    const containsComparisons = useTypedSelector(
      (state) =>
        get(state, "profitability.overview.containsComparisons") || false
    );

    const profitabilityCount = useTypedSelector(
      (state) => get(state, "profitability.overview.count") || 0
    );
    const loadingData = useTypedSelector((state) =>
      get(state, "profitability.overview.fetching")
    );

    const categoriesData = useTypedSelector(
      (state) => get(state.profitability, "categories.data") || []
    );
    const loadingCategories = useTypedSelector((state) =>
      get(state, "categories.fetching")
    );
    const allowedLinks = useTypedSelector((state) =>
      get(state, "customLayout.layoutConfig.sideNav.pages")
    );

    const theme = useTheme();
    const onMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const [displayType, setDisplayType] = useState(DISPLAY_TYPE.sales);

    const dispatch = useDispatch();

    const columns: Column[] = useMemo(
      () => [
        {
          id: "market",
          Header: t("dashboardWidget.marketplaceColumn"),
          accessor: (row: any) => ({
            market: row.market,
            countryCode: row.countryCode,
          }),
          Cell: MarketplaceAndCountryCell,
        },
        {
          id: "shopName",
          Header: t("dashboardWidget.storeColumn"),
          accessor: "storeName",
          Cell: LinkCell,
        },
        {
          Header: t("dashboardWidget.salesColumn"),
          id: "totalSales",
          accessor: "totalSales",
          align: "right",
          Cell: ValueAndGrowthCell,
          hiddenDown: "md",
        },
        {
          Header: t("dashboardWidget.sellingFeesColumn"),
          id: "totalSellingFees",
          accessor:
            getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
              ? "percentageSellingFees"
              : "totalSellingFees",
          align: "right",
          Cell:
            getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
              ? ValueCell
              : ValueAndGrowthCell,
          hiddenDown: report ? "xl" : "md",
        },
        {
          Header: t("dashboardWidget.cogsColumn"),
          id: "totalCogs",
          accessor:
            getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
              ? "percentageCogs"
              : "totalCogs",
          align: "right",
          Cell:
            getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
              ? ValueCell
              : ValueAndGrowthCell,
          isVisible: true,
          sortDescFirst: true,
          hiddenDown: "md",
        },
        {
          Header: t("dashboardWidget.profitColumn"),
          id: "totalProfit",
          accessor:
            getKeyByValue(DISPLAY_TYPE, displayType) === "percentage"
              ? "percentageProfit"
              : "totalProfit",
          align: "right",
          Cell: ProfitCell,
        },
      ],
      [displayType, t]
    );

    const fetchData = useCallback(
      ({ pageSize, pageIndex }) => {
        dispatch(
          fetchOverviewProfitability(
            {
              userId: userInfo._id,
              includeTax,
              currentRange,
              filteredStores,
              filter: currentFilter,
              pageSize,
              pageIndex,
            },
            params
          )
        );
      },
      [
        filteredStores,
        currentFilter,
        currentRange,
        dispatch,
        userInfo._id,
        includeTax,
        params,
      ]
    );

    const fetchCategories = useCallback(
      () => {
        dispatch(
          fetchProfitabilityCategories({
            mid: undefined,
            includeTax,
          })
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [includeTax]
    );

    useEffect(() => {
      fetchCategories();
      fetchData({ pageSize: pageSize, pageIndex: 0 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo._id, filteredStores]);

    useEffect(() => {
      if (!containsComparisons) {
        fetchData({ pageSize: pageSize, pageIndex: 0 });
      }
    }, [filteredStores]);

    const data = useMemo(
      () =>
        formatData({
          profitability,
          categoriesData,
          filteredStores,
          currencyRates,
          currentCurrency,
          allowedLinks,
          conditionalFormatting,
        }),
      [
        profitability,
        containsComparisons,
        categoriesData,
        filteredStores,
        currencyRates,
        currentCurrency,
        allowedLinks,
      ]
    );

    return (
      <Panel
        id="widget-profitability-summary"
        title={t("profitSummary.title")}
        tooltip={t("profitSummary.tooltip")}
        footerLink={
          report
            ? undefined
            : {
                url: "/overview/profitOverview",
                label: t("generic.viewAllLink"),
              }
        }
        actions={
          report ? undefined : (
            <>
              <Box mr="10px">
                <Typography variant="subtitle2" color="textSecondary">
                  {t("profitSummary.subtitle")}
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={displayType}
                    onChange={() => setDisplayType(!displayType)}
                  />
                }
                label={t("dashboardWidget.averageSales.showLabel", {
                  label: displayType
                    ? getCurrencySymbol[
                        currentCurrency as keyof typeof getCurrencySymbol
                      ]
                    : "%",
                })}
              />
            </>
          )
        }
        content={
          condensed ? (
            <NoScrollTable
              {...{
                columns: columns,
                data: report ? data : data.slice(0, CONDENSED_ROWS),
                pageSize,
                loading: loadingData || loadingCategories,
                isReport: report,
              }}
            />
          ) : (
            <Box>
              <Table
                {...{
                  columns,
                  data,
                  fetchData,
                  loading: loadingData || loadingCategories,
                  pageSize,
                  pagination: !report,
                  pageCount: profitability
                    ? Math.ceil(profitabilityCount / pageSize)
                    : 1,
                  gridLayoutColumns: onMobile ? 12 : 8,
                }}
              />
            </Box>
          )
        }
      />
    );
  }
);

export default ProfitabilitySummary;
