import {
  CCPPSummaryRecord,
  useCcppSummaryQuery,
} from "~/store/overview/crossChannelPerformance.redux";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import InfoAlert from "~/components/alert/infoAlert";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { MAX_STORES_WARNING } from "./crossChannelProductPerformanceExpanded";
import Panel from "~/components/panel/panel";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { formatCurrency } from "~/utils/currencyUtils";
import get from "lodash/get";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CrossChannelProductPerformance = memo(
  function CrossChannelProductPerformance() {
    const { t } = useTranslation();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );

    const { currentRange } = useDateRangeFilters();

    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );

    const filteredStores = useTypedSelector((state) =>
      get(state, "mystore.filteredStores.stores", [])
    );

    const widgetDisabled = filteredStores.length > MAX_STORES_WARNING;

    const [queryParameters, setQueryParameters] = useState({
      pageIndex: 0,
      pageSize: 5,
      sortKey: "totalSales",
      sortOrder: "desc",
      searchText: "",
    });

    const { products, totalCount, isLoading } = useCcppSummaryQuery(
      {
        queryParameters,
        currentRange,
        currentFilter,
        includeTax,
      },
      {
        skip: widgetDisabled,
        selectFromResult: ({ data, isFetching }) => {
          return {
            products: data?.products || [],
            totalCount: data?.count || 0,
            isLoading: isFetching,
          };
        },
      }
    );

    const columns = useMemo(
      () => [
        {
          Header: t("crossChannelProduct.product"),
          id: "title",
          accessor: (row: CCPPSummaryRecord) => ({
            value: row.title,
            secondRowValue: `SKU: ${row.sellerSku}`,
            image: row.imageUrl,
            link: row.linkUrl,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          sticky: "left",
          customWidth: 250,
        },
        {
          Header: t("crossChannelProduct.sales"),
          id: "totalSales",
          accessor: (row: CCPPSummaryRecord) => {
            return {
              value: formatCurrency(
                row.totalSales,
                currencyRates,
                "USD",
                currentCurrency
              ),
            };
          },
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("crossChannelProduct.quantity"),
          id: "totalQuantity",
          accessor: (row: CCPPSummaryRecord) => {
            return row.totalQuantity.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
          isVisible: true,
          align: "right",
        },
        {
          Header: t("crossChannelProduct.inventory"),
          id: "inventory",
          accessor: (row: CCPPSummaryRecord) => {
            return row.inventory.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
          isVisible: true,
          align: "right",
        },
      ],
      [currencyRates, currentCurrency, t]
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setQueryParameters({
        pageIndex,
        pageSize,
        sortKey: sortBy[0]?.id || "totalSales",
        sortOrder: sortBy[0]?.asc ? "asc" : "desc",
        searchText: "",
      });
    }, []);

    return (
      <Panel
        id="cross-channel-product-performance"
        title={t("crossChannelProduct.widgetTitle")}
        subtitle={t("crossChannelProduct.tableSubtitle")}
        footerLink={{
          url: "/overview/crossChannelProductPerformance",
          label: t("generic.viewAllLink"),
        }}
        badge={t("generic.new")}
        content={
          widgetDisabled ? (
            <Box p={1}>
              <InfoAlert
                message={t("crossChannelProduct.numberOfStoresWarning")}
              />
            </Box>
          ) : (
            <Table
              columns={columns}
              disableScroll
              data={products}
              loading={isLoading}
              fetchData={fetchData}
              pagination
              pageCount={Math.ceil(
                (totalCount || 0) / queryParameters.pageSize
              )}
              pageSize={queryParameters.pageSize}
            />
          )
        }
      />
    );
  }
);

export default CrossChannelProductPerformance;
