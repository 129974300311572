import { Box, FormControlLabel } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useMemo, useState } from "react";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";

import { CurrentStore } from "~/typedef/store";
import DownloadCsv from "../reportDownload/downloadCsv";
import SalesPerformanceLineChart from "../widgets/salesPerformanceLineChart";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import { getExchangeRate } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

export const CHART_TYPE = {
  Sales: "sales",
  Orders: "orders",
  AvgOrderValue: "avgOrderValue",
  UnitsSold: "unitsSold",
  OrderItems: "orderItems",
};

export const DISPLAY_CHART = {
  Bar: "Bar chart",
  Line: "Line chart",
};

export const FOREACST_TOGGLE = {
  Forecast: "Forecast",
  Budget: "Budget",
};

interface SalesPerformanceProps {
  market: string;
  store?: CurrentStore;
}

interface ChartOption {
  value: string;
  label: string;
}

const SalesPerformance = memo<SalesPerformanceProps>(function SalesPerformance({
  market,
  store,
}) {
  const { t } = useTranslation();
  const overview = Boolean(market === "overview");
  const userInfo = useTypedSelector((state) => state.user);
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const vendorRevenueType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
      "orderedRevenue"
  );
  const isVendorStore = useVendorStore(store?.merchantId);

  const CHART_OPTIONS: ChartOption[] = useMemo(
    () =>
      [
        {
          value: CHART_TYPE.Sales,
          label: t("dashboardWidget.salesPerformance.salesOption"),
        },
        {
          value: CHART_TYPE.Orders,
          label: t("dashboardWidget.salesPerformance.ordersOption"),
        },
        {
          value: CHART_TYPE.AvgOrderValue,
          label: t("dashboardWidget.salesPerformance.avgOrderValueOption"),
        },
        {
          value: CHART_TYPE.UnitsSold,
          label: t("dashboardWidget.salesPerformance.unitsSoldOption"),
        },
        {
          value: CHART_TYPE.OrderItems,
          label: t("dashboardWidget.salesPerformance.orderItemsOption"),
        },
      ].filter((o) =>
        isVendorStore
          ? [CHART_TYPE.Sales, CHART_TYPE.UnitsSold].includes(o.value)
          : true
      ),
    [isVendorStore]
  );

  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
  const [lineChart, setLineChart] = useState<boolean>(false);
  const [forecast, setForecast] = useState<boolean>(false);
  const footerLink =
    !overview && store?.merchantId
      ? {
          url: marketplaceLink(market, store.merchantId, "salesbycategory"),
          label: t("generic.showFullReportLink"),
        }
      : undefined;

  const switchType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  const chartToggle: SettingsMenuItem = {
    title: t("salesPerformance.chartToggle"),
    checked: lineChart,
    setChecked: (newValue: boolean) => setLineChart(newValue),
    tooltip: t("includeTax.tooltip"),
  };

  const forecastToggle: SettingsMenuItem = {
    title: t("salesPerformance.forecastingToggle"),
    checked: forecast,
    setChecked: (newValue: boolean) => setForecast(newValue),
    tooltip: t("includeTax.forecastingTooltip"),
  };

  const currencyRates = useTypedSelector((state) =>
    get(state, "globalVar.currencyRates")
  );

  const exchangeRate = getExchangeRate(
    currencyRates,
    // In case of multi-channel, generic returns the data in AUD (DESTINATION_CURRENCY)
    store?.marketplaceCountry
      ? getCurrencyByCountryCode[store.marketplaceCountry]
      : "AUD",
    currentCurrency
  );

  const actions = (
    <>
      <TableFilter
        {...{
          width: "165px",
          options: CHART_OPTIONS,
          currentValue: chartType.label,
          handleChange: switchType,
        }}
      />
      <Box pl="12px">
        <FormControlLabel
          control={includeTaxSwitch(chartToggle)}
          label={chartToggle.checked ? DISPLAY_CHART.Line : DISPLAY_CHART.Bar}
        />
      </Box>
      {store?.merchantId && chartType?.value === "sales" && (
        <Box pl="12px">
          <FormControlLabel
            control={includeTaxSwitch(forecastToggle)}
            label={
              forecastToggle.checked
                ? FOREACST_TOGGLE.Forecast
                : FOREACST_TOGGLE.Budget
            }
          />
        </Box>
      )}
      <DownloadCsv
        reportType="salesPerformance"
        path="/api/generic/salesPerformance"
        mid={store?.merchantId || "all"}
        params={{
          mid: store?.merchantId || "all",
          filter: currentFilter,
          currentRange,
          includeTax,
          currency: currentCurrency,
          exchangeRate,
          includeVendor: true,
          vendorRevenueType,
        }}
      />
    </>
  );

  return (
    <>
      <SalesPerformanceLineChart
        userInfo={userInfo}
        mid={store?.merchantId}
        marketplaceType={store?.marketplace}
        marketplaceSubtype={store?.marketplaceSubtype ?? store?.marketplace}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        currentFilter={currentFilter}
        footerLink={footerLink}
        actions={actions}
        chartType={chartType}
        includeTax={includeTax}
        timezone={selectedTimezone}
        isLineChart={lineChart}
        isForecast={forecast}
        vendorRevenueType={vendorRevenueType}
      />
    </>
  );
});

export default SalesPerformance;
