import { TAG_TYPES, api } from "../apiSlice";

import { AdvertisingPortfolio } from "~/typedef/brandFilterConnection";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export interface NewBrandFilterStoreState {
  loading: boolean;
  parentStoreLoading: boolean;
  portfolios: AdvertisingPortfolio[] | null;
}

interface FetchStoreParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
}

interface ConnectStandaloneAdvertisingParams {
  portfolioIds: number[];
  parentStoreParams: FetchStoreParams;
  storeName: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    connectStandaloneAdvertising: build.mutation<
      { status: number },
      ConnectStandaloneAdvertisingParams
    >({
      query: (params) => {
        return {
          method: "POST",
          url: `${baseUrl}/auth/brandFilter/connectStandaloneAdvertising`,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      invalidatesTags: [TAG_TYPES.MarketingCampaigns],
    }),
    fetchStorePortfolios: build.query<AdvertisingPortfolio[], FetchStoreParams>(
      {
        query: (params) => {
          return {
            url: `${baseUrl}/auth/brandFilter/portfolio`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              ...params,
              // we use the parent store to get the portfolios in the case of standalone advertising
              // filtered stores, so we need to pass this flag to the backend
              isParentStore: true,
            },
          };
        },
        onQueryStarted: globalQueryErrorHandler(),
      }
    ),
  }),
});

export const {
  useFetchStorePortfoliosQuery,
  useConnectStandaloneAdvertisingMutation,
} = extendedApiSlice;
