import { CurrencyRate, CurrentStore } from "~/typedef/store";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  getConvertedValue,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import { Box } from "@material-ui/core";
import NoData from "~/components/loadingIndicator/noData";
import { Panel } from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import StepChart from "~/components/charts/stepChart/stepChart";
import { fetchAverageSalesByDay } from "../../store/mystore/averageSalesByDay.redux";
import { fetchSalesByDay } from "../../store/mystore/salesByDay.redux";
import get from "lodash/get";
import { getExpectedAndActualSales } from "~/utils/salesUtils";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const getConvertedSales = (
  sales: { data: any[]; fetching: boolean },
  currencyRates: CurrencyRate[],
  currentCurrency: string,
  currentStore: CurrentStore,
  mid?: string
) => {
  if (!sales || !sales.data) return sales;
  const { data, fetching } = sales;
  const totalAverageSales = data.map((d) => ({
    ...d,
    amount: getConvertedValue(
      currencyRates,
      mid ? getCurrencyByCountryCode[currentStore.marketplaceCountry] : "AUD",
      currentCurrency,
      d.amount
    ),
  }));

  return {
    fetching,
    data: totalAverageSales,
  };
};

const getConvertedAverageSales = (
  averageSales: { data: any[]; fetching: boolean },
  currencyRates: CurrencyRate[],
  currentCurrency: string,
  currentStore: CurrentStore,
  mid?: string
) => {
  if (!averageSales || !averageSales.data) return averageSales;
  const { data, fetching } = averageSales;
  const totalAverageSales = data.map((d) => ({
    ...d,
    amount: getConvertedValue(
      currencyRates,
      mid ? getCurrencyByCountryCode[currentStore.marketplaceCountry] : "AUD",
      currentCurrency,
      d.amount
    ),
  }));

  return {
    fetching,
    data: totalAverageSales,
  };
};

interface SalesByDayProps {
  mid?: string;
  market?: string;
}

const SalesByDay = memo<SalesByDayProps>(function SalesByDay({ mid, market }) {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(null);
  const userInfo = useTypedSelector((state) => state.user);
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );
  const currentStore = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const salesByDay = useTypedSelector((state) =>
    mid ? state.mystore.salesByDay : state.overview.salesByDay
  );
  const averageSalesByDay = useTypedSelector((state) =>
    mid ? state.mystore.averageSalesByDay : state.overview.averageSalesByDay
  );
  const vendorRevenueType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
      "orderedRevenue"
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dispatchFetchSalesByDay = useCallback(
    (storeMid, marketplace, filter) => {
      dispatch(
        fetchSalesByDay(
          {
            mid: storeMid,
            market: marketplace,
            filter,
            filteredStores,
            includeTax,
            timezone: selectedTimezone,
            vendorRevenueType,
          },
          salesByDay?.params
        )
      );
      dispatch(
        fetchAverageSalesByDay(
          {
            timezone: selectedTimezone,
            mid: storeMid,
            market: marketplace,
            filter,
            filteredStores,
            includeTax,
          },
          averageSalesByDay?.params
        )
      );
    },
    [
      dispatch,
      includeTax,
      filteredStores,
      salesByDay?.params,
      averageSalesByDay?.params,
      selectedTimezone,
    ]
  );

  const fetchData = async () => {
    await dispatchFetchSalesByDay(mid, market, currentFilter);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, market, currentFilter, filteredStores, includeTax]);

  useEffect(() => {
    const isFetching = salesByDay?.fetching || averageSalesByDay?.fetching;
    setFetching(isFetching);
  }, [averageSalesByDay?.fetching, salesByDay?.fetching]);

  useEffect(() => {
    if (
      !fetching &&
      salesByDay?.data &&
      averageSalesByDay?.data &&
      currentStore
    ) {
      const convertedSales = getConvertedSales(
        salesByDay,
        currencyRates,
        currentCurrency,
        currentStore,
        mid
      );
      const convertedAverageSales = getConvertedAverageSales(
        averageSalesByDay,
        currencyRates,
        currentCurrency,
        currentStore,
        mid
      );
      const chartData = getExpectedAndActualSales(
        get(convertedSales, "data"),
        get(convertedAverageSales, "data")
      );
      setData(chartData);
    } else {
      setData(null);
    }
  }, [averageSalesByDay, currencyRates, currentCurrency, currentStore, fetching, mid, salesByDay, includeTax]);

  return (
    <Panel
      id="widget-sales-trend"
      title={t("dashboardWidget.salesTrend.mainTitle")}
      tooltip={t("dashboardWidget.salesTrend.mainTooltip")}
      content={
        <Box p={2}>
          {fetching ? (
            <PanelLoading />
          ) : isEmpty(data) ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <NoData />
            </Box>
          ) : (
            <StepChart
              {...{
                data,
                currentCurrency,
                timezone: selectedTimezone,
              }}
            />
          )}
        </Box>
      }
    />
  );
});

export default SalesByDay;
