import { Box, Grid, Typography } from "@material-ui/core";
import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";
import {
  SuccessDirection,
  getStatus,
} from "~/components/charts/percentageChange";

import StatusText from "~/components/typography/status";
import styled from "styled-components";

const StyledStatusText = styled(StatusText)`
  line-height: 1;
  margin-top: 0.25rem;
`;

interface CCPPTableCellProps {
  cell: {
    value: {
      value: number | string | null;
      percentage?: number | string | null;
      conditionalFormatting?: boolean;
      successDirection?: SuccessDirection;
      customSuffix?: string;
    };
  };
}

export const CCPPTableCell = memo<CCPPTableCellProps>(function CCPPTableCell({
  cell,
}) {
  const value = cell.value.value;
  const rawPercentage = cell.value.percentage;
  const percentage = Number(rawPercentage);
  const conditionalFormatting = cell.value.conditionalFormatting;
  const successDirection = cell.value.successDirection;
  const customSuffix = cell.value.customSuffix;

  const status =
    percentage >= 0
      ? getStatus(
          successDirection,
          SuccessDirection.ASCENDING,
          conditionalFormatting
        )
      : getStatus(
          successDirection,
          SuccessDirection.DESCENDING,
          conditionalFormatting
        );

  return (
    <Grid container direction="column" alignItems="center">
      <Box>
        <Typography variant="body2" align="center">
          {value ?? "-"}
        </Typography>
      </Box>
      {rawPercentage && (
        <StyledStatusText
          variant="subtitle2"
          status={status}
          align="center"
          paragraph={false}
        >
          {conditionalFormatting &&
            (percentage >= 0 ? (
              <UpArrow fontSize="inherit" status={status} />
            ) : (
              <DownArrow fontSize="inherit" status={status} />
            ))}
          {isNaN(percentage) ? rawPercentage : Math.abs(percentage).toFixed(1)}
          {!isNaN(percentage) && (customSuffix ? customSuffix : "%")}
        </StyledStatusText>
      )}
    </Grid>
  );
});
