import { Box, FormControl, Tooltip, Typography } from "@material-ui/core";
import React, { useMemo } from "react";

import { AMCAdditionalFieldFilter } from "~/typedef/amc/reportType";
import { AMCAdditionalFieldValues } from "~/typedef/amc/scheduledReport";
import { CurrentStore } from "~/typedef/store";
import { InlineIconButton } from "~/icons/inlineIconButton";
import MultiSelect from "~/components/select/multiSelectDropDown";
import { useGetCampaignsQuery } from "~/store/mystore/amc.redux";
import { useTranslation } from "react-i18next";

interface AMCCampaignsFieldProps {
  name: string;
  label: string;
  required: boolean;
  tooltip?: string;
  values: AMCAdditionalFieldValues;
  onChange: (data: AMCAdditionalFieldValues) => void;
  store: CurrentStore;
  maxSelections?: number;
  filter?: AMCAdditionalFieldFilter;
}

const AMCCampaignsField: React.FC<AMCCampaignsFieldProps> = ({
  name,
  label,
  required,
  tooltip,
  values,
  onChange,
  store,
  maxSelections = 5,
  filter,
}) => {
  const { t } = useTranslation();

  const { campaigns, isFetching } = useGetCampaignsQuery(
    { mid: store.merchantId, ...filter },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          campaigns: data?.campaigns ?? [],
          isFetching,
        };
      },
    }
  );

  const selectedValues = useMemo<string[]>(() => {
    const value = values[name];
    if (!value) return [];
    const paramValues = Array.isArray(value) ? value : [value];
    return maxSelections ? paramValues.slice(0, maxSelections) : paramValues;
  }, [values, name, maxSelections]);

  const handleOnChange = (selectedIds: string[]) => {
    onChange({ [name]: selectedIds });
  };

  const multiSelectOptions = useMemo(() => {
    return campaigns.map((campaign) => ({
      value: campaign.campaignId,
      label: campaign.campaignName,
    }));
  }, [campaigns]);

  return !isFetching ? (
    <FormControl fullWidth>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" color="textSecondary">
          {t(label)} {required && <span>*</span>}
        </Typography>
        {tooltip && (
          <Tooltip title={t(tooltip)}>
            <InlineIconButton />
          </Tooltip>
        )}
      </Box>
      <MultiSelect
        currentValues={selectedValues}
        handleChange={handleOnChange}
        options={multiSelectOptions}
        disabled={isFetching}
        maxSelections={maxSelections}
      />
    </FormControl>
  ) : null;
};

export default AMCCampaignsField;
