import { Theme } from "@material-ui/core";

export const pathToConversionPathRegex = /(?<=\[\d+, )\w(.*?)(?=\])/g;

export const getPTCTileColor = (() => {
  // Persistent mapping for custom pathNames.
  const colorMap: Record<string, string> = {};

  // availableColours is initialized on the first call using the provided theme.
  let availableColours: string[] | null = null;

  return (theme: Theme, pathName: string): string => {
    // On first call, set up the available colours list.
    if (!availableColours) {
      availableColours = [
        // Note: We omit the colors used below for fixed path names
        // (magenta, purple, darkBlue, orange) so they won't be reassigned.
        theme.palette.primary.main,
        theme.palette.chart.lightGreen,
        theme.palette.chart.spaceGray,
        theme.palette.chart.deepOrange,
        theme.palette.chart.pink,
        theme.palette.chart.lightGreen1,
        theme.palette.chart.lightBlue1,
        theme.palette.chart.brown,
        theme.palette.chart.lightGray1,
        theme.palette.chart.indigo,
        theme.palette.chart.lightBrown,
        theme.palette.chart.green,
        theme.palette.chart.cyan,
        theme.palette.chart.lightPink,
        theme.palette.chart.lightBlue,
        theme.palette.chart.progressBlue,
        theme.palette.chart.lightGray,
        theme.palette.chart.darkGray,
        theme.palette.chart.red,
      ];
    }

    // Handle special cases first.
    switch (pathName) {
      case "DSP":
        return theme.palette.chart.magenta;
      case "SP":
        return theme.palette.chart.purple;
      case "SD":
        return theme.palette.chart.darkBlue;
      case "SB":
        return theme.palette.chart.orange;
      default:
        // If we've already assigned a colour to this pathName, return it.
        if (colorMap[pathName]) {
          return colorMap[pathName];
        }
        // If no colours are left, fall back to a default.
        if (availableColours.length === 0) {
          return theme.palette.secondary.main;
        }
        // Otherwise, assign the next available colour.
        const color = availableColours.shift()!;
        colorMap[pathName] = color;
        return color;
    }
  };
})();
