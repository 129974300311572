import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "@store/utils/dateTimeUtils";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { CurrencyRate, Store } from "~/typedef/store";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "@store/persistentAppSettings.redux";
import {
  MarketplaceOverviewExpandedAdvertisingEntry,
  MarketplaceOverviewExpandedEntry,
  useMarketplaceOverviewExpandedAdvertisingQuery,
  useMarketplaceOverviewExpandedQuery,
} from "@store/overview/marketplaceOverview.redux";
import React, { memo, useCallback, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatCurrencyRounded,
  getConvertedValue,
} from "~/utils/currencyUtils";
import { formatNumber, getPercentageDifference } from "~/utils/salesUtils";
import { get, isEmpty } from "lodash";
import {
  getShopName,
  marketplaceLink,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import ACOSCell from "../../../components/table/cells/acosCell";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkCell } from "../../../components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import MarketplaceOverviewTotals from "./marketplaceOverviewTotals";
import { OutOfStockCell } from "../../../components/table/cells/outOfStockCell";
import { OverviewToolbar } from "../../../components/toolbars/overviewToolbar/overviewToolbar";
import PageBlock from "../../../components/containers/pageBlock";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "../../../components/panel/panel";
import SearchFilter from "../../../components/adTable/searchFilter";
import StatusIndicator from "../../../components/statusIndicator/statusIndicator";
import TACOSCell from "../../../components/table/cells/tacosCell";
import Table from "../../../components/adTable/table";
import { ThresholdCell } from "~/components/table/cells/thresholdCell";
import { ValueAndGrowthCell } from "../../../components/table/cells/valueAndGrowthCell";
import { getStatusTitle } from "~/utils/accountHealthUtils";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 10;
const DEFAULT_SORT = "sales";

const FORECAST_TARGET_THRESHOLDS = {
  lowThreshold: 70,
  midThreshold: 90,
};

const ADSPEND_LIMIT_THRESHOLDS = {
  lowThreshold: 90,
  midThreshold: 100,
};

const formatMarketplaceData = (
  filteredStores: Store[],
  marketplaceOverviewRows: MarketplaceOverviewExpandedEntry[],
  marketplaceOverviewExpandedAdvertisingData: MarketplaceOverviewExpandedAdvertisingEntry[],
  currencyRates: CurrencyRate[],
  currentCurrency: string,
  t: TFunction<"translation", undefined>
) => {
  const marketplaceOverviewData = marketplaceOverviewRows.map((marketplace) => {
    const advertisingData = marketplaceOverviewExpandedAdvertisingData.find(
      (row) => row.storeId == marketplace.storeId
    );

    // We still use conversion - API endpoint converts it all to a target currency (USD for now)
    const convertedSalesValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.sales.value
    );
    const convertedPriorValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.sales.priorPeriodValue
    );
    const convertedOrderValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.avOrderValue.value
    );
    const convertedOrderPriorValue = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.avOrderValue.priorPeriodValue
    );
    const convertedAdSpend = advertisingData
      ? getConvertedValue(
          currencyRates,
          marketplace.currency,
          currentCurrency,
          advertisingData.adSpend
        )
      : null;
    const convertedAdSales = advertisingData
      ? getConvertedValue(
          currencyRates,
          marketplace.currency,
          currentCurrency,
          advertisingData.adSales
        )
      : null;
    const convertedForecastTarget = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.forecastTarget
    );
    const convertedAdspendLimit = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.adspendLimit
    );
    const convertedBudgetTarget = getConvertedValue(
      currencyRates,
      marketplace.currency,
      currentCurrency,
      marketplace.budgetTarget
    );
    return {
      ...marketplace,
      convertedSalesValue,
      convertedPriorValue,
      convertedOrderValue,
      convertedOrderPriorValue,
      convertedAdSpend,
      convertedAdSales,
      convertedForecastTarget,
      convertedAdspendLimit,
      convertedBudgetTarget,
      acos: advertisingData ? advertisingData.acos : null,
      tacos: advertisingData
        ? marketplace.sales.value > 0
          ? (100 * advertisingData.adSpend) / marketplace.sales.value
          : 0
        : null,
    };
  });
  return marketplaceOverviewData.map((marketplace) => {
    const isAmazonVendor =
      stripFilteredSuffix(marketplace.marketplace.market) === "amazon_vendor";
    const shopName = {
      value: getShopName(
        filteredStores,
        marketplace.marketplace.market,
        marketplace.store
      ),
      link: marketplaceLink(marketplace.marketplace.market, marketplace.store),
    };
    const orders = {
      value: isAmazonVendor ? "-" : marketplace.orders.value,
      growth: isAmazonVendor
        ? "N/A"
        : getPercentageDifference(
            marketplace.orders.value,
            marketplace.orders.priorPeriodValue
          ),
    };
    const sales = {
      sortValue: marketplace.convertedSalesValue,
      value: formatCurrencyRounded(
        marketplace.convertedSalesValue,
        currencyRates,
        currentCurrency,
        currentCurrency
      ),
      growth: getPercentageDifference(
        marketplace.convertedSalesValue,
        marketplace.convertedPriorValue
      ),
    };
    const units = {
      value:
        isAmazonVendor || !marketplace.units ? "-" : marketplace.units.value,
      growth:
        isAmazonVendor || !marketplace.units
          ? "N/A"
          : getPercentageDifference(
              marketplace.units.value,
              marketplace.units.priorPeriodValue
            ),
    };
    const avOrderValue = {
      value: isAmazonVendor
        ? "-"
        : formatCurrency(
            marketplace.convertedOrderValue,
            currencyRates,
            currentCurrency,
            currentCurrency
          ),
      growth: isAmazonVendor
        ? "N/A"
        : getPercentageDifference(
            marketplace.convertedOrderValue,
            marketplace.convertedOrderPriorValue
          ),
    };
    const avOrderSize = {
      value: isAmazonVendor ? "-" : formatNumber(marketplace.avOrderSize.value),
      growth: isAmazonVendor
        ? "N/A"
        : getPercentageDifference(
            marketplace.avOrderSize.value,
            marketplace.avOrderSize.priorPeriodValue
          ),
    };
    const adSpend = marketplace.convertedAdSpend
      ? formatCurrencyRounded(
          marketplace.convertedAdSpend,
          currencyRates,
          currentCurrency,
          currentCurrency
        )
      : "-";
    const adSales = marketplace.convertedAdSales
      ? formatCurrencyRounded(
          marketplace.convertedAdSales,
          currencyRates,
          currentCurrency,
          currentCurrency
        )
      : "-";
    const forecastTarget = {
      currentValue: marketplace.convertedSalesValue,
      thresholdValue: marketplace.convertedForecastTarget,
      displayValue: marketplace.convertedForecastTarget
        ? formatCurrencyRounded(
            marketplace.convertedForecastTarget,
            currencyRates,
            currentCurrency,
            currentCurrency
          )
        : "-",
    };
    const adspendLimit = {
      currentValue: marketplace.convertedAdSpend,
      thresholdValue: marketplace.convertedAdspendLimit,
      displayValue: marketplace.convertedAdspendLimit
        ? formatCurrencyRounded(
            marketplace.convertedAdspendLimit,
            currencyRates,
            currentCurrency,
            currentCurrency
          )
        : "-",
    };
    const budgetTarget = {
      currentValue: marketplace.convertedSalesValue,
      thresholdValue: marketplace.convertedBudgetTarget,
      displayValue: marketplace.convertedBudgetTarget
        ? formatCurrencyRounded(
            marketplace.convertedBudgetTarget,
            currencyRates,
            currentCurrency,
            currentCurrency
          )
        : "-",
    };

    return {
      ...marketplace,
      shopName,
      orders,
      sales,
      units,
      avOrderValue,
      avOrderSize,
      salesSplit: `${Math.round(marketplace.salesSplit)}%`,
      healthStatus: {
        value: getStatusTitle(marketplace.healthStatus, t),
        status: marketplace.healthStatus,
      },
      buyBoxSnapshot:
        marketplace.buyBoxSnapshot !== null
          ? `${marketplace.buyBoxSnapshot}%`
          : "-",
      outOfStockPercentage: {
        value: marketplace.outOfStockPercentage,
      },
      adSpend,
      adSales,
      forecastTarget,
      adspendLimit,
      budgetTarget,
    };
  });
};

type RowDataType = ReturnType<typeof formatMarketplaceData>[number];
const columns = (t: TFunction<"translation", undefined>) => [
  {
    id: "marketplace",
    Header: t("dashboardWidget.marketplaceOverview.marketplaceColumn"),
    accessor: "marketplace",
    Cell: MarketplaceAndCountryCell,
    isVisible: true,
    isLocked: true,
    disableSortBy: true,
    sticky: "left",
    // used by react-table-sticky; must be in sync with customWidth
    // if sticky and customWidth are set
    width: 90,
    customWidth: 90,
  },
  {
    id: "shop_name",
    Header: t("dashboardWidget.marketplaceOverview.storeColumn"),
    accessor: "shopName",
    Cell: LinkCell,
    isVisible: true,
    isLocked: true,
    disableSortBy: true,
    sticky: "left",
  },
  {
    id: "sales",
    Header: t("dashboardWidget.marketplaceOverview.salesColumn"),
    accessor: "sales",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: true,
    sortDescFirst: true,
  },
  {
    id: "salesSplit",
    Header: t("dashboardWidget.marketplaceOverview.salesMixColumn"),
    accessor: "salesSplit",
    align: "center",
    isVisible: true,
  },
  {
    id: "forecastTarget",
    Header: t("dashboardWidget.marketplaceOverview.forecastTargetColumn"),
    accessor: (row: RowDataType) => {
      return {
        ...row.forecastTarget,
        ...FORECAST_TARGET_THRESHOLDS,
      };
    },
    Cell: ThresholdCell,
    align: "right",
    isVisible: false,
    sortDescFirst: true,
  },
  {
    id: "budgetTarget",
    Header: t("dashboardWidget.marketplaceOverview.budgetTargetColumn"),
    accessor: (row: RowDataType) => {
      return {
        ...row.budgetTarget,
        ...FORECAST_TARGET_THRESHOLDS,
      };
    },
    Cell: ThresholdCell,
    align: "right",
    isVisible: false,
    sortDescFirst: true,
  },
  {
    id: "orders",
    Header: t("dashboardWidget.marketplaceOverview.ordersColumn"),
    accessor: "orders",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: false,
    sortDescFirst: true,
  },
  {
    id: "units",
    Header: t("dashboardWidget.marketplaceOverview.unitsColumn"),
    accessor: "units",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: false,
    sortDescFirst: true,
  },
  {
    id: "avOrderSize",
    Header: t("dashboardWidget.marketplaceOverview.avgOrderSizeColumn"),
    accessor: "avOrderSize",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: false,
    sortDescFirst: true,
  },
  {
    id: "avOrderValue",
    Header: t("dashboardWidget.marketplaceOverview.avgOrderValueColumn"),
    accessor: "avOrderValue",
    Cell: ValueAndGrowthCell,
    align: "right",
    isVisible: true,
    sortDescFirst: true,
  },
  {
    id: "outOfStockPercentage",
    Header: t("dashboardWidget.inventoryStatus.outOfStockPercentageColumn"),
    accessor: (row: RowDataType) => {
      return {
        outOfStockPercentage: row.outOfStockPercentage.value,
        align: "center",
      };
    },
    Cell: OutOfStockCell,
    align: "center",
    isVisible: true,
    sortDescFirst: true,
  },
  {
    id: "healthStatus",
    Header: t("dashboardWidget.accountHealth.healthColumn"),
    accessor: "healthStatus",
    Cell: ({ cell }: any) => {
      return (
        <StatusIndicator
          {...{
            statusText: cell.value.value,
            status:
              cell.value.status === "noData" ? "disabled" : cell.value.status,
            size: "medium",
          }}
        />
      );
    },
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "buyBoxSnapshot",
    Header: t("dashboardWidget.marketplaceOverview.buyBoxWinRate"),
    accessor: "buyBoxSnapshot",
    align: "center",
    isVisible: true,
    sortDescFirst: true,
  },
  {
    id: "adSpend",
    Header: t("dashboardWidget.marketplaceOverview.adSpend"),
    accessor: "adSpend",
    align: "center",
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "adspendLimit",
    Header: t("dashboardWidget.marketplaceOverview.adSpendLimitColumn"),
    accessor: (row: RowDataType) => {
      return {
        ...row.adspendLimit,
        ...ADSPEND_LIMIT_THRESHOLDS,
        reverseFormatting: true,
      };
    },
    Cell: ThresholdCell,
    align: "right",
    isVisible: true,
    disableSortBy: true,
  },
  {
    id: "adSales",
    Header: t("dashboardWidget.marketplaceOverview.adSales"),
    accessor: "adSales",
    align: "center",
    isVisible: true,
    disableSortBy: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.acosColumn"),
    id: "acos",
    accessor: (row: RowDataType) => ({
      value: row.acos !== null ? `${row.acos.toFixed(1)}%` : "-",
    }),
    align: "center",
    Cell: ACOSCell,
    isVisible: true,
    disableSortBy: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.tacosColumn"),
    id: "tacos",
    accessor: (row: RowDataType) => ({
      value: row.tacos !== null ? `${row.tacos.toFixed(1)}%` : "-",
    }),
    align: "center",
    Cell: TACOSCell,
    isVisible: true,
    disableSortBy: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.roasColumn"),
    id: "roas",
    accessor: (row: RowDataType) => ({
      value: row.acos !== null ? `${(100 / row.acos).toFixed(1)}` : "-",
    }),
    align: "center",
    Cell: ACOSCell,
    isVisible: false,
    disableSortBy: true,
  },
  {
    Header: t("advertisingDashboardWidget.adTable.troasColumn"),
    id: "troas",
    accessor: (row: RowDataType) => ({
      value: row.tacos !== null ? `${(100 / row.tacos).toFixed(1)}` : "-",
    }),
    align: "center",
    Cell: TACOSCell,
    isVisible: false,
    disableSortBy: true,
  },
];

const MarketplaceOverview = memo(function MarketplaceOverview() {
  const { t } = useTranslation();
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const userInfo = useTypedSelector((state) => state.user);
  const filteredStores = useTypedSelector((state) =>
    get(state, "mystore.filteredStores.stores", [])
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      state.persistentAppSettings.setting.data.currentCurrency ||
      DEFAULT_CURRENCY
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );
  const vendorRevenueType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
      "orderedRevenue"
  );

  const columnsMemo = useMemo(() => columns(t), [t]);
  const [myColumns, setMyColumns] = useState<Column[]>(columnsMemo);
  const [searchText, setSearchText] = useState("");

  const [paginationParams, setPaginationParams] = useState<
    Omit<PaginationArgs, "pageSize" | "pageIndex">
  >({
    sortKey: DEFAULT_SORT,
    sortOrder: "desc",
  });

  const {
    marketplaceOverviewExpandedRows,
    marketplaceOverviewExpandedCount,
    marketplaceOverviewExpandedFetching,
  } = useMarketplaceOverviewExpandedQuery(
    {
      currentPeriod,
      currentRange,
      filter: currentFilter,
      includeTax,
      vendorRevenueType,
      ...paginationParams,
      searchText,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        marketplaceOverviewExpandedRows: data?.rows || [],
        marketplaceOverviewExpandedCount: data?.count || 0,
        marketplaceOverviewExpandedFetching: isFetching,
      }),
    }
  );

  const { marketplaceOverviewExpandedAdvertisingData } =
    useMarketplaceOverviewExpandedAdvertisingQuery(
      {
        currentRange,
        storeIds: marketplaceOverviewExpandedRows.map(
          (marketplace) => marketplace.storeId
        ),
        searchText,
        filter: currentFilter,
      },
      {
        skip:
          marketplaceOverviewExpandedFetching ||
          isEmpty(marketplaceOverviewExpandedRows),
        selectFromResult: ({ data }) => ({
          marketplaceOverviewExpandedAdvertisingData: data?.rows || [],
        }),
      }
    );

  const fetchData = useCallback(({ sortBy }) => {
    setPaginationParams({
      sortKey: sortBy && sortBy.length ? sortBy[0].id : DEFAULT_SORT,
      sortOrder:
        sortBy && sortBy.length ? (sortBy[0].desc ? "desc" : "asc") : "desc",
    });
  }, []);

  const data = useMemo(
    () =>
      formatMarketplaceData(
        filteredStores,
        marketplaceOverviewExpandedRows,
        marketplaceOverviewExpandedAdvertisingData,
        currencyRates,
        currentCurrency,
        t
      ),
    [
      filteredStores,
      marketplaceOverviewExpandedRows,
      marketplaceOverviewExpandedAdvertisingData,
      currencyRates,
      currentCurrency,
      t,
    ]
  );

  const shopNameObjArray = data.map((store) => ({
    shopId: store.storeId,
    mid: store.store,
    storeName: store.shopName.value,
  }));

  return (
    <PageBlock>
      <OverviewToolbar
        selectedItem={{
          currentPage: t("dashboardWidget.marketplaceOverview.mainTitle"),
          breadcrumb: [],
        }}
      />{" "}
      <Panel
        id="widget-marketplace-overview"
        title={t("dashboardWidget.marketplaceOverview.mainTitle")}
        tooltip={t("dashboardWidget.marketplaceOverview.mainTooltip")}
        content={
          <>
            <MarketplaceOverviewTotals />
            <Table
              loading={marketplaceOverviewExpandedFetching}
              columns={myColumns}
              data={data}
              fetchData={fetchData}
              sorting
              pagination={false}
              pageSize={PAGE_SIZE}
              pageCount={Math.ceil(
                marketplaceOverviewExpandedCount / PAGE_SIZE
              )}
            />
          </>
        }
        actions={
          <>
            <SearchFilter setSearchText={setSearchText} />
            <ColumnSelect
              {...{ columns: myColumns, setColumns: setMyColumns }}
            />
            {
              <DownloadCsv
                reportType="marketplaceOverview"
                path="/api/generic/marketplaceOverview"
                mid="all"
                params={{
                  currentPeriod,
                  ...currentRange,
                  searchText,
                  filter: currentFilter,
                  includeTax,
                  currentCurrency,
                  exchangeRates: currencyRates.find(
                    (i) => i._id === currentCurrency
                  )?.rates,
                  shopNameObjArray: shopNameObjArray,
                  vendorRevenueType,
                }}
              />
            }
          </>
        }
      />
    </PageBlock>
  );
});

export default MarketplaceOverview;
