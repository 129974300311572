import React, { memo } from "react";

import PageBlock from "~/components/containers/sideNavPageBlock";

// Placeholder for the InsightSummary component
const InsightSummary = memo(function InsightSummary() {
  return <PageBlock>Insights Summary</PageBlock>;
});

export default InsightSummary;
