import React, { useCallback } from "react";
import { flatMap, get, uniq } from "lodash";

import { Filter } from "~/typedef/store";
import FilterTagSelect from "../../select/filterTagSelect";
import { fetchCustomTags } from "../../../store/overview/customTags.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface FilterTagDropdownProps {
  filterWidth?: number;
  selectedFilters: Filter;
  setSelectedFilters: (filter: Filter) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const FilterTagDropdown: React.FC<FilterTagDropdownProps> = ({
  selectedFilters,
  setSelectedFilters,
  filterWidth,
  fullWidth,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useTypedSelector((state) => get(state, "user"));
  const isTagsLoading = useTypedSelector((state) =>
    get(state, "overview.customTags.fetching")
  );
  const customTags = useTypedSelector((state) =>
    get(state, "overview.customTags.tags")
  );
  const selectedTags = get(selectedFilters, "tags");
  const filterTagTitle = t("filters.allCustomTags");
  const tags = uniq(flatMap(customTags, (val) => val.tags));

  const handleOpenMenu = useCallback(() => {
    dispatch(fetchCustomTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  const handleOptionChange = (tags: string[]) => {
    setSelectedFilters({ ...selectedFilters, tags });
  };

  return (
    <FilterTagSelect
      title={t(`filterTitle.tags`)}
      isFullWidth={fullWidth}
      maxWidth={filterWidth}
      additionalMenuWidth={50}
      isLoading={isTagsLoading}
      isWithSuggestion
      options={tags}
      selectedOptions={selectedTags}
      handleOpenMenu={handleOpenMenu}
      filterTagTitle={filterTagTitle}
      handleOptionChange={handleOptionChange}
      isDisabled={disabled}
    />
  );
};

export default FilterTagDropdown;
