import React, { useMemo } from "react";

import { AMCAdditionalFieldValues } from "~/typedef/amc/scheduledReport";
import TagInput from "~/components/tagInput/tagInput";

interface AMCAsinFieldProps {
  name: string;
  label: string;
  required: boolean;
  tooltip?: string;
  values: AMCAdditionalFieldValues;
  onChange: (data: AMCAdditionalFieldValues) => void;
  placeholder?: string;
}

const AMCAsinField: React.FC<AMCAsinFieldProps> = ({
  name,
  label,
  required,
  tooltip,
  values,
  onChange,
  placeholder,
}) => {
  const asins = useMemo<string[]>(() => {
    const value = values[name];
    if (!value) return [];
    return Array.isArray(value) ? value : [value];
  }, [values, name]);

  const handleChange = (newValues: string[]) => {
    onChange({
      [name]: newValues,
    });
  };

  return (
    <TagInput
      label={label}
      required={required}
      tooltip={tooltip}
      values={asins}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

export default AMCAsinField;
