import React, { memo, useMemo } from "react";

import { AmcReportConfigForm } from "./scheduleConfigForm";
import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CreateReport = memo(function CreateReport({}) {
  const store = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const connections = useTypedSelector(
    (state) => state.connections.connectionStatus
  );
  const isConnectionInProgress = useMemo(() => {
    if (!store) {
      return false;
    }

    const connection = connections?.connections?.find(
      (connection) => connection.mid === store.merchantId
    );

    if (connection && connection.percentage !== 100) {
      return true;
    }

    return false;
  }, [connections, store]);

  return (
    <PageBlock>
      {!isConnectionInProgress && store && (
        <Grid container spacing={2}>
          <AmcReportConfigForm store={store} />
        </Grid>
      )}
    </PageBlock>
  );
});

export default CreateReport;
