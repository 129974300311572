import {
  FETCH_PERFORMANCE_YTD_ERROR,
  FETCH_PERFORMANCE_YTD_FETCHING,
  FETCH_PERFORMANCE_YTD_SUCCESS,
} from "./overview.redux";
import {
  FETCH_STORE_PERFORMANCE_YTD_ERROR,
  FETCH_STORE_PERFORMANCE_YTD_FETCHING,
  FETCH_STORE_PERFORMANCE_YTD_SUCCESS,
} from "../mystore/mystore.redux";
import { Filter, Range } from "~/typedef/store";

import { Dispatch } from "redux";
import { User } from "~/typedef/user";
import axios from "axios";
import { baseUrl } from "~/configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

export interface FetchPerformanceYtdParams {
  user: User;
  mid?: string;
  currentRange: Range;
  filter: Filter;
  showPendingOrders?: boolean;
  includeTax: boolean;
  vendorRevenueType?: "orderedRevenue" | "shippedRevenue";
  includeVendor?: boolean;
}

export const fetchSalesPerformanceYtd =
  (params: FetchPerformanceYtdParams, oldParams?: FetchPerformanceYtdParams) =>
  async (dispatch: Dispatch) => {
    const {
      user,
      mid,
      currentRange,
      filter,
      includeTax,
      includeVendor,
      vendorRevenueType,
    } = params;

    try {
      if (!shouldUseCache(params, oldParams)) {
        dispatch({
          type: mid
            ? FETCH_STORE_PERFORMANCE_YTD_FETCHING
            : FETCH_PERFORMANCE_YTD_FETCHING,
        });

        const currentSales = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/salesPerformance/ytd`,
          {
            user,
            ...currentRange,
            mid,
            filter,
            includeTax,
            includeVendor,
            vendorRevenueType,
          }
        );
        const salesData = currentSales.data;

        return dispatch({
          type: mid
            ? FETCH_STORE_PERFORMANCE_YTD_SUCCESS
            : FETCH_PERFORMANCE_YTD_SUCCESS,
          payload: {
            ...salesData,
            params,
          },
        });
      }
    } catch (e) {
      const err = get(e, "response.data.errMsg");
      const msg = get(err, "data.error");
      const statusText = get(err, "statusText");
      dispatchError(
        dispatch,
        mid ? FETCH_STORE_PERFORMANCE_YTD_ERROR : FETCH_PERFORMANCE_YTD_ERROR
      );
      return setError(dispatch, msg || statusText, get(err, "status"));
    }
  };
