import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";

import React from "react";
import ShippedRevenueByStoresTable from "~/modules/vendor/shippedRevenueByStoresTable/shippedRevenueByStoresTable";
import get from "lodash/get";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

const ShippedRevenueByStores = () => {
  const { currentRange } = useDateRangeFilters();

  const currentFilter = useTypedSelector(
    (state) =>
      state?.persistentAppSettings?.setting?.data?.currentFilter ||
      DEFAULT_FILTER
  );

  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currentViewPreference = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.viewPreference") ||
      "sourcing"
  );

  return (
    <ShippedRevenueByStoresTable
      currentCurrency={currentCurrency}
      currentRange={currentRange}
      pageSize={PAGE_SIZE}
      currentFilter={currentFilter}
      view={currentViewPreference}
      filteredStores={filteredStores}
    />
  );
};

export default ShippedRevenueByStores;
