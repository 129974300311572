import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import PieChart, { PieChartData } from "~/components/charts/pieChart/pieChart";
import {
  PieChartConfig,
  PieChartMetricOption,
  VisualizationType,
} from "~/typedef/amc/reportType";
import React, { memo, useMemo, useState } from "react";

import Bold from "@components/typography/bold";
import { CurrentStore } from "~/typedef/store";
import GenericTotal from "~/components/totals/genericTotal";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import i18next from "i18next";
import { isNumber } from "lodash";
import { numberWithCommas } from "~/utils/utils";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useReportRunChartDataQuery } from "~/store/mystore/amc.redux";
import { useTranslation } from "react-i18next";

interface AMCPieChartLegendProps {
  name: string;
  value?: string;
  count: number;
  piePercentage?: number;
}

const AMCPieChartLegend: React.FC<AMCPieChartLegendProps> = ({
  name,
  value,
  count,
  piePercentage,
}) => {
  const piePercentageText = Number.isNaN(piePercentage)
    ? "N/A"
    : `${piePercentage}%`;
  return (
    <Box>
      <Grid container wrap="wrap" spacing={1}>
        <Grid item>
          <Typography variant="body1" noWrap>
            {name}:
          </Typography>
        </Grid>
        {value && (
          <Grid item>
            <Bold variant="body2">{numberWithCommas(value)}</Bold>
          </Grid>
        )}
      </Grid>
      <Grid container wrap="wrap" spacing={1}>
        <Grid item>
          <Bold variant="body2">{count}</Bold>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 300 }}
          >
            ({piePercentageText})
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

interface AMCPieChartProps {
  store: CurrentStore;
  reportTypeName: string;
  currentReportRunId: string;
  pieChartConfig: PieChartConfig;
}

const AMCPieChart = memo<AMCPieChartProps>(function AMCPieChart({
  reportTypeName,
  currentReportRunId,
  pieChartConfig,
}) {
  const { t } = useTranslation();

  const theme = useTheme();
  const pieColors = [
    theme.palette.primary.main,
    theme.palette.chart.darkBlue,
    theme.palette.chart.orange,
    theme.palette.chart.purple,
    theme.palette.chart.lightGreen,
    theme.palette.chart.spaceGray,
    theme.palette.chart.deepOrange,
    theme.palette.chart.pink,
    theme.palette.chart.lightGreen1,
    theme.palette.chart.lightBlue1,
    theme.palette.chart.brown,
  ];

  const {
    currentRange: { interval },
  } = useDateRangeFilters();

  const [metricOption, setMetricOption] = useState<PieChartMetricOption>(
    pieChartConfig.metricOptions[0]
  );

  const switchMetricOption = (value: string) => {
    const option = pieChartConfig.metricOptions.find(
      (metricOption) => metricOption.key === value
    );
    option && setMetricOption(option);
  };

  const { currentData, isFetchingCurrentData } = useReportRunChartDataQuery(
    {
      reportRunId: currentReportRunId,
      type: VisualizationType.PieChart,
      interval,
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          currentData: data?.chartData ?? [],
          reportColumns: data?.reportColumns ?? [],
          isFetchingCurrentData: isFetching,
        };
      },
    }
  );

  const { pieChartData, pieChartTotal } = useMemo<{
    pieChartData: PieChartData[];
    pieChartTotal: number;
  }>(() => {
    const bucketByKey = pieChartConfig.bucketBy.columnKey;
    let pieChartTotal = 0;

    const pieChartData: PieChartData[] = currentData.map((record, index) => {
      const rawValue = record[metricOption.key];
      const value = isNumber(rawValue) ? rawValue : 0;
      pieChartTotal += value;
      const recordKey = record[bucketByKey]
        ? record[bucketByKey].toString()
        : "Unknown";
      const translationKey = `amcWidget.${recordKey}`;
      const hasTranslation = i18next.exists(translationKey);
      return {
        name: hasTranslation ? t(translationKey) : recordKey,
        value,
        color: pieColors[index],
      };
    });

    pieChartData.forEach((pieData) => {
      const piePercentage = Math.round((pieData.value / pieChartTotal) * 100);
      const pieValue = pieData.value;
      pieData.tooltipContent = `${pieData.name}: ${numberWithCommas(
        pieValue
      )} (${piePercentage}%)`;
      pieData.legendContent = (
        <AMCPieChartLegend
          name={pieData.name}
          count={pieValue}
          piePercentage={piePercentage}
        />
      );
    });

    return { pieChartTotal, pieChartData };
  }, [currentData, metricOption, t]);

  const actions = (
    <>
      {pieChartConfig.metricOptions.length > 1 && (
        <TableFilter
          width="180px"
          options={pieChartConfig.metricOptions.map((option) => ({
            value: option.key,
            label: t(`amcWidget.${option.label}Label`),
          }))}
          currentValue={metricOption.key}
          handleChange={switchMetricOption}
        />
      )}
    </>
  );

  return (
    <Panel
      id="widget-amc-pie-chart"
      title={t(`amcWidget.pieChart.${reportTypeName}Title`)}
      tooltip={t(`amcWidget.pieChart.${reportTypeName}Tooltip`)}
      actions={actions}
      content={
        isFetchingCurrentData ? (
          <Box>
            <LoadingIndicator />
          </Box>
        ) : (
          <Grid container item xs={12} alignItems="center">
            <PieChart
              data={pieChartData}
              legendHeader={
                <GenericTotal
                  title={t(`amcWidget.${metricOption.label}Label`)}
                  number={pieChartTotal}
                  condensed
                  lockLayout
                />
              }
            />
          </Grid>
        )
      }
    />
  );
});

export default AMCPieChart;
