import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Campaign, PrunedCampaignGroup } from "~/typedef/amc/campaignGroups";
import React, { useMemo, useState } from "react";
import SearchableMultiSelect, {
  Option,
} from "~/components/select/searchableMultiSelect";
import axios, { AxiosError } from "axios";
import {
  useCreateCampaignGroupMutation,
  useGetCampaignsQuery,
} from "~/store/mystore/amc.redux";

import Bold from "~/components/typography/bold";
import InfoAlert from "@components/alert/infoAlert";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ModalPanel from "~/components/panel/modalPanel";
import RaisedButton from "~/components/buttons/raisedButton";
import { formatAdType } from "~/modules/marketing/commonColumns";
import { useTranslation } from "react-i18next";

interface CampaignOption {
  title: string;
  subtitle: string;
  value: Campaign;
  selected: boolean;
}

interface AMCCampaignGroupFormProps {
  mid: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreateCampaignGroup: (group: PrunedCampaignGroup) => void;
}

const AMCCampaignGroupForm: React.FC<AMCCampaignGroupFormProps> = ({
  mid,
  open,
  setOpen,
  onCreateCampaignGroup,
}) => {
  const { t } = useTranslation();

  const [newGroupName, setNewGroupName] = useState("");
  const [selectedCampaigns, setSelectedCampaigns] = useState<Campaign[]>([]);
  const [nameError, setNameError] = useState("");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [createError, setCreateError] = useState("");
  const [searchText, setSearchText] = useState("");

  const resetCreateForm = () => {
    setNewGroupName("");
    setSelectedCampaigns([]);
    setNameError("");
    setCreateError("");
    setSearchText("");
  };

  const [createCampaignGroup, { isLoading: isCreating }] =
    useCreateCampaignGroupMutation();

  const { campaigns, isFetching: campaignsLoading } = useGetCampaignsQuery(
    {
      mid,
      ...(searchText && searchText.trim() !== ""
        ? {
            filterBy: "campaignName",
            filterValue: searchText,
            filterType: "regex",
          }
        : {}),
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          campaigns: data?.campaigns ?? [],
          isFetching,
        };
      },
    }
  );

  const campaignOptions = useMemo<CampaignOption[]>(() => {
    const selectedCampaignIds = selectedCampaigns.map(
      (campaign) => campaign.campaignId
    );

    return campaigns.map((campaign) => ({
      title: `${campaign.campaignName} (${campaign.campaignId})`,
      subtitle: formatAdType(campaign.productType, t),
      value: campaign,
      selected: selectedCampaignIds.includes(campaign.campaignId),
    }));
  }, [campaigns, selectedCampaigns]);

  const handleCampaignSelectionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: Option
  ) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedCampaigns((prev) => [option.value, ...prev]);
    } else {
      setSelectedCampaigns((prev) =>
        prev.filter(
          (selectedOption) =>
            selectedOption.campaignId !== option.value.campaignId
        )
      );
    }
    setCreateSuccess(false);
  };

  const selectAllOptions = (checked: boolean) => {
    if (checked) {
      setSelectedCampaigns(campaignOptions.map((option) => option.value));
    } else {
      setSelectedCampaigns([]);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!newGroupName.trim()) {
      setNameError(t("amc.campaignGroup.nameRequired"));
      isValid = false;
    } else {
      setNameError("");
    }

    if (selectedCampaigns.length === 0) {
      return false;
    }

    return isValid;
  };

  const handleCreateCampaignGroup = async () => {
    if (!validateForm()) return;

    setCreateError("");
    setCreateSuccess(false);

    try {
      const result = await createCampaignGroup({
        mid,
        name: newGroupName,
        campaigns: selectedCampaigns.map((c) => ({
          id: c.campaignId,
          name: c.campaignName,
        })),
      }).unwrap();

      setCreateSuccess(true);

      if (result?.campaignGroup?._id) {
        onCreateCampaignGroup(result.campaignGroup);
        resetCreateForm();
      }
    } catch (error) {
      let errorMessage: string =
        (error as any)?.data || t("amc.campaignGroup.createError");
      if (axios.isAxiosError(error)) {
        errorMessage = (error as AxiosError).message || errorMessage;
      }

      setCreateError(errorMessage);
    }
  };

  return (
    <ModalPanel
      open={open}
      setOpen={setOpen}
      onClose={() => !isCreating && setOpen(false)}
      title={t("amc.createCampaignGroup.title")}
      content={
        <Box p={4}>
          {createSuccess && (
            <Box mb={3}>
              <InfoAlert message={t("amc.createCampaignGroup.success")} />
            </Box>
          )}
          {createError && (
            <Box mb={3}>
              <InfoAlert message={createError} type="error" />
            </Box>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label={t("amc.campaignGroup.nameLabel")}
                  placeholder={t("amc.campaignGroup.namePlaceholder")}
                  required
                  value={newGroupName}
                  onChange={(e) => {
                    setNewGroupName(e.target.value);
                    if (e.target.value.trim()) {
                      setNameError("");
                    }
                  }}
                  error={!!nameError}
                  helperText={nameError}
                  disabled={isCreating || createSuccess}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <SearchableMultiSelect
                  title={t("campaignCustomGroups.searchCampaigns")}
                  searchLimitText={t(
                    "campaignCustomGroups.searchLimitMessage",
                    {
                      pageSize: 100,
                    }
                  )}
                  setSearchText={setSearchText}
                  count={selectedCampaigns.length}
                  options={campaignOptions}
                  searching={campaignsLoading}
                  optionComponent={(option: CampaignOption) => (
                    <div>
                      <div>
                        <Bold variant="body2">{option.title}</Bold>
                      </div>
                      {option.subtitle && (
                        <Typography variant="subtitle2" color="textSecondary">
                          {option.subtitle}
                        </Typography>
                      )}
                    </div>
                  )}
                  changeSelectOption={handleCampaignSelectionChange}
                  selectAllOptions={selectAllOptions}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <RaisedButton
                type="button"
                color="primary"
                onClick={handleCreateCampaignGroup}
                disabled={
                  !newGroupName.trim() ||
                  selectedCampaigns.length === 0 ||
                  isCreating ||
                  createSuccess
                }
              >
                {isCreating ? (
                  <LoadingIndicator size={20} />
                ) : (
                  t("generic.save")
                )}
              </RaisedButton>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default AMCCampaignGroupForm;
