import styled, { useTheme } from "styled-components";

import AlertCard from "./alertCard";
import Bold from "../typography/bold";
import { Box } from "@material-ui/core";
import { PriorityHigh } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

export const AlertIcon = styled.div`
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.warning.light};
  margin-right: 1rem;
`;

interface MessageAlertProps {
  message: string;
}

const MessageAlert = ({ message }: MessageAlertProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <AlertCard isOpen={true} type="warning">
      <AlertIcon>
        <PriorityHigh fontSize="small" />
      </AlertIcon>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Bold
          variant="body1"
          $color={theme.palette.warning.contrastText as string}
        >
          {t(message)}
        </Bold>
      </Box>
    </AlertCard>
  );
};

export default MessageAlert;
