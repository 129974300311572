import {
  Checkbox,
  FormControlLabel,
  GridJustification,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { memo, useState } from "react";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import styled from "styled-components";

// Matching overall size of Download CSV Icon
const ColumnIcon = styled(ViewWeekIcon)`
  height: 20px;
  width: 20px;
  margin: -6px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const CheckedIcon = styled(CheckBoxIcon)<{
  disabled: boolean;
}>`
  height: 20px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.secondary.main : theme.palette.primary.main};
`;

const UncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  height: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const OptionHeader = styled.div`
  padding: 15px;
  border-bottom: ${({ theme }) => `1px ${theme.palette.grey["200"]} solid`};
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;

export interface Column {
  Header: string | React.ReactElement;
  id?: string;
  isVisible?: boolean;
  isLocked?: boolean;
  accessor?:
    | string
    | ((row: any) => string | Record<string, any> | number)
    // This accounts for the case when we have a cell/column for e.g. an action
    // button, which doesn't read any data
    | (() => void);
  Cell?: React.ReactNode;
  cellJustify?: GridJustification;
}

interface ColumnSelectProps {
  columns: Column[];
  setColumns: (columns: Column[]) => void;
  isColumnUpdating?: boolean;
}

const ColumnSelect = memo<ColumnSelectProps>(function ColumnSelect({
  columns,
  setColumns,
  isColumnUpdating,
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleColumnIndex = (index: number) => {
    // do not allow locked columns to be toggled
    if (columns[index].isLocked) {
      return;
    }
    const newColumns = [...columns];
    newColumns[index].isVisible = !columns[index].isVisible;
    setColumns(newColumns);
  };

  return (
    <div>
      <IconButton onClick={handleClick} title={"Select columns to display"}>
        <ColumnIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <OptionHeader>
          <Typography variant="h6">Show/Hide Columns</Typography>
        </OptionHeader>
        <OptionContainer>
          {columns.map((column, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disabled={isColumnUpdating}
                  checked={column.isVisible}
                  onChange={() => toggleColumnIndex(index)}
                  name={column.id}
                  icon={<UncheckedIcon />}
                  checkedIcon={
                    <CheckedIcon disabled={column.isLocked || false} />
                  }
                />
              }
              label={
                <Typography variant="subtitle1">{column.Header}</Typography>
              }
            />
          ))}
        </OptionContainer>
      </Popover>
    </div>
  );
});

export default ColumnSelect;
