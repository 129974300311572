import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { memo, useMemo } from "react";

import StyledArrowDropDown from "../toolbars/toolbarComponents/styledArrowDropdown";
import { ToolbarSelectBase } from "./toolbarSelect";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MultiSelectMenuItem = styled(MenuItem)<{ $width?: number | null }>`
  ${({ $width }) => ($width ? `width: ${$width}px;` : null)}
`;

const FlexBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

const SelectText = styled(Typography)`
  text-align: left;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type Option = {
  value: any;
  label: string;
};

interface MultiSelectProps {
  label?: string;
  currentValues: any[];
  handleChange: (values: any[]) => void;
  options: Option[];
  disabled?: boolean;
  maxSelections?: number;
}

const MultiSelect = memo<MultiSelectProps>(function MultiSelect({
  label,
  options,
  currentValues,
  handleChange,
  disabled,
  maxSelections,
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [menuWidth, setMenuWidth] = React.useState(
    anchorEl ? anchorEl.offsetWidth : null
  );
  const { t } = useTranslation();

  const selectedLabels = useMemo(() => {
    return options
      .filter((option) => currentValues.includes(option.value))
      .map((option) => option.label);
  }, [currentValues, options]);

  React.useEffect(() => {
    setMenuWidth(anchorEl ? anchorEl.offsetWidth : null);
  }, [anchorEl]);

  const openMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClick = (value: any, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }

    if (currentValues.includes(value)) {
      // Always allow deselection
      handleChange(currentValues.filter((v) => v !== value));
    } else if (!maxSelections || currentValues.length < maxSelections) {
      // Only allow selection if under max limit or no limit set
      handleChange([...currentValues, value]);
    }
  };

  const isAtMaxSelections =
    maxSelections && currentValues.length >= maxSelections;

  return (
    <Grid container item xs={12} direction="column">
      <ToolbarSelectBase
        id="multi-select"
        onClick={openMenu}
        disabled={disabled}
      >
        <FlexBox>
          <SelectText>
            {label
              ? t(label)
              : selectedLabels.length > 0
              ? selectedLabels.join(", ")
              : t("connectWizard.multiSelectLabel")}
          </SelectText>
          <StyledArrowDropDown />
        </FlexBox>
      </ToolbarSelectBase>
      <Menu
        id="multi-select-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        marginThreshold={1}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((option) => (
          <MultiSelectMenuItem
            key={option.value}
            $width={menuWidth}
            onClick={(e) => handleClick(option.value, e)}
            disabled={
              !!(isAtMaxSelections && !currentValues.includes(option.value))
            }
          >
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Checkbox
                  checked={currentValues.includes(option.value)}
                  size="small"
                />
                {option.label}
              </Grid>
            </Grid>
          </MultiSelectMenuItem>
        ))}
        {isAtMaxSelections && <Divider />}
        {isAtMaxSelections && (
          <MultiSelectMenuItem disabled $width={menuWidth}>
            <Typography color="textSecondary" variant="caption">
              {t("multiSelectDropdown.maxSelectionsReached", {
                max: maxSelections,
              })}
            </Typography>
          </MultiSelectMenuItem>
        )}
      </Menu>
    </Grid>
  );
});

export default MultiSelect;
