import { Box, Switch, Tooltip } from "@material-ui/core";

import { Detail } from "~/components/typography/detail";
import { InlineIconButton } from "~/icons/inlineIconButton";
import React from "react";
import { SettingItemProps } from "~/components/appSettings/menu";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface RowProps {
  isLastRow: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  ${({ isLastRow, theme }) =>
    isLastRow ? "" : `border-bottom: solid 1px ${theme.palette.border.main};`}
`;

const VendorRevenueMetricOption: React.FC<SettingItemProps> = ({
  title,
  tooltip,
  checked,
  setChecked,
  colour,
}) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" pt={0}>
      <Box display="flex" alignItems="center" pl={1} pr={1}>
        <Detail>{title}</Detail>
        <Tooltip title={tooltip}>
          <InlineIconButton />
        </Tooltip>
      </Box>
      <Row isLastRow={true} key={title}>
        <Switch
          size="small"
          color={colour ?? "secondary"}
          checked={checked}
          onClick={() => setChecked(!checked)}
        />
        <Detail>
          {t(
            checked
              ? "shippedRevenue.revenueOption"
              : "orderedRevenue.revenueOption"
          )}
        </Detail>
      </Row>
    </Box>
  );
};

export default VendorRevenueMetricOption;
