import { Filter, Range } from "~/typedef/store";
import Panel, { FooterLink } from "~/components/panel/panel";
import React, { ReactChild, memo, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { Column } from "~/components/adTable/columnSelect";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import NoScrollTable from "~/components/table/table";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { formatCurrency } from "~/utils/currencyUtils";
import { getPercentageDifference } from "~/utils/salesUtils";
import moment from "moment";
import { numberWithCommas } from "~/utils/utils";
import { useSalesPerformanceQuery } from "@store/overview/salesPerformance.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByIntervalProps {
  mid?: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  currentFilter?: Filter;
  footerLink?: FooterLink;
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  pageSize?: number;
  timezone: string;
  includeTax: boolean;
  conditionalFormatting?: boolean;
}

const SalesByIntervalWidget = memo<SalesByIntervalProps>(
  function SalesByIntervalWidget({
    mid,
    condensed,
    currentPeriod,
    currentRange,
    currentCurrency,
    currentFilter,
    actions,
    report,
    pageSize,
    includeTax,
    timezone,
  }) {
    const CONDENSED_ROWS = 5;
    const { t } = useTranslation();
    const [intervalSortDesc, setIntervalSortDesc] = useState(true);

    const toggleIntervalSortDesc = () => {
      setIntervalSortDesc(!intervalSortDesc);
    };

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );
    const vendorRevenueType = useTypedSelector(
      (state) =>
        state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
        "orderedRevenue"
    );

    const { salesPerformance, loading } = useSalesPerformanceQuery(
      {
        mid,
        filter: currentFilter,
        currentPeriod,
        currentRange,
        includeTax,
        currency: currentCurrency,
        includeVendor: true,
        vendorRevenueType,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            salesPerformance: data,
            loading: isFetching,
          };
        },
      }
    );

    const salesPerformanceData = useMemo(() => {
      return (
        salesPerformance?.chartData.map((row) => {
          const { current, prior } = row;
          const startDateStr = moment
            .unix(current.startTime)
            .tz(timezone)
            .format("D MMM YY");
          const endDataStr = moment
            .unix(current.endTime)
            .tz(timezone)
            .format("D MMM YY");
          const interval =
            startDateStr === endDataStr
              ? startDateStr
              : currentRange.interval === "w"
              ? `w/c. ${startDateStr}`
              : `${startDateStr} - ${endDataStr}`;
          return {
            interval,
            ...current,
            sales:
              current.sales != undefined
                ? formatCurrency(
                    current?.sales?.toFixed(2),
                    currencyRates,
                    salesPerformance.currency,
                    currentCurrency
                  )
                : "-",
            avgOrderValue:
              current.avgOrderValue != undefined
                ? formatCurrency(
                    current?.avgOrderValue?.toFixed(2),
                    currencyRates,
                    salesPerformance.currency,
                    currentCurrency
                  )
                : "-",
            salesChange: {
              value:
                current.sales != undefined
                  ? formatCurrency(
                      current?.sales?.toFixed(2),
                      currencyRates,
                      salesPerformance.currency,
                      currentCurrency
                    )
                  : "-",
              growth: prior?.sales
                ? getPercentageDifference(current.sales, prior.sales)
                : "N/A",
              hideValue: true,
            },
            ordersChange: {
              value:
                current.orders != undefined
                  ? numberWithCommas(current.orders)
                  : "-",
              growth: prior?.orders
                ? getPercentageDifference(current.orders, prior.orders)
                : "N/A",
              hideValue: true,
            },
            unitsChange: {
              value:
                current.unitsSold != undefined
                  ? numberWithCommas(current.unitsSold)
                  : "-",
              growth: prior?.unitsSold
                ? getPercentageDifference(current.unitsSold, prior.unitsSold)
                : "N/A",
              hideValue: true,
            },
            avgOrderValueChange: {
              value:
                current.avgOrderValue != undefined
                  ? formatCurrency(
                      current?.avgOrderValue?.toFixed(2),
                      currencyRates,
                      salesPerformance.currency,
                      currentCurrency
                    )
                  : "-",
              growth: prior?.avgOrderValue
                ? getPercentageDifference(
                    current.avgOrderValue,
                    prior.avgOrderValue
                  )
                : "N/A",
              hideValue: true,
            },
            ...(prior
              ? {
                  priorSales: formatCurrency(
                    prior?.sales?.toFixed(2),
                    currencyRates,
                    salesPerformance.currency,
                    currentCurrency
                  ),
                  priorAvgOrder: formatCurrency(
                    prior?.avgOrderValue?.toFixed(2),
                    currencyRates,
                    salesPerformance.currency,
                    currentCurrency
                  ),
                  priorUnits: numberWithCommas(prior?.unitsSold?.toFixed(0)),
                  priorOrders: numberWithCommas(prior?.orders?.toFixed(0)),
                }
              : {
                  priorAvgOrder: "N/A",
                  priorUnits: "N/A",
                  priorOrders: "N/A",
                  priorSales: "N/A",
                }),
          };
        }) || []
      );
    }, [
      salesPerformance?.chartData,
      currentRange,
      timezone,
      currencyRates,
      currentCurrency,
    ]);

    const salesPerformanceTableData = useMemo(() => {
      return intervalSortDesc
        ? salesPerformanceData.reverse()
        : salesPerformanceData;
    }, [intervalSortDesc, salesPerformanceData]);

    const columns: Column[] = useMemo(
      () => [
        {
          id: "timeIntervals",
          Header: `${t("myStoresWidget.salesByInterval.timeIntervals")} ${t(
            `generic.interval.${currentRange.interval}`
          )}`,
          accessor: "interval",
          Cell: TextCell,
          divideRight: true,
          customWidth: 250,
          isSorted: true,
          getSortDesc: () => {
            return intervalSortDesc;
          },
          onHeaderClick: () => {
            toggleIntervalSortDesc();
          },
        },
        {
          id: "sales",
          Header: t("myStoresWidget.salesByInterval.sales"),
          accessor: "sales",
          Cell: ValueCell,
          align: "right",
          customWidth: 180,
        },
        {
          id: "priorSales",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorSales",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 180,
        },
        {
          id: "salesChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "salesChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
          divideRight: true,
        },
        {
          id: "orders",
          Header: t("myStoresWidget.salesByInterval.orders"),
          accessor: "orders",
          Cell: ValueCell,
          align: "right",
          customWidth: 120,
        },
        {
          id: "priorOrders",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorOrders",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 120,
        },
        {
          id: "orderChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "ordersChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
          divideRight: true,
        },
        {
          id: "units",
          Header: t("myStoresWidget.salesByInterval.units"),
          accessor: "unitsSold",
          Cell: ValueCell,
          align: "right",
          customWidth: 120,
        },
        {
          id: "priorUnits",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorUnits",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 120,
        },
        {
          id: "unitsChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "unitsChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
          divideRight: true,
        },
        {
          id: "avgOrderValue",
          Header: t("myStoresWidget.salesByInterval.avgOrder"),
          accessor: "avgOrderValue",
          Cell: ValueCell,
          align: "right",
          customWidth: 120,
          hiddenDown: report ? "md" : "sm",
        },
        {
          id: "priorAvgOrder",
          Header: t("myStoresWidget.salesByInterval.comparison"),
          accessor: "priorAvgOrder",
          Cell: ValueCell,
          hiddenDown: report ? "md" : "sm",
          align: "right",
          customWidth: 120,
        },
        {
          id: "avgOrderValueChange",
          Header: t("myStoresWidget.salesByInterval.percentChange"),
          accessor: "avgOrderValueChange",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
          customWidth: 120,
        },
      ],
      [currentRange.interval, intervalSortDesc, t, report]
    );

    return (
      <Panel
        id="widget-sales-by-interval"
        title={t(`myStoresWidget.salesByInterval.mainTitle`)}
        actions={actions}
        content={
          loading ? (
            <PanelLoading />
          ) : condensed ? (
            <NoScrollTable
              {...{
                columns: columns,
                data: salesPerformanceTableData.slice(0, CONDENSED_ROWS),
                pageSize,
                loading,
                isReport: report,
              }}
            />
          ) : (
            <Box>
              <Table
                {...{
                  columns: columns,
                  data: salesPerformanceTableData,
                  gridLayoutColumns: 12,
                  pageSize: pageSize ?? 50,
                  pagination: true,
                  isReport: report,
                  isManualSort: true,
                  sortBy: [
                    {
                      id: "timeIntervals",
                      desc: intervalSortDesc,
                    },
                  ],
                }}
              />
            </Box>
          )
        }
      />
    );
  }
);

export default SalesByIntervalWidget;
