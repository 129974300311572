import {
  Box,
  Collapse,
  FormControl,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { FrequencyMenuItem, ReportType } from "./createReport";
import React, { useCallback, useMemo, useState } from "react";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";
import { TFunction, useTranslation } from "react-i18next";
import {
  filterTimezones,
  shouldLimitTimezones,
} from "~/components/dateRangePicker/fullDateTimePicker";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CurrencyDropdown from "~/components/toolbars/toolbarComponents/currencyDropdown";
import { InlineIconButton } from "~/icons/inlineIconButton";
import IntervalInput from "~/components/dateRangePicker/intervalInput";
import TimezoneSelect from "~/components/toolbars/toolbarComponents/timezoneDropdown";
import ToolbarSelect from "~/components/select/toolbarSelect";
import VendorRevenueMetricOption from "~/modules/widgets/amazonVendor/vendorRevenueMetricOption";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const StyledDownArrow = styled(ArrowDropDownIcon)`
  font-size: 20px;
  ${({ theme }) => `
  color: ${theme.palette.link.secondary};
  `}
`;

const StyledRightArrow = styled(ArrowRightIcon)`
  font-size: 20px;
  ${({ theme }) => `
  color: ${theme.palette.link.secondary};
  `}
`;

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.palette.link.secondary};
  `}
`;

interface ReportingDelayOption {
  value: number;
  text: string;
}

const getReportingDelayOptions = (
  t: TFunction<"translation", undefined>
): ReportingDelayOption[] => {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
    switch (num) {
      case 0:
        return { value: num, text: t("createReport.noReportingDelay") };
      case 1:
        return { value: num, text: `${num} ${t("day")}` };
      default:
        return { value: num, text: `${num} ${t("days")}` };
    }
  });
};

interface AdvancedConfigurationProps {
  includeTax: boolean;
  conditionalFormatting: boolean;
  vendorRevenueType: "orderedRevenue" | "shippedRevenue";
  setIncludeTax: (includeTax: boolean) => void;
  setConditionalFormatting: (conditionalFormatting: boolean) => void;
  setTimezone: (timezone: string) => void;
  setCurrency: (curr: string) => void;
  setInterval: (interval: INTERVAL) => void;
  setReportingDelay: (reportingDelay: number | undefined) => void;
  setVendorRevenue: (newvalue: "orderedRevenue" | "shippedRevenue") => void;
  currency: string;
  fromDate: number;
  toDate: number;
  period: DATETIME_PERIODS;
  timezone: string;
  interval: INTERVAL;
  frequency: string | number;
  reportType: ReportType;
  reportingDelay?: number;
  showVendorRevenueRadios?: boolean;
}

const AdvancedConfiguration = ({
  includeTax,
  conditionalFormatting,
  currency,
  fromDate,
  toDate,
  period,
  timezone,
  interval,
  frequency,
  reportingDelay,
  setIncludeTax,
  setConditionalFormatting,
  setTimezone,
  setInterval,
  setCurrency,
  setReportingDelay,
  setVendorRevenue,
  vendorRevenueType,
  reportType,
  showVendorRevenueRadios,
}: AdvancedConfigurationProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const vendorDefaultRevenueCheck = vendorRevenueType == "shippedRevenue";

  const includeTaxItem: SettingsMenuItem = {
    title: t("globalSettings.includeTax"),
    checked: includeTax,
    setChecked: (newValue: boolean) => dispatch(setIncludeTax(newValue)),
    tooltip: t("includeTax.tooltip"),
  };

  const vendorMetricOptionsItem: SettingsMenuItem = {
    title: t("globalSettings.vendorRevenueMetric"),
    checked: vendorDefaultRevenueCheck,
    setChecked: (newValue: boolean) =>
      dispatch(
        setVendorRevenue(newValue ? "shippedRevenue" : "orderedRevenue")
      ),
    tooltip: t("vendorRevenueMetric.tooltip"),
  };

  const conditionalFormattingItem: SettingsMenuItem = {
    title: t("globalSettings.conditionalFormatting"),
    checked: conditionalFormatting,
    setChecked: (newValue: boolean) => setConditionalFormatting(newValue),
    tooltip: t("conditionalFormatting.tooltip"),
  };

  const [reportingDelayAnchorEl, setReportingDelayAnchorEl] =
    useState<HTMLElement | null>(null);
  const [reportingDelayMenuWidth, setReportingDelayMenuWidth] = useState(
    reportingDelayAnchorEl ? reportingDelayAnchorEl.offsetWidth : null
  );

  const handleReportingDelaySelect = (option: ReportingDelayOption) => {
    setReportingDelay(option.value);
    setReportingDelayAnchorEl(null);
  };

  const reportingDelayOptions = useMemo(() => getReportingDelayOptions(t), [t]);
  const ReportingDelayOption = useCallback(
    ({ option }: { option: ReportingDelayOption }) => (
      <FrequencyMenuItem
        key={option.text}
        width={reportingDelayMenuWidth}
        onClick={() => handleReportingDelaySelect(option)}
      >
        <Typography variant="body2">{option.text}</Typography>
      </FrequencyMenuItem>
    ),
    [reportingDelayMenuWidth]
  );
  const renderReportingDelayOptions = useCallback(
    () =>
      reportingDelayOptions.map((option) => (
        <ReportingDelayOption key={option.value} option={option} />
      )),
    [reportingDelayOptions]
  );

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="flex-end"
        onClick={() => {
          setExpanded(!expanded);
        }}
        pb={2}
      >
        <StyledTypography>
          {t("createReport.advancedConfiguration")}
        </StyledTypography>
        {expanded ? <StyledDownArrow /> : <StyledRightArrow />}
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <FormControl>
              <Box>
                <CurrencyDropdown
                  currentCurrency={currency}
                  switchCurrency={setCurrency}
                />
              </Box>
            </FormControl>
          </Grid>
          {frequency !== 1 && (
            <>
              <Grid item>
                <FormControl fullWidth>
                  <ToolbarSelect
                    {...{
                      id: "report-delay-select",
                      title: t("createReport.selectReportingDelayTitle"),
                      renderOptions: renderReportingDelayOptions,
                      anchorEl: reportingDelayAnchorEl,
                      setAnchorEl: setReportingDelayAnchorEl,
                      menuWidth: reportingDelayMenuWidth,
                      setMenuWidth: setReportingDelayMenuWidth,
                      rightAlign: false,
                      tooltip: t("reportingDelay.tooltip"),
                      displayComponent: (
                        <ReportingDelayOption
                          option={
                            reportingDelayOptions.find(
                              (option) => option.value === reportingDelay
                            ) || reportingDelayOptions[0]
                          }
                        />
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <Box width={"100%"}>
                    <TimezoneSelect
                      handleSelect={setTimezone}
                      selectedTimezone={timezone}
                      tooltip={
                        shouldLimitTimezones(interval)
                          ? t("timezoneSelect.disabledInterval")
                          : t("createReport.timezoneTooltip")
                      }
                      interval={interval}
                      filterTimezones={filterTimezones}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <Box width={"100%"}>
                    <IntervalInput
                      chartInterval={interval}
                      setChartInterval={setInterval}
                      startDate={moment.unix(fromDate).toDate()}
                      endDate={moment.unix(toDate).toDate()}
                      currentPeriod={period}
                    />
                  </Box>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item>
            <FormControl>
              <Box pl={2} dir="row">
                <Typography variant="subtitle1" color="textSecondary" noWrap>
                  {t("globalSettings.includeTax")}
                </Typography>
                {includeTaxSwitch(includeTaxItem)}
              </Box>
            </FormControl>
          </Grid>
          {reportType === ReportType.MULTI && showVendorRevenueRadios && (
            <Grid item>
              <FormControl>
                <Box pl={2} dir="row">
                  <VendorRevenueMetricOption
                    title={vendorMetricOptionsItem.title}
                    tooltip={vendorMetricOptionsItem.tooltip}
                    checked={vendorMetricOptionsItem.checked}
                    setChecked={vendorMetricOptionsItem.setChecked}
                  />
                </Box>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormControl>
              <Box pl={2} dir="row">
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" color="textSecondary" noWrap>
                    {t("globalSettings.conditionalFormatting")}
                  </Typography>
                  <Tooltip
                    title={
                      t("globalSettings.conditionalFormattingTooltip") as string
                    }
                  >
                    <InlineIconButton aria-label="info" />
                  </Tooltip>
                </Box>
                {includeTaxSwitch(conditionalFormattingItem)}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default AdvancedConfiguration;
