import {
  Box,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";

import { AMCAdditionalFieldValues } from "~/typedef/amc/scheduledReport";
import AMCCampaignGroupForm from "./amcCampaignGroupForm";
import AddIcon from "@material-ui/icons/Add";
import { CurrentStore } from "~/typedef/store";
import { InlineIconButton } from "~/icons/inlineIconButton";
import MultiSelect from "~/components/select/multiSelectDropDown";
import { PrunedCampaignGroup } from "~/typedef/amc/campaignGroups";
import styled from "styled-components";
import { useGetCampaignGroupsQuery } from "~/store/mystore/amc.redux";
import { useTranslation } from "react-i18next";

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AddButton = styled(IconButton)`
  min-width: 25px;
  min-height: 25px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

interface AMCCampaignGroupFieldProps {
  label: string;
  required: boolean;
  tooltip?: string;
  values: AMCAdditionalFieldValues;
  onChange: (data: AMCAdditionalFieldValues) => void;
  store: CurrentStore;
  maxSelections?: number;
}

const AMCCampaignGroupField: React.FC<AMCCampaignGroupFieldProps> = ({
  label,
  required,
  tooltip,
  values,
  onChange,
  store,
  maxSelections = 5,
}) => {
  const { t } = useTranslation();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const { campaignGroups, isFetching: campaignGroupsLoading } =
    useGetCampaignGroupsQuery(
      { mid: store.merchantId },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            campaignGroups: data?.campaignGroups ?? [],
            isFetching,
          };
        },
      }
    );

  const selectedGroupIds = useMemo(() => {
    const result: string[] = [];
    for (let i = 1; i <= maxSelections; i++) {
      const groupKey = `group${i}` as keyof typeof values;
      const groupNameKey = `group${i}name` as keyof typeof values;

      if (values[groupKey]?.length && values[groupNameKey]) {
        const foundGroup = campaignGroups.find(
          (group) => group.name === values[groupNameKey]
        );

        if (foundGroup?._id) {
          result.push(foundGroup._id);
        }
      }
    }
    return result;
  }, [values, campaignGroups, maxSelections]);

  const handleOnChange = (
    selectedIds: string[],
    newGroup?: PrunedCampaignGroup
  ) => {
    const newValues: AMCAdditionalFieldValues = {};

    for (let i = 1; i <= maxSelections; i++) {
      newValues[`group${i}`] = [];
      newValues[`group${i}name`] = "";
    }

    selectedIds.forEach((groupId, index) => {
      if (index < maxSelections) {
        const position = index + 1;
        if (newGroup && groupId === newGroup._id) {
          newValues[`group${position}name`] = newGroup.name;
          newValues[`group${position}`] = newGroup.campaigns.map((c) => c.id);
        } else {
          const group = campaignGroups.find((g) => g._id === groupId);

          if (group) {
            newValues[`group${position}name`] = group.name;
            newValues[`group${position}`] = group.campaigns.map((c) => c.id);
          }
        }
      }
    });

    onChange(newValues);
  };

  const onCreateCampaignGroup = (group: PrunedCampaignGroup) => {
    handleOnChange([...selectedGroupIds, group._id], group);
  };

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  const multiSelectOptions = useMemo(() => {
    return campaignGroups.map((group) => ({
      value: group._id,
      label: group.name,
    }));
  }, [campaignGroups]);

  return (
    <>
      <FormControl fullWidth>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary">
            {t(label)} {required && <span>*</span>}
          </Typography>
          {tooltip && (
            <Tooltip title={t(tooltip)}>
              <InlineIconButton />
            </Tooltip>
          )}
        </Box>
        <SelectWrapper>
          <Box flex="1">
            <MultiSelect
              currentValues={selectedGroupIds}
              handleChange={handleOnChange}
              options={multiSelectOptions}
              disabled={campaignGroupsLoading || !store}
              maxSelections={maxSelections}
            />
          </Box>
          <Tooltip title={t("amc.campaignGroups.createNew")}>
            <AddButton
              size="small"
              onClick={handleOpenCreateDialog}
              disabled={!store}
              aria-label={t("amc.campaignGroups.createNew")}
            >
              <AddIcon fontSize="small" />
            </AddButton>
          </Tooltip>
        </SelectWrapper>
      </FormControl>
      {createDialogOpen && (
        <AMCCampaignGroupForm
          mid={store.merchantId}
          open={createDialogOpen}
          setOpen={setCreateDialogOpen}
          onCreateCampaignGroup={onCreateCampaignGroup}
        />
      )}
    </>
  );
};

export default AMCCampaignGroupField;
