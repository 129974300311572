import React, { memo, useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";

import cookies from "browser-cookies";
import { useAppStatusQuery } from "@store/appStatus.redux";

interface MaintenanceBoundaryProps {
  children: React.ReactElement;
}

const MaintenanceBoundary = memo<MaintenanceBoundaryProps>(({ children }) => {
  const location = useLocation();

  /* Allow devs to debug behind maintenance */
  const msDevDebug = cookies.get("msdevdebug") ? true : false;
  /* Allow devs to debug behind maintenance END */

  const { appDown } = useAppStatusQuery(
    {},
    {
      pollingInterval: 60000, // refetch every minute
      selectFromResult: ({ data }) => ({
        appDown: data?.down ?? false,
      }),
    }
  );

  const displayMaintenanceWall = useMemo(
    () => appDown && !msDevDebug,
    [appDown, msDevDebug]
  );

  if (displayMaintenanceWall && location.pathname !== "/maintenance") {
    return (
      <Redirect
        to={{
          pathname: "/maintenance",
        }}
      />
    );
  }
  if (!displayMaintenanceWall && location.pathname === "/maintenance") {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return children;
});

export default MaintenanceBoundary;
