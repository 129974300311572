import { Box, Grid, useTheme } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import {
  StackedBarChartConfig,
  VisualizationType,
} from "~/typedef/amc/reportType";

import { CurrentStore } from "~/typedef/store";
import HorizontalStackedBarChart from "~/components/charts/horizontalStackedBarChart/horizontalStackedBarChart";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import { isNil } from "lodash";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useReportRunChartDataQuery } from "~/store/mystore/amc.redux";
import { useTranslation } from "react-i18next";

const DEFAULT_REMAINING_BAR = {
  key: "other",
  label: "other",
};

interface AMCStackedBarChartProps {
  store: CurrentStore;
  reportTypeName: string;
  currentReportRunId: string;
  chartConfig: StackedBarChartConfig;
  currentCurrency: string;
}

const AMCStackedBarChart = memo<AMCStackedBarChartProps>(
  function AMCStackedBarChart({
    reportTypeName,
    currentReportRunId,
    chartConfig,
    currentCurrency,
  }) {
    const { t } = useTranslation();

    const theme = useTheme();
    const barColors = [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.chart.darkBlue,
      theme.palette.chart.orange,
      theme.palette.chart.purple,
      theme.palette.chart.lightGreen,
      theme.palette.chart.spaceGray,
      theme.palette.chart.deepOrange,
      theme.palette.chart.pink,
      theme.palette.chart.lightGreen1,
      theme.palette.chart.lightBlue1,
      theme.palette.chart.brown,
    ];

    const {
      currentRange: { interval },
    } = useDateRangeFilters();

    const { currentData, isFetchingCurrentData } = useReportRunChartDataQuery(
      {
        reportRunId: currentReportRunId,
        type: VisualizationType.StackedBarChart,
        interval,
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          return {
            currentData: data?.chartData ?? [],
            reportColumns: data?.reportColumns ?? [],
            isFetchingCurrentData: isFetching,
          };
        },
      }
    );

    const { bars, totalsTooltip, barLabelsKey } = useMemo(() => {
      return {
        bars: [
          ...chartConfig.columnsToInclude.map((column, index) => ({
            title: column.label,
            key: column.key,
            colour: barColors[index % barColors.length],
          })),
          ...(chartConfig.formatAsPercentage
            ? [
                {
                  title:
                    chartConfig.remaining?.label ?? DEFAULT_REMAINING_BAR.label,
                  key: chartConfig.remaining?.key ?? DEFAULT_REMAINING_BAR.key,
                  colour:
                    barColors[
                      chartConfig.columnsToInclude.length % barColors.length
                    ],
                },
              ]
            : []),
        ],
        totalsTooltip: chartConfig.totalColumn
          ? {
              title: chartConfig.totalColumn.label,
              key: chartConfig.totalColumn.key,
              view: chartConfig.totalColumn.view,
              colour: theme.palette.common.black,
            }
          : undefined,
        barLabelsKey: chartConfig.bucketBy.columnKey,
      };
    }, [chartConfig]);

    const chartData = useMemo(() => {
      const computeRemaining =
        !isNil(chartConfig.totalColumn) && !isNil(chartConfig.remaining);
      const remainingKey =
        chartConfig.remaining?.key ?? DEFAULT_REMAINING_BAR.key;
      const formatAsPercentage = chartConfig.formatAsPercentage ?? false;

      return currentData.map((record) => {
        const totalColumnValue = chartConfig.totalColumn?.key
          ? record[chartConfig.totalColumn.key]
          : null;
        const totalValue = chartConfig.totalColumn
          ? typeof totalColumnValue === "number"
            ? totalColumnValue
            : chartConfig.columnsToInclude.reduce((total, column) => {
                const columnValue = record[column.key];
                total += typeof columnValue === "number" ? columnValue : 0;
                return total;
              }, 0)
          : null;
        let remainingValue = totalValue;

        const columnKeyValue = record[chartConfig.bucketBy.columnKey]
          ? record[chartConfig.bucketBy.columnKey].toString()
          : "Unknown";
        const parsedRecord = chartConfig.columnsToInclude.reduce<
          Record<string, number | string>
        >(
          (acc, column) => {
            const columnValue = record[column.key] ?? 0;
            acc[column.key] =
              typeof columnValue === "number"
                ? formatAsPercentage && !isNil(totalValue)
                  ? totalValue > 0
                    ? (columnValue / totalValue) * 100
                    : 0
                  : columnValue
                : 0;
            if (remainingValue) {
              remainingValue -=
                typeof columnValue === "number" ? columnValue : 0;
            }
            return acc;
          },
          {
            [`${chartConfig.bucketBy.columnKey}`]: columnKeyValue,
          }
        );

        if (computeRemaining && !isNil(remainingValue)) {
          parsedRecord[remainingKey] =
            formatAsPercentage && !isNil(totalValue)
              ? totalValue > 0
                ? (remainingValue / totalValue) * 100
                : 0
              : remainingValue;
        }

        if (chartConfig.totalColumn?.key && !isNil(totalValue)) {
          parsedRecord[chartConfig.totalColumn.key] = totalValue;
        }

        return parsedRecord;
      });
    }, [currentData, chartConfig, t]);

    return (
      <Panel
        id="widget-amc-stacked-bar-chart"
        title={t(`amcWidget.stackedBarChart.${reportTypeName}Title`)}
        tooltip={t(`amcWidget.stackedBarChart.${reportTypeName}Tooltip`)}
        content={
          isFetchingCurrentData ? (
            <Box>
              <LoadingIndicator />
            </Box>
          ) : (
            <Box p={2}>
              <HorizontalStackedBarChart
                isLoading={isFetchingCurrentData}
                currentCurrency={currentCurrency}
                bars={bars}
                barLabelsKey={barLabelsKey}
                totalsTooltip={totalsTooltip}
                chartData={chartData}
                view={chartConfig.view}
              />
            </Box>
          )
        }
      />
    );
  }
);

export default AMCStackedBarChart;
