import {
  FETCH_MARKETPLACE_OVERVIEW_TOTALS,
  FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR,
  FETCH_MARKETPLACE_OVERVIEW_TOTALS_FETCHING,
} from "./overview.redux";
import { Filter, Range } from "~/typedef/store";

import { Dispatch } from "redux";
import { User } from "~/typedef/user";
import { api } from "../apiSlice";
import axios from "axios";
import { baseUrl } from "../../configs";
import { dispatchError } from "../utils/error.redux";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

async function setFetchingTotals(dispatch: Dispatch) {
  await dispatch({
    type: FETCH_MARKETPLACE_OVERVIEW_TOTALS_FETCHING,
  });
}

interface FetchMarketplaceOverviewTotalsParams {
  user: User;
  currentPeriod: string;
  currentRange: Range;
  filter: Filter;
  includeTax: boolean;
  vendorRevenueType?: "orderedRevenue" | "shippedRevenue";
}

export const fetchMarketplaceOverviewTotals =
  (
    params: FetchMarketplaceOverviewTotalsParams,
    oldParams?: FetchMarketplaceOverviewTotalsParams
  ) =>
  async (dispatch: Dispatch) => {
    const {
      user,
      currentPeriod,
      currentRange,
      filter,
      includeTax,
      vendorRevenueType,
    } = params;
    if (!shouldUseCache(params, oldParams)) {
      await setFetchingTotals(dispatch);
      const res = await axios
        .post(
          `${baseUrl}/api/generic-mws-service/api/marketplaceOverviewExpanded/summary`,
          {
            currentPeriod,
            ...currentRange,
            filter,
            includeTax,
            vendorRevenueType,
          }
        )
        .catch((e) => {
          const err = get(e, "response.data.errMsg");
          const msg = get(err, "data.error");
          const statusText = get(err, "statusText");
          dispatchError(dispatch, FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR);
          return setError(dispatch, msg || statusText, get(err, "status"));
        });
      if (res) {
        if (isHttpResponseValid(res.status)) {
          const data = res.data;
          return await dispatch({
            type: FETCH_MARKETPLACE_OVERVIEW_TOTALS,
            payload: {
              ...data,
              params,
            },
          });
        } else {
          await dispatchError(
            dispatch,
            FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR
          );
          return setError(dispatch, res.data.errMsg, res.status);
        }
      }
      await dispatchError(dispatch, FETCH_MARKETPLACE_OVERVIEW_TOTALS_ERROR);
      return setError(dispatch);
    }
  };

interface MarketplaceOverviewExpandedArgs {
  currentPeriod: string;
  currentRange: Range;
  filter: Filter;
  includeTax: boolean;
  pageIndex?: number;
  pageSize?: number;
  sortKey: string;
  sortOrder: string;
  searchText?: string;
  vendorRevenueType?: "orderedRevenue" | "shippedRevenue";
}

export interface MarketplaceOverviewExpandedEntry {
  storeId: number;
  marketplace: {
    market: string;
    countryCode: string;
  };
  store: string;
  currency: string;
  sales: {
    value: number;
    priorPeriodValue: number;
  };
  orders: {
    value: number;
    priorPeriodValue: number;
  };
  avOrderValue: {
    value: number;
    priorPeriodValue: number;
  };
  avOrderSize: {
    value: number;
    priorPeriodValue: number;
  };
  units: {
    value: number;
    priorPeriodValue: number;
  };
  salesSplit: number;
  outOfStockPercentage: number;
  healthStatus: string;
  buyBoxSnapshot: number | null;
  forecastTarget: number | null;
  adspendLimit: number | null;
  budgetTarget: number | null;
}

export interface MarketplaceOverviewExpandedResponse {
  rows: MarketplaceOverviewExpandedEntry[];
  count: number;
}

interface MarketplaceOverviewExpandedAdvertisingArgs {
  currentRange: Range;
  storeIds: number[];
  searchText?: string;
  filter: Filter;
}

export interface MarketplaceOverviewExpandedAdvertisingEntry {
  storeId: number;
  adSpend: number;
  adSales: number;
  acos: number;
}

interface MarketplaceOverviewExpandedAdvertisingResponse {
  rows: MarketplaceOverviewExpandedAdvertisingEntry[];
}

interface MarketplaceOverviewArgs {
  currentPeriod: string;
  currentRange: Range;
  filter: Filter;
  includeTax: boolean;
  vendorRevenueType?: "orderedRevenue" | "shippedRevenue";
  pageIndex: number;
  pageSize: number;
}

export interface MarketplaceOverviewRow {
  marketplace: {
    market: string;
    countryCode: string;
  };
  store: string;
  currency: string;
  salesSplit: number;
  sales: {
    value: string;
    priorPeriodValue: string;
  };
  orders: {
    value: number;
    priorPeriodValue: number;
  };
  avOrderValue: {
    value: number;
    priorPeriodValue: number;
  };
  avOrderSize: {
    value: number;
    priorPeriodValue: number;
  };
}

interface MarketplaceOverviewResponse {
  rows: MarketplaceOverviewRow[];
  count: number;
}

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    marketplaceOverview: build.query<
      MarketplaceOverviewResponse,
      MarketplaceOverviewArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/marketplaceOverview`,
          method: "POST",
          data: {
            ...currentRange,
            ...otherParams,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    marketplaceOverviewExpanded: build.query<
      MarketplaceOverviewExpandedResponse,
      MarketplaceOverviewExpandedArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/marketplaceOverviewExpanded`,
          method: "POST",
          data: {
            ...currentRange,
            ...otherParams,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    marketplaceOverviewExpandedAdvertising: build.query<
      MarketplaceOverviewExpandedAdvertisingResponse,
      MarketplaceOverviewExpandedAdvertisingArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/marketplaceOverviewExpanded/advertising`,
          method: "POST",
          data: {
            ...currentRange,
            ...otherParams,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const {
  useMarketplaceOverviewQuery,
  useMarketplaceOverviewExpandedQuery,
  useMarketplaceOverviewExpandedAdvertisingQuery,
} = extendedApiSlice;
