import { get, sortedUniq } from "lodash";

import { Filter } from "~/typedef/store";
import FilterSelect from "../../select/filterSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CountryDropdownProps {
  filterWidth?: number;
  selectedFilters: Filter;
  setSelectedFilters: (filter: Filter) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  selectedFilters,
  setSelectedFilters,
  filterWidth,
  fullWidth,
  disabled,
}) => {
  const { t } = useTranslation();
  const allStores = useTypedSelector((state) =>
    get(state, "mystore.allStores.stores", [])
  );
  const flattenedCountries = sortedUniq(
    allStores.map((s) => s.marketplaceCountry).sort()
  );
  const selectedCountries = get(selectedFilters, "countries");

  const handleOptionChange = (countries: string[]) => {
    setSelectedFilters({ ...selectedFilters, countries: countries });
  };

  return (
    <FilterSelect
      title={t(`filterTitle.country`)}
      isFullWidth={fullWidth ?? false}
      maxWidth={filterWidth}
      options={flattenedCountries}
      selectedOptions={selectedCountries}
      handleSelect={handleOptionChange}
      isCountry
      isDisplayIcons
      filterTitle={t("filters.allCountries")}
      canSelectAll
      disabled={disabled ?? false}
      isMarketplace={false}
      condensed={false}
      searchable={false}
    />
  );
};

export default CountryDropdown;
