import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { CurrentStore, StoreState } from "~/typedef/store";
import React, { memo, useMemo } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { AvailabilityCell } from "~/components/table/cells/availabilityCell";
import { Column } from "~/components/adTable/columnSelect";
import DownloadCsv from "../reportDownload/downloadCsv";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import Panel from "~/components/panel/panel";
import PanelSelect from "~/components/select/panelSelect";
import PrimeIcon from "~/img/amazon_prime.png";
import Table from "~/components/table/table";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchOutOfStockProducts } from "../../store/overview/outOfStockProducts.redux";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface OutOfStockTableProps {
  store: CurrentStore;
  market: string;
}

interface Product {
  title: string;
  productSku: string;
  sku: string;
  url: string;
  imageUrl: string;
  noQuantitySinceTime: moment.MomentInput;
  sales: { value: number; currency: string; quantity: number };
  missedSales: number;
  availableQuantity: number;
  daysCover: number;
}

const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_DAYS_COVER_LIMIT = 30;

const OutOfStockTable = memo(({ market, store }: OutOfStockTableProps) => {
  const { t } = useTranslation();

  const currentCurrency = useTypedSelector((state: StoreState) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const currencyRates = useTypedSelector(
    (state: StoreState) => state.globalVar.currencyRates
  );
  const exchangeRate = getExchangeRate(
    currencyRates,
    getCurrencyByCountryCode[store.marketplaceCountry],
    currentCurrency
  );
  const includeTax: boolean = useTypedSelector((state: StoreState) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const outOfStockProducts = useTypedSelector(
    (state: StoreState) => state.mystore.outOfStockProducts
  );
  const [hasDispatched, setHasDispatched] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const daysCoverLimit =
    useTypedSelector((state) => state.notifications.daysCoverLimits.data)?.find(
      (d) => d.mid === store.merchantId
    )?.daysCoverLimit ?? DEFAULT_DAYS_COVER_LIMIT;
  const [daysCoverFilter, setDaysCoverFilter] = React.useState<number[]>([
    0,
    daysCoverLimit,
  ]);
  const filterOptions = [
    { value: [0, 0], label: t("myStoresWidget.outOfStock.outOfStockLabel") },
    {
      value: [1, daysCoverLimit],
      label: t("myStoresWidget.outOfStock.atRiskLabel"),
    },
    {
      value: [null, null],
      label: t("myStoresWidget.outOfStock.allListingsLabel"),
    },
  ];

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      dispatch(
        fetchOutOfStockProducts({
          mid: store.merchantId,
          pageSize,
          pageIndex,
          sortKey: sortBy.length > 0 ? sortBy[0].id : "no_quantity_since_time",
          sortOrder:
            sortBy.length > 0 ? (sortBy[0].desc ? "desc" : "asc") : "desc",
          daysCoverFilter,
          includeTax,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [market, store, daysCoverFilter, includeTax]
  );
  React.useEffect(() => {
    setHasDispatched(true);
  }, []);

  const data = useMemo(
    () =>
      get(outOfStockProducts, "data", []).map((product: Product) => ({
        title: {
          value: product.title,
          secondRowValue: `${market === "amazon" ? "ASIN" : "LISTING ID"}: ${
            product.productSku
          }   |    SKU: ${product.sku}`,
          link: product.url,
          image: product.imageUrl,
          target: "_blank",
        },
        daysOutOfStock:
          product.noQuantitySinceTime &&
          moment().diff(moment(product.noQuantitySinceTime), "days")
            ? moment().diff(moment(product.noQuantitySinceTime), "days")
            : "-",
        sales: product.sales.value
          ? formatCurrency(
              product.sales.value,
              currencyRates,
              product.sales.currency,
              currentCurrency
            )
          : "-",
        quantitySold: product.sales.quantity ? product.sales.quantity : "-",
        missedSales: product.missedSales
          ? formatCurrency(
              product.missedSales,
              currencyRates,
              product.sales.currency,
              currentCurrency
            )
          : "-",
        availableQuantity: product.availableQuantity,
        daysCover: product.daysCover,
        icon: get(product, "marketplaceSpecific.isFulfilledByAmazon") && (
          <Tooltip title={t("generic.fulfilledByAmazon") as string}>
            <SmallIcon src={PrimeIcon} />
          </Tooltip>
        ),
      })),
    [outOfStockProducts, market, currencyRates, currentCurrency, t]
  );

  const columns: Column[] = useMemo(
    () => [
      {
        Header: t("myStoresWidget.outOfStock.productColumn"),
        id: "title",
        accessor: "title",
        Cell: (props: any) => (
          <LinkAndImageCell {...props} colorVariant="external" />
        ),
        colSpan: 2,
      },
      {
        Header: t("myStoresWidget.outOfStock.daysOutOfStockColumn"),
        id: "no_quantity_since_time",
        accessor: "daysOutOfStock",
        align: "right",
      },
      {
        Header: t("myStoresWidget.outOfStock.salesColumn"),
        id: "sales",
        accessor: "sales",
        disableSortBy: true,
        hiddenDown: "sm",
        align: "right",
      },
      {
        Header: t("myStoresWidget.outOfStock.unitsSoldColumn"),
        id: "quantity_sold",
        accessor: "quantitySold",
        disableSortBy: true,
        align: "right",
        hiddenDown: "sm",
      },
      {
        Header: t("myStoresWidget.outOfStock.missedSalesColumn"),
        id: "missed_sales",
        accessor: "missedSales",
        disableSortBy: true,
        hiddenDown: "sm",
        align: "right",
        Cell: ValueCell,
      },
      {
        Header: t("myStoresWidget.outOfStock.availableQuantityColumn"),
        id: "available_quantity",
        accessor: (row: { availableQuantity: number; daysCover: number }) => ({
          availableValue: row.availableQuantity,
          compareValue: row.daysCover,
          threshold: daysCoverLimit,
        }),
        align: "right",
        Cell: AvailabilityCell,
      },
    ],
    []
  );

  const currentValue = useMemo(
    () =>
      filterOptions.find((option) => isEqual(option?.value, daysCoverFilter))
        ?.label,
    [daysCoverFilter]
  );

  return (
    <Panel
      id="widget-out-of-stock-products"
      title={t("myStoresWidget.outOfStock.mainTitle")}
      tooltip={undefined}
      actions={
        <>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} sm={5} md={5}>
              <PanelSelect
                {...{
                  currentValue,
                  handleChange: (value: number[]) => setDaysCoverFilter(value),
                  options: filterOptions,
                }}
              />
            </Grid>
          </Grid>
          <DownloadCsv
            {...{
              mid: store.merchantId,
              reportType: "outOfStock",
              path: "/api/generic/outOfStock",
              params: {
                market,
                shopName: store.storeName,
                countryCode: store.marketplaceCountry,
                currentCurrency,
                exchangeRate,
                mid: store.merchantId,
                sortKey: "no_quantity_since_time",
                sortOrder: "desc",
                daysCoverFilter,
                includeTax,
              },
            }}
          />
        </>
      }
      content={
        !outOfStockProducts.error || !hasDispatched ? (
          <Table
            {...{
              filter: daysCoverFilter.toString(), // expected a string instead of array
              // only used in a useEffect dependency array
              columns,
              data,
              pagination: true,
              sorting: true,
              pageCount: Math.ceil(
                outOfStockProducts.count / DEFAULT_PAGE_SIZE
              ),
              pageSize: DEFAULT_PAGE_SIZE,
              loading: outOfStockProducts.fetching,
              fetchData: fetchData,
            }}
          />
        ) : (
          <Box p={2}>
            <Typography align="center" color="error">
              {outOfStockProducts.error instanceof Error
                ? outOfStockProducts.error.message
                : "An unexpected error has occurred."}
            </Typography>
          </Box>
        )
      }
    />
  );
});

export default OutOfStockTable;
