import { Grid } from "@material-ui/core";
import React from "react";
import SyncButton from "~/components/buttons/syncButton";
import TimePeriodDropdown from "./timePeriodDropdown";
import styled from "styled-components";

const SyncGridItem = styled(Grid)`
  flex-grow: 0;
  flex-shrink: 0;
`;

interface TimePeriodDropdownWithSyncProps {
  isDisabled?: boolean;
}

const TimePeriodDropdownWithSync: React.FC<TimePeriodDropdownWithSyncProps> = ({
  isDisabled,
}) => {
  return (
    <Grid
      container
      alignItems="flex-end"
      justifyContent="flex-start"
      wrap="nowrap"
    >
      <SyncGridItem>
        <SyncButton />
      </SyncGridItem>
      <Grid item xs>
        <TimePeriodDropdown fullWidth={true} isDisabled={isDisabled} />
      </Grid>
    </Grid>
  );
};

export default TimePeriodDropdownWithSync;
