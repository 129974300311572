import { Redirect, Route, useLocation } from "react-router-dom";

import { MenuItem } from "~/components/toolbars/sideNavigation/sideNavConstants";
import React from "react";
import { User } from "~/typedef/user";
import { findMenuItem } from "~/components/toolbars/sideNavigation/sideNavUtils";
import { useSideNavConfig } from "~/hooks/useSideNavConfig";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface RouteGuardProps {
  component: any;
  /** Pass down props */
  [x: string]: any;
}

const isSupportedLink = (
  link: string,
  menuItems: MenuItem[],
  user: User,
  marketplaceType: Marketplace,
  marketplaceSubtype: Marketplace,
  marketplaceCountry: string
) => {
  const menuItem = findMenuItem(link, menuItems);

  if (!menuItem) return false;
  if (
    menuItem.featureFlagged &&
    !user?.[menuItem.featureFlagged as keyof User]
  ) {
    return false;
  }
  if (menuItem.disabledFor?.markets.includes(marketplaceType)) {
    const disabledForCountries =
      menuItem.disabledFor?.countries?.[marketplaceType] ?? [];
    if (
      disabledForCountries.length === 0 ||
      disabledForCountries.includes(marketplaceCountry)
    ) {
      return false;
    }
  }

  if (!menuItem.restricted) return true;
  return Boolean(menuItem.restricted.markets.includes(marketplaceSubtype));
};

const MyStoreRouteGuard: React.FC<RouteGuardProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();

  const user = useTypedSelector((state) => state.user);
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  if (!currentStore) {
    return null;
  }

  const menuItems = useSideNavConfig(
    currentStore?.marketplaceSubtype ?? currentStore?.marketplace
  );

  const pathSegments = location.pathname.split("/");
  const page = pathSegments.pop() ?? "";

  const isSupported = isSupportedLink(
    page,
    menuItems,
    user,
    currentStore.marketplace as Marketplace,
    currentStore.marketplaceSubtype as Marketplace,
    currentStore.marketplaceCountry
  );
  const supportedDefaultLinks =
    menuItems?.[0]?.subMenus?.[0]?.link ?? "storeoverview";

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSupported) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect to={{ ...location, pathname: supportedDefaultLinks }} />
          );
        }
      }}
    />
  );
};

export default MyStoreRouteGuard;
