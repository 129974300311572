import { ButtonMenuItem, StoreMenuItem, WrapText } from "./myStoresDropdown";
import {
  CurrentStore,
  Filter,
  FilteredStore,
  StoreState,
} from "~/typedef/store";
import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import { MenuItem } from "../sideNavigation/sideNavConstants";
import RaisedButton from "~/components/buttons/raisedButton";
import StoreLink from "./storeLink";
import ToolbarSelect from "../../select/toolbarSelect";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import usePrevious from "~/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface StoreSelectorProps {
  changeStore: (market: string, mid: string, menuItems: MenuItem[]) => void;
  currentStore?: CurrentStore;
  currentFilter?: Filter;
  clearFilters?: () => void;
  hideClearFilters?: boolean;
  maxWidth?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  rightAlign?: boolean;
  centerAlign?: boolean;
  title?: string;
  dropdownTitle?: string;
  advertisingOnly?: boolean;
  excludeMids?: string[];
}
export const StoreSelector = ({
  changeStore,
  currentStore,
  currentFilter,
  clearFilters,
  hideClearFilters = false,
  maxWidth,
  disabled = false,
  fullWidth = true,
  rightAlign = false,
  centerAlign = false,
  title,
  dropdownTitle,
  advertisingOnly,
  excludeMids,
}: StoreSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = React.useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores
  );
  const previousFilters = usePrevious(currentFilter);

  const hasFiltersApplied =
    currentFilter?.marketplaces?.length ||
    currentFilter?.countries?.length ||
    currentFilter?.tags?.length;

  useEffect(() => {
    if (
      !isEqual(previousFilters, currentFilter) ||
      (!filteredStores?.stores?.length && !filteredStores?.fetching)
    ) {
      dispatch(fetchFilteredStores(currentFilter));
    }
  }, [JSON.stringify(previousFilters), JSON.stringify(currentFilter)]);

  const selectStore = (market: string, mid: string, menuItems: MenuItem[]) => {
    setAnchorEl(null);
    changeStore(market, mid, menuItems);
  };

  const renderOptions = () => {
    if (filteredStores?.fetching) {
      return (
        <StoreMenuItem key={`loading`} width={menuWidth}>
          <LoadingIndicator />
        </StoreMenuItem>
      );
    }

    if (!filteredStores?.stores?.length) {
      return (
        <StoreMenuItem key={`loading`} width={menuWidth}>
          {t("storeLink.emptyStoreSelection")}
        </StoreMenuItem>
      );
    }

    const options = [...filteredStores.stores]
      .sort((a: FilteredStore, b: FilteredStore): number =>
        a.storeName.localeCompare(b.storeName)
      )
      .filter((store: FilteredStore) => {
        if (advertisingOnly) {
          return !!store.advertisingStatus;
        }
        return true;
      })
      .filter((store: FilteredStore) => {
        if (excludeMids) {
          return !excludeMids.includes(store.merchantId);
        }
        return true;
      })
      .map((store: FilteredStore) => (
        <StoreMenuItem key={`${store.merchantId}`} width={menuWidth}>
          <StoreLink
            {...{
              activeLink: true,
              changeStore: selectStore,
              market: store.marketplaceType,
              marketplaceSubtype: store.marketplaceSubtype,
              countryCode: store.marketplaceCountry,
              mid: store.merchantId,
              shopName: store.storeName,
            }}
          />
        </StoreMenuItem>
      ));

    if (hasFiltersApplied && !hideClearFilters) {
      options.push(
        <ButtonMenuItem key={`tags-applied`} width={menuWidth}>
          <InlineIconButton />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <WrapText variant="body1">
              {t("storeDropdown.tagsApplied")}
            </WrapText>
            <RaisedButton variant="text" onClick={clearFilters} color="primary">
              {t("storeDropdown.clearTags")}
            </RaisedButton>
          </Box>
        </ButtonMenuItem>
      );
    }

    return options;
  };

  return (
    <ToolbarSelect
      id="store-select"
      title={title || t("dashboard.selectStoreTitle")}
      maxWidth={maxWidth}
      setMenuWidth={setMenuWidth}
      renderOptions={renderOptions}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      fullWidth={fullWidth}
      alignItems={rightAlign ? "flex-end" : "flex-start"}
      disabled={disabled}
      centerAlign={centerAlign}
      displayComponent={
        <StoreLink
          {...{
            title: dropdownTitle,
            activeLink: false,
            market: currentStore?.marketplace,
            marketplaceSubtype: currentStore?.marketplaceSubtype,
            countryCode: currentStore?.marketplaceCountry,
            mid: currentStore?.merchantId,
            shopName: currentStore?.storeName,
          }}
        />
      }
    />
  );
};
