import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  CCPPAdvertisingMetrics,
  CCPPByStoreMetrics,
  CCPPOtherMetricsTotalsResponse,
  CCPPTotalsResponse,
  useCcppByStoreQuery,
  useCcppOtherMetricsQuery,
  useCcppOtherMetricsTotalsQuery,
  useCcppTotalsQuery,
} from "~/store/overview/crossChannelPerformance.redux";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { formatCurrency, formatCurrencyRounded } from "~/utils/currencyUtils";
import { get, isNil, keyBy, omit } from "lodash";

import { CCPPTableCell } from "./ccppTableCell";
import { CountryLogo } from "~/img/flags/country_logos";
import InfoAlert from "~/components/alert/infoAlert";
// import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { MarketplaceIcon } from "~/img/marketplaces/icons/marketplaceIcon";
import { OverviewToolbar } from "../../../../components/toolbars/overviewToolbar/overviewToolbar";
import PageBlock from "../../../../components/containers/pageBlock";
import Panel from "../../../../components/panel/panel";
import SearchFilter from "~/components/adTable/searchFilter";
import { SuccessDirection } from "~/components/charts/percentageChange";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { getPercentageDifference } from "~/utils/salesUtils";
import { numberWithCommas } from "~/utils/utils";
import styled from "styled-components";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const MAX_STORES_WARNING = 50;

const HeaderSelect = styled(Select)`
  min-width: 150px;
`;

const HeaderTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
`;

/* Make the first row a different colour for this table only */
const StyledTableWrapper = styled.div`
  table > tbody > tr:first-child > td {
    background-color: #f6f5f4;
  }
`;

enum ViewMode {
  totalSales = "totalSales",
  avgPrice = "avgPrice",
  salesRank = "salesRank",
  price = "price",
  daysCover = "daysCover",
  categoryName = "categoryName",
  parentCategoryName = "parentCategoryName",
  adCost = "adCost",
  adSales = "adSales",
  acos = "acos",
  inventory = "inventory",
  outOfStockSinceDays = "outOfStockSinceDays",
  totalQuantity = "totalQuantity",
}

const VIEW_MODE_METRICS: Record<ViewMode, "average" | "total"> = {
  totalSales: "total",
  avgPrice: "average",
  salesRank: "average",
  price: "average",
  daysCover: "average",
  adCost: "total",
  adSales: "total",
  acos: "total",
  inventory: "total",
  outOfStockSinceDays: "average",
  totalQuantity: "total",
  categoryName: "total",
  parentCategoryName: "total",
};

const VIEW_OPTION_ITEMS = Object.values(ViewMode).map((key) => ({
  value: key,
  label: `crossChannelProduct.filter${
    key.charAt(0).toUpperCase() + key.slice(1)
  }`,
}));

enum PercentageType {
  total = "total",
  channel = "channel",
  sku = "sku",
  //asin = "asin",
  compare = "compare",
}

const PERCENTAGE_OPTION_ITEMS = Object.values(PercentageType).map((key) => ({
  value: key,
  label: `crossChannelProduct.filter${
    key.charAt(0).toUpperCase() + key.slice(1)
  }Percentage`,
}));

enum MetricToFetchOnDemand {
  ADVERTISING = "advertising",
}

const getMetricToFetchOnDemandFromViewMode = (
  viewMode: ViewMode
): MetricToFetchOnDemand | undefined => {
  switch (viewMode) {
    case ViewMode.adCost:
    case ViewMode.adSales:
    case ViewMode.acos:
      return MetricToFetchOnDemand.ADVERTISING;
    default:
      return undefined;
  }
};

// all the data we get from the backend is in USD
const SOURCE_CURRENCY = "USD";

const TOTALS_DEFAULT: CCPPTotalsResponse["totals"] = {
  totalQuantity: 0,
  totalShippingCost: 0,
  totalSales: 0,
  salesRank: null,
  daysCover: null,
  price: null,
  inventory: null,
  outOfStockSinceDays: null,
  avgPrice: null,
  storeMap: {},
};

const AD_TOTALS_DEFAULT: CCPPOtherMetricsTotalsResponse = {
  adImpressions: 0,
  adClicks: 0,
  adCost: 0,
  adSales: 0,
  acos: 0,
  storeMap: {},
};

type ValueAndPercentage = {
  value: number | string | null;
  percentage?: number | string | null;
  isPptChange?: boolean;
  successDirection?: SuccessDirection;
  conditionalFormatting?: boolean;
};

type ProcessedCCPPMetrics = Record<ViewMode, ValueAndPercentage>;

type ProcessedCCPPRow = {
  isTotalRow: boolean;
  sellerSku: string;
  title: string;
  imageUrl: string;
  linkUrl: string;
  allStores: ProcessedCCPPMetrics;
  storeMap: Record<string, ProcessedCCPPMetrics>;
};

const PROCESSED_CCPP_DEFAULT: ProcessedCCPPMetrics = {
  totalSales: {
    value: null,
  },
  avgPrice: {
    value: null,
  },
  price: {
    value: null,
  },
  inventory: {
    value: null,
  },
  outOfStockSinceDays: {
    value: null,
  },
  totalQuantity: {
    value: null,
  },
  adCost: {
    value: null,
  },
  adSales: {
    value: null,
  },
  acos: {
    value: null,
  },
  salesRank: {
    value: null,
  },
  daysCover: {
    value: null,
  },
  categoryName: {
    value: null,
  },
  parentCategoryName: {
    value: null,
  },
};

const DEFAULT_SORT_BY = "totalSales";
const DEFAULT_SORT_ORDER = "desc";
const DEFAULT_PAGE_SIZE = 25;
const QUERY_PARAMS_DEFAULTS = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sortKey: DEFAULT_SORT_BY,
  sortOrder: DEFAULT_SORT_ORDER,
  searchText: "",
};

const CrossChannelProductPerformanceExpanded = memo(
  function CrossChannelProductPerformanceExpanded() {
    const { t } = useTranslation();

    const { currentRange } = useDateRangeFilters();

    const includeTax = useTypedSelector((state) =>
      Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
    );

    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );

    const filteredStores = useTypedSelector((state) =>
      get(state, "mystore.filteredStores.stores", [])
    );

    const widgetDisabled = filteredStores.length > MAX_STORES_WARNING;

    const [queryParameters, setQueryParameters] = useState(
      QUERY_PARAMS_DEFAULTS
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.totalSales);
    const [percentageType, setPercentageType] = useState<PercentageType>(
      PercentageType.total
    );

    // Current Page
    const {
      products: baseProducts,
      isFetching: isLoadingBaseData,
      totalCount,
    } = useCcppByStoreQuery(
      {
        queryParameters,
        currentRange,
        currentFilter,
        includeTax,
      },
      {
        skip: widgetDisabled,
        selectFromResult: ({ data, isFetching }) => {
          return {
            products: data?.products ?? [],
            totalCount: data?.count ?? 0,
            isFetching,
          };
        },
      }
    );

    // Totals for each store
    const {
      totals: baseTotals,
      storeInfoMap,
      isFetching: isLoadingBaseTotals,
    } = useCcppTotalsQuery(
      {
        currentRange,
        currentFilter,
        includeTax,
      },
      {
        skip: widgetDisabled,
        selectFromResult: ({ data, isFetching }) => {
          return {
            totals: data?.totals ?? TOTALS_DEFAULT,
            storeInfoMap: data?.storeInfoMap ?? {},
            isFetching,
          };
        },
      }
    );

    // Advertising Metrics
    const { products: metricProducts, isFetching: isMetricDataLoading } =
      useCcppOtherMetricsQuery(
        {
          currentRange,
          currentFilter,
          includeTax,
          skus: baseProducts.map((p) => p.sellerSku),
          metric: getMetricToFetchOnDemandFromViewMode(viewMode),
        },
        {
          skip:
            !getMetricToFetchOnDemandFromViewMode(viewMode) ||
            baseProducts.length === 0 ||
            widgetDisabled,
          selectFromResult: ({ data, isFetching }) => {
            return {
              products: data?.products || [],
              isFetching,
            };
          },
        }
      );

    // Totals for Advertising Metrics for each store
    const { metricTotals, isFetching: isMetricsTotalsDataLoading } =
      useCcppOtherMetricsTotalsQuery(
        {
          currentRange,
          currentFilter,
          includeTax,
          metric: getMetricToFetchOnDemandFromViewMode(viewMode),
        },
        {
          skip:
            !getMetricToFetchOnDemandFromViewMode(viewMode) || widgetDisabled,
          selectFromResult: ({ data, isFetching }) => {
            return {
              metricTotals: data || AD_TOTALS_DEFAULT,
              isFetching,
            };
          },
        }
      );

    // Prior Period Data
    const { products: priorProducts, isFetching: isLoadingPriorData } =
      useCcppByStoreQuery(
        {
          includeTax,
          currentRange: {
            ...currentRange,
            fromDate: currentRange.priorFromDate,
            toDate: currentRange.priorToDate,
          },
          currentFilter,
          queryParameters: QUERY_PARAMS_DEFAULTS,
          skus: baseProducts.map((p) => p.sellerSku),
        },
        {
          skip:
            percentageType !== "compare" ||
            baseProducts.length === 0 ||
            widgetDisabled,
          selectFromResult: ({ data, isFetching }) => {
            return {
              products: data?.products ?? [],
              isFetching,
            };
          },
        }
      );

    // Prior Period - Totals for each store
    const { totals: priorTotals, isFetching: isLoadingPriorTotals } =
      useCcppTotalsQuery(
        {
          currentRange: {
            ...currentRange,
            fromDate: currentRange.priorFromDate,
            toDate: currentRange.priorToDate,
          },
          currentFilter,
          includeTax,
        },
        {
          skip: percentageType !== "compare" || widgetDisabled,
          selectFromResult: ({ data, isFetching }) => {
            return {
              totals: data?.totals ?? TOTALS_DEFAULT,
              isFetching,
            };
          },
        }
      );

    // Prior Period: Advertising Metrics
    const {
      products: priorMetricProducts,
      isFetching: isPriorMetricDataLoading,
    } = useCcppOtherMetricsQuery(
      {
        currentRange: {
          ...currentRange,
          fromDate: currentRange.priorFromDate,
          toDate: currentRange.priorToDate,
        },
        currentFilter,
        includeTax,
        skus: baseProducts.map((p) => p.sellerSku),
        metric: getMetricToFetchOnDemandFromViewMode(viewMode),
      },
      {
        skip:
          !getMetricToFetchOnDemandFromViewMode(viewMode) ||
          percentageType !== "compare" ||
          baseProducts.length === 0 ||
          widgetDisabled,
        selectFromResult: ({ data, isFetching }) => {
          return {
            products: data?.products || [],
            isFetching,
          };
        },
      }
    );

    // Prior Period - Totals for Advertising Metrics for each store
    const {
      metricTotals: priorMetricTotals,
      isFetching: isPriorMetricsTotalsDataLoading,
    } = useCcppOtherMetricsTotalsQuery(
      {
        currentRange: {
          ...currentRange,
          fromDate: currentRange.priorFromDate,
          toDate: currentRange.priorToDate,
        },
        currentFilter,
        includeTax,
        metric: getMetricToFetchOnDemandFromViewMode(viewMode),
      },
      {
        skip: !getMetricToFetchOnDemandFromViewMode(viewMode) || widgetDisabled,
        selectFromResult: ({ data, isFetching }) => {
          return {
            metricTotals: data || AD_TOTALS_DEFAULT,
            isFetching,
          };
        },
      }
    );

    const calculatePercentage = (
      current: number | null | undefined,
      comparison: number | null | undefined,
      percentageType: PercentageType | "ppt"
    ) => {
      if (isNil(current) || isNil(comparison)) {
        return null;
      }

      const diff =
        percentageType === "ppt"
          ? getPercentageDifference(current, comparison, true, 1)
          : percentageType === PercentageType.compare
          ? getPercentageDifference(current, comparison, false, 1)
          : comparison > 0
          ? ((current / comparison) * 100).toFixed(1)
          : null;

      return diff === "N/A" ? null : diff;
    };

    const calculateMetricsForCell = useCallback<
      (
        record: Omit<CCPPByStoreMetrics, "sellerSku"> &
          Partial<Omit<CCPPAdvertisingMetrics, "storeId" | "sellerSku">>,
        comparisonRecord:
          | (Omit<CCPPByStoreMetrics, "sellerSku"> &
              Partial<Omit<CCPPAdvertisingMetrics, "storeId" | "sellerSku">>)
          | null,
        percentageType: PercentageType
      ) => ProcessedCCPPMetrics
    >(
      (record, comparisonRecord, percentageType) => {
        const conditionalFormatting = percentageType === PercentageType.compare;
        return {
          totalSales: {
            value: record.totalSales
              ? formatCurrencyRounded(
                  record.totalSales,
                  currencyRates,
                  SOURCE_CURRENCY,
                  currentCurrency
                )
              : null,
            percentage: calculatePercentage(
              record.totalSales,
              comparisonRecord?.totalSales,
              percentageType
            ),
            conditionalFormatting,
          },
          avgPrice: {
            value: record.avgPrice
              ? formatCurrency(
                  record.avgPrice,
                  currencyRates,
                  SOURCE_CURRENCY,
                  currentCurrency
                )
              : null,
            percentage: calculatePercentage(
              record.avgPrice,
              comparisonRecord?.avgPrice,
              percentageType
            ),
            conditionalFormatting,
          },
          price: {
            value: record.price
              ? formatCurrency(
                  record.price,
                  currencyRates,
                  SOURCE_CURRENCY,
                  currentCurrency
                )
              : null,
            // Snapshot data
            percentage:
              percentageType === PercentageType.compare
                ? null
                : calculatePercentage(
                    record.price,
                    comparisonRecord?.price,
                    percentageType
                  ),
            conditionalFormatting,
          },
          inventory: {
            value: record.inventory ? numberWithCommas(record.inventory) : null,
            // Snapshot data
            percentage:
              percentageType === PercentageType.compare
                ? null
                : calculatePercentage(
                    record.inventory,
                    comparisonRecord?.inventory,
                    percentageType
                  ),
            conditionalFormatting,
          },
          outOfStockSinceDays: {
            value: record.outOfStockSinceDays ?? null,
            // Snapshot data
            percentage:
              percentageType === PercentageType.compare
                ? null
                : calculatePercentage(
                    record.outOfStockSinceDays,
                    comparisonRecord?.outOfStockSinceDays,
                    percentageType
                  ),
            conditionalFormatting,
          },
          totalQuantity: {
            value: record.totalQuantity
              ? numberWithCommas(record.totalQuantity)
              : null,
            percentage: calculatePercentage(
              record.totalQuantity,
              comparisonRecord?.totalQuantity,
              percentageType
            ),
            conditionalFormatting,
          },
          adCost: {
            value: record.adCost
              ? formatCurrency(
                  record.adCost,
                  currencyRates,
                  SOURCE_CURRENCY,
                  currentCurrency
                )
              : null,
            percentage: calculatePercentage(
              record?.adCost,
              comparisonRecord?.adCost,
              percentageType
            ),
            conditionalFormatting,
          },
          adSales: {
            value: record.adSales
              ? formatCurrencyRounded(
                  record.adSales,
                  currencyRates,
                  SOURCE_CURRENCY,
                  currentCurrency
                )
              : null,
            percentage: calculatePercentage(
              record?.adSales,
              comparisonRecord?.adSales,
              percentageType
            ),
            conditionalFormatting,
          },
          acos: {
            value: record.acos ? `${record.acos.toFixed(1)}%` : null,
            percentage:
              percentageType === PercentageType.compare
                ? calculatePercentage(
                    record?.acos,
                    comparisonRecord?.acos,
                    "ppt"
                  )
                : null,
            conditionalFormatting,
            isPptChange: true,
            successDirection: SuccessDirection.DESCENDING,
          },
          salesRank: {
            value: record.salesRank ?? null,
          },
          daysCover: {
            value: record.daysCover?.toFixed(1) ?? null,
          },
          categoryName: {
            value: record.categoryName ?? null,
          },
          parentCategoryName: {
            value: record.parentCategoryName ?? null,
          },
        };
      },
      [currentCurrency, currencyRates]
    );

    const { products, sortedStoreIdsByTotalSales } = useMemo<{
      products: ProcessedCCPPRow[];
      sortedStoreIdsByTotalSales: string[];
    }>(() => {
      const products: ProcessedCCPPRow[] = [];
      /* Compute a sorted list of storeIds sorted by totalSales so that our columns
      (which are rendered from that map) are sorted accordingly */
      const sortedStoreIdsByTotalSales = Object.values(baseTotals.storeMap)
        .sort((storeA, storeB) => {
          return storeB.totalSales - storeA.totalSales;
        })
        .map((storeRecord) => storeRecord.storeId);

      if (
        isLoadingBaseData ||
        isLoadingBaseTotals ||
        isMetricDataLoading ||
        isMetricsTotalsDataLoading ||
        isLoadingPriorData ||
        isLoadingPriorTotals ||
        isPriorMetricDataLoading ||
        isPriorMetricsTotalsDataLoading
      ) {
        return {
          products,
          sortedStoreIdsByTotalSales,
        };
      }

      /* Totals Row */
      const allStoreTotalsRaw = {
        ...omit(baseTotals, "storeMap"),
        ...omit(metricTotals, "storeMap"),
        categoryName: null,
        parentCategoryName: null,
      };
      const priorAllStoreTotalsRaw = {
        ...omit(priorTotals, "storeMap"),
        ...omit(priorMetricTotals, "storeMap"),
        categoryName: null,
        parentCategoryName: null,
      };

      const totalsStoreMap = Object.values(baseTotals.storeMap).map(
        (record) => {
          const storeId = record.storeId;
          const matchingOtherMetricsRecord =
            metricTotals.storeMap[storeId] ?? {};
          const matchingPriorRecord = priorTotals.storeMap[storeId] ?? {};
          const matchingPriorOtherMetricsRecord =
            priorMetricTotals.storeMap[storeId] ?? {};
          const currentData = {
            ...record,
            ...matchingOtherMetricsRecord,
            categoryName: null,
            parentCategoryName: null,
          };
          const priorData = {
            ...matchingPriorRecord,
            ...matchingPriorOtherMetricsRecord,
            categoryName: null,
            parentCategoryName: null,
          };
          const comparisonData =
            percentageType === PercentageType.total ||
            percentageType === PercentageType.sku
              ? allStoreTotalsRaw
              : percentageType === PercentageType.compare
              ? priorData
              : percentageType === PercentageType.channel
              ? currentData
              : null;
          return {
            storeId,
            ...calculateMetricsForCell(
              currentData,
              comparisonData,
              percentageType
            ),
          };
        }
      );

      const totalsRow = {
        isTotalRow: true,
        sellerSku: "",
        title: "",
        imageUrl: "",
        linkUrl: "",
        allStores: calculateMetricsForCell(
          allStoreTotalsRaw,
          percentageType === PercentageType.compare
            ? priorAllStoreTotalsRaw
            : null,
          percentageType
        ),
        storeMap: keyBy(totalsStoreMap, "storeId"),
      };
      products.push(totalsRow);
      /* Totals Row */

      /* Product Rows */
      baseProducts.forEach((record) => {
        const sellerSku = record.sellerSku;
        const matchingOtherMetricsRecord = metricProducts.find(
          (item) => item.sellerSku === sellerSku
        );
        const matchingPriorRecord = priorProducts.find(
          (item) => item.sellerSku === sellerSku
        );
        const matchingPriorOtherMetricsRecord = priorMetricProducts.find(
          (item) => item.sellerSku === sellerSku
        );

        const productCurrentData = {
          ...omit(record, [
            "sellerSku",
            "title",
            "imageUrl",
            "linkUrl",
            "storeMap",
          ]),
          ...omit(matchingOtherMetricsRecord, ["sellerSku", "storeMap"]),
        };
        const productPriorData = {
          ...omit(matchingPriorRecord, [
            "sellerSku",
            "title",
            "imageUrl",
            "linkUrl",
            "storeMap",
          ]),
          ...omit(matchingPriorOtherMetricsRecord, ["sellerSku", "storeMap"]),
        };

        const storesArray = Object.values(record.storeMap).map(
          (storeRecord) => {
            const storeId = storeRecord.storeId;
            const matchingStoreOtherMetricsRecord =
              matchingOtherMetricsRecord?.storeMap[storeId] ?? {};
            const matchingStorePriorRecord =
              matchingPriorRecord?.storeMap[storeId];
            const matchingStorePriorOtherMetricsRecord =
              matchingPriorOtherMetricsRecord?.storeMap[storeId] ?? {};
            const storeCurrentData = {
              ...omit(storeRecord, "storeId"),
              ...omit(matchingStoreOtherMetricsRecord, "storeId"),
            };
            const storePriorData = {
              ...omit(matchingStorePriorRecord, "storeId"),
              ...omit(matchingStorePriorOtherMetricsRecord, "storeId"),
            };
            const storeComparisonData =
              percentageType === PercentageType.total
                ? allStoreTotalsRaw
                : percentageType === PercentageType.channel
                ? {
                    ...omit(baseTotals.storeMap[storeId], "storeId"),
                    ...omit(metricTotals.storeMap[storeId], "storeId"),
                    categoryName: null,
                    parentCategoryName: null,
                  }
                : percentageType === PercentageType.sku
                ? productCurrentData
                : percentageType === PercentageType.compare
                ? storePriorData
                : null;
            return {
              storeId,
              ...calculateMetricsForCell(
                storeCurrentData,
                storeComparisonData,
                percentageType
              ),
            };
          }
        );

        const productRow = {
          isTotalRow: false,
          sellerSku: record.sellerSku,
          title: record.title,
          imageUrl: record.imageUrl,
          linkUrl: record.linkUrl,
          allStores: calculateMetricsForCell(
            productCurrentData,
            percentageType === PercentageType.total ||
              percentageType === PercentageType.channel
              ? allStoreTotalsRaw
              : percentageType === PercentageType.compare
              ? productPriorData
              : percentageType === PercentageType.sku
              ? productCurrentData
              : null,
            percentageType
          ),
          storeMap: keyBy(storesArray, "storeId"),
        };
        products.push(productRow);
      });
      /* Product Rows */

      return {
        products,
        sortedStoreIdsByTotalSales,
      };
    }, [
      storeInfoMap,
      baseProducts,
      isLoadingBaseData,
      baseTotals,
      isLoadingBaseTotals,
      metricProducts,
      isMetricDataLoading,
      metricTotals,
      isMetricsTotalsDataLoading,
      priorProducts,
      isLoadingPriorData,
      priorTotals,
      isLoadingPriorTotals,
      priorMetricProducts,
      isPriorMetricDataLoading,
      priorMetricTotals,
      isPriorMetricsTotalsDataLoading,
      percentageType,
    ]);

    const handleOnSelectViewMode = (viewMode: ViewMode) => {
      setViewMode(viewMode);
    };

    const fetchData = useCallback(({ sortBy, pageIndex, pageSize }) => {
      setQueryParameters({
        pageIndex,
        pageSize,
        sortKey: sortBy[0]?.id || DEFAULT_SORT_BY,
        sortOrder: sortBy[0]?.asc ? "asc" : "desc",
        searchText: queryParameters.searchText,
      });
    }, []);

    /* Taken from: https://bitbucket.org/merchantspring/marketoverview/commits/ad3c658fa606953b0f305a8255c0ba1b97ea6db6#Lclient/src/modules/widgets/vendorProfitability/monthlyProfitAndLoss.tsxT289 */
    const getStoreDataColumnWidth = useCallback((columnCount: number) => {
      const containerRefWidth = containerRef?.current?.clientWidth || 0;
      const columnWidth = (containerRefWidth - 300) / columnCount;
      return columnWidth < 80 ? 100 : columnWidth;
    }, []);

    const columns = useMemo(() => {
      const baseColumns = [
        {
          Header: t("crossChannelProduct.product"),
          id: "title",
          accessor: (row: ProcessedCCPPRow) => {
            return {
              isTotalRow: row.isTotalRow,
              value: row.title,
              secondRowValue: `SKU: ${row.sellerSku}`,
              image: row.imageUrl,
              link: row.linkUrl,
              target: "_blank",
            };
          },
          Cell: (
            props: TableCellProp<{
              isTotalRow: true;
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => {
            if (props.value.isTotalRow) {
              return (
                <Typography variant="body2" noWrap align="right">
                  {[
                    ViewMode.categoryName,
                    ViewMode.parentCategoryName,
                  ].includes(viewMode)
                    ? "-"
                    : t(
                        `crossChannelProduct.view${VIEW_MODE_METRICS[viewMode]}`
                      )}
                </Typography>
              );
            } else {
              return <LinkAndImageCell {...props} colorVariant="external" />;
            }
          },
          isVisible: true,
          sticky: "left",
          colSpan: 2,
          customWidth: 600,
          maxWidth: 600,
        },
        {
          Header: (
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid item>
                <HeaderTypography align="center" variant="body2" noWrap>
                  {t("crossChannelProduct.allChannels")}
                </HeaderTypography>
              </Grid>
              <Grid item>
                <HeaderTypography align="center" variant="body2" noWrap>
                  {[
                    ViewMode.categoryName,
                    ViewMode.parentCategoryName,
                  ].includes(viewMode)
                    ? "-"
                    : t(
                        `crossChannelProduct.view${VIEW_MODE_METRICS[viewMode]}`
                      )}
                </HeaderTypography>
              </Grid>
            </Grid>
          ),
          id: "totalSalesAcrossStores",
          align: "center",
          maxWidth: getStoreDataColumnWidth(sortedStoreIdsByTotalSales.length),
          accessor: (row: ProcessedCCPPRow) => {
            const cellValue = row.allStores[viewMode];
            return cellValue;
          },
          Cell: (props: TableCellProp<ValueAndPercentage>) => (
            <CCPPTableCell {...props} />
          ),
          isVisible: true,
        },
      ];

      const storeColumns = sortedStoreIdsByTotalSales
        .map((storeId) => storeInfoMap[storeId])
        .map(({ storeId, storeName, marketplaceCountry, marketplaceType }) => {
          return {
            Header: (
              <Grid container direction="column">
                <Box display="block" width="100%" mb={1}>
                  <HeaderTypography align="center" variant="body2" noWrap>
                    {storeName}
                  </HeaderTypography>
                </Box>
                <Grid item container justifyContent="center">
                  <Box>
                    <CountryLogo code={marketplaceCountry} />
                  </Box>
                  <Box>
                    <MarketplaceIcon market={marketplaceType} />
                  </Box>
                </Grid>
              </Grid>
            ),
            align: "center",
            maxWidth: getStoreDataColumnWidth(
              sortedStoreIdsByTotalSales.length
            ),
            id: `${storeName}-${storeId}`,
            accessor: (row: ProcessedCCPPRow) => {
              const storeValues =
                row.storeMap[storeId] ?? PROCESSED_CCPP_DEFAULT;
              const cellValue = storeValues[viewMode];
              return cellValue;
            },
            Cell: (props: TableCellProp<ValueAndPercentage>) => (
              <CCPPTableCell {...props} />
            ),
            isVisible: true,
          };
        });

      return [...baseColumns, ...storeColumns];
    }, [t, viewMode, sortedStoreIdsByTotalSales, storeInfoMap]);

    return (
      <PageBlock>
        <OverviewToolbar
          selectedItem={{
            currentPage: t("crossChannelProduct.tableTitle"),
            breadcrumb: [],
          }}
        />
        <Grid container spacing={3}>
          {widgetDisabled && (
            <Grid item xs={12}>
              <InfoAlert
                message={t("crossChannelProduct.numberOfStoresWarning")}
              />
            </Grid>
          )}
          <Grid container item xs={12} alignItems="center" ref={containerRef}>
            <Panel
              id="widget-crosschannelproductperformance-overview-table"
              title={t("crossChannelProduct.tableTitle")}
              subtitle={t("crossChannelProduct.tableSubtitle")}
              badge={t("generic.new")}
              actions={
                <Grid container alignItems="center" justifyContent="flex-end">
                  <FormControl variant="outlined" size="small">
                    <HeaderSelect
                      value={viewMode}
                      onChange={(e) =>
                        handleOnSelectViewMode(e.target.value as ViewMode)
                      }
                    >
                      {VIEW_OPTION_ITEMS.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </HeaderSelect>
                  </FormControl>
                  <Box mx={1}>
                    <FormControl variant="outlined" size="small">
                      <HeaderSelect
                        value={percentageType}
                        onChange={(e) =>
                          setPercentageType(e.target.value as PercentageType)
                        }
                      >
                        {PERCENTAGE_OPTION_ITEMS.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {t(item.label)}
                          </MenuItem>
                        ))}
                      </HeaderSelect>
                    </FormControl>
                  </Box>
                  <Box mx={1}>
                    <SearchFilter
                      setSearchText={(text: string) => {
                        setQueryParameters({
                          pageIndex: 0,
                          pageSize: queryParameters.pageSize,
                          sortKey: queryParameters.sortKey,
                          sortOrder: queryParameters.sortOrder,
                          searchText: text,
                        });
                      }}
                    />
                  </Box>
                  {/* <DownloadCsv
                    reportType={"crossChannelProductPerformance"}
                    path={"/api/generic/crossChannelProductPerformance"}
                    mid={undefined}
                    params={{
                      currentFilter,
                      currentRange,
                      currentCurrency,
                    }}
                  /> */}
                </Grid>
              }
              content={
                <StyledTableWrapper>
                  <Table
                    columns={columns}
                    data={products}
                    loading={
                      isLoadingBaseData ||
                      isLoadingBaseTotals ||
                      isMetricDataLoading ||
                      isMetricsTotalsDataLoading ||
                      isLoadingPriorData ||
                      isLoadingPriorTotals ||
                      isPriorMetricDataLoading ||
                      isPriorMetricsTotalsDataLoading
                    }
                    fetchData={fetchData}
                    pagination
                    pageCount={Math.ceil(totalCount / queryParameters.pageSize)}
                    pageSize={queryParameters.pageSize}
                  />
                </StyledTableWrapper>
              }
            />
          </Grid>
        </Grid>
      </PageBlock>
    );
  }
);

export default CrossChannelProductPerformanceExpanded;
