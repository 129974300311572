import React, { memo } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import SalesByBrandWidget from "../widgets/salesByBrand";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesByBrandProps {
  market: string;
  mid: string;
  condensed?: boolean;
}

const SalesByBrand = memo<SalesByBrandProps>(function SalesByBrand({
  market,
  mid,
  condensed,
}) {
  const { t } = useTranslation();

  const userInfo = useTypedSelector((state) => state.user);
  const { currentRange, currentPeriod, selectedTimezone } =
    useDateRangeFilters();
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const footerLink =
    condensed &&
    (!market.includes("amazon_vendor") || userInfo.brandAnalyticsOn)
      ? {
          url: marketplaceLink(market, mid, "salesbybrand"),
          label: t("generic.viewAllLink"),
        }
      : undefined;

  return (
    <SalesByBrandWidget
      mid={mid}
      currentPeriod={currentPeriod}
      currentRange={currentRange}
      currentCurrency={currentCurrency}
      footerLink={footerLink}
      condensed={condensed}
      timezone={selectedTimezone}
      includeTax={includeTax}
    />
  );
});

export default SalesByBrand;
