import Panel, { FooterLink } from "~/components/panel/panel";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { formatNumber, getPercentageDifference } from "~/utils/salesUtils";

import { Box } from "@material-ui/core";
import NoScrollTable from "~/components/table/table";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import Table from "~/components/table/table";
import { TextCell } from "~/components/table/cells/textCell";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchSalesByBrand } from "../../store/mystore/salesByBrand.redux";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { numberWithCommas } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

interface SalesByBrandProps {
  mid: string;
  currentPeriod: string;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: FooterLink;
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  pageSize?: number;
  timezone?: string;
  conditionalFormatting?: boolean;
  includeTax: boolean;
}

const SalesByBrandTable = memo<SalesByBrandProps>(function SalesByBrandTable({
  mid,
  condensed,
  currentPeriod,
  currentRange,
  currentCurrency,
  footerLink,
  actions,
  report,
  pageSize,
  timezone,
  conditionalFormatting,
  includeTax,
}) {
  const CONDENSED_ROWS = 5;
  const { t } = useTranslation();
  const isVendorStore = useVendorStore(mid);
  const dispatch = useDispatch();

  const salesByBrand = useTypedSelector((state) => state.mystore.salesByBrand);
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const dispatchFetchSalesByBrand = useCallback(
    (store) => {
      dispatch(
        fetchSalesByBrand({
          mid: store,
          currentPeriod,
          currentRange,
          includeTax,
          timezone,
        })
      );
    },
    [currentPeriod, currentRange, includeTax, timezone]
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatchFetchSalesByBrand(mid);
    };
    if (mid) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, dispatchFetchSalesByBrand]);

  const data = useMemo(() => {
    if (!salesByBrand || !salesByBrand.data) {
      return [];
    }
    const salesByBrandData = salesByBrand.data.map((brand: any) => {
      const { current, prior } = brand;
      return {
        brand: current.brand || t("generic.notSpecified"),
        priorPeriod: prior
          ? formatCurrencyRounded(
              prior.totalSales,
              currencyRates,
              prior.currency,
              currentCurrency
            )
          : "N/A",
        thisPeriod: {
          value: formatCurrencyRounded(
            current.totalSales,
            currencyRates,
            current.currency,
            currentCurrency
          ),
          growth: prior
            ? getPercentageDifference(current.totalSales, prior.totalSales)
            : "N/A",
          conditionalFormatting: conditionalFormatting,
        },
        units: {
          value: numberWithCommas(current.quantity),
          growth: prior
            ? getPercentageDifference(current.quantity, prior.quantity)
            : "N/A",
          conditionalFormatting: conditionalFormatting,
        },
        avgUnit: {
          value: formatCurrencyRounded(
            current.totalSales / current.quantity,
            currencyRates,
            current.currency,
            currentCurrency
          ),
          growth: prior
            ? getPercentageDifference(
                current.totalSales / current.quantity,
                prior.totalSales / prior.quantity
              )
            : "N/A",
          conditionalFormatting: conditionalFormatting,
        },
        avgOrder: {
          value: current.orderCount
            ? numberWithCommas(
                formatNumber(current.quantity / current.orderCount)
              )
            : "N/A",
          growth:
            prior && current.orderCount
              ? getPercentageDifference(
                  current.quantity / current.orderCount,
                  prior.quantity / prior.orderCount
                )
              : "N/A",
          conditionalFormatting: conditionalFormatting,
        },
      };
    });
    return salesByBrandData;
  }, [currencyRates, currentCurrency, salesByBrand, includeTax]);

  const columns = useMemo(
    () => [
      {
        id: "brand",
        Header: t("myStoresWidget.salesByBrand.brandColumn"),
        accessor: "brand",
        Cell: TextCell,
        colSpan: 3,
      },
      ...(!condensed && !report
        ? [
            {
              id: "priorPeriod",
              Header: t("myStoresWidget.salesByBrand.priorPeriodColumn"),
              accessor: "priorPeriod",
              Cell: ValueCell,
              hiddenDown: report ? "md" : "sm",
              align: "right",
            },
          ]
        : []),
      {
        id: "thisPeriod",
        Header: t("myStoresWidget.salesByBrand.thisPeriodColumn"),
        accessor: "thisPeriod",
        Cell: ValueAndGrowthCell,
        align: "right",
      },
      {
        id: "units",
        Header: t("myStoresWidget.salesByBrand.unitsColumn"),
        accessor: "units",
        Cell: ValueAndGrowthCell,
        align: "right",
      },
      {
        id: "avgUnit",
        Header: t("myStoresWidget.salesByBrand.avgUnitColumn"),
        accessor: "avgUnit",
        Cell: ValueAndGrowthCell,
        align: "right",
        hiddenDown: report ? "md" : "sm",
      },
      ...(!condensed
        ? [
            {
              id: "avgOrder",
              Header: t("myStoresWidget.salesByBrand.avgOrderColumn"),
              accessor: "avgOrder",
              Cell: ValueAndGrowthCell,
              align: "right",
              hiddenDown: report ? "md" : "sm",
            },
          ]
        : []),
    ],
    []
  );

  return (
    <Panel
      id="widget-sales-by-brand"
      title={t(
        `myStoresWidget.${isVendorStore ? "poBrand" : "salesByBrand"}.mainTitle`
      )}
      footerLink={footerLink}
      tooltip={undefined}
      actions={actions}
      content={
        salesByBrand?.fetching || !mid ? (
          <PanelLoading />
        ) : condensed || report ? (
          <NoScrollTable
            columns={columns}
            data={report ? data : data.slice(0, CONDENSED_ROWS)}
            pageSize={pageSize}
            loading={salesByBrand?.fetching}
            isReport={report}
          />
        ) : (
          <Box>
            <Table
              columns={columns}
              data={data}
              gridLayoutColumns={12}
              pageSize={pageSize ?? 10}
              pagination={true}
              isReport={report}
            />
          </Box>
        )
      }
    />
  );
});

export default SalesByBrandTable;
