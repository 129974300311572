import {
  AdAccount,
  useUpdateMetaAdAccountMutation,
  useValidateMetaAdvertisingAuthMutation,
} from "~/store/connections/connectMetaAdvertising.redux";
import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import CountrySelectFromCurrencyCode from "~/components/select/countrySelectFromCurrencyCode";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import Radio from "@material-ui/core/Radio";
import RaisedButton from "~/components/buttons/raisedButton";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface MetaAdvertisingProfilePageProps {
  selectProfile: (
    mid: string,
    profileId?: string,
    entityId?: string,
    countryCode?: string
  ) => void;
  onSuccess: () => void;
}

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 425px;
  width: 100%;
  padding-top: 2rem;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const MetaAdvertisingProfilePage: React.FC<MetaAdvertisingProfilePageProps> = ({
  selectProfile,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [_, { adAccounts }] = useValidateMetaAdvertisingAuthMutation({
    fixedCacheKey: "validateMetaAuthCode",
    selectFromResult: ({ data }) => ({
      adAccounts: data?.adAccounts ?? null,
    }),
  });

  const [updateMetaAdsAccount, { isLoading: loading }] =
    useUpdateMetaAdAccountMutation();

  const [error, setError] = useState<string | null>(null);
  const [selectedAdAccount, setSelectedAdAccount] = useState<AdAccount | null>(
    null
  );
  const [countryCode, setCountryCode] = useState<string | null>(null);

  const handleSubmit = () => {
    if (selectedAdAccount?.id && countryCode) {
      updateMetaAdsAccount({
        adAccountId: selectedAdAccount.id,
        countryCode,
      })
        .unwrap()
        .then(({ store }) => {
          if (store) {
            selectProfile(
              selectedAdAccount.id,
              undefined,
              undefined,
              store.countryCode
            );
            onSuccess();
          } else {
            setError(t("advertisingConnectLabel.generalError"));
          }
        })
        .catch((error) => {
          setError(
            t(
              error?.message ||
                error?.data?.errMsg ||
                "advertisingConnectLabel.generalError"
            )
          );
        });
    } else {
      setError(t("connectWizard.invalidMetaAdAccountSelected"));
    }
  };

  const handleAccountSelect = (adAccount: AdAccount) => {
    setSelectedAdAccount(adAccount);
  };

  const renderAdAccounts = (adAccounts: AdAccount[]) =>
    adAccounts.map((adAccount: AdAccount) => (
      <Grid
        key={adAccount.id}
        container
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Grid item>
          <Radio
            checked={selectedAdAccount?.id === adAccount.id}
            name={`${adAccount.name}`}
            color="primary"
            onChange={() => handleAccountSelect(adAccount)}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" noWrap>
            {adAccount.name} ({adAccount.id})
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <GridWrapper id="meta-selectaccount-wrapper">
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Medium color="textSecondary" align="center">
                {t("connectWizard.metaAdvertisingAccountTitle")}
              </Medium>
            </Grid>
            <Grid item xs={12}>
              {adAccounts
                ? renderAdAccounts(adAccounts)
                : t("connectWizard.connectionError")}
            </Grid>
            <Grid container item alignItems="center" justifyContent="center">
              {selectedAdAccount && (
                <CountrySelectFromCurrencyCode
                  currencyCode={selectedAdAccount.currency}
                  onChange={(_, countryDetail) => {
                    setCountryCode(countryDetail?.iso3 ?? null);
                  }}
                  setError={setError}
                  label={t("connectWizard.countrySelectTitle")}
                />
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid item xs={12}>
                <Box pt={2}>
                  <RaisedButton
                    disabled={!selectedAdAccount?.id || !countryCode}
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="primary"
                  >
                    {t("generic.continueButton")}
                  </RaisedButton>
                </Box>
              </Grid>
            </Grid>
            <>
              {error && (
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  <ErrorMessage color="error">{error}</ErrorMessage>
                </Grid>
              )}
            </>
          </Grid>
        </GridWrapper>
      )}
    </>
  );
};

export default MetaAdvertisingProfilePage;
