import { Box, Typography } from "@material-ui/core";
import {
  CampaignCustomGroupMetrics,
  CampaignCustomGroups,
} from "@typedef/customGroups/advertising";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
/** A table containing advertising campaigns collected
 * together in user-defined groups. */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  deleteCampaignGroup,
  fetchCustomGroupsMetrics,
  uploadCampaignCustomGroups,
} from "~/store/customGroups/advertising.redux";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  getExchangeRate,
  intFormatterRounded,
} from "~/utils/currencyUtils";

import ACOSCell from "~/components/table/cells/acosCell";
import CampaignCustomGroupsModal from "./campaignCustomGroupsModal";
import CampaignGroupTemplateDialog from "./campaignTemplateDialog";
import ConfirmDialog from "~/components/dialogs/confirmDialog";
import CustomGroupCell from "~/components/table/cells/customGroupCell";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DateRange } from "~/typedef/date";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import NoScrollTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import ROASCell from "~/components/table/cells/roasCell";
import SearchFilter from "~/components/adTable/searchFilter";
import SmallButton from "~/components/buttons/smallButton";
import SyncDialog from "../syncDialog";
import Table from "../../../../components/adTable/table";
import { ValueAndTextCell } from "~/components/table/cells/valueAndTextCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledButton = styled(SmallButton)`
  margin-right: 1rem;
`;

interface CampaignCustomGroupTableProps {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  countryCode: string;
  currentCurrency: string;
  currentRange: DateRange;
  currentPeriod: DATETIME_PERIODS;
  pageSize: number;
  selectedCampaignGroups?: CampaignCustomGroups[];
  report?: boolean;
}

const CampaignCustomGroupTable = memo<CampaignCustomGroupTableProps>(
  function CampaignCustomGroupTable({
    mid,
    marketplaceType,
    marketplaceSubtype,
    countryCode,
    currentCurrency,
    currentRange,
    currentPeriod,
    pageSize,
    selectedCampaignGroups,
    report,
  }) {
    const { t } = useTranslation();

    const campaignCustomGroups = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.metrics?.data || []
    );
    const campaignCustomGroupsCount = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.metrics?.count || 0
    );
    const shouldRefetch = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.triggerRefetch ?? false
    );
    const uploadResponse = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.upload
    );
    const fetching = useTypedSelector(
      (state) => state?.advertisingCustomGroups?.metrics?.fetching
    );
    const user = useTypedSelector((state) => state.user);
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currencyRates = useTypedSelector(
      (state) => state?.globalVar?.currencyRates
    );

    const [searchText, setSearchText] = useState("");
    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
    const [syncDialogOpen, setSyncDialogOpen] = useState(false);
    const [markedForDelete, setMarkedForDelete] = useState<{
      groupId: number;
      groupName: string;
    } | null>(null);
    const [markedForEdit, setMarkedForEdit] = useState<{
      groupId: number;
      groupName: string;
    } | null>(null);
    const [openModal, setOpenModal] = useState(false);

    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[countryCode],
      currentCurrency
    );

    const dispatch = useDispatch();

    const fetchData = useCallback(
      ({ pageIndex, pageSize, sortBy }) => {
        dispatch(
          fetchCustomGroupsMetrics({
            mid,
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            currentRange,
            pageIndex,
            pageSize,
            sortKey: sortBy?.[0]?.id || "groupName",
            sortDesc: sortBy?.[0]?.id
              ? sortBy?.[0]?.desc
                ? true
                : false
              : true,
            searchText,
            campaignGroups: selectedCampaignGroups,
          })
        );
      },
      [
        mid,
        marketplaceType,
        marketplaceSubtype,
        countryCode,
        currentRange,
        openModal,
        searchText,
      ]
    );

    const dispatchDeleteGroup = useCallback(async () => {
      if (markedForDelete) {
        dispatch(
          deleteCampaignGroup({
            t,
            mid,
            marketplaceType,
            marketplaceSubtype,
            countryCode,
            groupId: markedForDelete.groupId,
          })
        );
      }
    }, [
      mid,
      marketplaceType,
      marketplaceSubtype,
      countryCode,
      user,
      markedForDelete,
    ]);

    const onMarkDeleted = ({
      groupId,
      groupName,
    }: {
      groupId: number;
      groupName: string;
    }) => {
      setMarkedForDelete({ groupId, groupName });
    };

    const onDelete = async () => {
      await dispatchDeleteGroup();
      setMarkedForDelete(null);
    };

    useEffect(() => {
      if (shouldRefetch) {
        fetchData({ pageIndex: 0, pageSize });
      }
    }, [shouldRefetch]);

    const onMarkEdit = ({
      groupId,
      groupName,
    }: {
      groupId: number;
      groupName: string;
    }) => {
      setMarkedForEdit({ groupId, groupName });
      setOpenModal(true);
    };

    const onEdit = () => {
      setMarkedForEdit(null);
    };

    const onCreateGroup = () => {
      setMarkedForEdit(null);
      setOpenModal(true);
    };

    const columns = useMemo(
      () => [
        {
          Header: t("customGroups.campaignGroupColumn"),
          accessor: (row: CampaignCustomGroupMetrics) => ({
            groupId: row.groupId,
            groupName: row.groupName,
            groupSize: row.campaignCount,
            groupType: t("customGroups.campaigns"),
            groupLink: marketplaceLink(
              marketplaceType,
              mid,
              "marketingCampaigns",
              {
                groupId: row.groupId,
                groupName: row.groupName,
              }
            ),
          }),
          id: "groupName",
          Cell: (props: any) => (
            <CustomGroupCell
              {...props}
              report={report}
              onDelete={onMarkDeleted}
              onEdit={onMarkEdit}
              colorVariant="external"
              isCampaignGroupCell
            />
          ),
          isVisible: true,
          sticky: "left",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.salesColumn"),
          id: "attributedSales",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedSales
              ? formatCurrency(
                  row.attributedSales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
            text: "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.spendColumn"),
          id: "cost",
          accessor: (row: CampaignCustomGroupMetrics) =>
            row.cost
              ? formatCurrency(
                  row.cost,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.acosColumn"),
          id: "acos",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.acos ? `${row.acos.toFixed(2)}%` : "-",
          }),
          align: "right",
          Cell: ACOSCell,
          isVisible: true,
          sortDescFirst: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.impressionsColumn"),
          id: "impressions",
          accessor: (row: CampaignCustomGroupMetrics) =>
            row.impressions ? intFormatterRounded.format(row.impressions) : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.clicksColumn"),
          id: "clicks",
          accessor: (row: CampaignCustomGroupMetrics) =>
            row.clicks ? intFormatterRounded.format(row.clicks) : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.clickThroughRateColumn"
          ),
          id: "clickThroughRate",
          accessor: (row: CampaignCustomGroupMetrics) =>
            row.clickThroughRate ? `${row.clickThroughRate.toFixed(2)}%` : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.costPerClickColumn"),
          id: "costPerClick",
          accessor: (row: CampaignCustomGroupMetrics) =>
            row.costPerClick
              ? formatCurrency(
                  row.costPerClick,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.conversionColumn"),
          id: "conversionRate",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.conversionRate
              ? `${row.conversionRate.toFixed(2)}%`
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.ordersColumn"),
          id: "attributedConversions",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedConversions
              ? intFormatterRounded.format(row.attributedConversions)
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.unitsColumn"),
          id: "attributedUnitsOrdered",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedUnitsOrdered
              ? intFormatterRounded.format(row.attributedUnitsOrdered)
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.returnOnAdvertisingSpendColumn"
          ),
          id: "roas",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.roas ? `${row.roas.toFixed(2)}` : "-",
          }),
          align: "right",
          Cell: ROASCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.ntbSalesColumn"),
          id: "attributedSalesNtb",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedSalesNtb
              ? formatCurrency(
                  row.attributedSalesNtb,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
          hiddenDown: "xl",
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.ntbSalesPercentageColumn"
          ),
          id: "attributedSalesNtbPercentage",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedSalesNtbPercentage
              ? `${row.attributedSalesNtbPercentage.toFixed(2)}%`
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
          hiddenDown: "xl",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.ntbOrdersColumn"),
          id: "attributedOrdersNtb",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedOrdersNtb
              ? intFormatterRounded.format(row.attributedOrdersNtb)
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
          hiddenDown: "xl",
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.ntbOrdersPercentageColumn"
          ),
          id: "attributedOrdersNtbPercentage",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedOrdersNtbPercentage
              ? `${row.attributedOrdersNtbPercentage.toFixed(2)}%`
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
          hiddenDown: "xl",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.ntbUnitsColumn"),
          id: "attributedUnitsOrderedNtb",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedUnitsOrderedNtb
              ? intFormatterRounded.format(row.attributedUnitsOrderedNtb)
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: !report,
          hiddenDown: "xl",
        },
        {
          Header: t(
            "advertisingDashboardWidget.adTable.ntbUnitsPercentageColumn"
          ),
          id: "attributedUnitsOrderedNtbPercentage",
          accessor: (row: CampaignCustomGroupMetrics) => ({
            value: row.attributedUnitsOrderedNtbPercentage
              ? `${row.attributedUnitsOrderedNtbPercentage.toFixed(2)}%`
              : "-",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          isVisible: false,
          hiddenDown: "xl",
        },
      ],
      [
        mid,
        marketplaceType,
        currentRange,
        currentPeriod,
        currentCurrency,
        homeCurrency,
      ]
    );

    const [myColumns, setMyColumns] = useState<Column[]>(columns);

    useEffect(() => {
      setMyColumns(
        columns.map((column) => {
          if (
            myColumns.find((myColumn) => myColumn.id === column.id)
              ?.isVisible === false
          ) {
            return {
              ...column,
              isVisible: false,
            } as any;
          } else {
            return column;
          }
        })
      );
    }, [columns]);

    return (
      <>
        <CampaignGroupTemplateDialog
          mid={mid}
          marketplaceType={marketplaceType}
          marketplaceSubtype={marketplaceSubtype}
          countryCode={countryCode}
          open={templateDialogOpen}
          onClose={() => {
            setTemplateDialogOpen(false);
          }}
        />
        <ConfirmDialog
          open={Boolean(markedForDelete)}
          title={t("generic.confirmTitle")}
          onClose={() => setMarkedForDelete(null)}
          onConfirm={onDelete}
          content={
            <>
              <Typography variant="body1">
                {t("customGroups.removeCampaignConfirmMessage", {
                  groupName: markedForDelete?.groupName,
                })}
              </Typography>
            </>
          }
        />
        <SyncDialog
          mid={mid}
          open={syncDialogOpen}
          onClose={() => {
            setSyncDialogOpen(false);
          }}
          onConfirm={(mid: string, file: any) => {
            dispatch(
              uploadCampaignCustomGroups({
                mid,
                marketplaceType,
                marketplaceSubtype,
                countryCode,
                file,
                t,
              })
            );
          }}
          loading={uploadResponse?.fetching}
          unsyncedItems={uploadResponse?.campaignsNotFound}
          errorLabel={t("customGroups.campaignsNotFound")}
          warningLabel={t("customGroups.campaignUploadWarning")}
        />
        <Panel
          id="campaigns-custom-group-table"
          title={`${t("customGroups.campaignGroupsTable.mainTitle")}`}
          content={
            report ? (
              <NoScrollTable
                {...{
                  columns: myColumns,
                  data: campaignCustomGroups,
                  pageSize,
                  fetchData: fetchData,
                  loading: fetching,
                  isReport: report,
                }}
              />
            ) : (
              <>
                <Table
                  columns={myColumns}
                  data={campaignCustomGroups}
                  fetchData={fetchData}
                  loading={fetching}
                  sorting={true}
                  pagination={!report}
                  pageCount={Math.ceil(
                    (campaignCustomGroupsCount || 0) / pageSize
                  )}
                  pageSize={pageSize}
                />
              </>
            )
          }
          actions={
            report ? undefined : (
              <>
                <StyledButton
                  variant="outlined"
                  color="info"
                  onClick={() => setTemplateDialogOpen(true)}
                >
                  {t("customGroups.downloadButtonLabel")}
                </StyledButton>
                {user.isDemoMode ? (
                  <DemoTooltip
                    arrow
                    placement="top"
                    open={!openModal}
                    title={`${t("generic.notAvailableDemoMode")}`}
                  >
                    <span>
                      <StyledButton
                        variant="contained"
                        color="info"
                        disabled={true}
                      >
                        {t("customGroups.uploadButtonLabel")}
                      </StyledButton>
                    </span>
                  </DemoTooltip>
                ) : (
                  <StyledButton
                    variant="contained"
                    color="info"
                    onClick={() => setSyncDialogOpen(true)}
                  >
                    {t("customGroups.uploadButtonLabel")}
                  </StyledButton>
                )}
                <CampaignCustomGroupsModal
                  mid={mid}
                  marketplace={marketplaceType}
                  marketplaceSubtype={marketplaceSubtype}
                  countryCode={countryCode}
                  openModal={openModal}
                  onEdit={onEdit}
                  groupId={markedForEdit?.groupId}
                  setOpenModal={setOpenModal}
                />
                <StyledButton color="info" onClick={onCreateGroup}>
                  {t("customGroups.createGroup")}
                </StyledButton>
                <SearchFilter setSearchText={setSearchText} />
                <Box pl={2}>
                  <ColumnSelect
                    {...{ columns: myColumns, setColumns: setMyColumns }}
                  />
                </Box>
                <DownloadCsv
                  reportType="campaignGroups"
                  path="/api/generic/campaignGroups"
                  mid={mid}
                  params={{
                    ...currentRange,
                    mid: mid,
                    marketplaceType,
                    marketplaceSubtype,
                    countryCode,
                    currency: currentCurrency,
                    includeVendor: true,
                    exchangeRate,
                  }}
                />
              </>
            )
          }
        ></Panel>
      </>
    );
  }
);

export default CampaignCustomGroupTable;
