import {
  DEFAULT_FILTER,
  upsertPersistentAppSettings,
} from "~/store/persistentAppSettings.redux";
import { Grid, Typography } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";

import CountryDropdown from "../toolbarComponents/countryDropdown";
import FilterTagDropdown from "../toolbarComponents/filterTagDropdown";
import MarketPlaceDropdown from "../toolbarComponents/marketPlaceDropdown";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import usePrevious from "../../../hooks/usePrevious";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export const OptionText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
`;

const FiltersBox = styled(Grid)<{ $rightAlign?: boolean }>`
  max-width: 700px;
  justify-content: ${({ $rightAlign }) =>
    $rightAlign ? "flex-end" : "flex-start"};
`;

interface FilterToolbarProps {
  disabled?: boolean;
  isDemoMode?: boolean;
  rightAlign?: boolean;
}

const FilterToolbar = memo<FilterToolbarProps>(
  ({ disabled, isDemoMode, rightAlign }) => {
    const user = useTypedSelector((state) => state.user);
    const defaultFilters = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );
    const dispatch = useDispatch();
    const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
    const prevSelectedFilters = usePrevious(selectedFilters);

    const updateFilters = useCallback(() => {
      const dispatchCurrentFilter = () =>
        dispatch(
          upsertPersistentAppSettings(
            {
              organisationId: user.organisationId,
              userId: user._id,
              settings: {
                currentFilter: selectedFilters,
              },
            },
            isDemoMode ? false : true
          )
        );

      const dispatchFetchFilteredStores = () =>
        dispatch(fetchFilteredStores(selectedFilters));
      if (!isEqual(prevSelectedFilters, selectedFilters)) {
        dispatchCurrentFilter();
        dispatchFetchFilteredStores();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevSelectedFilters, selectedFilters]);

    useEffect(() => {
      updateFilters();
    }, [updateFilters, selectedFilters]);

    return (
      <FiltersBox
        container
        alignItems="flex-start"
        spacing={1}
        $rightAlign={rightAlign}
      >
        <Grid item xs={12} sm={4}>
          <MarketPlaceDropdown
            fullWidth={true}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CountryDropdown
            fullWidth={true}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FilterTagDropdown
            fullWidth={true}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disabled={disabled}
          />
        </Grid>
      </FiltersBox>
    );
  }
);

export default FilterToolbar;
