import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { CategoryCell } from "~/components/table/cells/categoryCell";
import { DaysCoverCell } from "~/components/table/cells/daysCoverCell";
import { ErrorCell } from "~/components/table/cells/errorCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import Panel from "~/components/panel/panel";
import PrimeIcon from "~/img/amazon_prime.png";
import { Range } from "~/typedef/store";
import { SmallIcon } from "../widgets/salesByProduct";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import { ValueCell } from "~/components/table/cells/valueCell";
import { ValueLowWarningCell } from "~/components/table/cells/valueLowWarningCell";
import { fetchInventoryHealth } from "~/store/mystore/vendor.redux";
import { formatCurrency } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment-timezone";
import { numberWithCommas } from "~/utils/utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export interface InventoryOption {
  value: string;
  label: string;
}

export const TABLE_TYPE = {
  Units: "units",
  Cogs: "cogs",
};

interface InventoryHealthTableProps {
  user: User;
  mid: string;
  currentRange: Range;
  currentCurrency: string;
  searchText?: string;
  actions?: ReactChild;
  tableType: InventoryOption;
  pageSize: number;
  condensed?: boolean;
  conditionalFormatting?: boolean;
  report?: boolean;
}

const InventoryHealthTable = memo(
  ({
    user,
    mid,
    currentRange,
    currentCurrency,
    searchText,
    actions,
    tableType,
    pageSize,
    condensed,
    conditionalFormatting,
    report,
  }: InventoryHealthTableProps) => {
    const { t } = useTranslation();

    const inventoryHealth = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products.data || []
    );
    const inventoryHealthCurrency = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products?.currency || ""
    );
    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products?.lastReportDate
    );
    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products?.lastUpdatedAt
    );
    const inventoryHealthCount = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products?.count || 0
    );
    const inventoryHealthFetching = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products?.fetching || false
    );
    const inventoryHealthParams = useTypedSelector(
      (state) => state?.vendor?.inventoryHealth?.products?.params || {}
    );
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const showCost = tableType.value === TABLE_TYPE.Cogs;

    const dispatch = useDispatch();

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchInventoryHealth(
            {
              user,
              mid,
              currentRange,
              searchText,
              sortKey: sortBy[0]?.id || "sellable_inventory_units",
              sortOrder: sortBy[0]?.id
                ? sortBy[0]?.desc
                  ? "desc"
                  : "asc"
                : "desc",
              pageIndex,
              pageSize,
            },
            inventoryHealthParams
          )
        );
      },
      [user, mid, currentRange, searchText]
    );

    useEffect(() => {
      // NoScrollTable doesn't do its own data fetching, so we have to
      // explicitly fetch the data ourselves in condensed mode
      if (condensed) {
        fetchData({
          pageSize,
          pageIndex: 0,
          sortBy: [{ id: "sellable_inventory_units", desc: "desc" }],
        });
      }
    }, [condensed, user, mid, currentRange]);

    const columns: Column[] = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          id: "title",
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: `ASIN: ${row.asin} | VPI: ${row.vpi}`,
            image: row.imageUrl,
            link: row.url,
            target: "_blank",
            icon: row.isFulfilledByAmazon ? (
              <SmallIcon src={PrimeIcon} />
            ) : null,
          }),
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          colSpan: 3,
          isVisible: true,
          sticky: "left",
        },
        ...(condensed
          ? []
          : [
              {
                id: "brand",
                Header: t("myStoresWidget.salesByBrand.brandColumn"),
                accessor: "brand",
                Cell: TextCell,
                colSpan: 3,
              },
              {
                id: "category",
                Header: t("myStoresWidget.salesByCategory.categoryColumn"),
                accessor: (row: {
                  categoryLabel: string;
                  parentCategory: string;
                }) => ({
                  current: row.categoryLabel,
                  parent: row.parentCategory,
                }),
                Cell: (props: any) => <CategoryCell {...props} />,
                colSpan: 3,
                disableSortBy: true,
              },
            ]),
        {
          Header: t("inventoryHealth.sellableInventory"),
          wrapHeaderText: true,
          accessor: (row: {
            sellableInventoryCost?: number;
            sellableInventoryUnits?: number;
          }) =>
            showCost
              ? row.sellableInventoryCost !== undefined &&
                row.sellableInventoryCost !== 0
                ? formatCurrency(
                    row.sellableInventoryCost,
                    currencyRates,
                    inventoryHealthCurrency,
                    currentCurrency
                  )
                : "-"
              : row.sellableInventoryUnits !== undefined &&
                row.sellableInventoryUnits !== 0
              ? numberWithCommas(row.sellableInventoryUnits.toFixed(0))
              : "-",
          id: showCost ? "sellable_inventory_cost" : "sellable_inventory_units",
          Cell: ValueCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("inventoryHealth.unhealthyInventory"),
          wrapHeaderText: true,
          accessor: (row: {
            unhealthyInventoryCost?: number;
            unhealthyInventoryUnits?: number;
          }) => ({
            displayValue: showCost
              ? row.unhealthyInventoryCost !== undefined &&
                row.unhealthyInventoryCost !== 0
                ? formatCurrency(
                    row.unhealthyInventoryCost,
                    currencyRates,
                    inventoryHealthCurrency,
                    currentCurrency
                  )
                : "-"
              : row.unhealthyInventoryUnits !== undefined &&
                row.unhealthyInventoryUnits !== 0
              ? numberWithCommas(row.unhealthyInventoryUnits.toFixed(0))
              : "-",
            conditionalFormatting: conditionalFormatting,
            compareValue: row.unhealthyInventoryUnits,
            threshold: 0,
          }),
          id: showCost
            ? "unhealthy_inventory_cost"
            : "unhealthy_inventory_units",
          Cell: ErrorCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        {
          Header: t("inventoryHealth.aged90Days"),
          wrapHeaderText: true,
          accessor: (row: {
            aged90DaysCost?: number;
            aged90DaysUnits?: number;
          }) =>
            showCost
              ? row.aged90DaysCost !== undefined && row.aged90DaysCost !== 0
                ? formatCurrency(
                    row.aged90DaysCost,
                    currencyRates,
                    inventoryHealthCurrency,
                    currentCurrency
                  )
                : "-"
              : row.aged90DaysUnits !== undefined && row.aged90DaysUnits !== 0
              ? numberWithCommas(row.aged90DaysUnits.toFixed(0))
              : "-",
          id: showCost ? "aged_90_days_cost" : "aged_90_days_units",
          Cell: ValueCell,
          align: "right",
          isVisible: true,
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("inventoryHealth.unsellableInventory"),
                wrapHeaderText: true,
                accessor: (row: {
                  unsellableInventoryCost?: number;
                  unsellableInventoryUnits?: number;
                }) => ({
                  displayValue: showCost
                    ? row.unsellableInventoryCost !== undefined &&
                      row.unsellableInventoryCost !== 0
                      ? formatCurrency(
                          row.unsellableInventoryCost,
                          currencyRates,
                          inventoryHealthCurrency,
                          currentCurrency
                        )
                      : "-"
                    : row.unsellableInventoryUnits !== undefined &&
                      row.unsellableInventoryUnits !== 0
                    ? numberWithCommas(row.unsellableInventoryUnits.toFixed(0))
                    : "-",
                  conditionalFormatting: conditionalFormatting,
                  compareValue: row.unsellableInventoryUnits,
                  lowThreshold: 10,
                  highThreshold: 100,
                }),
                id: showCost
                  ? "unsellable_inventory_cost"
                  : "unsellable_inventory_units",
                Cell: ValueLowWarningCell,
                align: "right",
                isVisible: true,
              },
            ]),
        {
          Header: t("inventoryHealth.unitsShipped"),
          accessor: (row: { totalShippedUnits: number }) => ({
            displayValue:
              Number.isFinite(row.totalShippedUnits) &&
              row.totalShippedUnits !== 0
                ? numberWithCommas(row.totalShippedUnits)
                : "-",
            conditionalFormatting: false,
          }),
          id: "total_shipped_units",
          Cell: DaysCoverCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        {
          Header: t("inventoryHealth.daysCover"),
          accessor: (row: { daysCover: number }) => ({
            displayValue:
              Number.isFinite(row.daysCover) && row.daysCover !== 0
                ? numberWithCommas(row.daysCover.toFixed(1))
                : "-",
            conditionalFormatting: conditionalFormatting,
            compareValue: Number.isFinite(row.daysCover)
              ? row.daysCover
              : Number.POSITIVE_INFINITY,
            lowThreshold: 30,
            midThreshold: 60,
            highThreshold: 90,
          }),
          id: "days_cover",
          Cell: DaysCoverCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        {
          Header: t("inventoryHealth.openPo"),
          accessor: (row: { openPo?: number }) =>
            row.openPo !== undefined && row.openPo !== 0
              ? numberWithCommas(row.openPo.toFixed(0))
              : "-",
          id: "open_po",
          Cell: ValueCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("inventoryHealth.receivedInventory"),
                wrapHeaderText: true,
                accessor: (row: {
                  receivedInventoryCost?: number;
                  receivedInventoryUnits?: number;
                }) =>
                  showCost
                    ? row.receivedInventoryCost !== undefined &&
                      row.receivedInventoryCost !== 0
                      ? formatCurrency(
                          row.receivedInventoryCost,
                          currencyRates,
                          inventoryHealthCurrency,
                          currentCurrency
                        )
                      : "-"
                    : row.receivedInventoryUnits !== undefined &&
                      row.receivedInventoryUnits !== 0
                    ? numberWithCommas(row.receivedInventoryUnits.toFixed(0))
                    : "-",
                id: showCost
                  ? "received_inventory_cost"
                  : "received_inventory_units",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("inventoryHealth.unfilledInventory"),
                wrapHeaderText: true,
                accessor: (row: { unfilledInventory?: number }) =>
                  row.unfilledInventory !== undefined &&
                  row.unfilledInventory !== 0
                    ? numberWithCommas(row.unfilledInventory.toFixed(0))
                    : "-",
                id: "unfilled_inventory",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("inventoryHealth.sellThroughRate"),
                wrapHeaderText: true,
                accessor: (row: { sellThroughRate?: number }) =>
                  row.sellThroughRate
                    ? `${(row.sellThroughRate * 100).toFixed(1)}%`
                    : "-",
                id: "sell_through_rate",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("inventoryHealth.averageVendorLeadTimeDays"),
                wrapHeaderText: true,
                accessor: (row: { averageVendorLeadTimeDays?: number }) =>
                  row.averageVendorLeadTimeDays !== undefined
                    ? numberWithCommas(row.averageVendorLeadTimeDays.toFixed(1))
                    : "-",
                id: "average_vendor_lead_time_days",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("inventoryHealth.netFilledRate"),
                wrapHeaderText: true,
                accessor: (row: {
                  receivedInventoryUnits?: number;
                  unfilledInventory?: number;
                }) => {
                  const totalOrderedUnits =
                    (row.receivedInventoryUnits ?? 0) +
                    (row.unfilledInventory ?? 0);
                  return totalOrderedUnits &&
                    row.receivedInventoryUnits !== undefined
                    ? `${(
                        (row.receivedInventoryUnits / totalOrderedUnits) *
                        100
                      ).toFixed(1)}%`
                    : "-";
                },
                id: "net_filled_rate",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
                disableSortBy: true,
              },
            ]),
      ],
      [
        mid,
        currentRange,
        currentCurrency,
        currencyRates,
        inventoryHealthCurrency,
        tableType,
      ]
    );
    const [columnConfig, setColumnConfig] = useState<Column[]>(
      columns.map((c) => ({
        Header: c.Header,
        id: c.id,
        isVisible: c.isVisible,
      }))
    );

    const footerLink =
      condensed && user.brandAnalyticsOn && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "inventoryhealth"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="inventory-health-table"
        title={t("inventoryHealth.mainTitle")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.sourcingView")} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t("retailAnalytics.sourcingView")}`
        }
        content={
          condensed ? (
            <NoScrollTable
              {...{
                columns: columns.map((c) => ({
                  ...c,
                  isVisible: columnConfig.find((cc) => cc.id === c.id)
                    ?.isVisible,
                })),
                data: inventoryHealth,
                pageSize,
                loading: inventoryHealthFetching,
                isReport: report,
              }}
            />
          ) : (
            <Table
              {...{
                columns: columns.map((c) => ({
                  ...c,
                  isVisible: columnConfig.find((cc) => cc.id === c.id)
                    ?.isVisible,
                })),
                data: inventoryHealth,
                fetchData,
                loading: inventoryHealthFetching,
                sorting: true,
                pagination: true,
                pageCount: Math.ceil((inventoryHealthCount || 0) / pageSize),
                pageSize: pageSize,
              }}
            />
          )
        }
        actions={
          <>
            {actions}
            {!report && (
              <ColumnSelect
                {...{ columns: columnConfig, setColumns: setColumnConfig }}
              />
            )}
          </>
        }
        footerLink={footerLink}
      />
    );
  }
);

export default InventoryHealthTable;
