import { Box, Input } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  useConnectStandaloneAdvertisingMutation,
  useFetchStorePortfoliosQuery,
} from "~/store/connections/connectStandaloneAdvertisingStore.redux";

import { AdvertisingPortfolio } from "~/typedef/brandFilterConnection";
import GenericConnectButton from "~/modules/login/genericConnectButton";
import Grid from "@material-ui/core/Grid";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import PortfolioMenu from "~/components/toolbars/toolbarComponents/portfolioDropdown";
import { Store } from "~/typedef/store";
import { StoreSelector } from "~/components/toolbars/myStoresToolbar/storeSelector";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const SUPPORTED_MARKETPLACE_SUBTYPES = [
  "amazon",
  "amazon_vendor",
  "advertising",
];
const SUPPORTED_MARKETPLACE_TYPES = ["amazon", "amazon_vendor"];

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ButtonWrapper = styled(Grid)`
  margin: 0 auto;
`;

interface AdvertisingProps {
  store: Store;
  onSuccess: (
    goToNextStage?: boolean,
    payload?: Record<string, any>,
    finalStep?: boolean
  ) => void;
  setIsSubmitting?: (bool: boolean) => void;
}

const StandaloneFilteredAdvertisingConnectPage = ({
  onSuccess,
  setIsSubmitting,
}: Omit<AdvertisingProps, "store">) => {
  const { t } = useTranslation();

  const [store, setStore] = useState<Store | null>(null);
  const [selectedPortfolios, setSelectedPortfolios] = useState<
    AdvertisingPortfolio[]
  >([]);
  const [customStoreName, setCustomStoreName] = useState<string | undefined>(
    undefined
  );
  const dispatch = useDispatch();

  const { portfolios, isLoading } = useFetchStorePortfoliosQuery(
    {
      marketplaceCountry: store?.marketplaceCountry || "",
      marketplaceSubtype: store?.marketplaceSubtype || "",
      marketplaceType: store?.marketplaceType || "",
      mid: store?.merchantId || "",
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          portfolios: data || [],
          isLoading: isFetching,
        };
      },
      skip: !store,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    connectStandaloneAdvertisingStore,
    {
      connectingStandaloneAdvertisingStore,
      brandFilterAdvertisingStoreConnected,
    },
  ] = useConnectStandaloneAdvertisingMutation({
    selectFromResult: ({ isLoading, isSuccess }) => ({
      connectingStandaloneAdvertisingStore: isLoading,
      brandFilterAdvertisingStoreConnected: isSuccess,
    }),
  });

  useEffect(() => {
    if (brandFilterAdvertisingStoreConnected) {
      fetchFilteredStores({}, true)(dispatch);
      onSuccess(false, undefined, true);
    }
  }, [brandFilterAdvertisingStoreConnected]);

  const changePortfolio = useCallback(
    (portfolioNames: string[]): void => {
      const filteredPortfolios = portfolios?.filter((p) =>
        portfolioNames.includes(p.name)
      );

      if (filteredPortfolios) {
        setSelectedPortfolios(filteredPortfolios);
      }
    },
    [portfolios]
  );

  const handleSuccess = useCallback(async () => {
    if (store && selectedPortfolios.length && customStoreName) {
      setIsSubmitting && setIsSubmitting(true);
      connectStandaloneAdvertisingStore({
        parentStoreParams: {
          mid: store.merchantId,
          marketplaceType: store.marketplaceType,
          marketplaceSubtype: store.marketplaceSubtype,
          marketplaceCountry: store.marketplaceCountry,
        },
        portfolioIds: selectedPortfolios.map((p) => p.portfolioId),
        storeName: customStoreName,
      });
    }
  }, [store, selectedPortfolios, customStoreName]);

  const allStores = useTypedSelector(
    (state) => state.mystore?.allStores?.stores
  );

  return (
    <GridWrapper id="amazon-connect-wrapper">
      <Grid container spacing={5}>
        <Grid container item xs={12}>
          <Box pt={1} pb={1} width={1}>
            <StoreSelector
              changeStore={(market, mid, items) => {
                const matchingStore = allStores?.find(
                  (s) => s.marketplaceType === market && s.merchantId === mid
                );
                if (matchingStore) {
                  setStore(matchingStore);
                  setCustomStoreName(
                    `${matchingStore.storeName} - Filtered Advertising`
                  );
                  setSelectedPortfolios([]);
                  setCustomStoreName(undefined);
                  setIsSubmitting && setIsSubmitting(true);
                }
              }}
              currentStore={
                store
                  ? {
                      marketplace: store?.marketplaceType,
                      merchantId: store?.merchantId,
                      sourceSystemId: store?.merchantId.split(" @")[0],
                      marketplaceCountry: store?.marketplaceCountry,
                      storeName: store?.storeName,
                    }
                  : undefined
              }
              currentFilter={{
                marketplaces: SUPPORTED_MARKETPLACE_SUBTYPES,
                countries: [],
                tags: [],
                marketplaceTypes: SUPPORTED_MARKETPLACE_TYPES,
              }}
              advertisingOnly={true}
              maxWidth={350}
              fullWidth={true}
              hideClearFilters={true}
              disabled={false}
              centerAlign={true}
              title={t("storeLink.selectAdvertisingChannel")}
            />
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Box pt={0} pb={1} width={1}>
            {isLoading ? (
              <LoadingIndicator size={30} />
            ) : !portfolios?.length ? (
              <Box display="flex" flexDirection="column">
                <Medium align="center">{"No portfolios found"}</Medium>
              </Box>
            ) : (
              <PortfolioMenu
                changePortfolios={changePortfolio}
                currentPortfolioNames={selectedPortfolios.map((p) => p.name)}
                portfolioNames={portfolios?.map((p) => p.name) || []}
                maxWidth={350}
                fullWidth={true}
              />
            )}
          </Box>
        </Grid>
        {!isLoading && portfolios?.length > 0 && (
          <Grid container item xs={12}>
            <Box pt={0} pb={1} width={1}>
              <Input
                value={customStoreName}
                onChange={(e) => {
                  setCustomStoreName(e.target.value);
                }}
                placeholder={t("connectWizard.customStoreNamePlaceholder")}
                fullWidth
              />
            </Box>
          </Grid>
        )}
        <ButtonWrapper container spacing={2} item xs={12}>
          <Grid container item xs={12} justifyContent="center">
            <GenericConnectButton
              disabled={!!isEmpty(selectedPortfolios)}
              onSuccess={handleSuccess}
            >
              {connectingStandaloneAdvertisingStore ? (
                <LoadingIndicator />
              ) : (
                t("connectWizard.createFilteredStore")
              )}
            </GenericConnectButton>
          </Grid>
        </ButtonWrapper>
      </Grid>
    </GridWrapper>
  );
};

export default StandaloneFilteredAdvertisingConnectPage;
