import { get, sortedUniq } from "lodash";

import { Filter } from "~/typedef/store";
import FilterSelect from "../../select/filterSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface MarketPlaceDropdownProps {
  filterWidth?: number;
  selectedFilters: Filter;
  setSelectedFilters: (filter: Filter) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const MarketPlaceDropdown: React.FC<MarketPlaceDropdownProps> = ({
  selectedFilters,
  setSelectedFilters,
  filterWidth,
  fullWidth,
  disabled,
}) => {
  const { t } = useTranslation();
  const allStores = useTypedSelector((state) =>
    get(state, "mystore.allStores.stores", [])
  );
  const marketAccs = sortedUniq(
    allStores.map((s) => s.marketplaceSubtype).sort()
  );
  const selectedMarketplaces = get(selectedFilters, "marketplaces");

  const handleOptionChange = (marketplaces: string[]) => {
    setSelectedFilters({ ...selectedFilters, marketplaces: marketplaces });
  };

  return (
    <FilterSelect
      title={t(`filterTitle.marketplace`)}
      isFullWidth={fullWidth ?? false}
      maxWidth={filterWidth}
      options={marketAccs}
      selectedOptions={selectedMarketplaces}
      handleSelect={handleOptionChange}
      isMarketplace
      isDisplayIcons
      filterTitle={t("filters.allMarketplaces")}
      disabled={disabled ?? false}
      isCountry={false}
      condensed={false}
      searchable={false}
    />
  );
};

export default MarketPlaceDropdown;
