import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface RemoveAdStoreParams {
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  marketplaceCountry: string;
  advertisingMarketplaceType: string;
}

interface RemoveAdStoreRes {
  status: string;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    removeAdStore: build.mutation<RemoveAdStoreRes, RemoveAdStoreParams>({
      query: (data) => ({
        url: `${baseUrl}/auth/amazon/advertising/remove`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      }),
      onQueryStarted: globalQueryErrorHandler("RemoveAdStore", false),
    }),
  }),
});

export const { useRemoveAdStoreMutation } = extendedApiSlice;
