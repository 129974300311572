import { Grid, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import Medium from "~/components/typography/medium";
import MetaConnectButton from "~/components/buttons/metaConnectButton";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import styled from "styled-components";
import useMetaAuthcodeAuth from "~/hooks/useMetaAuthcodeAuth";
import { useTranslation } from "react-i18next";
import { useValidateMetaAdvertisingAuthMutation } from "~/store/connections/connectMetaAdvertising.redux";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

interface MetaAdvertisingConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const MetaAdvertisingConnectPage: React.FC<MetaAdvertisingConnectPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const onAuthError = useCallback((error: string) => {
    setError(t(error));
  }, []);

  const [validateMetaAuth] = useValidateMetaAdvertisingAuthMutation({
    fixedCacheKey: "validateMetaAuthCode",
  });

  const onAuthCode = useCallback(async (authCode: string, _state: string) => {
    validateMetaAuth({
      authCode,
    })
      .unwrap()
      .then(({ code, errMsg }) => {
        if (errMsg) {
          onAuthError(errMsg);
        } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
          onAuthError("connectWizard.connectionError");
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        const errorMessage = error.message || "connectWizard.connectionError";
        onAuthError(errorMessage);
      });
  }, []);

  const triggerSignIn = useMetaAuthcodeAuth(onAuthCode, onAuthError);

  const handleSignIn = () => {
    triggerSignIn();
  };

  return (
    <GridWrapper id="meta-advertising-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item>
          <Medium color="textSecondary">
            {t("connectWizard.metaAdvertisingLinkTitle")}
          </Medium>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            <MetaConnectButton onClick={handleSignIn} />
          </Grid>
        </Grid>
        {error && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <ErrorMessage color="error">{error}</ErrorMessage>
          </Grid>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default MetaAdvertisingConnectPage;
