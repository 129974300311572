import { Filter, Range, Store } from "~/typedef/store";
import React, { useCallback, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";
import { LinkCell } from "~/components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "~/components/table/cells/marketplaceAndCountryCell";
import { PaginationArgs } from "~/typedef/pagination";
import { Panel } from "../../../components/panel/panel";
import Table from "../../../components/adTable/table";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { generateShippedRevenueByStoresTableRows } from "./helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useShippedRevenueByStoresQuery } from "~/store/mystore/vendor.redux";
import { useTheme } from "@material-ui/core/styles";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_SORT_KEY = "shippedRevenue";
const DEFAULT_SORT_ORDER = "desc";

export const columns = (t: TFunction, view: "sourcing" | "manufacturing") => {
  const isSourcingView = view === "sourcing";

  return [
    {
      id: "marketplace",
      Header: t("dashboardWidget.shippedRevenueByStores.marketplaceColumn"),
      accessor: "marketplace",
      Cell: MarketplaceAndCountryCell,
      isVisible: true,
    },
    {
      id: "channel",
      Header: t("dashboardWidget.shippedRevenueByStores.channelColumn"),
      accessor: "channel",
      Cell: LinkCell,
      isVisible: true,
    },
    {
      id: "shippedRevenue",
      Header: t("dashboardWidget.shippedRevenueByStores.shippedRevenueColumn"),
      accessor: isSourcingView
        ? "shippedRevenue"
        : "shippedRevenueManufacturing",
      Cell: ValueAndGrowthCell,
      align: "right",
      isVisible: true,
    },
    {
      id: "shippedCogs",
      Header: t("dashboardWidget.shippedRevenueByStores.shippedCogsColumn"),
      accessor: isSourcingView ? "shippedCogs" : "shippedCogsManufacturing",
      Cell: ValueAndGrowthCell,
      align: "right",
      isVisible: true,
    },
    {
      id: "shippedUnits",
      Header: t("dashboardWidget.shippedRevenueByStores.shippedUnitsColumn"),
      accessor: isSourcingView ? "shippedUnits" : "shippedUnitsManufacturing",
      Cell: ValueAndGrowthCell,
      align: "right",
      isVisible: true,
    },
    {
      id: "unitCogs",
      Header: t("dashboardWidget.shippedRevenueByStores.unitCogsColumn"),
      accessor: isSourcingView ? "avgCogs" : "avgCogsManufacturing",
      Cell: ValueAndGrowthCell,
      align: "right",
      isVisible: true,
    },
  ];
};

interface ShippedRevenueByStoresTableProps {
  currentRange: Range;
  currentCurrency: string;
  currentFilter: Filter;
  pageSize?: number;
  view: "sourcing" | "manufacturing";
  filteredStores: Store[];
  conditionalFormatting?: boolean;
  report?: boolean;
}

const ShippedRevenueByStoresTable: React.FC<
  ShippedRevenueByStoresTableProps
> = ({
  currentRange,
  currentFilter,
  pageSize = DEFAULT_PAGE_SIZE,
  currentCurrency,
  filteredStores,
  conditionalFormatting,
  view,
  report = false,
}) => {
  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageSize,
    pageIndex: 0,
    sortKey:
      view === "manufacturing"
        ? "shippedRevenueManufacturing"
        : DEFAULT_SORT_KEY,
    sortOrder: DEFAULT_SORT_ORDER,
  });

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const fetchData = useCallback(({ pageIndex, pageSize }) => {
    setPaginationParams({
      pageIndex,
      pageSize,
      sortKey: paginationParams.sortKey,
      sortOrder: paginationParams.sortOrder,
    });
  }, []);

  const { shippedRevenueByStores, isFetching, count } =
    useShippedRevenueByStoresQuery(
      {
        currentRange,
        paginationParams,
        currentFilter,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          shippedRevenueByStores: data?.shippedRevenueByStores || [],
          isFetching,
          count: data?.count || 0,
        }),
      }
    );

  const data = useMemo(
    () =>
      generateShippedRevenueByStoresTableRows(
        shippedRevenueByStores,
        filteredStores,
        currentCurrency,
        currencyRates,
        conditionalFormatting
      ),
    [shippedRevenueByStores, currencyRates, currentCurrency]
  );

  const columnsMemo = useMemo(() => columns(t, view), [view]);

  return (
    <Panel
      id="widget-sales-summary-by-stores"
      title={t("dashboardWidget.shippedRevenueByStores.mainTitle")}
      subtitle={view === "sourcing" ? "Sourcing View" : "Manufacturing View"}
      tooltip={t("dashboardWidget.shippedRevenueByStores.mainTooltip")}
      content={
        <Box
          mb={isMobile || report || data.length < pageSize + 1 ? 0 : "-53px"}
        >
          <Table
            columns={columnsMemo}
            data={data}
            loading={isFetching}
            fetchData={fetchData}
            pageSize={pageSize}
            pagination={!report}
            pageCount={Math.ceil(count / pageSize)}
            disableScroll={true}
          />
        </Box>
      }
    />
  );
};

export default ShippedRevenueByStoresTable;
