import * as Sentry from "@sentry/browser";
import * as crypto from "crypto";

import axios from "axios";
import { baseUrl } from "~/configs/index";
import cookies from "browser-cookies";

export const hasMultipleAmazonMarketplaces = async (
  userId: string,
  sellerId: string,
  countryCode: string
) => {
  const inputParam = {
    amazonSellerId: sellerId,
    customerId: userId,
    countryCode: countryCode,
  };

  try {
    const options = {
      method: "GET",
      params: inputParam,
      url: `${baseUrl}/api/amazon-mws-service/api/sellerHealth/listOpenMarketplaces`,
    };
    const { data } = await axios(options);

    return data.length > 1;
  } catch (err) {
    Sentry.captureException(err);
  }

  return false;
};

export const hashAccessToken = (): string | null => {
  /* get user's current access token and hash it.
  the idea is to generate a state we can verify later when
  the user returns from the auth flow. hashing the token
  from the user's current session creates a neat way for us
  to re-generate that state and verify it, without the need
  to store it somewhere separately. it also expires automatically
  which is neat.
  Learn more: https://datatracker.ietf.org/doc/html/rfc6749#section-10.12
  */
  const accessToken = cookies.get("accessToken");
  if (!accessToken) return null;
  return crypto.createHash("sha256").update(accessToken).digest("hex");
};
