import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";

import { BlackTooltip } from "../chartUtils/chartComponents";
import Bold from "~/components/typography/bold";
import { LegendIndicator } from "../multiBarLineChart/multiBarLineChartLegend";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";

interface TooltipProps {
  key: string;
  fillColor: string;
  toFixed?: number;
  name: string;
  shape: "bar" | "line";
  unit?: string;
}

interface TooltipData {
  [key: string]: number;
}

interface HorizontalStackedBarChartTooltipProps {
  active?: boolean;
  payload?: { payload: TooltipData }[];
  label?: string;
  tooltipProps: TooltipProps[];
}

export const HorizontalStackedBarChartTooltip =
  memo<HorizontalStackedBarChartTooltipProps>(
    function HorizontalStackedBarChartTooltip({
      active,
      payload,
      label,
      tooltipProps,
    }) {
      const { t } = useTranslation();
      if (active) {
        const tooltipData = payload?.length ? payload[0].payload : null;

        return (
          <BlackTooltip>
            <Box p={1}>
              {label && <Bold variant="body2">{label}</Bold>}
              {tooltipProps.map((bar) => {
                return (
                  <Box
                    key={`${bar.key}`}
                    display="flex"
                    mt={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" mr={1}>
                      <LegendIndicator shape={bar.shape} fill={bar.fillColor} />
                      <Typography variant="body2">{t(bar.name)}</Typography>
                    </Box>
                    <Typography variant="body2">
                      {tooltipData && typeof tooltipData?.[bar.key] === "number"
                        ? numberWithCommas(
                            tooltipData[bar.key].toFixed(bar.toFixed ?? 0)
                          ) + (bar.unit ?? "")
                        : "-"}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </BlackTooltip>
        );
      }
      return null;
    }
  );

export default HorizontalStackedBarChartTooltip;
