import {
  AvailabilityCell,
  NO_DATA,
} from "../../../components/table/cells/availabilityCell";
import { Filter, Store } from "~/typedef/store";
import React, { memo, useCallback, useMemo } from "react";
import {
  formatCurrencyRounded,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";
import {
  getShopName,
  marketplaceLink,
  stripFilteredSuffix,
} from "~/utils/marketplaceUtils";

import { Column } from "~/components/adTable/columnSelect";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { DateRange } from "~/typedef/date";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { LinkCell } from "../../../components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import { Panel } from "../../../components/panel/panel";
import { StrongValueCell } from "../../../components/table/cells/strongValueCell";
import Table from "../../../components/table/table";
import { TableCellProp } from "~/components/table/cellProps";
import { ValueCell } from "@components/table/cells/valueCell";
import { fetchTopProducts } from "../../../store/overview/topProducts.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ParsedTopPerformingProduct {
  product: {
    value: string;
    image: string;
    link: string;
  };
  mid: string;
  market: {
    market: string;
    marketplaceSubtype: string;
    countryCode: string;
  };
  shopName: {
    value: string;
    link:
      | {
          pathname: string;
          search: string;
        }
      | string;
  };
  availability: number;
  daysCover: number | null;
  salesValue: number;
  sales: string;
  quantity: string;
}

interface TopPerformingProductsProps {
  currentCurrency: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: DateRange;
  currentFilter: Filter;
  pageSize: number;
  includeTax: boolean;
  filteredStores: Store[];
  report?: boolean;
}

const TopPerformingProducts = memo<TopPerformingProductsProps>(
  function TopPerformingProducts({
    includeTax,
    currentPeriod,
    currentCurrency,
    currentRange,
    pageSize,
    currentFilter,
    report,
    filteredStores,
  }) {
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const topProducts = useTypedSelector((state) => state.overview.topProducts);
    const allowedLinks = useTypedSelector(
      (state) => state.customLayout?.layoutConfig?.sideNav?.pages
    );
    const daysCoverLimits = useTypedSelector(
      (state) => state?.notifications?.daysCoverLimits?.data
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dispatchFetchTopProducts = useCallback(
      ({ pageIndex, pageSize }) => {
        dispatch(
          fetchTopProducts(
            {
              currentPeriod,
              currentRange,
              pageIndex,
              pageSize,
              filter: currentFilter,
              includeTax,
            },
            topProducts.params
          )
        );
      },
      [
        currentFilter,
        currentPeriod,
        currentRange,
        dispatch,
        includeTax,
        topProducts.params,
      ]
    );

    const data = useMemo<ParsedTopPerformingProduct[]>(
      () =>
        topProducts && topProducts.data
          ? topProducts.data.map((topSeller) => {
              const countryCode = topSeller.countryCode;
              const sourceCurrency = getCurrencyByCountryCode[countryCode];
              const shop = topSeller.mid;
              const shopName = {
                value: getShopName(
                  filteredStores,
                  topSeller.marketplaceSubtype,
                  topSeller.mid
                ),
                link: marketplaceLink(
                  topSeller.marketplace,
                  shop,
                  "topsellers",
                  /* eslint-disable-next-line no-undefined */
                  undefined,
                  allowedLinks
                ),
              };
              return {
                product: {
                  value: topSeller.title,
                  image: topSeller.imageUrl,
                  link: topSeller.url,
                },
                mid: topSeller.mid,
                market: {
                  market: topSeller.marketplace,
                  marketplaceSubtype: topSeller.marketplaceSubtype,
                  countryCode: countryCode,
                },
                shopName,
                availability:
                  stripFilteredSuffix(topSeller.marketplace ?? null) ===
                  "amazon_vendor"
                    ? NO_DATA
                    : topSeller.available,
                daysCover: topSeller.daysCover,
                salesValue: parseFloat(topSeller.totalSales),
                sales: formatCurrencyRounded(
                  parseFloat(topSeller.totalSales),
                  currencyRates,
                  sourceCurrency,
                  currentCurrency
                ),
                quantity: topSeller.totalQuantity,
              };
            })
          : [],
      [
        topProducts,
        currencyRates,
        filteredStores,
        currentCurrency,
        allowedLinks,
      ]
    );

    const columns: Column[] = useMemo(
      () => [
        {
          id: "product",
          Header: t("dashboardWidget.topPerformingProducts.productColumn"),
          accessor: (row: ParsedTopPerformingProduct) => ({
            value: row.product.value,
            image: row.product.image,
            link: row.product.link,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              image: string;
              link: string;
              target: "_blank";
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
        },
        {
          id: "market",
          Header: t("dashboardWidget.topPerformingProducts.marketplaceColumn"),
          accessor: "market",
          Cell: MarketplaceAndCountryCell,
          hiddenDown: report ? "xl" : "sm",
        },
        {
          id: "shopName",
          Header: t("dashboardWidget.topPerformingProducts.storeColumn"),
          accessor: "shopName",
          Cell: LinkCell,
          hiddenDown: "md",
        },
        {
          id: "sales",
          Header: t("dashboardWidget.topPerformingProducts.salesColumn"),
          accessor: "sales",
          Cell: StrongValueCell,
          align: "right",
        },
        {
          id: "quantity",
          Header: t("dashboardWidget.topPerformingProducts.quantityColumn"),
          accessor: "quantity",
          Cell: ValueCell,
          align: "right",
        },
        {
          id: "availability",
          Header: t("dashboardWidget.topPerformingProducts.availabilityColumn"),
          accessor: ({
            availability,
            daysCover,
            mid,
          }: ParsedTopPerformingProduct) => ({
            availableValue: availability,
            compareValue: daysCover,
            threshold: daysCoverLimits?.find((d) => d.mid === mid)
              ?.daysCoverLimit,
          }),
          Cell: AvailabilityCell,
          hiddenDown: report ? "xl" : "sm",
          customWidth: 130,
          align: "right",
        },
      ],
      [t]
    );

    return (
      <Panel
        id="widget-top-performing-products"
        title={t("dashboardWidget.topPerformingProducts.mainTitle")}
        tooltip={t("dashboardWidget.topPerformingProducts.mainTooltip")}
        content={
          <Table
            columns={columns}
            data={data}
            pageSize={pageSize}
            pageCount={2}
            fetchData={dispatchFetchTopProducts}
            loading={topProducts.fetching}
            pagination={!report}
          />
        }
      />
    );
  }
);

export default TopPerformingProducts;
