import { Filter, Range } from "~/typedef/store";

import { PaginationArgs } from "~/typedef/pagination";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface CCPPSummaryRequest {
  currentRange: Range;
  queryParameters: PaginationArgs & { searchText?: string };
  currentFilter: Filter;
  includeTax: boolean;
}

export interface CCPPSummaryRecord {
  sellerSku: string;
  totalQuantity: number;
  totalShippingCost: number;
  totalSales: number;
  title: string;
  imageUrl: string;
  linkUrl: string;
  inventory: number;
}

interface CCPPSummaryResponse {
  products: CCPPSummaryRecord[];
  count: number;
}

interface CCPPByStoreRequest extends CCPPSummaryRequest {
  skus?: string[];
}

export interface CCPPByStoreMetrics {
  sellerSku: string;
  totalQuantity: number;
  totalShippingCost: number;
  totalSales: number;
  salesRank: number | null;
  daysCover: number | null;
  price: number | null;
  categoryName: string | null;
  parentCategoryName: string | null;
  inventory: number | null;
  outOfStockSinceDays: number | null;
  avgPrice: number | null;
}

type BasicStoreDetails = {
  storeId: string;
  storeName: string;
  marketplaceCountry: string;
  marketplaceType: string;
};

type CCPPByStoreMap = Record<
  string,
  CCPPByStoreMetrics & {
    storeId: string;
  }
>;

type CCPPByStoreRecord = CCPPByStoreMetrics & {
  title: string;
  imageUrl: string;
  linkUrl: string;
  storeMap: CCPPByStoreMap;
};

interface CCPPByStoreResponse {
  products: CCPPByStoreRecord[];
  count: number;
}

interface CCPPTotalsRequest {
  currentRange: Range;
  currentFilter: Filter;
  includeTax: boolean;
}

type CCPPTotalsMetrics = Omit<
  CCPPByStoreMetrics,
  "sellerSku" | "categoryName" | "parentCategoryName"
>;

type CCPPTotalsStoreMap = Record<
  string,
  CCPPTotalsMetrics & {
    storeId: string;
  }
>;

export type CCPPTotalsResponse = {
  totals: CCPPTotalsMetrics & {
    storeMap: CCPPTotalsStoreMap;
  };
  storeInfoMap: Record<string, BasicStoreDetails>;
};

// /* Fetching additional metrics is only available for a specific set of SKUs,
// typically the current page the user is looking at */
interface CCPPOtherMetricsRequest
  extends Omit<CCPPByStoreRequest, "queryParameters"> {
  metric: "advertising" | undefined;
}

export type CCPPAdvertisingMetrics = {
  storeId: string;
  sellerSku: string;
  adImpressions: number;
  adClicks: number;
  adCost: number;
  adSales: number;
  acos: number;
};

type CCPPAdvertisingStoreMap = Record<string, CCPPAdvertisingMetrics>;

type CCPPAdvertisingMetricsRecord = Omit<CCPPAdvertisingMetrics, "storeId"> & {
  storeMap: CCPPAdvertisingStoreMap;
};

interface CCPPOtherMetricsResponse {
  products: CCPPAdvertisingMetricsRecord[];
}

interface CCPPOtherMetricsTotalsRequest extends CCPPTotalsRequest {
  metric: "advertising" | undefined;
}

type CCPPAdvertisingTotalsStoreMap = Record<
  string,
  Omit<CCPPAdvertisingMetrics, "sellerSku">
>;

type CCPPAdvertisingTotals = Omit<
  CCPPAdvertisingMetrics,
  "storeId" | "sellerSku"
> & {
  storeMap: CCPPAdvertisingTotalsStoreMap;
};

export type CCPPOtherMetricsTotalsResponse =
  | CCPPAdvertisingTotals
  | Record<string, never>;

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    ccppSummary: build.query<CCPPSummaryResponse, CCPPSummaryRequest>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/crossChannel/products`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CrossChannelProduct"),
    }),
    ccppByStore: build.query<CCPPByStoreResponse, CCPPByStoreRequest>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/crossChannel/store`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CrossChannelProductPerChannel"),
    }),
    ccppTotals: build.query<CCPPTotalsResponse, CCPPTotalsRequest>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/crossChannel/totals`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CrossChannelProductTotals"),
    }),
    ccppOtherMetrics: build.query<
      CCPPOtherMetricsResponse,
      CCPPOtherMetricsRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/crossChannel/metrics`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CrossChannelProductMetrics"),
    }),
    ccppOtherMetricsTotals: build.query<
      CCPPOtherMetricsTotalsResponse,
      CCPPOtherMetricsTotalsRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/crossChannel/metrics/totals`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "CrossChannelProductMetricsTotals"
      ),
    }),
  }),
});

export const {
  useCcppSummaryQuery,
  useCcppByStoreQuery,
  useCcppTotalsQuery,
  useCcppOtherMetricsQuery,
  useCcppOtherMetricsTotalsQuery,
} = extendedApiSlice;
