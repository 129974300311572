import { DownArrow, UpArrow } from "~/icons/percentageChangeArrows";
import React, { memo } from "react";

import StatusText from "~/components/typography/status";
import { getPercentageDifference } from "~/utils/salesUtils";

export enum SuccessDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export const getStatus = (
  successDirection: SuccessDirection = SuccessDirection.ASCENDING,
  arrowDirection: SuccessDirection,
  conditionalFormatting?: boolean
): "success" | "error" | undefined => {
  if (conditionalFormatting === false) {
    return undefined;
  } else if (successDirection === arrowDirection) {
    return "success";
  } else {
    return "error";
  }
};

interface PercentageChangeProps {
  current?: number | null;
  prior?: number | null;
  isPptChange?: boolean;
  successDirection?: SuccessDirection;
  conditionalFormatting?: boolean;
  toFixedDigits?: number;
  noUnit?: boolean;
}

export const PercentageChange = memo<PercentageChangeProps>(
  ({
    current,
    prior,
    isPptChange,
    successDirection,
    conditionalFormatting,
    toFixedDigits,
    noUnit,
  }) => {
    if (typeof current !== "number" || typeof prior !== "number") {
      return (
        <StatusText
          variant="body2"
          align="right"
          paragraph={false}
          status="disabled"
        >
          -
        </StatusText>
      );
    }

    const diff: number = isPptChange
      ? current - prior
      : Number(getPercentageDifference(current, prior));

    if (isNaN(diff)) {
      return (
        <StatusText
          variant="body2"
          align="right"
          paragraph={false}
          status="disabled"
        >
          -
        </StatusText>
      );
    }

    let arrow: React.ReactElement, status: "success" | "error" | undefined;
    if (diff >= 0) {
      status = getStatus(
        successDirection,
        SuccessDirection.ASCENDING,
        conditionalFormatting
      );
      arrow = <UpArrow fontSize="inherit" {...{ status }} />;
    } else {
      status = getStatus(
        successDirection,
        SuccessDirection.DESCENDING,
        conditionalFormatting
      );
      arrow = <DownArrow fontSize="inherit" {...{ status }} />;
    }
    return (
      <StatusText
        variant="body2"
        align="right"
        paragraph={false}
        status={status}
      >
        {arrow}
        {isNaN(diff)
          ? diff.toString()
          : Math.abs(diff).toFixed(toFixedDigits ?? 0)}
        {!isNaN(diff) && (isPptChange ? (noUnit ? "" : "ppt") : "%")}
      </StatusText>
    );
  }
);
