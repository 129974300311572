import RaisedButton from "~/components/buttons/raisedButton";
import React from "react";
import { TFunction } from "i18next";
import { authoriseAdvertising } from "~/store/mystore/marketing.redux";
import { createIntercomUserEvents } from "~/utils/intercomUtils";
import moment from "moment-timezone";
import styled from "styled-components";
import useAmazonAuthcodeAdvertisingAuth from "~/hooks/useAmazonAuthcodeAdvertisingAuth";
import { useDispatch } from "react-redux";

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

interface AmazonAuthoriseAdvertisingProps {
  id?: string;
  url: string;
  mid: string;
  countryCode: string;
  sellerId: string | null; // null when connecting advertising for Amazon Vendor
  userId: string;
  shopName: string;
  marketplace: string;
  isReconnect?: boolean;
  localisation: TFunction;
  setIsSubmitting?: (bool: boolean) => void;
  onSuccess?: () => void;
  onError?: (e: Error) => void;
  children?: React.ReactElement;
  variant?: "text" | "contained" | "outlined";
  existingAdvertisingMarketplaceType?: string;
  setExistingAdStoreError?: (error: string) => void;
}
const AmazonAuthoriseAdvertising = (props: AmazonAuthoriseAdvertisingProps) => {
  const {
    children,
    setIsSubmitting,
    onSuccess,
    onError,
    url,
    mid,
    userId,
    shopName,
    countryCode,
    sellerId,
    isReconnect,
    marketplace,
    localisation,
    variant = "contained",
    existingAdvertisingMarketplaceType,
    setExistingAdStoreError,
  } = props;
  const dispatch = useDispatch();
  const onCode = React.useCallback(
    async (authCode, state, scope) => {
      //'scope' is returned but we're not currently using it
      const region = state;
      try {
        await dispatch(
          authoriseAdvertising(
            authCode,
            region,
            mid,
            countryCode,
            sellerId,
            isReconnect,
            marketplace,
            localisation
          )
        );
        createIntercomUserEvents(
          "Amazon Advertising New Connection Finish",
          moment().unix(),
          userId,
          {
            Marketplaces: shopName || mid,
          }
        );
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        if (onError) {
          onError(e as Error);
        } else {
          throw e;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, countryCode, marketplace]
  );
  const triggerSignin = useAmazonAuthcodeAdvertisingAuth(onCode, url);
  const handleSignin = () => {
    if (
      existingAdvertisingMarketplaceType &&
      existingAdvertisingMarketplaceType !== marketplace &&
      setExistingAdStoreError
    ) {
      setExistingAdStoreError(
        localisation("advertisingConnectLabel.existingPortfolioAdStoreMsg")
      );
      return;
    }

    setIsSubmitting && setIsSubmitting(true);
    createIntercomUserEvents(
      "Amazon Advertising New Connection Start",
      moment().unix(),
      userId,
      {
        Marketplaces: shopName || mid,
      }
    );

    triggerSignin();
  };

  return (
    <RaisedButton variant={variant} color="secondary" onClick={handleSignin}>
      <StyledWrapper>
        <StyledChildren>{children}</StyledChildren>
      </StyledWrapper>
    </RaisedButton>
  );
};

export default AmazonAuthoriseAdvertising;
