import { BaseDialog, BaseDialogTitle } from "~/components/dialogs/baseDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Input,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  HistoricalDirectCost,
  useGetDirectCostsByProductQuery,
} from "~/store/mystore/profitability.redux";
import React, { ChangeEvent, useCallback, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import AdTable from "@components/adTable/table";
import Bold from "~/components/typography/bold";
import CurrencyInput from "@components/inputs/currencyInput";
import { CurrentStore } from "~/typedef/store";
import { LinkAndImageComponent } from "@components/table/cells/linkAndImageCell";
import { PaginationArgs } from "~/typedef/pagination";
import RaisedButton from "@components/buttons/raisedButton";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import { TextCell } from "@components/table/cells/textCell";
import { ValueCell } from "@components/table/cells/valueCell";
import VendorCodeField from "./vendorCodeField";
import { formatCurrency } from "~/utils/currencyUtils";
import { get } from "lodash";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DirectCostWrapper = styled(Box)`
  max-height: 25rem;
`;

const ProductCogsPaper = styled(Paper)`
  width: 80%;
  max-width: 30rem;
  min-height: 16rem;
  max-height: 55rem;
  overflow-x: hidden;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

const GreyDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.palette.border.main};
`;

export type SingleProductCogs = {
  sku: string;
  currency: string;
  value: number;
};

export type SingleProductCogsVendor = SingleProductCogs & {
  vendorCode?: string;
};

type CommonProduct = {
  sellerSku: string;
  productSku: string;
  title: string;
  imageUrl: string;
  linkUrl: string;
};

interface SyncDialogParams {
  store: CurrentStore;
  product?: CommonProduct;
  storeCurrency: string;
  timezone: string;
  open: boolean;
  vendorSellType?: SellType;
  onClose: () => void;
  onConfirm: (
    store: { mid: string; marketplaceType: string; marketplaceSubtype: string },
    fromDate: string | undefined,
    cogs: SingleProductCogs | SingleProductCogsVendor
  ) => void;
}

const generateColumns = (
  t: TFunction<"translation">,
  timezone: string,
  displayVendorCode = false
) => {
  return [
    {
      Header: t(`profitability.effectiveDateFromLabel`),
      id: "effectiveDateFromLabel",
      accessor: (row: HistoricalDirectCost) =>
        row.effectiveDateFrom
          ? moment(row.effectiveDateFrom).tz(timezone).format("DD MMM YYYY")
          : "-",
      Cell: TextCell,
      isVisible: true,
    },
    {
      Header: t(`profitability.effectiveDateToLabel`),
      id: "effectiveDateToLabel",
      accessor: (row: HistoricalDirectCost) =>
        row.effectiveDateTo
          ? moment(row.effectiveDateTo).tz(timezone).format("DD MMM YYYY")
          : "-",
      Cell: TextCell,
      isVisible: true,
    },
    {
      Header: t(`profitability.unitCostOfGoodsLabel`),
      id: "unitCostOfGoodsLabel",
      accessor: (row: HistoricalDirectCost) =>
        formatCurrency(
          row.eventValue,
          // we don't need actual conversion rates, since we always just
          // display this in the store's currency
          [{ [row.eventCurrency]: 1 }],
          row.eventCurrency,
          row.eventCurrency
        ),
      Cell: ValueCell,
      cellJustify: "center" as const,
      isVisible: true,
    },
    {
      Header: t(`vendorProfitability.vendorCodeLabel`),
      id: "vendorCodeLabel",
      Cell: TextCell,
      accessor: (row: HistoricalDirectCost) => row.vendorCode ?? "",
      cellJustify: "center" as const,
      isVisible: displayVendorCode,
    },
  ];
};

const ProductCogsSyncDialog: React.FC<SyncDialogParams> = ({
  store,
  product,
  open,
  onClose,
  onConfirm,
  storeCurrency,
  timezone,
  vendorSellType = SellType.SELL_IN,
}) => {
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );

  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<string>("");
  const [cogsAmount, setCogsAmount] = useState(0);
  const [currency, setCurrency] = useState<string>(storeCurrency);
  const [vendorCode, setVendorCode] = useState<string>("");
  const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
    pageSize: 5,
    pageIndex: 0,
    sortKey: "",
    sortOrder: "",
  });

  const isVendor = store.marketplace === "amazon_vendor";
  const isVendorSellIn = isVendor && vendorSellType === SellType.SELL_IN;
  const sku = isVendor ? product?.productSku : product?.sellerSku;

  const disableCogsDialogOnConfirm = isVendorSellIn
    ? !fromDate || !String(cogsAmount) || !sku || !vendorCode
    : !fromDate || !String(cogsAmount) || !sku;

  const fromDateHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const commonProductQueryParams = {
    mid: store.merchantId,
    marketplaceType: store.marketplace,
    marketplaceSubtype: store.marketplaceSubtype ?? store.marketplace,
    ...paginationParams,
  };

  const { isLoading, data, count } = useGetDirectCostsByProductQuery(
    isVendor
      ? {
          ...commonProductQueryParams,
          productSku: product?.productSku ?? "",
          isVendor,
          sellType: vendorSellType,
        }
      : {
          ...commonProductQueryParams,
          sellerSku: product?.sellerSku ?? "",
          isVendor,
        },
    {
      skip: !sku,
      selectFromResult: (result) => {
        return {
          isLoading: result.isFetching,
          data: result.data?.directCosts ?? [],
          count: result.data?.count ?? 0,
        };
      },
    }
  );

  const onPageChange = useCallback(({ pageSize, pageIndex }) => {
    setPaginationParams({
      pageSize,
      pageIndex,
      sortOrder: "",
      sortKey: "",
    });
  }, []);

  return (
    <BaseDialog open={open} onClose={onClose} PaperComponent={ProductCogsPaper}>
      <BaseDialogTitle onClose={onClose} style={{ padding: "0 0 0 12px" }}>
        <b>{t("profitability.unitCogsDialog")}</b>
      </BaseDialogTitle>
      <DialogContent style={{ padding: "0 0" }}>
        {product && (
          <>
            <Box p={1}>
              <LinkAndImageComponent
                image={product.imageUrl}
                value={product.title}
                link={product.linkUrl}
                target="_blank"
                colorVariant="external"
                secondRowValue={`ASIN: ${product.productSku} ${
                  product.sellerSku ? `| SKU: ${product.sellerSku}` : ""
                }`}
                fontVariant="body1"
                maxColumnWidth="100%"
              />
            </Box>
            <GreyDivider />
          </>
        )}
        <Box p={2}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("profitability.effectiveDateFromLabel")}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Input
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={fromDateHandler}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("profitability.directCostUnitInputLabel")}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                switchCurrency={setCurrency}
                currentCurrency={currency}
                value={cogsAmount}
                setValue={setCogsAmount}
                fullWidth={true}
              />
            </Grid>
          </Grid>

          {isVendorSellIn ? (
            <VendorCodeField
              vendorCode={vendorCode}
              setVendorCode={setVendorCode}
              mid={store.merchantId}
              marketplaceType={store.marketplace}
              marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mb={1} width="100%">
          <Grid item xs={12} container justifyContent="flex-end">
            <Grid item xs={12} sm={6}>
              <RaisedButton
                fullWidth={true}
                disabled={disableCogsDialogOnConfirm}
                onClick={() => {
                  const commonAttributes = {
                    currency,
                    sku,
                    value: cogsAmount,
                  };

                  const singleProductCogs = isVendorSellIn
                    ? ({
                        ...commonAttributes,
                        vendorCode,
                      } as SingleProductCogsVendor)
                    : (commonAttributes as SingleProductCogs);

                  sku &&
                    onConfirm(
                      {
                        mid: store?.merchantId,
                        marketplaceType: store?.marketplace,
                        marketplaceSubtype:
                          store?.marketplaceSubtype ?? store.marketplace,
                      },
                      moment.tz(fromDate, selectedTimezone).format(),
                      singleProductCogs
                    );
                }}
                variant="contained"
                color="primary"
              >
                {t("generic.saveButton")}
              </RaisedButton>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
      <GreyDivider />
      <Box py={1} px={2}>
        <Bold variant="h6">{t("profitability.unitCogsDialogTableTitle")}</Bold>
      </Box>
      <GreyDivider />
      <DirectCostWrapper>
        <AdTable
          columns={generateColumns(t, timezone, isVendorSellIn)}
          data={data ?? []}
          fetchData={onPageChange}
          loading={isLoading}
          sorting={false}
          pagination={true}
          pageSize={paginationParams.pageSize}
          pageCount={Math.ceil(count / paginationParams.pageSize)}
        />
      </DirectCostWrapper>
    </BaseDialog>
  );
};

export default ProductCogsSyncDialog;
