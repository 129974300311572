import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  DATETIME_PERIODS_KEY,
  INTERVAL,
} from "~/store/utils/dateTimeUtils";
import { MenuItem, useMediaQuery } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";

import DatePicker from "./fullDateTimePicker";
import { DateRange } from "~/typedef/date";
import { TimePeriodDisplay } from "../toolbars/toolbarComponents/timePeriodDisplay";
import ToolbarSelect from "~/components/select/toolbarSelect";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";

interface TimePeriodMenuItemProps {
  width?: number | null;
}
const TimePeriodMenuItem = styled(MenuItem)`
  width: ${({ width }: TimePeriodMenuItemProps) => width}px;
  padding-left: 0;
`;
interface DateRangeDropdownProps {
  title: string;
  tooltip?: string;
  maxWidth?: number;
  minWidth?: number;
  fullWidth?: boolean;
  rightAlign?: boolean;
  disabled?: boolean;
  setDates: (
    range: DateRange,
    period: DATETIME_PERIODS,
    comparison: COMPARISON_PERIOD,
    timezone: string
  ) => void;
  period: DATETIME_PERIODS;
  range: DateRange;
  timezone: string;
  restrictTimezone?: string[] | null;
  preferredTimezone?: string;
  compare: COMPARISON_PERIOD;
  options: DATETIME_PERIODS_KEY[];
  useList?: boolean;
}

export interface ApplyProps {
  timePeriod: DATETIME_PERIODS;
  comparisonPeriod: COMPARISON_PERIOD;
  startDate: number;
  endDate: number;
  priorStartDate: number;
  priorEndDate: number;
  interval: INTERVAL;
  timezone: string;
}

const DateRangeDropdown = memo<DateRangeDropdownProps>(
  function DateRangeDropdown({
    title,
    tooltip,
    minWidth,
    maxWidth,
    fullWidth,
    rightAlign,
    disabled,
    setDates,
    period,
    range,
    compare,
    timezone,
    preferredTimezone,
    restrictTimezone,
    options,
    useList,
  }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [mobileAnchorEl, setMobileAnchorEl] = useState<HTMLElement | null>(
      null
    );
    const [timePeriod, setTimePeriod] = useState(period);
    const [comparison, setComparisonPeriod] = useState(compare);
    const [selectedTimezone, setSelectedTimezone] = useState(timezone);
    const [selectedInterval, setSelectedInterval] = useState<INTERVAL>(
      range.interval
    );
    const [menuWidth, setMenuWidth] = useState(
      Boolean(anchorEl) ? anchorEl?.offsetWidth : null
    );

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = () => {
      setAnchorEl(null);
      setMobileAnchorEl(null);
    };

    useEffect(() => {
      setTimePeriod(period);
    }, [period]);
    useEffect(() => {
      setComparisonPeriod(compare);
    }, [compare]);
    useEffect(() => {
      setSelectedTimezone(timezone);
    }, [timezone]);
    useEffect(() => {
      setSelectedInterval(range.interval);
    }, [range.interval]);

    const onApply = ({
      timePeriod,
      comparisonPeriod,
      startDate,
      endDate,
      priorStartDate,
      priorEndDate,
      interval,
      timezone,
    }: ApplyProps) => {
      setDates(
        {
          fromDate: startDate,
          toDate: endDate,
          priorFromDate: priorStartDate,
          priorToDate: priorEndDate,
          interval,
          timezone,
        },
        timePeriod,
        comparisonPeriod,
        timezone
      );
      handleClose();
    };

    const setMobileSelection = (
      mobileAnchor: HTMLElement,
      period: DATETIME_PERIODS
    ) => {
      setTimePeriod(period);
      setMobileAnchorEl(mobileAnchor);
    };

    const renderOptions = () =>
      options.map((period: DATETIME_PERIODS_KEY, index: number) => (
        <TimePeriodMenuItem key={index} width={menuWidth}>
          <TimePeriodDisplay
            activeLink={true}
            period={DATETIME_PERIODS[period]}
            range={range}
            restrictTimezone={restrictTimezone}
            compare={compare}
            timezone={selectedTimezone}
            onApply={onApply}
            setMobileSelection={setMobileSelection}
          />
        </TimePeriodMenuItem>
      ));

    return (
      <>
        <ToolbarSelect
          {...{
            id: "date-range-select",
            title,
            tooltip,
            setMenuWidth,
            anchorEl: smDown || useList ? anchorEl : null,
            setAnchorEl,
            fullWidth,
            minWidth: minWidth || (maxWidth && maxWidth < 365 ? maxWidth : 300),
            maxWidth: maxWidth || (fullWidth ? undefined : 400),
            disabled,
            rightAlign,
            renderOptions,
            displayComponent: (
              <TimePeriodDisplay
                period={timePeriod}
                range={range}
                compare={comparison}
                restrictTimezone={restrictTimezone}
                timezone={selectedTimezone}
                useList={useList}
              />
            ),
          }}
        />
        {!useList && (
          <DatePicker
            anchorEl={smDown ? mobileAnchorEl : anchorEl}
            selectedTimezone={selectedTimezone}
            handleClose={handleClose}
            onApply={onApply}
            period={timePeriod}
            restrictTimezone={restrictTimezone}
            range={range}
            compare={comparison}
            selectedInterval={selectedInterval}
            preferredTimezone={preferredTimezone}
          />
        )}
      </>
    );
  }
);

export default DateRangeDropdown;
