import {
  AdvertiserType,
  AdvertisersPayload,
  Profile,
  useCreateAmcInstanceMutation,
  useFetchAMCProfilesQuery,
} from "~/store/connections/connectAMC.redux";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  countries,
  topCountries,
} from "~/pages/marketplaceConnectors/amazonAdvertisingSelectCountry";

import AlertCard from "~/components/alert/alertCard";
import Medium from "~/components/typography/medium";
import MultiSelect from "~/components/select/multiSelectDropDown";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import RaisedButton from "~/components/buttons/raisedButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const WrapperGrid = styled(Grid)`
  max-width: 300px;
  position: relative;
`;

const NoMarginGrid = styled(Grid)`
  margin: 0;
`;

const WrapperFormControl = styled(FormControl)`
  width: 300px;
  padding: 10px;
  border-radius: 8px;
  margin: auto;
`;

const CenteredFormControlLabel = styled(FormControlLabel)`
  margin: auto;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const Divider = styled.div`
  height: 1px;
  width: 45%;
  background-color: ${({ theme }) => theme.palette.border.dark};
`;

const FlexGrid = styled(Grid)`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinearProgressBar = styled(LinearProgress)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

enum STAGE {
  INITIAL,
  NEW_INSTANCE,
  EXISTING_INSTANCE,
  AUTHORIZE,
  SUCCESS,
}

export interface ConnectionProps {
  onSuccess: () => void;
}

const AmcConnectionPrompt: React.FC<ConnectionProps> = ({ onSuccess }) => {
  const { t } = useTranslation();

  const [countryCode, setCountryCode] = React.useState("");
  const [amcName, setAmcName] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [linkedExistAMC, setExistAmcLinked] = useState(false);
  const [stage, setStage] = useState<STAGE | null>(STAGE.INITIAL);
  const [shouldFetch, setShouldFetch] = useState(false);

  const [
    createAmcInstance,
    {
      isLoading: mutationLoading,
      isSuccess: isInstanceRequestSuccess,
      isError: mutationError,
    },
  ] = useCreateAmcInstanceMutation();

  const {
    data: profilesData,
    error: queryError,
    isFetching: queryFetching,
  } = useFetchAMCProfilesQuery(
    {},
    {
      skip: !shouldFetch, //only when I click next then fetch
    }
  );

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedDSPValues, setSelectedDSPValues] = useState<string[]>([]);

  const handleMultiSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };

  const handleMultiSelectChangeDSP = (values: string[]) => {
    setSelectedDSPValues(values);
  };

  //use for existing instance connection
  const handleLinkExistingAMC = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setExistAmcLinked(isChecked);

    if (isChecked) {
      setAmcName("");
      setCountryCode("");
    }
  };

  const handleClickNext = () => {
    setShouldFetch(true);
  };

  const getProfileById = (
    id: string,
    profiles: Profile[],
    type: AdvertiserType
  ): AdvertisersPayload => {
    const profile = profiles.find((profile) => profile.entityId === id);
    return {
      id,
      type,
      marketplaceId: profile?.marketplaceId ?? null,
      mid: profile?.mid ?? "",
      marketplaceType: profile?.marketplaceType ?? "",
    };
  };

  const handleCreateInstance = () => {
    const advertisersPayload: AdvertisersPayload[] = [
      ...selectedValues.map((id: string) =>
        getProfileById(
          id,
          profilesData?.amcAdProfiles ?? [],
          AdvertiserType.SPONSORED_ADS
        )
      ),
      ...selectedDSPValues.map((id: string) =>
        getProfileById(
          id,
          profilesData?.amcDspProfiles ?? [],
          AdvertiserType.DISPLAY
        )
      ),
    ];

    createAmcInstance({
      instanceName: amcName,
      countryCode,
      advertisersPayload,
    });
  };

  const data = useMemo(() => {
    if (profilesData) {
      const amazonAdvertisers = profilesData?.amcAdProfiles
        ? profilesData.amcAdProfiles.map((profile: Profile) => ({
            value: profile.entityId,
            label: profile.accountName,
          }))
        : [];

      const dspAdvertisers = profilesData.amcDspProfiles
        ? profilesData.amcDspProfiles.map((profile: Profile) => ({
            value: profile.entityId,
            label: profile.accountName,
          }))
        : [];

      return { amazonAdvertisers, dspAdvertisers };
    }
    return { amazonAdvertisers: [], dspAdvertisers: [] };
  }, [profilesData]);

  const handleCountryChange = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const selectedCountry = [...topCountries, ...countries].find(
      (country) => country.value === e.target.value
    );

    if (selectedCountry) {
      setCountryCode(selectedCountry.value);
    }
  };

  useEffect(() => {
    if (profilesData) {
      setStage(STAGE.NEW_INSTANCE);
    }
    if (isInstanceRequestSuccess) {
      setStage(STAGE.SUCCESS);
      onSuccess();
    }
    if (mutationError) {
      setError("Error when create AMC instance");
    }

    if (queryError) {
      setError("Error when fetching advertisers");
    }
  }, [profilesData, isInstanceRequestSuccess, mutationError, queryError]);

  const renderActionButton = () => {
    if (stage === STAGE.INITIAL) {
      if (linkedExistAMC) {
        return (
          <Grid item>
            <RaisedButton
              color="secondary"
              variant="outlined"
              disabled
              onClick={() => setStage(STAGE.AUTHORIZE)}
            >
              {t("connectWizard.authorizeButton")}
            </RaisedButton>
          </Grid>
        );
      }

      if (amcName && countryCode) {
        return (
          <Grid item>
            <RaisedButton
              disabled={queryFetching}
              color="primary"
              onClick={handleClickNext}
            >
              {t("generic.next")}
            </RaisedButton>
          </Grid>
        );
      }
    }

    if (
      stage === STAGE.NEW_INSTANCE &&
      (selectedValues.length > 0 || selectedDSPValues.length > 0)
    ) {
      return (
        <Grid item>
          <RaisedButton
            disabled={mutationLoading}
            color="primary"
            onClick={handleCreateInstance}
          >
            {t("connectWizard.AmcCreateBtn")}
          </RaisedButton>
        </Grid>
      );
    }

    return null;
  };

  const renderInitialStage = () => (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="textSecondary" noWrap align="center">
          {t("connectWizard.AmcInitialPageTitle")}
        </Typography>
      </Grid>
      <WrapperFormControl>
        <Grid container item xs={12} justifyContent="flex-start">
          <Medium>{t("connectWizard.amazonCountryLabel")}</Medium>
          <Box pt={1} pb={1} width={1}>
            <NativeSelect
              name="countryCode"
              value={countryCode}
              topOptions={topCountries}
              options={countries}
              disabled={linkedExistAMC}
              onChange={handleCountryChange}
              autoFocus
              fullWidth
            />
          </Box>
        </Grid>
      </WrapperFormControl>
      <WrapperFormControl>
        <Typography variant="body1" color="textSecondary">
          {t("connectWizard.AmcInitialPageNameInput")}
        </Typography>
        <TextField
          fullWidth
          required
          value={amcName}
          onChange={(e) => setAmcName(e.target.value)}
          disabled={linkedExistAMC}
        />
      </WrapperFormControl>
      {/* Comment this part for Link Existing Instance */}
      {/* <FlexGrid item xs={12}>
        <Divider />
        <Typography variant="body1" color="textSecondary" align="center">
          {t("generic.or")}
        </Typography>
        <Divider />
      </FlexGrid>
      <Grid item xs={12}>
        <CenteredFormControlLabel
          control={
            <Checkbox
              checked={linkedExistAMC}
              size="small"
              color="primary"
              onChange={handleLinkExistingAMC}
            />
          }
          label={t("connectWizard.AmcLinkAmc")}
        />
      </Grid> */}
      {renderActionButton()}
    </>
  );

  const renderNewInstanceStage = () => (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="textSecondary" noWrap align="center">
          {t("connectWizard.AmcNewInstanceTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          {t("connectWizard.AmcAdvertiserSelect")}
        </Typography>
        <MultiSelect
          currentValues={selectedValues}
          handleChange={handleMultiSelectChange}
          options={data.amazonAdvertisers}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          {t("connectWizard.AmcDSPAdvertiserSelect")}
        </Typography>
        <MultiSelect
          currentValues={selectedDSPValues}
          handleChange={handleMultiSelectChangeDSP}
          options={data.dspAdvertisers}
        />
      </Grid>
      {renderActionButton()}
    </>
  );

  return (
    <WrapperGrid container alignItems="center">
      {(queryFetching || mutationLoading) && <LinearProgressBar />}

      <NoMarginGrid
        container
        item
        alignItems="center"
        justifyContent="center"
        xs={12}
        spacing={2}
      >
        {error && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <ErrorMessage color="error">{error}</ErrorMessage>
          </Grid>
        )}

        {stage === STAGE.INITIAL && renderInitialStage()}
        {stage === STAGE.NEW_INSTANCE && renderNewInstanceStage()}

        {stage === STAGE.SUCCESS && isInstanceRequestSuccess && (
          <Grid item xs={12}>
            <AlertCard isOpen type="success">
              <Medium variant="body1">
                {t("connectWizard.AmcCongratulationsBanner")}
              </Medium>
            </AlertCard>
          </Grid>
        )}

        {stage === STAGE.AUTHORIZE && <>Not available atm</>}
      </NoMarginGrid>
    </WrapperGrid>
  );
};
export default AmcConnectionPrompt;
