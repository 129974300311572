import {
  Grid,
  GridSize,
  Link,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { memo } from "react";

import { CustomBadge } from "../toolbars/sideNavigation/drawerMenuItem";
import { InlineIconButton } from "~/icons/inlineIconButton";
import { ReactChild } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

interface CompactProps {
  $compact?: boolean; // reduces padding
}

const PanelHeader = styled(Grid)<CompactProps>`
  padding: ${({ $compact }) => ($compact ? "0.5rem" : "1rem")};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  min-height: ${({ $compact }) => ($compact ? "0px" : "63px")};
`;

const ActionPanel = styled(Grid)`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
  width: min-content;
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      display: flex;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      margin-right: -1rem;
      padding-top: 1rem;
      margin-top: 0.75rem;
      align-items: center;
      justify-content: center;
      border-top: 1px solid ${theme.palette.border.main};
    }
  `}

  @media print {
    display: none;
  }
`;

const SectionWrapper = styled(Grid)<CompactProps>`
  margin-top: auto;
  padding: ${({ $compact }) => ($compact ? "0.5rem" : "1rem")};
  border-top: 1px solid ${({ theme }) => theme.palette.border.main};
`;

interface PanelWrapperProps {
  $minOpenHeight?: string; // for side panel
  $drawerOpen?: boolean; // for side panel
}
const PanelWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  ${({ $minOpenHeight, $drawerOpen }: PanelWrapperProps) =>
    $minOpenHeight && $drawerOpen ? `min-height: ${$minOpenHeight};` : ""}
`;

export type FooterLink =
  | {
      label?: string;
      external: true;
      url: string;
    }
  | {
      label?: string;
      external?: false;
      url: string | { pathname: string; search: string };
    };

interface PanelProps {
  id: string;
  title: string;
  subtitle?: string;
  badge?: string;
  titleGridSizeMd?: GridSize;
  content: ReactChild;
  secondaryContent?: ReactChild;
  secondaryContentTitle?: ReactChild;
  tooltip?: string;
  actions?: ReactChild;
  footer?: ReactChild;
  footerLink?: FooterLink;
  minOpenHeight?: string; // for side panel
  drawerOpen?: boolean; // for side panel
  compact?: boolean; // reduces padding
  elevation?: number;
}

export const Panel = memo<PanelProps>(function Panel({
  id,
  title,
  subtitle,
  titleGridSizeMd,
  content,
  tooltip,
  badge,
  actions,
  footer,
  footerLink,
  minOpenHeight,
  drawerOpen,
  compact,
  secondaryContent,
  secondaryContentTitle,
  elevation = 2,
}) {
  return (
    <PanelWrapper
      className="panel"
      id={id}
      elevation={elevation}
      $minOpenHeight={minOpenHeight}
      $drawerOpen={drawerOpen}
    >
      <PanelHeader
        container
        justifyContent="space-between"
        id="panel-card-header"
        $compact={compact}
      >
        <Grid
          container
          item
          xs={12}
          md={titleGridSizeMd ? titleGridSizeMd : actions ? 4 : 12}
        >
          <Grid container item xs={12} alignItems="center">
            <Typography variant="h3">{title}</Typography>
            {badge && <CustomBadge>{badge}</CustomBadge>}
            {tooltip && (
              <Tooltip title={tooltip} id="panel-card-tooltip">
                <InlineIconButton id="panel-card-tooltip-icon" />
              </Tooltip>
            )}
            {subtitle && (
              <Grid container item xs={12} alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  {subtitle}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {actions && (
          <ActionPanel item id="panel-card-actions">
            {actions}
          </ActionPanel>
        )}
      </PanelHeader>
      {content}
      {secondaryContentTitle && (
        <SectionWrapper>{secondaryContentTitle}</SectionWrapper>
      )}
      {secondaryContent && <SectionWrapper>{secondaryContent}</SectionWrapper>}
      {(footer || footerLink) && (
        <SectionWrapper
          id="panel-card-footer"
          container
          justifyContent="center"
          $compact={compact}
        >
          {footer}
          {footerLink && (
            <Typography
              color="primary"
              variant="body1"
              align="center"
              id="panel-card-footer-link"
            >
              {footerLink.external ? (
                <Link id={`${id}-link`} href={footerLink.url} target="_blank">
                  {footerLink.label}
                </Link>
              ) : (
                <Link
                  id={`${id}-link`}
                  component={RouterLink}
                  to={footerLink.url}
                >
                  {footerLink.label}
                </Link>
              )}
            </Typography>
          )}
        </SectionWrapper>
      )}
    </PanelWrapper>
  );
});

export default Panel;
