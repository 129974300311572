import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import { formatCurrency, formatCurrencyRounded } from "~/utils/currencyUtils";

import { CategoryCell } from "~/components/table/cells/categoryCell";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "~/components/panel/panel";
import PercentageAndGrowthCell from "~/components/table/cells/percentageAndGrowthCell";
import PrimeIcon from "~/img/amazon_prime.png";
import { Range } from "~/typedef/store";
import { SmallIcon } from "../widgets/salesByProduct";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment";
import { useShippedRevenueQuery } from "~/store/mystore/vendor.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ShippedRevenueTableProps {
  userInfo: User;
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  searchText?: string;
  actions?: ReactChild;
  view: "sourcing" | "manufacturing";
  pageSize: number;
  condensed?: boolean;
  report?: boolean;
  conditionalFormatting?: boolean;
}

const SHARED_COLUMNS_BETWEEN_SOURCING_AND_MANUFACTURING = [
  "shipped_revenue",
  "shipped_cogs",
  "shipped_units",
  "unit_cogs",
  "amazon_margin",
  "net_pure_product_margin",
];

const ShippedRevenueTable = memo<ShippedRevenueTableProps>(
  function ShippedRevenueTable({
    userInfo,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    searchText,
    actions,
    view,
    pageSize,
    condensed,
    report,
    conditionalFormatting,
  }) {
    const { t } = useTranslation();

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize,
      pageIndex: 0,
      sortKey: "shipped_revenue_manufacturing",
      sortOrder: "desc",
    });

    const {
      shippedRevenue,
      shippedRevenueCurrency,
      shippedRevenueCount,
      lastReportDate,
      lastUpdatedAt,
      shippedRevenueFetching,
    } = useShippedRevenueQuery(
      {
        mid,
        currentRange,
        searchText,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          shippedRevenue: data?.[view]?.data || [],
          shippedRevenueCurrency: data?.currency || "",
          shippedRevenueCount: data?.[view]?.count || 0,
          lastReportDate: data?.lastReportDate,
          lastUpdatedAt: data?.lastUpdatedAt,
          shippedRevenueFetching: isFetching,
        }),
      }
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        setPaginationParams({
          sortKey: sortBy[0]?.id
            ? `${sortBy[0]?.id}${
                view === "manufacturing" &&
                SHARED_COLUMNS_BETWEEN_SOURCING_AND_MANUFACTURING.includes(
                  sortBy[0]?.id
                )
                  ? "_manufacturing"
                  : ""
              }`
            : `shipped_revenue${
                view === "manufacturing" ? "_manufacturing" : ""
              }`,
          sortOrder: sortBy[0]?.id
            ? sortBy[0]?.desc
              ? "desc"
              : "asc"
            : "desc",
          pageIndex,
          pageSize,
        });
      },
      [view]
    );

    const columns: Column[] = useMemo(
      () => [
        {
          Header: t("myStoresWidget.salesByProduct.productColumn"),
          id: "title",
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: `ASIN: ${row.asin} | VPI: ${row.vpi}`,
            image: row.imageUrl,
            link: row.url,
            target: "_blank",
            icon: row.isFulfilledByAmazon ? (
              <SmallIcon src={PrimeIcon} />
            ) : null,
          }),
          Cell: (props: any) => (
            <LinkAndImageCell {...props} colorVariant="external" />
          ),
          isVisible: true,
          sticky: "left",
          colSpan: 2,
        },
        ...(condensed
          ? []
          : [
              {
                id: "brand",
                Header: t("myStoresWidget.salesByBrand.brandColumn"),
                accessor: "brand",
                Cell: TextCell,
                colSpan: 3,
                align: "right",
              },
              {
                id: "category",
                Header: t("myStoresWidget.salesByCategory.categoryColumn"),
                accessor: (row: {
                  categoryLabel: string;
                  parentCategory: string;
                }) => ({
                  current: row.categoryLabel,
                  parent: row.parentCategory,
                }),
                Cell: (props: any) => <CategoryCell {...props} />,
                colSpan: 3,
                disableSortBy: true,
              },
            ]),
        {
          Header: t("vendor.shippedRevenue"),
          id: "shipped_revenue",
          accessor: (row: {
            shippedRevenue: number;
            priorShippedRevenue: number;
          }) => {
            return {
              value:
                row.shippedRevenue === 0.0
                  ? "-"
                  : formatCurrencyRounded(
                      row.shippedRevenue,
                      currencyRates,
                      shippedRevenueCurrency,
                      currentCurrency
                    ),
              growth: row.priorShippedRevenue
                ? getPercentageDifference(
                    row.shippedRevenue,
                    row.priorShippedRevenue
                  )
                : "N/A",
              conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          align: "right",
        },
        {
          Header: t("vendor.shippedCogs"),
          accessor: (row: {
            shippedCogs: number;
            priorShippedCogs: number;
          }) => {
            return {
              value:
                row.shippedCogs === 0.0
                  ? "-"
                  : row.shippedCogs !== undefined
                  ? formatCurrencyRounded(
                      row.shippedCogs,
                      currencyRates,
                      shippedRevenueCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorShippedCogs
                ? getPercentageDifference(row.shippedCogs, row.priorShippedCogs)
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "shipped_cogs",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("vendor.shippedUnits"),
          accessor: (row: {
            shippedUnits: number;
            priorShippedUnits: number;
          }) => {
            return {
              value:
                row.shippedUnits === 0.0
                  ? "-"
                  : row.shippedUnits !== undefined
                  ? row.shippedUnits.toFixed(0)
                  : "-",
              growth: row.priorShippedUnits
                ? getPercentageDifference(
                    row.shippedUnits,
                    row.priorShippedUnits
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "shipped_units",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
        ...(condensed
          ? []
          : [
              {
                Header: t("vendor.unitCogs"),
                accessor: (row: {
                  unitCogs: number;
                  priorUnitCogs: number;
                }) => {
                  return {
                    value:
                      row.unitCogs === 0.0
                        ? "-"
                        : row.unitCogs !== undefined
                        ? formatCurrency(
                            row.unitCogs,
                            currencyRates,
                            shippedRevenueCurrency,
                            currentCurrency
                          )
                        : "-",
                    growth: row.priorUnitCogs
                      ? getPercentageDifference(row.unitCogs, row.priorUnitCogs)
                      : "N/A",
                  };
                },
                id: "unit_cogs",
                Cell: ValueAndGrowthCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("vendor.amzMargin"),
                accessor: (row: {
                  amazonMargin: number;
                  priorAmazonMargin: number;
                }) => {
                  return {
                    value:
                      row.amazonMargin === 0.0
                        ? "-"
                        : row.amazonMargin !== undefined
                        ? row.amazonMargin.toFixed(1)
                        : "-",
                    growth: row.priorAmazonMargin
                      ? (row.amazonMargin - row.priorAmazonMargin).toFixed(1)
                      : "N/A",
                    reverseFormatting: true,
                  };
                },
                id: "amazon_margin",
                Cell: PercentageAndGrowthCell,
                align: "right",
                isVisible: true,
              },
              {
                Header: t("vendor.netPureProductMargin"),
                accessor: (row: {
                  netPureProductMargin: number;
                  priorNetPureProductMargin: number;
                }) => {
                  return {
                    value:
                      row.netPureProductMargin === 0.0
                        ? "N/A"
                        : row.netPureProductMargin !== undefined
                        ? row.netPureProductMargin.toFixed(1)
                        : "N/A",
                    growth: row.priorNetPureProductMargin
                      ? (
                          row.netPureProductMargin -
                          row.priorNetPureProductMargin
                        ).toFixed(1)
                      : "N/A",
                  };
                },
                id: "net_pure_product_margin",
                Cell: PercentageAndGrowthCell,
                align: "right",
                isVisible: true,
              },
            ]),
      ],
      [
        mid,
        currentRange,
        currentPeriod,
        currentCurrency,
        currencyRates,
        shippedRevenueCurrency,
        view,
      ]
    );
    const [columnConfig, setColumnConfig] = useState<Column[]>(
      columns.map((c) => ({
        Header: c.Header,
        id: c.id,
        isVisible: c.isVisible,
      }))
    );

    const footerLink =
      condensed && userInfo.brandAnalyticsOn && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "shippedrevenue"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="shipped-revenue-table"
        title={t("shippedRevenue.tableTitle")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t(`retailAnalytics.${view}View`)} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t(`retailAnalytics.${view}View`)}`
        }
        footerLink={footerLink}
        content={
          condensed ? (
            <NoScrollTable
              {...{
                columns: columns.map((c) => ({
                  ...c,
                  isVisible: columnConfig.find((cc) => cc.id === c.id)
                    ?.isVisible,
                })),
                data: shippedRevenue,
                pageSize,
                loading: shippedRevenueFetching,
                isReport: report,
              }}
            />
          ) : (
            <Table
              {...{
                columns: columns.map((c) => ({
                  ...c,
                  isVisible: columnConfig.find((cc) => cc.id === c.id)
                    ?.isVisible,
                })),
                data: shippedRevenue,
                fetchData,
                loading: shippedRevenueFetching,
                sorting: true,
                pagination: true,
                pageCount: Math.ceil((shippedRevenueCount || 0) / pageSize),
                pageSize: pageSize,
              }}
            />
          )
        }
        actions={
          <>
            {actions}
            {!report && (
              <ColumnSelect
                {...{ columns: columnConfig, setColumns: setColumnConfig }}
              />
            )}
          </>
        }
      />
    );
  }
);

export default ShippedRevenueTable;
