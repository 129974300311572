import React, { ReactChild, memo, useCallback, useEffect } from "react";

import { SimplifiedNavigation } from "./simplifiedNavigation";
import { StoreNavigation } from "./storeNavigation";
import get from "lodash/get";
import { pathNavMap } from "~/utils/navigationUtils";
import { useLocation } from "react-router-dom";
import { useSideNavConfig } from "~/hooks/useSideNavConfig";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export type SelectedItem = {
  currentPage: string;
  breadcrumb: { text: string; link?: string }[];
};

interface SideMenuProps {
  content: ReactChild;
  mobileToolbarHeight: number;
}

export const SideMenu = memo<SideMenuProps>(function SideMenu({
  content,
  mobileToolbarHeight,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const menuItems = useSideNavConfig();

  const currentStore = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const getSelectedItem = useCallback(() => {
    let newSelectedItem: SelectedItem = {
      currentPage: t("nav.storeOverview"),
      breadcrumb: [
        { text: t("nav.mainPage"), link: "/overview" },
        ...(location.pathname.includes("/mystores")
          ? [
              {
                text: t("nav.myStores"),
                link: `/mystores/${
                  currentStore?.marketplace
                }/storeoverview?store=${encodeURI(
                  currentStore?.merchantId || ""
                )}`,
              },
            ]
          : []),
      ],
    };
    menuItems.forEach((menuItem) => {
      if (menuItem.link ? location.pathname.includes(menuItem.link) : false) {
        newSelectedItem = {
          currentPage: t(menuItem.title),
          breadcrumb: [
            { text: t("nav.mainPage"), link: "/overview" },
            ...(location.pathname.includes("/mystores")
              ? [
                  {
                    text: t("nav.myStores"),
                    link: `/mystores/${
                      currentStore?.marketplace
                    }/storeoverview?store=${encodeURI(
                      currentStore?.merchantId || ""
                    )}`,
                  },
                ]
              : []),
          ],
        };
      }
      if (menuItem.subMenus) {
        menuItem.subMenus.forEach((subItem) => {
          if (subItem.link ? location.pathname.includes(subItem.link) : false) {
            newSelectedItem = {
              currentPage: subItem.customMainTitle
                ? t(subItem.customMainTitle)
                : t(subItem.title),
              breadcrumb: [
                { text: t("nav.mainPage"), link: "/overview" },
                ...(location.pathname.includes("/mystores")
                  ? [
                      {
                        text: t("nav.myStores"),
                        link: `/mystores/${
                          currentStore?.marketplace
                        }/storeoverview?store=${encodeURI(
                          currentStore?.merchantId || ""
                        )}`,
                      },
                    ]
                  : []),
                { text: t(menuItem.title) },
              ],
            };
          }
          if (subItem.subMenus) {
            subItem.subMenus.forEach((subSubItem) => {
              if (
                subSubItem.link
                  ? location.pathname.includes(subSubItem.link)
                  : false
              ) {
                newSelectedItem = {
                  currentPage: t(subSubItem.title),
                  breadcrumb: [
                    { text: t("nav.mainPage"), link: "/overview" },
                    ...(location.pathname.includes("/mystores")
                      ? [
                          {
                            text: t("nav.myStores"),
                            link: `/mystores/${
                              currentStore?.marketplace
                            }/storeoverview?store=${encodeURI(
                              currentStore?.merchantId || ""
                            )}`,
                          },
                        ]
                      : []),
                    { text: t(menuItem.title) },
                    {
                      text: t(subItem.title),
                      link: `${subItem.link}?store=${encodeURI(
                        currentStore?.merchantId || ""
                      )}`,
                    },
                  ],
                };
              }
            });
          }
        });
      }
    });
    return newSelectedItem;
  }, [
    t,
    currentStore?.marketplace,
    currentStore?.sourceSystemId,
    location.pathname,
  ]);

  const [selectedItem, setSelectedItem] = React.useState(getSelectedItem());
  useEffect(
    () => setSelectedItem(getSelectedItem()),
    [getSelectedItem, location]
  );

  return ["reports"].includes(pathNavMap(location.pathname)) ? (
    <SimplifiedNavigation
      selectedItem={selectedItem}
      content={content}
      includeFilters={false}
      hideMyStoreFilters={true}
      mobileToolbarHeight={mobileToolbarHeight}
    />
  ) : ["notifications"].includes(pathNavMap(location.pathname)) ? (
    <SimplifiedNavigation
      selectedItem={selectedItem}
      content={content}
      includeFilters={true}
      hideMyStoreFilters={true}
      mobileToolbarHeight={mobileToolbarHeight}
    />
  ) : (
    <StoreNavigation
      selectedItem={selectedItem}
      content={content}
      mobileToolbarHeight={mobileToolbarHeight}
    />
  );
});

export default SideMenu;
