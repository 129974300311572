import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import Medium from "~/components/typography/medium";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import RaisedButton from "~/components/buttons/raisedButton";
import { baseUrl } from "../../configs";
import styled from "styled-components";
import { useSaveInstacartCredentialsMutation } from "~/store/connections/connectInstacart.redux";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const authUrl =
  process.env.INSTACART_AUTH_URL || "https://ads.instacart.com/login";

const InstacartAdvertisingConnectPage: React.FC = () => {
  const { t } = useTranslation();

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [shopName, setShopName] = useState("");

  const [error, setError] = useState<string | null>(null);

  const [saveInstacartAuth] = useSaveInstacartCredentialsMutation({
    fixedCacheKey: "instacartAuth",
  });

  const onAuthError = useCallback((error: string) => {
    setError(t(error));
  }, []);

  const handleSignin = () => {
    saveInstacartAuth({
      clientId,
      clientSecret,
      shopName,
    })
      .unwrap()
      .then(({ code, errMsg }) => {
        if (errMsg) {
          onAuthError(errMsg);
        } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
          onAuthError("connectWizard.connectionError");
        } else {
          window.open(authUrl, "_self");
        }
      })
      .catch((error: { message: string }) => {
        const errorMessage = error.message || "connectWizard.connectionError";
        onAuthError(errorMessage);
      });
  };
  return (
    <GridWrapper id="instacart-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Medium align="center">
            {t("connectWizard.instacartLinkTitle")}
          </Medium>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LogoWrapper src={`${baseUrl}/static/icons/instacart.jpg`} />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Box my={1}>
            <Medium color="textPrimary" align="center">
              {t("connectWizard.instacartAuthorizationInstructions")}
            </Medium>
          </Box>
        </Grid>
        <Medium color="textPrimary" align="center">
          <a href={t("connectWizard.instacartHelpLink")} target="_blank">
            {t("connectWizard.instacartInstallationHelpLinkText")}
          </a>
        </Medium>
        <Grid container item xs={12} justifyContent="flex-start">
          <TextField
            fullWidth
            label={t("connectWizard.instacartClientIdLabel")}
            margin="normal"
            required
            onChange={(e) => setClientId(e.target.value)}
          />
          <TextField
            fullWidth
            label={t("connectWizard.instacartClientSecretLabel")}
            type="password"
            margin="normal"
            required
            onChange={(e) => setClientSecret(e.target.value)}
          />
          <TextField
            fullWidth
            label={t("connectWizard.instacartShopNameLabel")}
            margin="normal"
            required
            onChange={(e) => setShopName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <ButtonWrapper>
            <RaisedButton
              disabled={!clientId || !clientSecret || !shopName}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSignin}
            >
              {t("connectWizard.connectButton")}
            </RaisedButton>
          </ButtonWrapper>
        </Grid>
        {error && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <ErrorMessage color="error">{error}</ErrorMessage>
          </Grid>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default InstacartAdvertisingConnectPage;
