import {
  Bar,
  ComposedChart,
  Label,
  LabelProps,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { memo, useMemo } from "react";
import { getCurrencySymbol, intFormatterRounded } from "~/utils/currencyUtils";
import {
  getEvenTicks,
  getEvenTicksFromZero,
  useLayoutProps,
} from "../chartUtils/chartComponents";
import {
  getNearestHundredOrTen,
  getNearestPowerOfTenFloor,
} from "../chartUtils/chartUtils";

import ComboChartLegend from "../comboChart/ComboChartLegend";
import HorizontalStackedBarChartTooltip from "./horizontalStackedBarChartTooltip";

interface PlotProps {
  title: string;
  key: string;
  colour: string;
  toFixed?: number;
}

export enum StackedBarChartView {
  units = "units",
  currency = "currency",
  percentage = "percentage",
}

interface HorizontalStackedBarChartProps {
  title?: string;
  isLoading: boolean;
  currentCurrency: string;
  bars: PlotProps[];
  totalsTooltip?: PlotProps & { view: StackedBarChartView };
  barLabelsKey: string;
  chartData: Record<string, string | number>[];
  axisLabels?: { x?: LabelProps; y?: LabelProps };
  view?: StackedBarChartView;
  report?: boolean;
}

// Height in px, account for spacing b/w bars
const EACH_BAR_HEIGHT = 50;
const LEGEND_HEIGHT_BUFFER = 80;

const HorizontalStackedBarChart = memo<HorizontalStackedBarChartProps>(
  function HorizontalStackedBarChart({
    title,
    isLoading,
    currentCurrency,
    bars,
    totalsTooltip,
    barLabelsKey,
    chartData,
    axisLabels,
    view,
  }) {
    const { margin } = useLayoutProps("horizontalChart");
    const height = EACH_BAR_HEIGHT * chartData.length + LEGEND_HEIGHT_BUFFER;

    const currencySymbol =
      getCurrencySymbol[currentCurrency as keyof typeof getCurrencySymbol];

    const { dataMinBar, dataMaxBar } = useMemo(() => {
      const dataMinBar = 0;
      const dataMaxBar =
        chartData &&
        getNearestHundredOrTen(
          Math.max.apply(
            Math,
            chartData.map((o) =>
              bars.reduce((total, bar) => {
                const value = o[bar.key];
                // We do math.floor to avoid 100.0001 resulting in a max of 200
                return Math.floor(
                  (total += typeof value === "number" ? value : 0)
                );
              }, 0)
            )
          )
        );

      return {
        dataMinBar,
        dataMaxBar,
      };
    }, [chartData, bars]);

    const xAxisTicks = useMemo(() => {
      return dataMinBar < 0
        ? getEvenTicks(dataMinBar, dataMaxBar, 5)
        : getEvenTicksFromZero(
            getNearestHundredOrTen(dataMaxBar),
            5,
            Math.max(10, getNearestPowerOfTenFloor(dataMaxBar / 100))
          );
    }, [dataMinBar, dataMaxBar]);

    const xAxisTickFormatter = (tick: number) => {
      if (view && view != "currency") {
        return `${intFormatterRounded.format(tick)}${
          view === "percentage" ? "%" : null
        }`;
      } else {
        return currencySymbol + intFormatterRounded.format(tick);
      }
    };

    const legendAndToolTipData = bars.map((bar) => ({
      key: bar.key,
      fillColor: bar.colour,
      toFixed: bar.toFixed,
      name: `chartKey.${bar.title}`,
      shape: "bar" as const,
      unit:
        view === "currency" ? currencySymbol : view === "percentage" ? "%" : "",
    }));

    const totalsTooltipData = totalsTooltip
      ? {
          key: totalsTooltip.key,
          fillColor: totalsTooltip.colour,
          toFixed: totalsTooltip.toFixed,
          name: `chartKey.${totalsTooltip.title}`,
          shape: "bar" as const,
          unit: totalsTooltip.view === "currency" ? currencySymbol : "",
        }
      : null;

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        {title && (
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="h6">{title}</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          {!isLoading && (
            <ResponsiveContainer width="100%" height={height}>
              <ComposedChart
                data={chartData}
                layout="vertical"
                margin={{
                  // 40px buffer for legend
                  top: margin.top + 40,
                  bottom:
                    axisLabels?.x && axisLabels.x.offset
                      ? margin.bottom + axisLabels.x.offset * 2 // we want the label to be centered in the padding
                      : margin.bottom,
                  left:
                    axisLabels?.y && axisLabels.y.offset
                      ? margin.left + axisLabels.y.offset * 2 // we want the label to be centered in the padding
                      : margin.left + 20,
                  right: margin.right,
                }}
                stackOffset="sign"
              >
                <XAxis
                  type="number"
                  tickFormatter={xAxisTickFormatter}
                  tick={{ fontSize: 14 }}
                  domain={[dataMinBar, dataMaxBar]}
                  ticks={xAxisTicks}
                >
                  {axisLabels?.x && <Label {...axisLabels.x} />}
                </XAxis>
                <YAxis
                  yAxisId="left"
                  type="category"
                  dataKey={barLabelsKey}
                  tick={{ fontSize: 14 }}
                >
                  {axisLabels?.y && <Label {...axisLabels.y} />}
                </YAxis>
                <Tooltip
                  content={
                    <HorizontalStackedBarChartTooltip
                      tooltipProps={[
                        ...legendAndToolTipData,
                        ...(totalsTooltipData ? [totalsTooltipData] : []),
                      ]}
                    />
                  }
                />
                <Legend
                  content={
                    <ComboChartLegend legendItems={legendAndToolTipData} />
                  }
                  wrapperStyle={{ top: 0, fontSize: "12px" }}
                />
                {bars.map((bar) => {
                  return (
                    <Bar
                      layout="vertical"
                      key={`bar-${bar.key}`}
                      yAxisId="left"
                      stackId="a"
                      dataKey={bar.key}
                      fill={bar.colour}
                    />
                  );
                })}
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default HorizontalStackedBarChart;
