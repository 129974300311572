import { MenuItem, Typography } from "@material-ui/core";

import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import React from "react";
import ToolbarSelect from "~/components/select/toolbarSelect";
import styled from "styled-components";

const CustomMenuItem = styled(MenuItem)`
  width: ${({ width }: { width?: number | null }) => width}px;
`;

export interface Option {
  name: string;
  label: string;
}

interface AMCGenericSelectProps {
  id: string;
  title: string;
  tooltip?: string;
  currentOption?: Option;
  setOption: (reportType: Option) => void;
  options: Option[];
  placeholderLabel: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const GenericSelect = ({
  id,
  title,
  tooltip,
  currentOption,
  setOption,
  options,
  placeholderLabel,
  isLoading,
  disabled,
}: AMCGenericSelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = React.useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const changeOption = (reportType: Option) => {
    setAnchorEl(null);
    setOption(reportType);
  };

  const renderOptions = () => {
    if (isLoading) {
      return (
        <CustomMenuItem key={`loading`} width={menuWidth}>
          <LoadingIndicator />
        </CustomMenuItem>
      );
    }

    return options.map((option) => {
      return (
        <CustomMenuItem
          key={option.name}
          width={menuWidth}
          onClick={() => changeOption(option)}
        >
          <Typography>{option.label}</Typography>
        </CustomMenuItem>
      );
    });
  };

  return (
    <ToolbarSelect
      id={id}
      title={title}
      tooltip={tooltip}
      renderOptions={renderOptions}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      setMenuWidth={setMenuWidth}
      fullWidth
      alignItems="center"
      disabled={disabled}
      displayComponent={
        <Typography>
          {currentOption ? currentOption.label : placeholderLabel}
        </Typography>
      }
    />
  );
};
