import ActionCell, {
  ReactTableCell,
} from "~/components/table/cells/actionCell";
import { Grid, PropTypes, Typography } from "@material-ui/core";
import {
  Healing,
  HourglassEmpty,
  LocalOffer,
  ViewHeadline,
  VisibilityOff,
  VpnKey,
} from "@material-ui/icons";
import {
  ProductNotificationType,
  StoreNotificationType,
} from "mm-utils-frontend";
import React, { memo } from "react";

import { NotificationsRow } from "~/typedef/store";
import { Status } from "~/typedef/status";
import { StatusProps } from "~/typedef/status";
import { Variant } from "@material-ui/core/styles/createTypography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const NOTIFICATION_TYPE_MAP = {
  [StoreNotificationType.SELLER_HEALTH_AT_RISK]: {
    icon: Healing,
    status: "error" as Status,
    viewIssueT: "nav.accountHealth",
    viewIssueLink: (r: NotificationsRow) => ({
      pathname: `/mystores/${r.marketplaceType}/accounthealth`,
      search: `?store=${r.mid}`,
    }),
  },
  [StoreNotificationType.STORE_CREDENTIAL_INVALIDATION_PRIMARY]: {
    icon: VpnKey,
    status: "error" as Status,
    viewIssueT: "generic.reconnectNowLink",
    viewIssueLink: (r: NotificationsRow) => ({
      pathname: `/connectStore`,
      search: `?step=1&marketplace=${r.marketplaceType}${
        r.marketplaceType === "mirakl"
          ? `&submarketplace=${r.marketplaceSubtype}`
          : ""
      }`,
    }),
  },
  [StoreNotificationType.STORE_CREDENTIAL_INVALIDATION_ADVERTISING]: {
    icon: VpnKey,
    status: "error" as Status,
    viewIssueT: "nav.marketingOverview",
    viewIssueLink: (r: NotificationsRow) => ({
      pathname: `/mystores/${r.marketplaceType}/marketingOverview`,
      search: `?store=${r.mid}`,
    }),
  },
  [StoreNotificationType.STORE_MISSING_PERMISSIONS]: {
    icon: VpnKey,
    status: "error" as Status,
    viewIssueT: "",
    viewIssueLink: (r: NotificationsRow) => "",
  },
  [StoreNotificationType.SUPPRESSED_LISTINGS_SUMMARY]: {
    icon: VisibilityOff,
    status: "error" as Status,
    viewIssueT: "nav.suppressedProducts",
    viewIssueLink: (r: NotificationsRow) => ({
      pathname: `/mystores/${r.marketplaceType}/suppressedproducts`,
      search: `?store=${r.mid}`,
    }),
  },
  [StoreNotificationType.STORE_REPORT_LANGUAGE_UNSUPPORTED]: {
    icon: VpnKey,
    status: "error" as Status,
    viewIssueT: "",
    viewIssueLink: (r: NotificationsRow) => "",
  },
  [ProductNotificationType.LOW_INVENTORY]: {
    icon: HourglassEmpty,
    status: "warning" as Status,
    viewIssueT: "",
    viewIssueLink: (r: NotificationsRow) => "",
  },
  [ProductNotificationType.LOST_BUYBOX]: {
    icon: LocalOffer,
    status: "error" as Status,
    viewIssueT: "nav.buyBox",
    viewIssueLink: (r: NotificationsRow) => ({
      pathname: `/mystores/${r.marketplaceType}/buyBoxPerformance`,
      search: `?store=${r.mid}`,
    }),
  },
  [ProductNotificationType.SUPPRESSED_LISTING]: {
    icon: VisibilityOff,
    status: "error" as Status,
    viewIssueT: "nav.suppressedProducts",
    viewIssueLink: (r: NotificationsRow) => ({
      pathname: `/mystores/${r.marketplaceType}/suppressedproducts`,
      search: `?store=${r.mid}`,
    }),
  },
  ["CONNECTION_ISSUE"]: {
    icon: VpnKey,
    status: "error" as Status,
  },
};

const ActionGrid = styled(Grid)`
  justify-content: right;
`;

const IconWrapper = styled.div<StatusProps>`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme, $status }) => theme.palette[$status].main};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  & svg {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`;

interface NotificationTypeCellProps extends ReactTableCell {
  value: {
    row: NotificationsRow;
    handleListingSelect: Function;
  };
}

export const NotificationTypeCell = memo(
  ({ value }: NotificationTypeCellProps) => {
    const { t } = useTranslation();

    const notificationType = value?.row?.notificationType;
    const viewDetailsAction = {
      text: t("notifications.viewDetails"),
      icon: ViewHeadline,
      color: "inherit" as PropTypes.Color,
      buttonVariant: "text" as "text",
      textVariant: "body1" as Variant,
      action: () => {
        value.handleListingSelect(value?.row);
      },
    };

    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Grid item>
          <NotificationTypeIcon notificationType={notificationType} />
        </Grid>
        <Grid item>
          <Typography variant="body2" noWrap>
            {t(`notificationType.${notificationType}`)}
          </Typography>
        </Grid>
        <ActionGrid container item>
          <Grid item>
            <ActionCell actions={[viewDetailsAction]} />
          </Grid>
        </ActionGrid>
      </Grid>
    );
  }
);

interface NotificationTypeIconProps {
  notificationType: keyof typeof NOTIFICATION_TYPE_MAP;
}
export const NotificationTypeIcon = memo(
  ({ notificationType }: NotificationTypeIconProps) => {
    const { t } = useTranslation();
    const matchingType = NOTIFICATION_TYPE_MAP[notificationType];
    const Icon = matchingType?.icon;
    return (
      <IconWrapper $status={matchingType?.status || "disabled"}>
        {Icon && <Icon fontSize="small" />}
      </IconWrapper>
    );
  }
);
