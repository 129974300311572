import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import ErrorBoundary from "~/components/errorBoundary";
import InstacartAdvertisingAuthCodePage from "~/pages/marketplaceConnectors/instacartAdvertisingAuthCodePage";
import PrivateRoute from "~/routes/privateRoute";
import { get } from "lodash";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";

export const EVENT_ORIGIN_PATTERN =
  process.env.TARGET_ENV === "production" ||
  process.env.TARGET_ENV === "staging"
    ? /mm.*\.merchantspring\.io/
    : /.*/;

const Container = styled.div`
  text-align: center;
`;

type AuthCallbackPageProps = {
  communicationTimeout?: number;
};

const CUSTOM_AUTH_HANDLERS: Record<string, React.ComponentType> = {
  instacart: InstacartAdvertisingAuthCodePage,
};

const AuthCallbackPage: React.FC<AuthCallbackPageProps> = (props) => {
  const { communicationTimeout = 15000 } = props;
  const { authProvider } = useParams<{ authProvider: string }>();
  const queryParams = useQueryParams();
  const [error, setError] = React.useState<string | null>(null);

  const CustomPageToRender = get(CUSTOM_AUTH_HANDLERS, authProvider, null);

  useEffect(() => {
    if (!CustomPageToRender) {
      if (window.opener) {
        window.opener.postMessage(
          {
            authProvider,
            params: Object.fromEntries(queryParams),
          },
          "*" // parent window can be from any of our custom domains
        );
        setTimeout(() => {
          setError("Unable to communicate with the original browser window.");
        }, communicationTimeout);
      } else {
        setError("The original browser window has been closed unexpectedly.");
      }
    }
  }, [CustomPageToRender]);

  return CustomPageToRender ? (
    <ErrorBoundary>
      <PrivateRoute fullNav={true} component={CustomPageToRender} />
    </ErrorBoundary>
  ) : (
    <Container>
      {error ? (
        <>
          <Typography variant="h1">
            {
              "Sorry, something didn't work quite right. Please close this window and try again."
            }
          </Typography>
          <Typography color="error">{error}</Typography>
        </>
      ) : (
        <>
          <Typography variant="h1">
            {"We're processing your login. Hang tight!"}
          </Typography>
          <CircularProgress />
        </>
      )}
    </Container>
  );
};

export default AuthCallbackPage;
