import { Box, Grid } from "@material-ui/core";
import {
  Filter,
  PoChartData,
  PurchaseOrdersPerformance,
  Range,
} from "~/typedef/store";
import { FooterLink, Panel } from "~/components/panel/panel";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import AvgOrderValue from "~/components/totals/avgOrderValue";
import BarChartNoData from "~/components/placeholders/barChartNoData";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import GenericTotal from "~/components/totals/genericTotal";
import MultiBarChart from "../../../components/charts/barChart/barChartMulti";
import MultiLineChartGeneric from "~/components/charts/multiLineChart/multiLineChartGeneric";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { QUERY_TYPE } from "./purchaseOrdersPerformance";
import TotalOrders from "~/components/totals/totalOrders";
import TotalSales from "~/components/totals/totalSales";
import { User } from "~/typedef/user";
import { fetchPurchaseOrdersPerformance } from "~/store/mystore/purchaseOrderPerformance.redux";
import { fetchPurchaseOrdersPerformanceByShipDate } from "~/store/mystore/purchaseOrderPerformanceShipDate.redux";
import { getConvertedValue } from "~/utils/currencyUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CHART_TYPE = {
  Sales: "sales",
  Orders: "orders",
  AvgOrderValue: "avgOrderValue",
  UnitsSold: "unitsSold",
};
const CHART_TYPE_LEGENDS_MAPPING = {
  [CHART_TYPE.Sales]: "chartLegends.sales",
  [CHART_TYPE.Orders]: "chartLegends.orders",
  [CHART_TYPE.AvgOrderValue]: "chartLegends.avgOrderValue",
  [CHART_TYPE.UnitsSold]: "chartLegends.unitsSold",
};

interface PurchaseOrdersChartProps {
  userInfo: User;
  mid?: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  currentFilter?: Filter;
  footerLink?: FooterLink;
  actions?: ReactChild;
  overview?: boolean;
  chartType?: ChartOption;
  report?: boolean;
  timezone: string;
  queryBy: string;
  conditionalFormatting?: boolean;
  isLineChart?: boolean;
}

export interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

const PurchaseOrdersLineChart = memo<PurchaseOrdersChartProps>(
  function PurchaseOrdersLineChart({
    userInfo,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    currentFilter,
    footerLink,
    timezone,
    overview,
    actions,
    chartType,
    report,
    queryBy,
    conditionalFormatting,
    isLineChart,
  }) {
    const { t } = useTranslation();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const { fromDate, interval, toDate } = currentRange;

    const purchaseOrdersPerformance = useTypedSelector((state) =>
      overview
        ? state.overview.purchaseOrdersPerformance
        : state.mystore.purchaseOrdersPerformance
    );
    const purchaseOrdersPerformanceShipDate = useTypedSelector((state) =>
      overview
        ? state.overview.purchaseOrdersPerformanceShipDate
        : state.mystore.purchaseOrdersPerformanceShipDate
    );
    const rawData: PurchaseOrdersPerformance | null = useMemo(
      () =>
        queryBy === QUERY_TYPE.shippedDate
          ? purchaseOrdersPerformanceShipDate
          : purchaseOrdersPerformance,
      [queryBy, purchaseOrdersPerformance, purchaseOrdersPerformanceShipDate]
    );

    const dispatch = useDispatch();

    const dispatchFetchPerformance = useCallback(
      ({ mid, filter }) => {
        if (queryBy === QUERY_TYPE.shippedDate) {
          dispatch(
            fetchPurchaseOrdersPerformanceByShipDate(
              {
                mid,
                filter,
                currentPeriod,
                currentRange,
                type: chartType?.value || "sales",
              },
              purchaseOrdersPerformanceShipDate?.params
            )
          );
        } else {
          dispatch(
            fetchPurchaseOrdersPerformance(
              {
                mid,
                filter,
                currentPeriod,
                currentRange,
                type: chartType?.value || "sales",
              },
              purchaseOrdersPerformance?.params
            )
          );
        }
      },
      [
        currentPeriod,
        currentRange,
        dispatch,
        queryBy,
        chartType,
        purchaseOrdersPerformance?.params,
        purchaseOrdersPerformanceShipDate?.params,
      ]
    );

    useEffect(() => {
      dispatchFetchPerformance({
        user: userInfo,
        mid,
        filter: currentFilter,
      });
    }, [
      currentPeriod,
      currentRange,
      queryBy,
      chartType,
      mid,
      currentFilter,
      purchaseOrdersPerformance?.params,
      purchaseOrdersPerformanceShipDate?.params,
    ]);

    const hasData = useCallback((): boolean => {
      const hasChartData =
        rawData?.chartData &&
        rawData?.chartData.some(
          (data: any) => data?.current?.[chartType?.value || "sales"] ?? 0 !== 0
        );
      const hasCurrentSales = rawData?.current && rawData?.current.sales !== 0;
      const hasPriorSales = rawData?.prior && rawData?.prior.sales !== 0;

      return hasChartData || hasCurrentSales || hasPriorSales;
    }, [rawData]);

    /** Converting currency for sales */
    const convertSalesValues = useCallback(() => {
      const type = chartType?.value || "sales";
      return (
        rawData?.chartData?.map(
          (unitData?: { current?: PoChartData; prior?: PoChartData }) => {
            const totalValue =
              [CHART_TYPE.Sales, CHART_TYPE.AvgOrderValue].includes(type) &&
              Number.isFinite(unitData?.current?.[type as keyof PoChartData])
                ? getConvertedValue(
                    currencyRates,
                    rawData?.currency,
                    currentCurrency,
                    unitData?.current?.[type as keyof PoChartData] as
                      | number
                      | undefined
                  )
                : (unitData?.current?.[type as keyof PoChartData] as
                    | number
                    | undefined);
            if (unitData?.prior) {
              const prior =
                [CHART_TYPE.Sales, CHART_TYPE.AvgOrderValue].includes(type) &&
                Number.isFinite(unitData?.prior?.[type as keyof PoChartData])
                  ? getConvertedValue(
                      currencyRates,
                      rawData?.currency,
                      currentCurrency,
                      unitData?.prior?.[type as keyof PoChartData] as
                        | number
                        | undefined
                    )
                  : (unitData?.prior?.[type as keyof PoChartData] as
                      | number
                      | undefined);
              return {
                startTime: unitData?.current?.startTime,
                endTime: unitData?.current?.endTime,
                current: totalValue,
                prior,
              };
            }
            return {
              startTime: unitData?.current?.startTime,
              endTime: unitData?.current?.endTime,
              current: totalValue,
              prior: null,
            };
          }
        ) || []
      );
    }, [currencyRates, currentCurrency, rawData, chartType]);

    return (
      <Panel
        id="widget-sales-performance"
        title={t(
          `dashboardWidget.poTrend${
            queryBy === QUERY_TYPE.poDate ? "" : "ShipDate"
          }.mainTitle`
        )}
        tooltip={report ? undefined : t("dashboardWidget.poTrend.mainTooltip")}
        footerLink={footerLink}
        actions={actions}
        content={
          purchaseOrdersPerformance?.fetching ||
          purchaseOrdersPerformanceShipDate?.fetching ? (
            <PanelLoading />
          ) : !hasData() ? (
            <BarChartNoData {...{ currentPeriod: currentPeriod, report }} />
          ) : (
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={9} lg={10}>
                  {!isLineChart ? (
                    <MultiBarChart
                      {...{
                        chartData: convertSalesValues(),
                        currentPeriod: currentPeriod,
                        currentCurrency,
                        fromDate: fromDate,
                        toDate: toDate,
                        interval,
                        report,
                        timezone,
                        valueTooltipKey: t(
                          CHART_TYPE_LEGENDS_MAPPING[
                            chartType?.value || "sales"
                          ]
                        ),
                        notCurrency: [
                          CHART_TYPE.Orders,
                          CHART_TYPE.UnitsSold,
                        ].includes(chartType?.value || "sales"),
                        displayLabelOnBar:
                          chartType?.value === CHART_TYPE.Orders,
                      }}
                    />
                  ) : (
                    <MultiLineChartGeneric
                      {...{
                        chartData: convertSalesValues(),
                        currentPeriod: currentPeriod,
                        currentCurrency,
                        fromDate: fromDate,
                        toDate: toDate,
                        interval,
                        report,
                        currency: currentCurrency,
                        timezone,
                        valueTooltipKey: t(
                          CHART_TYPE_LEGENDS_MAPPING[
                            chartType?.value || "sales"
                          ]
                        ),
                        notCurrency: [
                          CHART_TYPE.Orders,
                          CHART_TYPE.UnitsSold,
                        ].includes(chartType?.value || "sales"),
                      }}
                    />
                  )}
                </Grid>

                <Grid container item xs={12} md={3} lg={2} alignItems="center">
                  {chartType?.value === CHART_TYPE.Orders ? (
                    <TotalOrders
                      current={rawData.current.orders}
                      prior={rawData.prior?.orders}
                      currentPeriod={currentPeriod}
                    />
                  ) : chartType?.value === CHART_TYPE.AvgOrderValue ? (
                    <AvgOrderValue
                      current={rawData.current?.avgOrderValue}
                      prior={rawData.prior?.avgOrderValue}
                      currentPeriod={currentPeriod}
                      currentCurrency={currentCurrency}
                      currency={rawData.currency}
                    />
                  ) : chartType?.value === CHART_TYPE.Sales ? (
                    <TotalSales
                      current={rawData.current?.sales}
                      prior={rawData.prior?.sales}
                      currentPeriod={currentPeriod}
                      currentCurrency={currentCurrency}
                      currency={rawData.currency}
                      isVendorStore={true}
                      customTitle={t("purchaseOrder.totalSales")}
                      conditionalFormatting={conditionalFormatting}
                    />
                  ) : chartType?.value === CHART_TYPE.UnitsSold ? (
                    <GenericTotal
                      number={rawData.current.unitsSold}
                      comparison={{
                        prior: rawData.prior?.unitsSold,
                      }}
                      title={t(`genericTotal.${chartType?.value}`)}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default PurchaseOrdersLineChart;
