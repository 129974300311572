import {
  Box,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";

import Medium from "~/components/typography/medium";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import RaisedButton from "~/components/buttons/raisedButton";
import { baseUrl } from "../../configs";
import styled from "styled-components";
import tickLogo from "~/img/tick_large.png";
import { useConnectYahooJapanMutation } from "~/store/connections/connectYahooJapan.redux";
import { useTranslation } from "react-i18next";
import useYahooJapanAuthcodeAuth from "~/hooks/useYahooJapanAuthcodeAuth";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const StyledTick = styled.img`
  display: block;
  width: 30px;
  height: auto;
`;

interface YahooJapanConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const YahooJapanConnectPage: React.FC<YahooJapanConnectPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [sellerId, setSellerId] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [shopName, setShopName] = useState("");

  const [error, setError] = useState<string | null>(null);

  const onAuthError = useCallback((error: string) => {
    setError(t(error));
  }, []);

  const [connectYahooJapan, { isLoading, isSuccess }] =
    useConnectYahooJapanMutation();

  const onAuthCode = useCallback(
    async (code: string) => {
      connectYahooJapan({
        sellerId,
        publicKey,
        shopName,
        authCode: code,
      })
        .unwrap()
        .then(({ code, errMsg }) => {
          if (errMsg) {
            onAuthError(errMsg);
          } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
            onAuthError("connectWizard.connectionError");
          } else {
            onSuccess();
            setError(null);
          }
        })
        .catch((error) => {
          const errorMessage = error.message || "connectWizard.connectionError";
          onAuthError(errorMessage);
        });
    },
    [sellerId, publicKey, shopName]
  );

  const triggerSignin = useYahooJapanAuthcodeAuth(onAuthCode, onAuthError);
  const handleSignin = () => {
    if (!sellerId.trim() || !publicKey.trim() || !shopName.trim()) {
      onAuthError("connectWizard.requiredFieldsError");
    } else {
      triggerSignin();
    }
  };

  return (
    <GridWrapper id="yahoo-japan-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid container item xs={12} justifyContent="center">
          <LogoWrapper src={`${baseUrl}/static/icons/yahoojapan.jpg`} />
        </Grid>
        <Grid item>
          <Medium color="textSecondary">
            {t("connectWizard.yahooJapanLinkTitle")}
          </Medium>
        </Grid>
        {isSuccess && !error ? (
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Box mr={1}>
              <StyledTick src={tickLogo} alt="logo" />
            </Box>
            <Typography variant="body1" color="textPrimary" align="center">
              {t("connectWizard.yahooJapanSuccessMessage")}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid container item xs={12} justifyContent="flex-start">
              <TextField
                fullWidth
                label={t("connectWizard.yahooJapanSellerIdLabel")}
                margin="normal"
                required
                onChange={(e) => setSellerId(e.target.value)}
              />
              <TextField
                fullWidth
                label={t("connectWizard.yahooJapanPublicKeyLabel")}
                margin="normal"
                multiline={true}
                minRows={5}
                required
                onChange={(e) => setPublicKey(e.target.value)}
              />
              <Box display="block" width="100%" pb={1}>
                <Typography display="block" align="right">
                  <Link
                    href={t("connectWizard.yahooJapanHelpLinkUrl")}
                    target="_blank"
                  >
                    {t("connectWizard.yahooJapanHelpLink")}
                  </Link>
                </Typography>
              </Box>
              <TextField
                fullWidth
                label={t("connectWizard.shopNameLabel")}
                margin="normal"
                required
                onChange={(e) => setShopName(e.target.value)}
              />
            </Grid>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  item
                  xs={12}
                >
                  <Grid item>
                    <RaisedButton color="primary" onClick={handleSignin}>
                      {t("connectWizard.connectButton")}
                    </RaisedButton>
                  </Grid>
                </Grid>
                {error && (
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ErrorMessage color="error">{error}</ErrorMessage>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default YahooJapanConnectPage;
