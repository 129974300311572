import {
  VisualRepresentation,
  VisualizationType,
} from "~/typedef/amc/reportType";

import AMCPieChart from "./visualizations/amcPieChart";
import AMCStackedBarChart from "./visualizations/amcStackedBarChart";
import { CurrentStore } from "~/typedef/store";
import { GridWithErrorBoundary } from "../errorBoundary/errorBoundary";
import React from "react";

interface AMCReportVisualizationProps {
  store: CurrentStore;
  reportTypeName: string;
  currentReportRunId: string;
  priorReportRunId?: string;
  visualizations: VisualRepresentation[];
  currentCurrency: string;
}

const AMCReportVisualization: React.FC<AMCReportVisualizationProps> = ({
  store,
  reportTypeName,
  currentReportRunId,
  priorReportRunId,
  visualizations,
  currentCurrency,
}) => {
  return (
    <>
      {visualizations.map((visualization, index) => {
        switch (visualization.type) {
          case VisualizationType.PieChart:
            return (
              <GridWithErrorBoundary item xs={12} md={4} key={index}>
                <AMCPieChart
                  store={store}
                  reportTypeName={reportTypeName}
                  currentReportRunId={currentReportRunId}
                  pieChartConfig={visualization.config}
                />
              </GridWithErrorBoundary>
            );
          case VisualizationType.StackedBarChart:
            return (
              <GridWithErrorBoundary item xs={12} md={8} key={index}>
                <AMCStackedBarChart
                  store={store}
                  reportTypeName={reportTypeName}
                  currentReportRunId={currentReportRunId}
                  chartConfig={visualization.config}
                  currentCurrency={currentCurrency}
                />
              </GridWithErrorBoundary>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default AMCReportVisualization;
