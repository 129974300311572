import { AmazonCountry, AmazonRegion } from "mm-amazon-common/dist/typedef/mws";
import { Box, Grid } from "@material-ui/core";
import React, { ChangeEvent, useEffect } from "react";

import AmazonAuthoriseAdvertising from "~/modules/login/amazonAuthoriseAdvertising";
import Medium from "~/components/typography/medium";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import Typography from "@material-ui/core/Typography";
import { getCountryCodeISO3ToName } from "~/utils/countryUtils";
import { regionalCountryCodes } from "mm-amazon-common/dist/mwsConstants";
import { strcmp } from "@merchantspring/common";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

export const topCountries = [
  {
    value: "USA",
    label: "United States",
  },
  {
    value: "GBR",
    label: "United Kingdom",
  },
  {
    value: "DEU",
    label: "Germany",
  },
  {
    value: "JPN",
    label: "Japan",
  },
];

const NORTH_AMERICA = "NA";
const EUROPE = "EU";
const ASIA_PACIFIC = "AP";

export const countries = Object.keys(regionalCountryCodes)
  .reduce<
    {
      value: string;
      label: string;
    }[]
  >((acc, regionKey) => {
    const regionCountries = regionalCountryCodes[regionKey as AmazonRegion];

    const countryOptions = regionCountries.map((countryCode) => ({
      value: countryCode,
      label: getCountryCodeISO3ToName(countryCode),
    }));

    return acc.concat(countryOptions);
  }, [])
  .filter((a) => !topCountries.find((b) => b.value === a.value))
  .sort((a, b) => strcmp(a.label, b.label));

const AmazonAdvertisingStandaloneConnectPage = ({
  marketplace,
  onSuccess,
  setIsSubmitting,
  selectCountry,
}: {
  marketplace: string;
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
  selectCountry: (countryCode: string) => void;
}) => {
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.user);

  const [countryCode, setCountryCode] = React.useState("");
  const [region, setRegion] = React.useState("");
  const errMsg = React.useRef("");

  const onAuthError = () => {
    errMsg.current = t("connectWizard.connectionError");
  };

  const getRegion = (countryCode: AmazonCountry) => {
    if (regionalCountryCodes[NORTH_AMERICA].includes(countryCode)) {
      return NORTH_AMERICA;
    }

    if (regionalCountryCodes[EUROPE].includes(countryCode)) {
      return EUROPE;
    }
    if (regionalCountryCodes[ASIA_PACIFIC].includes(countryCode)) {
      return ASIA_PACIFIC;
    }

    return NORTH_AMERICA;
  };

  useEffect(() => {
    setRegion(getRegion(countryCode as AmazonCountry));
  }, [countryCode]);

  const generateAuthorizationURL = (storeRegion: string) => {
    let host = "";

    switch (storeRegion) {
      case EUROPE:
        host = "https://eu.account.amazon.com/ap/oa";
        break;
      case ASIA_PACIFIC:
        host = "https://apac.account.amazon.com/ap/oa";
        break;
      default:
        host = "https://www.amazon.com/ap/oa";
    }

    const url = new URL(host);
    const urlParams = new URLSearchParams({
      // eslint-disable-next-line no-undef
      client_id: AMAZON_ADVERTISING_CLIENT_ID,
      scope: "advertising::campaign_management",
      response_type: "code",
      // eslint-disable-next-line no-undef
      redirect_uri: AMAZON_REDIRECT_URI,
      state: storeRegion,
    });
    url.search = urlParams.toString();
    return url.toString();
  };

  return (
    <GridWrapper id="amazon-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item>
          <Medium>
            {marketplace === "amazon"
              ? t("connectWizard.amazonAdvertisingLinkTitle")
              : marketplace === "amazon_vendor"
              ? t("connectWizard.vendorAdvertisingLinkTitle")
              : marketplace === "dsp"
              ? t("connectWizard.dspAdvertisingLinkTitle")
              : null}
          </Medium>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start">
          <Medium>{t("connectWizard.amazonCountryLabel")}</Medium>
          <Box pt={1} pb={1} width={1}>
            <NativeSelect
              name={"countryCode"}
              value={countryCode}
              topOptions={topCountries}
              options={countries}
              onChange={(
                e: ChangeEvent<{ name?: string; value: unknown }>,
                _child
              ) => {
                const selectedCountry = topCountries
                  .concat(countries)
                  .find((country) => country.value === e.target.value);
                if (selectedCountry) {
                  selectCountry(selectedCountry.value);
                  setCountryCode(selectedCountry.value);
                }
              }}
              autoFocus={true}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          item
          xs={12}
        >
          <Grid item>
            <AmazonAuthoriseAdvertising
              id="amazon-advertising-link"
              url={generateAuthorizationURL(region || NORTH_AMERICA)}
              userId={user._id}
              mid=""
              sellerId={null}
              shopName=""
              countryCode={countryCode}
              localisation={t}
              marketplace={marketplace}
              onSuccess={onSuccess}
              onError={onAuthError}
              setIsSubmitting={setIsSubmitting}
              variant="outlined"
            >
              {t("connectWizard.authorizeButton")}
            </AmazonAuthoriseAdvertising>
          </Grid>
        </Grid>
        <>
          {errMsg && errMsg.current && (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <ErrorMessage color="error">{errMsg.current}</ErrorMessage>
            </Grid>
          )}
        </>
      </Grid>
    </GridWrapper>
  );
};

export default AmazonAdvertisingStandaloneConnectPage;
