import React, { memo } from "react";

import PageBlock from "~/components/containers/sideNavPageBlock";

// Placeholder for the ReportStatus component
const ReportStatus = memo(function ReportStatus() {
  return <PageBlock>Report Status - Coming Soon</PageBlock>;
});

export default ReportStatus;
