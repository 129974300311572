import { CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import React, { memo } from "react";
import AdTable from "~/components/adTable/table";
import { Column } from "~/components/adTable/columnSelect";
import { CurrentStore } from "~/typedef/store";
import { FinancialCategory } from "~/store/mystore/profitability.redux";
import ProductTableChart from "./productTableChart";
import { HandleListingSelectFunc } from "../widgets/profitability/profitabilityProduct";
import isEmpty from "lodash/isEmpty";

export interface ProductEventResponse {
  rows: ProductEventRow[];
  count: number;
}

export interface ProductEventRow {
  productId: string;
  sellerSku: string;
  productSku: string;
  title: string;
  imageUrl: string;
  linkUrl: string;
  availableQuantity: number;
  daysCover: number;
  fulfilledBy: string;
  totalSales: number;
  totalRefunds: number;
  totalReimbursements: number;
  totalOtherIncome: number;
  totalPromotions: number;
  totalSellingFees: number;
  totalShippingFees: number;
  totalRefundsAndReturns: number;
  totalCogs: number;
  totalOtherExpenses: number;
  totalRevenue: number;
  totalExpenses: number;
  totalAdvertising: number;
  data: ProductEventRowData;
}

interface Meta {
  sellerSku: string;
  orderCount: number;
  unitCount: number;
  refundedUnitCount: number;
}

interface Event {
  label: string;
  value: number;
}

export interface ProductEventRowData {
  meta: Meta;
  events: Event[];
}

interface ProductTableProps {
  store: CurrentStore;
  report?: boolean;
  categories: FinancialCategory[];
  loading: boolean;
  currency: string;
  data: ProductEventResponse;
  fetchData: (params: {
    pageIndex: number;
    pageSize: number;
    sortBy: { id: string; desc: boolean }[];
    dataVersion?: number;
  }) => void;
  pageSize: number;
  columns: Column[];
}

const ProductTable = memo<ProductTableProps>(function ProductTable({
  store,
  loading,
  data,
  fetchData,
  report = false,
  pageSize,
  columns,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  const renderProfitabilitySubComponent = ({
    row: {
      original: { sellerSku },
    },
  }: {
    row: { original: { sellerSku: string } };
  }) => (
    // eslint-disable-next-line no-magic-numbers
    <td colSpan={isMobile ? 4 : isSmall ? 6 : 9}>
      <ProductTableChart {...{ sellerSku }} />
    </td>
  );

  return (
    <>
      {!store || !data || !fetchData || isEmpty(columns) ? (
        <CircularProgress />
      ) : (
        <AdTable
          {...{
            columns,
            data: data.rows,
            fetchData,
            loading,
            sorting: true,
            pagination: !report, // disable pagination when this table is shown as a client report
            pageSize,
            pageCount: Math.ceil(data.count / pageSize),
            renderRowSubComponent: report
              ? undefined
              : renderProfitabilitySubComponent,
          }}
        />
      )}
    </>
  );
});

export default ProductTable;
