import { drawerWidthClosed, drawerWidthOpen } from "./sideNavConstants";

import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const ContentWrapper = styled(Grid)<{ open?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 120px);
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  align-self: flex-end;
  overflow-y: hidden;
  ${({ open, theme }) =>
    open
      ? {
          width: `calc(100% - ${drawerWidthOpen}px)`,
          transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            opacity: 0,
            pointerEvents: "none",
          },
        }
      : {
          width: `calc(100% - ${drawerWidthClosed}px)`,
          transition: theme.transitions.create(["width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }};
`;

ContentWrapper.displayName = "ContentWrapper";
