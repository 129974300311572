import {
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { ReactChild, useEffect } from "react";
import { drawerWidthClosed, drawerWidthOpen } from "./sideNavConstants";
import {
  getConnectionStatus,
  hasFilteredSuffix,
} from "~/utils/marketplaceUtils";

import ConnectionProgress from "../../connectionProgress/connectionProgress";
import ConnectionProgressV2 from "~/components/connectionProgress/connectionProgressV2";
import { ContentWrapper } from "./drawerContentWrapper";
import DemoStoreAlert from "../../alert/demoStoreAlert";
import DemoUserAlert from "../../alert/demoUserAlert";
import DisconnectedStoresAlert from "../../alert/disconnectedStores";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import ReportingCommentsDrawer from "~/modules/reportingCommentsDrawer";
import { SecondaryToolbar } from "../myStoresToolbar/secondaryToolbar";
import { SelectedItem } from "./sideMenu";
import { SideNavDrawer } from "./sideNavDrawer";
import { User } from "~/typedef/user";
import { fetchConnectionStatus } from "../../../store/connections/connectionStatus.redux";
import { fetchCustomLayoutConfig } from "~/store/customLayout.redux";
import { fetchStoreIssues } from "~/store/mystore/issues.redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useMarketplace } from "~/utils/navigationUtils";
import { useStoreConnection } from "~/hooks/useStoreConnection";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import useWindowSize from "../../../hooks/useWindowSize";

const PAGE_BLOCK_WIDTH = 1600;

interface StoreNavigationProps {
  content: ReactChild;
  selectedItem: SelectedItem;
  mobileToolbarHeight: number;
}

export const StoreNavigation: React.FC<StoreNavigationProps> = ({
  selectedItem,
  content,
  mobileToolbarHeight,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const marketplace = useMarketplace();

  const onMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [, setError] = React.useState();
  const [open, setOpen] = React.useState(!smDown);
  useEffect(() => setOpen(!smDown), [smDown]);

  const [disconnected, setDisconnected] = React.useState<boolean>(false);
  const [showingDemoData, setShowingDemoData] = React.useState(false);
  const user = useTypedSelector((state) => state.user);
  const filteredStores = useTypedSelector(
    (state) => state.mystore.filteredStores.stores
  );
  const currentStore = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const connections = useTypedSelector(
    (state) => state.connections.connectionStatus
  );
  const disconnectedStores = useTypedSelector(
    (state) => state.connections?.disconnectedStores?.stores || []
  );

  useEffect(() => {
    if (currentStore && currentStore.isDemoMode) {
      setShowingDemoData(true);
      setDisconnected(false);
    } else if (currentStore) {
      const { shopStatus } = getConnectionStatus(
        disconnectedStores,
        currentStore.marketplace,
        currentStore.merchantId
      );
      setDisconnected(!shopStatus);
      setShowingDemoData(false);
    }
  }, [marketplace, currentStore]);

  useEffect(() => {
    const dispatchFetchConnectionStatus = (userInfo: User) =>
      dispatch(fetchConnectionStatus({ user: userInfo }));
    const fetchData = async () => {
      dispatchFetchConnectionStatus(user);
    };
    const fetchConnections = () => {
      if (!connections.fetching) {
        fetchData().catch((err) =>
          setError(() => {
            throw err;
          })
        );
      }
    };
    fetchConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch(fetchCustomLayoutConfig());
  }, []);

  useEffect(() => {
    if (currentStore && currentStore.merchantId) {
      dispatch(fetchStoreIssues(currentStore));
    }
  }, [currentStore]);

  const { connection, firstConnectionIncomplete } = useStoreConnection(
    connections,
    currentStore,
    filteredStores
  );

  const toggleDrawer = () => {
    setOpen(!open);
  };

  /** Requirement to keep the mystores page in line with the app toolbar */
  const pageWidth = useWindowSize().width;
  const marginRight = (pageWidth! - PAGE_BLOCK_WIDTH) / 2;
  const drawerWidth = open ? drawerWidthOpen : drawerWidthClosed;
  const marginLeft = marginRight - drawerWidth;
  const marginLeftPx = marginLeft < 0 ? `0px` : `${marginLeft}px`;
  const marginRightPx = marginRight < 0 ? `0px` : `${marginRight}px`;

  const alertMarginX = onMobile ? "16px" : "24px";

  return currentStore ? (
    <>
      <SideNavDrawer
        open={open}
        setOpen={setOpen}
        currentStore={currentStore}
        mobileToolbarHeight={mobileToolbarHeight}
      />
      <ContentWrapper {...{ container: true, item: true, open }}>
        <SecondaryToolbar
          open={open}
          selectedItem={selectedItem}
          toggleDrawer={toggleDrawer}
          marginLeftPx={marginLeftPx}
          marginRightPx={marginRightPx}
        />
        <Box
          width={`calc(100% - ${marginRightPx} - ${marginLeftPx})`}
          mr={marginRightPx}
          ml={marginLeftPx}
          mt={1}
        >
          {connection && (
            <>
              <Box mx={alertMarginX}>
                <ConnectionProgress
                  {...{
                    user: user,
                    filteredStores,
                    connection: connection,
                    percentage: connection.percentage,
                    breakdown: connection.breakdown,
                  }}
                />
              </Box>
              <Box mx={alertMarginX}>
                {firstConnectionIncomplete && <DemoStoreAlert linkToDemo />}
              </Box>
            </>
          )}
          {hasFilteredSuffix(currentStore.marketplace) && (
            <Box mx={alertMarginX}>
              <ConnectionProgressV2
                marketplaceType={currentStore.marketplace}
                marketplaceSubtype={
                  currentStore.marketplaceSubtype || currentStore.marketplace
                }
                countryCode={currentStore.marketplaceCountry}
                mid={currentStore.merchantId}
                shopName={currentStore.storeName}
              />
            </Box>
          )}
          <Box marginX={alertMarginX}>
            {showingDemoData && !user.isDemoMode && <DemoStoreAlert />}
            {user.isDemoMode && <DemoUserAlert />}
          </Box>
          {disconnected && (
            <Box mx={alertMarginX}>
              <DisconnectedStoresAlert
                {...{
                  store: currentStore,
                  isOpen: Boolean(disconnected),
                  onClose: () => setDisconnected(false),
                }}
              />
            </Box>
          )}
          {!connections || !connections.connections ? (
            <CircularProgress />
          ) : (
            content
          )}
        </Box>
      </ContentWrapper>
      {user.reportsOn && <ReportingCommentsDrawer isMulti={false} />}
    </>
  ) : (
    <LoadingIndicator />
  );
};

export default StoreNavigation;
