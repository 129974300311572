import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export interface Account {
  accountId: string;
}

interface SaveInstacartCredsArgs {
  clientId: string;
  clientSecret: string;
  shopName: string;
}

interface SaveInstacartCredsResponse {
  errMsg?: string;
  code?: number;
}

interface ValidateInstacartAuthArgs {
  authCode: string;
}

interface ValidateInstacartAuthResponse {
  errMsg?: string;
  code?: number;
  store?: {
    marketplaceType: string;
    marketplaceSubtype: string;
    mid: string;
    countryCode: string;
  };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    saveInstacartCredentials: build.mutation<
      SaveInstacartCredsResponse,
      SaveInstacartCredsArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/instacart/save`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("Instacart connect", true),
    }),
    validateInstacartAuth: build.mutation<
      ValidateInstacartAuthResponse,
      ValidateInstacartAuthArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/auth/instacart/connect`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("Instacart connect", true),
    }),
  }),
});

export const {
  useSaveInstacartCredentialsMutation,
  useValidateInstacartAuthMutation,
} = extendedApiSlice;
