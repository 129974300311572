import { Box, Grid, Theme, useTheme } from "@material-ui/core";
import React, { memo } from "react";

import styled from "styled-components";

interface PathCellProps {
  cell: {
    value: {
      pathList: string[];
      getTileColor: (theme: Theme, pathName: string) => string;
    };
  };
}

export const CustomBadge = styled.div<{ $index: number; $bgColor: string }>`
  display: inline-block;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 6px 12px;
  margin-left: ${({ $index }) => ($index === 0 ? 0 : 6)}px;
  width: max-content;
  text-align: center;
  font-size: 10px;
  border-radius: 5px;
`;

const Span = styled.span`
  font-size: 12px;
  display: inline-block;
`;

export const PathCell = memo<PathCellProps>(({ cell }) => {
  const theme = useTheme();
  const path = cell.value.pathList;
  const getTileColor = cell.value.getTileColor;
  return (
    <Box py={1}>
      <Grid container spacing={1} justifyContent="flex-start">
        {path.map((pathName, index) => {
          const color = getTileColor(theme, pathName);
          return (
            <Grid item key={index}>
              {index !== 0 && <Span>&#8594;</Span>}
              <CustomBadge $index={index} $bgColor={color}>
                {pathName}
              </CustomBadge>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
});
