import { Box, Grid } from "@material-ui/core";
import { Filter, Range } from "@typedef/store";
import React, { memo, useEffect, useMemo } from "react";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import Panel from "../../../components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import PerformanceChart from "../advertising/performanceChart";
import get from "lodash/get";
import moment from "moment-timezone";
import { roundFractionalDigits } from "@utils/currencyUtils";
import { useMarketingOverviewChartQuery } from "@store/overview/marketing.redux";
import { useSalesPerformanceQuery } from "@store/overview/salesPerformance.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface ChartParams {
  includeTax: boolean;
  currentFilter: Filter;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
}

const MarketingOverviewChart = memo<ChartParams>(
  function MarketingOverviewChart({
    includeTax,
    currentFilter,
    currentPeriod,
    currentRange,
    currentCurrency,
    timezone,
  }) {
    const { t } = useTranslation();

    const { data, loading } = useMarketingOverviewChartQuery(
      {
        filter: currentFilter,
        reportDateFrom: currentRange.fromDate,
        reportDateTo: currentRange.toDate,
        interval: currentRange.interval,
        timezone,
        targetCurrency: currentCurrency,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          data: data || [],
          loading: isFetching,
        }),
      }
    );
    const vendorRevenueType = useTypedSelector(
      (state) =>
        state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
        "orderedRevenue"
    );

    const { salesPerformance } = useSalesPerformanceQuery(
      {
        filter: currentFilter,
        currentPeriod,
        currentRange,
        includeTax,
        currency: currentCurrency,
        includeVendor: true,
        vendorRevenueType,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          salesPerformance: data,
          salesPerformanceLoading: isFetching,
        }),
      }
    );

    const chartData = useMemo(() => {
      const salesData = salesPerformance?.chartData || [];
      return data.map((entry, index) => {
        return {
          reportDate: moment(get(entry, "report_date")).unix(),
          sales: roundFractionalDigits(get(entry, "attributed_sales", 0)),
          acos: roundFractionalDigits(get(entry, "acos", 0)),
          cost: roundFractionalDigits(get(entry, "cost", 0)),
          tacos:
            salesData[index] && salesData[index]?.current?.sales
              ? roundFractionalDigits(
                  (100 * get(entry, "cost", 0)) /
                    salesData[index]?.current?.sales
                )
              : 0,
        };
      });
    }, [data, salesPerformance?.chartData]);

    return (
      <Panel
        id="widget-marketing-overview-chart"
        title={t("advertisingDashboardWidget.performanceTrend.mainTitle")}
        tooltip={t("advertisingDashboardWidget.performanceTrend.mainTooltip")}
        content={
          <Box p={2}>
            {loading ? (
              <PanelLoading />
            ) : (
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <PerformanceChart
                    title={t(CHART_TITLES[currentPeriod])}
                    currentPeriod={currentPeriod}
                    currentCurrency={currentCurrency}
                    chartData={chartData}
                    isLoading={loading}
                    timezone={timezone}
                    interval={currentRange.interval}
                    line2Key="tacos"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    );
  }
);

export default MarketingOverviewChart;
