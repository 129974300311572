import { getConvertedValue } from "./currencyUtils";
import moment from "moment-timezone";

/* eslint-disable no-magic-numbers */
export const getPercentageDifference = (
  current,
  prior,
  percentage,
  numberOfDecimalPlaces
) => {
  const currentNum = parseFloat(current);
  const priorNum = parseFloat(prior);

  if (isNaN(currentNum) || isNaN(priorNum) || priorNum <= 0) {
    return "N/A";
  }

  if (percentage) {
    return (currentNum - priorNum).toFixed(numberOfDecimalPlaces || 0);
  }

  if (priorNum > 0) {
    const oneHundredPercent = 100.0;
    const increaseValue = currentNum - priorNum;
    return ((increaseValue / priorNum) * oneHundredPercent).toFixed(
      numberOfDecimalPlaces || 0
    );
  }
  return "N/A";
};

export const formatNumber = (number, toFixed = 2) => {
  if (number || number === 0) {
    return number.toFixed(toFixed);
  }
  return "N/A";
};

export const getComparisonValue = (metric) => {
  if (metric) {
    return metric.priorPeriodValue;
  }
  return null;
};

export const getConvertedComparisonValue = (metric, type) => {
  if (metric) {
    if (type === "orders") {
      return metric.convertedOrderPriorValue;
    }
    return metric.convertedPriorValue;
  }
  return null;
};

/**
 * @param {Array<{ value: string, currency: string }>} salesArray
 * @param {any} currencyRates
 * @param {string} targetCurrency
 */
export const getSalesValueNormalisedForCurrencyByArray = (
  salesArray,
  currencyRates,
  targetCurrency
) =>
  salesArray.reduce((cumSales, sales) => {
    const salesValue = sales.value;
    const sourceCurrency = sales.currency;
    const convertedTotalSales = getConvertedValue(
      currencyRates,
      sourceCurrency,
      targetCurrency,
      salesValue
    );
    return parseFloat(cumSales) + parseFloat(convertedTotalSales);
  }, 0);

export const getSalesValueNormalisedForCurrency = (
  currencyToSalesMapping,
  currencyRates,
  currentCurrency
) =>
  getSalesValueNormalisedForCurrencyByArray(
    Object.keys(currencyToSalesMapping).map((key) => ({
      value: currencyToSalesMapping[key],
      currency: key,
    })),
    currencyRates,
    currentCurrency
  );

export const getExpectedAndActualSales = (sales, averageSales) =>
  averageSales
    .map((averageSale, i) => ({
      average: averageSale.amount,
      sales: sales[i].amount,
      timestamp: sales[i].timestamp,
    }))
    .reduce((cumulativeData, dataForDay, i) => {
      if (i === 0) {
        cumulativeData[i] = { ...dataForDay };
      } else {
        const today = moment().startOf("d");
        const date = moment.unix(sales[i].timestamp);
        const cumAverage = cumulativeData[i - 1].average + dataForDay.average;
        const cumSales =
          today.diff(date) >= 0
            ? cumulativeData[i - 1].sales + dataForDay.sales
            : dataForDay.sales;
        cumulativeData[i] = {
          ...dataForDay,
          average: cumAverage,
          sales: cumSales,
        };
      }
      return cumulativeData;
    }, []);
