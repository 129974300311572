import { TFunction } from "i18next";
import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export enum AdvertiserType {
  SPONSORED_ADS = "SPONSORED_ADS",
  DISPLAY = "DISPLAY",
}
export interface AdvertisersPayload {
  id: string;
  type: AdvertiserType;
  mid: string;
  marketplaceType: string;
  marketplaceId?: string | null;
}

interface AmcInstanceRequest {
  instanceName: string;
  countryCode: string;
  advertisersPayload: AdvertisersPayload[];
}

interface AmcInstanceResponse {
  errMsg?: string;
  code?: number;
}
export interface Profile {
  accountName: string;
  entityId: string;
  mid: string;
  marketplaceType: string;
  marketplaceId?: string | null;
}

interface FetchAMCProfilesResponse {
  amcAdProfiles: Profile[];
  amcDspProfiles: Profile[];
  errMsg?: string;
  code?: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    createAmcInstance: build.mutation<AmcInstanceResponse, AmcInstanceRequest>({
      query: (params) => ({
        url: `${baseUrl}/auth/amc/create-instance`,
        method: "POST",
        data: params,
      }),
      onQueryStarted: globalQueryErrorHandler("AMC Instance connect", true),
    }),

    fetchAMCProfiles: build.query<FetchAMCProfilesResponse, {}>({
      query: () => ({
        url: `${baseUrl}/auth/amc/profile`,
        method: "GET",
      }),
      onQueryStarted: globalQueryErrorHandler("Fetch AMC Profiles", true),
    }),
  }),
});

export const { useCreateAmcInstanceMutation, useFetchAMCProfilesQuery } =
  extendedApiSlice;
