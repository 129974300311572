import {
  ArrowDropDown,
  Flag,
  StorefrontTwoTone as Store,
} from "@material-ui/icons";
import {
  Box,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { isEmpty, isEqual, uniq } from "lodash";

import { CountryLogo } from "../../img/flags/country_logos";
import { MarketplaceIcon } from "../../img/marketplaces/icons/marketplaceIcon";
import SearchFilter from "../adTable/searchFilter";
import { TFunction } from "react-i18next";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface StyleProps {
  $width?: number | null;
  $condensed?: boolean;
  $maxWidth?: number;
  $isFullWidth?: boolean;
  $canSelectAll?: boolean;
  $forceLowercase?: boolean;
}
const FilterMenuItem = styled(MenuItem)`
  width: ${({ $width }: StyleProps) => $width}px;
  margin-left: ${({ $canSelectAll }: StyleProps) => ($canSelectAll ? 20 : 0)}px;
`;

export const FilterSelectBase = styled(ButtonBase)`
  width: 100%;
  padding: ${({ $condensed }: StyleProps) =>
    $condensed ? "0 0.5rem" : "0.25rem 0.5rem"};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  max-width: ${({ $maxWidth }: StyleProps) => `${$maxWidth}px` || "none"};
  &:disabled {
    background-color: ${({ theme }) => theme.palette.disabled.light};
    box-shadow: ${({ theme }) => theme.shadows[0]};
    cursor: not-allowed;
  }
`;

export const OptionText = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  padding-left: ${({ $condensed }: StyleProps) => ($condensed ? "0px" : "8px")};
  text-transform: ${({ $forceLowercase }: StyleProps) =>
    $forceLowercase ? "none" : "uppercase"};
  text-wrap: pretty;
  max-width: ${({ $maxWidth }: StyleProps) => `calc(${$maxWidth}px - 100px)`};
`;

const FlexGrid = styled(Grid)`
  padding: 0 4px;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 5px;
  img {
    display: block;
  }
`;

const StyledArrowDropDown = styled(ArrowDropDown)`
  width: 20px;
  margin-right: -0.25rem;
  margin-left: 0.25rem;
`;

const RightAlignedGrid = styled(Grid)`
  width: ${({ $isFullWidth }: StyleProps) =>
    $isFullWidth ? "100%" : "fit-content"};
`;

const StoreWrapper = styled(Store)`
  font-size: 20px;
`;

const FlagWrapper = styled(Flag)`
  font-size: 20px;
`;

const ControlLabel = styled(FormControlLabel)`
  width: 100%;
`;

const SelectedOptionTypography = styled(Typography)`
  max-width: ${({ $maxWidth }: StyleProps) => `calc(${$maxWidth}px - 100px)`};
`;

// Get a friendly name for the marketplace, instead of the default
// strings like 'amazon', 'amazon_vendor' etc.
// Defaults to the marketplace if no friendly name is defined.
const getMarketplaceName = (t: TFunction, marketplace: string): string => {
  const translationKey = `generic.marketplaceName.${marketplace}`;
  const friendlyName = t(translationKey);
  if (friendlyName === translationKey) {
    return marketplace;
  } else {
    return friendlyName;
  }
};

interface FilterSelectProps {
  options: string[];
  selectedOptions: string[];
  filterTitle: TFunction;
  title: TFunction;
  handleSelect: (values: any[]) => void;
  isMarketplace: boolean;
  isCountry: boolean;
  forceLowercase?: boolean;
  isFullWidth: boolean;
  maxWidth?: number;
  isDisplayIcons: boolean;
  condensed: boolean;
  disabled: boolean;
  searchable: boolean;
  canSelectAll?: boolean;
  setSearchText?: (searchText: string) => void;
}
const FilterSelect = memo<FilterSelectProps>(function FilterSelect({
  title,
  maxWidth,
  isFullWidth,
  filterTitle,
  options,
  selectedOptions,
  handleSelect,
  isMarketplace,
  isCountry,
  forceLowercase,
  isDisplayIcons,
  condensed,
  disabled,
  searchable,
  canSelectAll,
  setSearchText,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [values, setValues] = useState(selectedOptions || []);
  const [checked, setChecked] = useState(selectedOptions || []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );
  const [selectAll, setSelectAll] = useState(false);
  const othersLength = checked.length - 1;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setValues(uniq([...values, e.target.name]));
    } else {
      const filteredVal = values.filter((val) => val !== e.target.name);
      setValues(filteredVal);
    }

    setChecked(
      checked.includes(e.target.name)
        ? checked.filter((val) => val !== e.target.name)
        : [...checked, e.target.name]
    );
  };

  useEffect(() => {
    if (isEqual(options, checked)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checked]);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    handleSelect(values);
    setAnchorEl(null);
  };

  useEffect(() => {
    setMenuWidth(
      Boolean(anchorEl) && anchorEl?.offsetWidth
        ? canSelectAll
          ? // removing 20 from width so that the width matches the parent
            anchorEl.offsetWidth - 20
          : anchorEl.offsetWidth
        : null
    );
  }, [anchorEl, canSelectAll]);

  const icon = (option: any) =>
    isMarketplace ? (
      <MarketplaceIcon market={option} />
    ) : isCountry ? (
      <CountryLogo code={option} />
    ) : (
      option
    );

  const selectedOption = (option: any, sideText?: string) => (
    <FlexGrid item>
      {isDisplayIcons && <IconContainer>{icon(option)}</IconContainer>}
      <OptionText
        variant="body2"
        noWrap
        $maxWidth={maxWidth}
        $condensed={condensed}
        $forceLowercase={forceLowercase}
      >
        {isMarketplace ? getMarketplaceName(t, option) : option}
        {sideText}
      </OptionText>
    </FlexGrid>
  );

  const changeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setValues(options);
      setChecked(options);
    } else {
      setValues([]);
      setChecked([]);
    }
  };

  const renderAvailableOptions = (currentOptions: any[]) =>
    !isEmpty(currentOptions) &&
    currentOptions.map((option: any, index: number) => {
      const isSelected = checked.includes(option);

      return (
        <FilterMenuItem
          key={option + index}
          $width={menuWidth}
          dense={condensed}
          $canSelectAll={canSelectAll}
        >
          <ControlLabel
            control={
              <Checkbox
                checked={isSelected}
                name={option}
                size="small"
                color="primary"
                onChange={(e) => handleChange(e)}
              />
            }
            label={selectedOption(option)}
          />
        </FilterMenuItem>
      );
    });

  return (
    <RightAlignedGrid
      container
      item
      xs={12}
      direction="column"
      wrap="nowrap"
      $isFullWidth={isFullWidth}
    >
      <Hidden smDown>
        <Box display="flex" width="100%" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {title}
          </Typography>
        </Box>
      </Hidden>
      <FilterSelectBase
        $maxWidth={maxWidth}
        onClick={openMenu}
        id="filter-select"
        data-cy="filter-select"
        theme={theme}
        $condensed={condensed}
        disabled={disabled}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
        >
          {isDisplayIcons && (
            <>{isCountry ? <FlagWrapper /> : <StoreWrapper />}</>
          )}
          <Grid container item xs alignItems="flex-end">
            {isEmpty(checked) ? (
              <OptionText
                theme={theme}
                variant="body2"
                $forceLowercase={forceLowercase}
              >
                {filterTitle}
              </OptionText>
            ) : (
              <SelectedOptionTypography $maxWidth={maxWidth}>
                {selectedOption(
                  checked[0],
                  Boolean(othersLength) ? `, ${othersLength}+` : undefined
                )}
              </SelectedOptionTypography>
            )}
          </Grid>
          <StyledArrowDropDown />
        </Grid>
      </FilterSelectBase>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        marginThreshold={1}
        onClose={onClose}
      >
        {canSelectAll && (
          <FilterMenuItem key="select-all" $width={menuWidth} dense={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  size="small"
                  color="primary"
                  onChange={changeSelectAll}
                />
              }
              label={t("generic.selectAll", { count: options.length })}
            />
          </FilterMenuItem>
        )}
        {searchable && setSearchText && (
          <FilterMenuItem key="search" $width={menuWidth} dense={condensed}>
            <SearchFilter setSearchText={setSearchText} />
          </FilterMenuItem>
        )}
        {renderAvailableOptions(options)}
      </Menu>
    </RightAlignedGrid>
  );
});

export default FilterSelect;
