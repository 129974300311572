import {
  AMCAdditionalFieldType,
  PrunedAMCReportType,
} from "~/typedef/amc/reportType";

import { AMCAdditionalFieldValues } from "~/typedef/amc/scheduledReport";
import AMCAsinField from "./amcAsinField";
import AMCCampaignGroupField from "./amcCampaignGroupField";
import AMCCampaignsField from "./amcCampaignsField";
import { CurrentStore } from "~/typedef/store";
import { Grid } from "@material-ui/core";
import React from "react";

interface AdditionalFieldsRendererProps {
  reportType: PrunedAMCReportType;
  store: CurrentStore;
  onChange: (fieldData: AMCAdditionalFieldValues) => void;
  values: AMCAdditionalFieldValues;
}

const AdditionalFieldsRenderer: React.FC<AdditionalFieldsRendererProps> = ({
  reportType,
  store,
  onChange,
  values,
}) => {
  if (
    !reportType.additionalFields ||
    Object.keys(reportType.additionalFields).length === 0
  ) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {Object.entries(reportType.additionalFields).map(
        ([fieldName, fieldConfig]) => {
          switch (fieldConfig.type) {
            case AMCAdditionalFieldType.CAMPAIGN_GROUP:
              return (
                <Grid key={fieldName} item xs={12}>
                  <AMCCampaignGroupField
                    label={fieldConfig.label}
                    required={fieldConfig.required}
                    tooltip={fieldConfig.tooltip}
                    values={values} // we pass all values to the input, and the input is responsible for working out what it needs
                    onChange={onChange}
                    store={store}
                    maxSelections={fieldConfig.maxSelections}
                  />
                </Grid>
              );
            case AMCAdditionalFieldType.ASIN:
              return (
                <Grid key={fieldName} item xs={12}>
                  <AMCAsinField
                    name={fieldName}
                    label={fieldConfig.label}
                    required={fieldConfig.required}
                    tooltip={fieldConfig.tooltip}
                    values={values}
                    onChange={onChange}
                    placeholder={fieldConfig.placeholder}
                  />
                </Grid>
              );

            case AMCAdditionalFieldType.CAMPAIGN:
              return (
                <Grid key={fieldName} item xs={6}>
                  <AMCCampaignsField
                    name={fieldName}
                    label={fieldConfig.label}
                    required={fieldConfig.required}
                    tooltip={fieldConfig.tooltip}
                    values={values} // we pass all values to the input, and the input is responsible for working out what it needs
                    onChange={onChange}
                    store={store}
                    maxSelections={fieldConfig.maxSelections}
                    filter={fieldConfig.filter}
                  />
                </Grid>
              );

            default:
              return null;
          }
        }
      )}
    </Grid>
  );
};

export default AdditionalFieldsRenderer;
