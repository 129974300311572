import {
  ChartData,
  Filter,
  Range,
  SalesPerformanceMetrics,
} from "~/typedef/store";

import { DATETIME_PERIODS } from "../utils/dateTimeUtils";
import { User } from "~/typedef/user";
import { api } from "@store/apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface FetchSalesPerformanceArgs {
  mid?: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  filter?: Filter;
  showPendingOrders?: boolean;
  includeTax: boolean;
  currency: string;
  vendorRevenueType?: "orderedRevenue" | "shippedRevenue";
  includeVendor?: boolean;
  isOrderItemsChart?: boolean;
}

interface FetchSalesPerformanceResponse {
  chartData: ChartData;
  current: SalesPerformanceMetrics;
  prior: SalesPerformanceMetrics;
  currency: string;
  summaryPriorRange: {
    fromDate: number; // unix timestamp
    toDate: number; // unix timestamp
  };
  summaryCurrentRange: {
    fromDate: number; // unix timestamp
    toDate: number; // unix timestamp
  };
}

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    salesPerformance: build.query<
      FetchSalesPerformanceResponse,
      FetchSalesPerformanceArgs
    >({
      query: (params) => {
        const { currentRange, isOrderItemsChart, ...otherParams } = params;
        return {
          url: isOrderItemsChart
            ? `${baseUrl}/api/generic-mws-service/api/orderItemsPerformance`
            : `${baseUrl}/api/generic-mws-service/api/salesPerformance`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const { useSalesPerformanceQuery } = extendedApiSlice;
