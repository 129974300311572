import {
  BlackTooltip,
  formatTooltipLabel,
} from "../chartUtils/chartComponents";
import { Box, Typography } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { PercentageChange, SuccessDirection } from "../percentageChange";
import React, { memo } from "react";

import { AttainmentOrLimit } from "../attainmentOrLimit";
import { CurrencyRate } from "~/typedef/store";
import { CurveType } from "recharts/types/shape/Curve";
import { LegendIndicator } from "./multiBarLineChartLegend";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";

export interface PlotProps {
  key: string;
  colour: string;
  negativeColour?: string;
  toFixed?: number;
  isCurrency?: boolean;
  type?: CurveType;
  unit?: string;
  legendKey?: string;
  tooltipKey?: string;
  axis: "1" | "2";
}

export interface TooltipPercentageChange {
  dataKey1: string;
  dataKey2: string;
  labelKey?: string;
  isPptChange?: boolean;
  successDirection?: SuccessDirection;
  conditionalFormatting?: boolean;
  toFixedDigits?: number;
  noUnit?: boolean;
}

export interface TooltipAttainment {
  labelKey?: string;
  currentValueKey: string;
  thresholdValueKey: string;
  conditionalFormatting?: boolean;
  reverseFormatting?: boolean;
  lowThreshold?: number; // Typically 0-100
  midThreshold?: number; // Typically 0-100
  highThreshold?: number; // Typically 0-100
}

interface TooltipData {
  [key: string]: number;
}

interface MultiBarLineChartTooltipProps {
  active?: boolean;
  payload?: { payload: TooltipData }[];
  label?: number;
  currentPeriod: DATETIME_PERIODS;
  currentCurrency: string;
  currencyRates: CurrencyRate[];
  interval: INTERVAL;
  timezone: string;
  bars: PlotProps[];
  lines: PlotProps[];
  percentageChange?: TooltipPercentageChange[];
  attainment?: TooltipAttainment[];
}

export const MultiBarLineChartTooltip = memo<MultiBarLineChartTooltipProps>(
  function MultiBarLineChartTooltip({
    active,
    payload,
    label,
    currentPeriod,
    currentCurrency,
    currencyRates,
    interval,
    timezone,
    bars,
    lines,
    percentageChange,
    attainment,
  }) {
    const { t } = useTranslation();
    if (active) {
      const date = formatTooltipLabel(label, currentPeriod, interval, timezone);
      const tooltipData = payload?.length ? payload[0].payload : null;
      const { endTime } = tooltipData || {};
      const endDate = endTime
        ? formatTooltipLabel(endTime, currentPeriod, interval, timezone)
        : null;

      const renderTooltipRow = (
        plot: PlotProps,
        type: "bar" | "line",
        index: number
      ) => {
        return (
          <Box
            key={`${type}-${index}`}
            display="flex"
            mt={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" mr={1}>
              <LegendIndicator
                shape={type}
                fill={
                  tooltipData &&
                  typeof tooltipData?.[plot.key] === "number" &&
                  tooltipData[plot.key] < 0
                    ? plot.negativeColour ?? plot.colour
                    : plot.colour
                }
              />
              <Typography variant="body2">
                {t(plot.tooltipKey ?? `chartKeys.${plot.key}`)}:
              </Typography>
            </Box>
            <Typography variant="body2">
              {tooltipData && typeof tooltipData?.[plot.key] === "number"
                ? plot.isCurrency
                  ? formatCurrencyRounded(
                      tooltipData[plot.key],
                      currencyRates,
                      currentCurrency,
                      currentCurrency
                    )
                  : (Number.isFinite(plot.toFixed)
                      ? numberWithCommas(
                          tooltipData[plot.key].toFixed(plot.toFixed)
                        )
                      : numberWithCommas(tooltipData[plot.key])) +
                    (plot.unit ?? "")
                : "-"}
            </Typography>
          </Box>
        );
      };
      const barsWithoutForecast = bars.map((bar) => {
        if (!bar.key.includes("Target")) {
          return bar;
        }
      }) as PlotProps[];

      const linesWithoutForecast = lines.map((line) => {
        if (!line.key.includes("Target")) {
          return line;
        }
      }) as PlotProps[];

      const barsWithForecast = bars.map((bar) => {
        if (bar.key.includes("Target")) {
          return bar;
        }
      }) as PlotProps[];

      const linesWithForecast = lines.map((line) => {
        if (line.key.includes("Target")) {
          return line;
        }
      }) as PlotProps[];

      return (
        <BlackTooltip>
          <Box p={1}>
            <Typography variant="body2">{date}</Typography>
            {endDate && <Typography variant="body2"> - {endDate}</Typography>}

            {barsWithoutForecast.map(
              (bar, index) => bar && renderTooltipRow(bar, "bar", index)
            )}
            {linesWithoutForecast.map(
              (line, index) => line && renderTooltipRow(line, "line", index)
            )}

            {percentageChange &&
              percentageChange.map((percentageChange, index) => {
                const { dataKey1, dataKey2, ...otherPercentageChangeProps } =
                  percentageChange;
                return tooltipData &&
                  typeof tooltipData?.[dataKey1] === "number" &&
                  typeof tooltipData?.[dataKey2] === "number" ? (
                  <Box
                    key={`change-${index}`}
                    display="flex"
                    mt={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2">
                      {t(percentageChange.labelKey ?? "chartKeys.change")}:
                    </Typography>
                    <PercentageChange
                      {...{
                        current: tooltipData[dataKey1],
                        prior: tooltipData[dataKey2],
                        ...otherPercentageChangeProps,
                      }}
                    />
                  </Box>
                ) : null;
              })}

            {barsWithForecast.map(
              (bar, index) => bar && renderTooltipRow(bar, "bar", index)
            )}
            {linesWithForecast.map(
              (line, index) => line && renderTooltipRow(line, "line", index)
            )}

            {attainment &&
              attainment.map((attainment, index) => {
                const {
                  currentValueKey,
                  thresholdValueKey,
                  labelKey,
                  ...otherAttainmentProps
                } = attainment;

                const currentValue =
                  tooltipData &&
                  typeof tooltipData?.[currentValueKey] === "number"
                    ? tooltipData[currentValueKey]
                    : null;
                const thresholdValue =
                  tooltipData &&
                  typeof tooltipData?.[thresholdValueKey] === "number"
                    ? tooltipData[thresholdValueKey]
                    : null;

                return currentValue && thresholdValue ? (
                  <Box
                    key={`attainment-${index}`}
                    display="flex"
                    mt={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2">
                      {t(labelKey ?? "chartKeys.attainment")}:
                    </Typography>
                    <AttainmentOrLimit
                      {...{
                        currentValue,
                        thresholdValue,
                        ...otherAttainmentProps,
                      }}
                    />
                  </Box>
                ) : null;
              })}
          </Box>
        </BlackTooltip>
      );
    }
    return null;
  }
);

export default MultiBarLineChartTooltip;
