import Panel, { FooterLink } from "~/components/panel/panel";
import React, { ReactChild, memo, useMemo } from "react";
import { formatNumber, getPercentageDifference } from "~/utils/salesUtils";

import { Box } from "@material-ui/core";
import { CategoryCell } from "~/components/table/cells/categoryCell";
import { CellProps } from "recharts";
import NoScrollTable from "~/components/table/table";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { Range } from "~/typedef/store";
import Table from "~/components/table/table";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import { numberWithCommas } from "~/utils/utils";
import { useSalesByCategoryQuery } from "../../store/mystore/salesByCategory.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

interface SalesByCategoryProps {
  mid: string;
  currentPeriod: string;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: FooterLink;
  actions?: ReactChild;
  condensed?: boolean;
  report?: boolean;
  pageSize?: number;
  timezone?: string;
  includeTax: boolean;
  conditionalFormatting?: boolean;
}

const SalesByCategoryTable = memo<SalesByCategoryProps>(
  function SalesByCategoryTable({
    mid,
    condensed,
    currentPeriod,
    currentRange,
    currentCurrency,
    footerLink,
    actions,
    report,
    pageSize,
    includeTax,
    timezone,
    conditionalFormatting,
  }) {
    const CONDENSED_ROWS = 5;
    const { t } = useTranslation();
    const isVendorStore = useVendorStore(mid);
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const { salesByCategory, salesByCategoryFetching } =
      useSalesByCategoryQuery(
        {
          mid,
          currentPeriod,
          currentRange,
          includeTax,
          timezone,
        },
        {
          skip: !mid,
          selectFromResult: ({ data, isFetching }) => {
            return {
              salesByCategoryFetching: isFetching,
              salesByCategory: (data || []).map((category) => {
                const { current, prior } = category;
                const currentTotalSales = parseFloat(current.totalSales);
                const currentQuantity = parseFloat(current.quantity);
                const priorTotalSales = prior
                  ? parseFloat(prior.totalSales)
                  : 0;
                const priorQuantity = prior ? parseFloat(prior.quantity) : 0;
                const {
                  parent4CategoryLabel,
                  parent3CategoryLabel,
                  parent2CategoryLabel,
                  parentCategoryLabel,
                  categoryLabel,
                } = current;
                return {
                  category: {
                    current: categoryLabel || t("generic.notSpecified"),
                    parent:
                      parent4CategoryLabel ||
                      parent3CategoryLabel ||
                      parent2CategoryLabel ||
                      parentCategoryLabel
                        ? `${parent4CategoryLabel || ""}${
                            parent3CategoryLabel
                              ? `${
                                  parent4CategoryLabel ? " > " : ""
                                }${parent3CategoryLabel}`
                              : ``
                          }${
                            parent2CategoryLabel
                              ? `${
                                  parent3CategoryLabel ? " > " : ""
                                }${parent2CategoryLabel}`
                              : ``
                          }${
                            parentCategoryLabel
                              ? `${
                                  parent2CategoryLabel ? " > " : ""
                                }${parentCategoryLabel}`
                              : ``
                          }`
                        : undefined,
                  },
                  priorPeriod: prior
                    ? formatCurrencyRounded(
                        prior.totalSales,
                        currencyRates,
                        prior.currency,
                        currentCurrency
                      )
                    : "N/A",
                  thisPeriod: {
                    value: formatCurrencyRounded(
                      current.totalSales,
                      currencyRates,
                      current.currency,
                      currentCurrency
                    ),
                    growth: prior
                      ? getPercentageDifference(
                          current.totalSales,
                          prior.totalSales
                        )
                      : "N/A",
                    conditionalFormatting: conditionalFormatting,
                  },
                  units: {
                    value: numberWithCommas(current.quantity),
                    growth: prior
                      ? getPercentageDifference(
                          current.quantity,
                          prior.quantity
                        )
                      : "N/A",
                    conditionalFormatting: conditionalFormatting,
                  },
                  avgUnit: {
                    value: formatCurrencyRounded(
                      currentTotalSales / currentQuantity,
                      currencyRates,
                      current.currency,
                      currentCurrency
                    ),
                    growth: prior
                      ? getPercentageDifference(
                          currentTotalSales / currentQuantity,
                          priorTotalSales / priorQuantity
                        )
                      : "N/A",
                    conditionalFormatting: conditionalFormatting,
                  },
                  avgOrder: {
                    value: current.orderCount
                      ? numberWithCommas(
                          formatNumber(currentQuantity / current.orderCount)
                        )
                      : "N/A",
                    growth:
                      prior && current.orderCount && prior.orderCount
                        ? getPercentageDifference(
                            currentQuantity / current.orderCount,
                            priorQuantity / prior.orderCount
                          )
                        : "N/A",
                    conditionalFormatting: conditionalFormatting,
                  },
                };
              }),
            };
          },
        }
      );

    const columns = useMemo(
      () => [
        {
          id: "category",
          Header: t("myStoresWidget.salesByCategory.categoryColumn"),
          accessor: "category",
          Cell: (props: CellProps) => (
            <CategoryCell {...{ ...props, report }} />
          ),
          colSpan: 3,
          ...(report
            ? {}
            : {
                customWidth: 250,
              }),
        },
        ...(!condensed && !report
          ? [
              {
                id: "priorPeriod",
                Header: t("myStoresWidget.salesByCategory.priorPeriodColumn"),
                accessor: "priorPeriod",
                Cell: ValueCell,
                hiddenDown: report ? "md" : "sm",
                align: "right",
              },
            ]
          : []),
        {
          id: "thisPeriod",
          Header: t("myStoresWidget.salesByCategory.thisPeriodColumn"),
          accessor: "thisPeriod",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "units",
          Header: t("myStoresWidget.salesByCategory.unitsColumn"),
          accessor: "units",
          Cell: ValueAndGrowthCell,
          align: "right",
        },
        {
          id: "avgUnit",
          Header: t("myStoresWidget.salesByCategory.avgUnitColumn"),
          accessor: "avgUnit",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "md" : "sm",
        },
        ...(!condensed
          ? [
              {
                id: "avgOrder",
                Header: t("myStoresWidget.salesByCategory.avgOrderColumn"),
                accessor: "avgOrder",
                Cell: ValueAndGrowthCell,
                align: "right",
                hiddenDown: report ? "md" : "sm",
              },
            ]
          : []),
      ],
      []
    );

    return (
      <Panel
        id="widget-sales-by-category"
        title={t(
          `myStoresWidget.${
            isVendorStore ? "poCategory" : "salesByCategory"
          }.mainTitle`
        )}
        footerLink={footerLink}
        tooltip={undefined}
        actions={actions}
        content={
          salesByCategoryFetching || !mid ? (
            <PanelLoading />
          ) : condensed || report ? (
            <NoScrollTable
              columns={columns}
              data={
                report
                  ? salesByCategory
                  : salesByCategory.slice(0, CONDENSED_ROWS)
              }
              pageSize={pageSize}
              loading={salesByCategoryFetching}
              isReport={report}
            />
          ) : (
            <Box>
              <Table
                columns={columns}
                data={salesByCategory}
                gridLayoutColumns={12}
                pageSize={pageSize ?? 10}
                pagination={true}
                isReport={report}
              />
            </Box>
          )
        }
      />
    );
  }
);

export default SalesByCategoryTable;
