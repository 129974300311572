import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { KeyboardEvent, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { InlineIconButton } from "~/icons/inlineIconButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TagsContainer = styled(Box)<{ theme: Theme }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const Tag = styled(Box)<{ theme: Theme }>`
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5, 1.5)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

const CloseButton = styled(IconButton)`
  padding: 2px;
  margin-left: 6px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 14px;
  color: inherit;
`;

interface TagInputProps {
  label: string;
  required?: boolean;
  tooltip?: string;
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
}

const TagInput: React.FC<TagInputProps> = ({
  label,
  required,
  tooltip,
  values,
  onChange,
  placeholder,
  error,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    if (values.includes(trimmedValue)) return;

    onChange([...values, trimmedValue]);
    setInputValue("");
  };

  const removeTag = (tagToRemove: string) => {
    onChange(values.filter((tag) => tag !== tagToRemove));
  };

  return (
    <FormControl fullWidth>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="subtitle1" color="textSecondary">
          {t(label)} {required && <span>*</span>}
        </Typography>
        {tooltip && (
          <Tooltip title={t(tooltip)}>
            <InlineIconButton />
          </Tooltip>
        )}
      </Box>
      <TextField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder ? t(placeholder) : ""}
        error={!!error}
        helperText={error}
        fullWidth
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={addTag}
                disabled={!inputValue.trim() || disabled}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {values.length > 0 && (
        <TagsContainer>
          {values.map((tag) => (
            <Tag key={tag}>
              {tag}
              {!disabled && (
                <CloseButton size="small" onClick={() => removeTag(tag)}>
                  <StyledCloseIcon />
                </CloseButton>
              )}
            </Tag>
          ))}
        </TagsContainer>
      )}
    </FormControl>
  );
};

export default TagInput;
