import { Box, useMediaQuery } from "@material-ui/core";
import {
  CampaignPaginationParams,
  useMarketingCampaignsQuery,
} from "~/store/mystore/marketing.redux";
import { FooterLink, Panel } from "~/components/panel/panel";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
} from "~/utils/currencyUtils";

import ACOSCell from "~/components/table/cells/acosCell";
import { LinkCell } from "~/components/table/cells/linkCell";
import { Range } from "~/typedef/store";
import StatusCell from "~/modules/marketing/statusCell";
import Table from "~/components/table/table";
import { User } from "~/typedef/user";
import { ValueCell } from "~/components/table/cells/valueCell";
import { formatAdType } from "~/modules/marketing/commonColumns";
import get from "lodash/get";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

interface CampaignPerformanceProps {
  userInfo: User;
  mid: string;
  countryCode: string;
  marketplaceType: string;
  marketplaceSubtype: string;
  currentPeriod: string;
  currentRange: Range;
  currentCurrency: string;
  footerLink?: FooterLink;
  report?: boolean;
  pageSize?: number;
  conditionalFormatting?: boolean;
}

const CampaignPerformance = memo<CampaignPerformanceProps>(
  function CampaignPerformance({
    userInfo,
    mid,
    countryCode,
    marketplaceType,
    marketplaceSubtype,
    currentRange,
    currentCurrency,
    footerLink,
    report,
    pageSize,
    conditionalFormatting,
  }) {
    const { t } = useTranslation();

    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const theme = useTheme();
    const onMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const [paginationParams, setPaginationParams] =
      useState<CampaignPaginationParams>({
        pageSize: PAGE_SIZE,
        pageIndex: 0,
        sortKey: "attributed_sales",
        sortDesc: true,
      });

    const columns = useMemo(
      () => [
        {
          Header: t("advertisingDashboardWidget.adTable.nameColumn"),
          id: "campaign_name",
          accessor: (row: any) => ({
            value: row.campaign_name,
            link: {
              pathname: `/mystores/${marketplaceSubtype}/marketingCampaignAdGroups`,
              search: `?store=${encodeURI(mid)}`,
              state: {
                campaignId: row.campaign_id,
                campaignName: row.campaign_name,
              },
            },
            subtitle: formatAdType(row.ad_type, t),
          }),
          Cell: (props: any) => <LinkCell {...props} />,
          isVisible: true,
          isLocked: true,
          colSpan: 5,
          customWidth: "100%",
        },
        {
          Header: t("advertisingDashboardWidget.adTable.statusColumn"),
          id: "campaign_status",
          accessor: (row: any) => ({
            value: row.campaign_status,
          }),
          Cell: (props: any) => <StatusCell {...props} />,
          isVisible: true,
          align: "center",
          colSpan: 1,
          customWidth: 100,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.salesColumn"),
          id: "attributed_sales",
          accessor: (row: any) =>
            row.attributed_sales
              ? formatCurrency(
                  row.attributed_sales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          isVisible: true,
          customWidth: 100,
        },
        ...(!onMobile
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.spendColumn"),
                id: "cost",
                accessor: (row: any) =>
                  row.cost
                    ? formatCurrency(
                        row.cost,
                        currencyRates,
                        homeCurrency,
                        currentCurrency
                      )
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
                customWidth: 100,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.acosColumn"),
                id: "acos",

                accessor: (row: any) => {
                  return {
                    value: row.acos ? `${row.acos.toFixed(2)}%` : "-",
                    conditionalFormatting: conditionalFormatting,
                  };
                },
                align: "right",
                Cell: ACOSCell,
                isVisible: true,
                sortDescFirst: true,
                customWidth: 100,
              },
            ]
          : []),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        marketplaceSubtype,
        mid,
        currencyRates,
        currentCurrency,
        homeCurrency,
        onMobile,
      ]
    );

    const EMPTY_SEARCH = "";

    const { campaigns, loading } = useMarketingCampaignsQuery(
      {
        customerId: userInfo._id,
        mid,
        countryCode,
        marketplaceType,
        marketplaceSubtype,
        searchText: EMPTY_SEARCH,
        reportDateFrom: currentRange.fromDate,
        reportDateTo: currentRange.toDate,
        ...paginationParams,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          campaigns: data?.data,
          loading: isFetching,
        }),
      }
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy?.[0]?.id || "attributed_sales",
        sortDesc: sortBy?.[0]?.desc ?? true,
        pageIndex,
        pageSize,
      });
    }, []);

    return (
      <Panel
        id="widget-marketing-campaign-summary"
        title={t("advertisingDashboardWidget.campaignPerformance")}
        tooltip={t("advertisingDashboardWidget.campaignPerformanceTooltip")}
        footerLink={footerLink}
        content={
          <Box>
            <Table
              {...{
                columns,
                data: campaigns ?? [],
                fetchData,
                loading,
                pageSize: pageSize ?? PAGE_SIZE,
                gridLayoutColumns: 6,
                isReport: report,
              }}
            />
          </Box>
        }
      />
    );
  }
);

export default CampaignPerformance;
