import React, { memo, useMemo } from "react";

import { AmazonOffer } from "~/typedef/amazon-offer";
import { AmazonProductData } from "~/typedef/amazon-product";
import { Column } from "~/components/adTable/columnSelect";
import EditablePriceCell from "./editablePriceCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { Money } from "~/typedef/money";
import PrimeIcon from "~/img/amazon_prime.png";
import SellerLinkCell from "./sellerLinkCell";
import { SimpleAvailabilityCell } from "~/components/table/cells/simpleAvailabilityCell";
import StatusCell from "./statusCell";
import Table from "~/components/table/table";
import { Tooltip } from "@material-ui/core";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getCurrencySymbol } from "~/utils/currencyUtils.js";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SmallIcon = styled.img`
  position: absolute;
  bottom: 2px;
  left: 32px;
  height: 18px;
`;

const formatPrice = (price?: Money) => {
  if (price && price.CurrencyCode && price.Amount) {
    return `${
      getCurrencySymbol[price.CurrencyCode as keyof typeof getCurrencySymbol]
    }${price.Amount}`;
  }
  return "-";
};

const formatDifference = (difference?: number) => {
  if (difference) {
    const sign = difference > 0 ? "+" : "";
    return `${sign}${difference}%`;
  }
  return "-";
};

interface ProductTableProps {
  filter: string;
  data: AmazonProductData[];
  fetchData: ({
    pageSize,
    pageIndex,
    sortBy,
  }: {
    pageSize: number;
    pageIndex: number;
    sortBy: string;
  }) => void;
  loading: boolean;
  pageCount: number;
  pageSize: number;
  handleListingSelect: (offer: AmazonProductData) => void;
  dataVersion: number;
}

const ProductTableComponent: React.FC<ProductTableProps> = ({
  filter,
  data,
  fetchData,
  loading,
  pageCount,
  pageSize,
  handleListingSelect,
  dataVersion,
}) => {
  const { t } = useTranslation();
  const columns: Column[] = useMemo(
    () => [
      {
        Header: t("myStoresWidget.buyBoxPerformance.productColumn"),
        id: "title",
        accessor: (row: AmazonProductData) => ({
          value: row.title,
          secondRowValue: `ASIN: ${row.asin} | SKU: ${row.sellerSku}`,
          image: row.imageUrl,
          link: row.linkUrl,
          target: "_blank",
          icon: row.status.isFulfilledByAmazon ? (
            <Tooltip title={t("generic.fulfilledByAmazon")}>
              <SmallIcon src={PrimeIcon} />
            </Tooltip>
          ) : null,
        }),
        Cell: (
          props: any // this type should be imported from the linkAndImageCell component (an interface should be exported from that other file once converted to typescript)
        ) => <LinkAndImageCell {...props} colorVariant="external" />,
        customWidth: 300,
        colSpan: 3,
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.winnerColumn"),
        id: "status",
        accessor: "status",
        Cell: StatusCell,
        align: "center",
        customWidth: 80,
        hiddenDown: "xs",
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.availabilityColumn"),
        id: "quantity",
        accessor: "quantity",
        Cell: SimpleAvailabilityCell,
        align: "center",
        customWidth: 80,
        hiddenDown: "xs",
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.sellersColumn"),
        id: "sellers",
        accessor: "sellers.length",
        Cell: SellerLinkCell,
        align: "right",
        customWidth: 80,
        getProps: () => ({ handleListingSelect }),
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.buyBoxPriceColumn"),
        id: "buyBoxPrice",
        accessor: (row: AmazonProductData) => formatPrice(row.buyBoxPrice),
        Cell: ValueCell,
        align: "right",
        hiddenDown: "sm",
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.yourPriceColumn"),
        id: "yourPrice",
        accessor: "yourPrice.Amount",
        Cell: EditablePriceCell,
        colSpan: 2,
        align: "right",
        customWidth: 100,
        getProps: (row: AmazonProductData) => ({
          currencyCode: row.yourPrice && row.yourPrice.CurrencyCode,
          sellerSku: row.sellerSku,
          title: row.title,
          imageUrl: row.imageUrl,
          isFulfilledByAmazon: row.status.isFulfilledByAmazon,
          originalPrice: row.yourPrice && row.yourPrice.Amount,
          isPriceDirty: row.status.isPriceDirty,
        }),
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.differenceColumn"),
        id: "difference",
        accessor: (row: AmazonProductData) => formatDifference(row.difference),
        Cell: ValueCell,
        align: "right",
        hiddenDown: "md",
      },
      {
        Header: t("myStoresWidget.buyBoxPerformance.suggestedPriceColumn"),
        id: "suggestedPrice",
        accessor: (row: AmazonProductData) => formatPrice(row.suggestedPrice),
        Cell: ValueCell,
        align: "right",
        hiddenDown: "md",
      },
    ],
    []
  );

  return (
    <Table
      filter={filter}
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      sorting={true}
      pagination={true}
      pageCount={pageCount}
      pageSize={pageSize}
      dataVersion={dataVersion}
    />
  );
};

const ProductTable = memo(ProductTableComponent);

export default ProductTable;
