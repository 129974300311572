import {
  Cell,
  ColumnInstance,
  Row,
  TableOptions,
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { CircularProgress, Typography } from "@material-ui/core";
import React, { ReactNode, memo, useEffect } from "react";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { DisconnectedStoreRow } from "../table/rows/disconnectedStore";
import { IndeterminateCheckbox } from "./indeterminateCheckbox";
import Pagination from "../table/tablePagination";
import { Column as TableColumn } from "~/components/adTable/columnSelect";
import styled from "styled-components";
import { useSticky } from "react-table-sticky";
import { useTranslation } from "react-i18next";

const ScrollStyles = styled.div`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) =>
    `${theme.palette.table.text.header} ${theme.palette.table.header}`};

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.table.header};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.border.main};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.palette.table.header};
  }
`;

const DownIcon = styled(ArrowDropDownIcon)`
  height: 20px;
  margin: -5px;
`;

const UpIcon = styled(ArrowDropUpIcon)`
  height: 20px;
  margin: -5px;
`;

const TypographyNoWrap = styled(Typography)`
  white-space: nowrap;
`;

const ScrollContainer = styled(ScrollStyles)<{ disableScroll?: boolean }>`
  ${({ disableScroll }) => (!disableScroll ? `overflow-x: scroll;` : null)}
  width: 100%;
`;

const TopScrollContainer = styled(ScrollStyles)`
  overflow-x: scroll;
  width: 100%;
`;

const TopScrollBar = styled.div`
  height: 0.1px; //smallest height possible to show scroll
  width: 100%;
`;

interface TableStyles {
  tdHeight?: string | number;
}

const Styles = styled.div<TableStyles>`
  display: block;
  width: 100%;
  height: 100%;
  ${({ theme }) => `
        ${theme.breakpoints.down("sm")} {
          height: auto;
        }
     `}
  table {
    width: 100%;
    border-spacing: 0;
    font-size: 0.75rem;
    font-weight: 400;
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
    overflow: hidden;
    tbody {
      height: 100%;
    }
    thead {
      background-color: ${({ theme }) => theme.palette.table.header};
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.palette.table.text.header};
      ${({ theme }) => `
        ${theme.breakpoints.down("xs")} {
          font-size: 0.75rem;
        }
     `}
    }
    tr {
      border-bottom: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
      &:last-of-type {
        border-bottom: none;
      }
    }
    th,
    td {
      padding: 0 1rem;
      height: ${({ tdHeight }) => (tdHeight ? tdHeight : "45")}px;
      align-items: center;
      position: relative;
    }
    &.sticky {
      overflow: scroll;

      th {
        background-color: ${({ theme }) => theme.palette.table.header};
      }

      td {
        background-color: ${({ theme }) => theme.palette.background.paper};
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        border-right: 2px solid #e5e5e5;
      }

      [data-sticky-first-right-td] {
        border-left: 2px solid #e5e5e5;
      }
    }
  }

  .pagination {
    border-top: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
    padding: 0.5rem;
  }
`;

interface TableHeaderStyles {
  width: number;
  maxWidth?: number;
  divideRight?: boolean;
  whiteSpace?: string;
}

const Header = styled.th<TableHeaderStyles>`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  min-width: ${({ width }) => (width ? `${width}px` : "inherit")};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "inherit")};
  white-space: ${({ whiteSpace }) => whiteSpace || "nowrap"};
  word-break: keep-all;
  border-right: ${({ divideRight }) =>
    divideRight ? `2px solid #e5e5e5` : `none`};
`;

interface TableCellStyles {
  divideRight?: boolean;
}

const CustomCell = styled.td<TableCellStyles>`
  justify-content: ${({ align }) =>
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start"};
  text-align: ${({ align }) => align || "left"};
  min-width: ${({ width }) => (width ? `${width}px` : "inherit")};
  word-break: keep-all;
  border-right: ${({ divideRight }) =>
    divideRight ? `2px solid #e5e5e5` : `none`};
`;

const NoDataRow = styled.tr`
  display: flex;
  height: 100%;
`;

const NoData = styled.td`
  min-height: 50px;
  height: calc(100% - 2rem) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

const headerWrapper = (
  header: ReactNode,
  wrapHeaderText: boolean
): ReactNode => {
  // Header can potentially be JSX
  if (typeof header !== "string") {
    return header;
  }

  // If header is short enough, return as-is
  if (!wrapHeaderText || header.length < 8) {
    return header;
  } else {
    const headerHalfpoint = header.length / 2;
    const headerWords = header.split(" ");

    let acc = 0;
    const distancesToHalfpoint = headerWords.map((word) => {
      acc += word.length + 1;
      return Math.abs(acc - headerHalfpoint);
    });

    let bestSplitIdx = 0;
    for (let idx = 0; idx < distancesToHalfpoint.length; idx++) {
      if (distancesToHalfpoint[idx] < distancesToHalfpoint[bestSplitIdx]) {
        bestSplitIdx = idx;
      }
    }

    return (
      <>
        <div>{headerWords.slice(0, bestSplitIdx + 1).join(" ")}</div>
        <div>
          {headerWords.slice(bestSplitIdx + 1, headerWords.length).join(" ")}
        </div>
      </>
    );
  }
};

export interface TableBaseProps {
  columns: TableColumn[];
  filter?: string;
  data: any[];
  fetchData: (params: {
    pageIndex: number;
    pageSize: number;
    sortBy: { id: any; desc: boolean }[];
    dataVersion?: number;
  }) => void;
  loading: boolean;
  sorting?: boolean;
  pagination: boolean;
  autoPagination?: boolean;
  pageCount: number;
  pageSize: number;
  currentPage?: number;
  renderRowSubComponent?: Function;
  getRowProps?: (row: any) => Record<string, any>;
  getCellProps?: Function;
  // a bit of a hack: increment dataVersion to reload the contents of table by
  // forcing fetchData to fire
  dataVersion?: number;
  hasCheckboxSelect?: boolean;
  onRowSelect?: Function;
  noDataLocizeKey?: string;
  disableScroll?: boolean;
}

type Column = ColumnInstance & {
  align: "left" | "right" | "center" | "justify" | "char" | undefined;
  customWidth: string;
  divideRight?: boolean;
};

type ReactUseTable = Omit<ReturnType<typeof useTable>, "headerGroups"> & {
  page: (Omit<Row, "cells"> & {
    original: Record<string, any>;
    isExpanded: boolean;
    cells: (Cell & { column: Column })[];
  })[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: any[];
  pageCount: number;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  selectedFlatRows: { original: any }[];
  toggleAllRowsExpanded: any;
  state: {
    pageIndex: number;
    selectedRowIds: any[];
    sortBy: {
      id: any;
      desc: boolean;
    }[];
  };
  headerGroups: {
    getHeaderGroupProps: Function;
    headers: {
      align: string;
      customWidth: string;
      maxWidth?: number | string;
      divideRight?: boolean;
      whiteSpace?: string;
      tooltip: string;
      getHeaderProps: Function;
      getSortByToggleProps: Function;
      isSortedDesc: boolean;
      isSorted: boolean;
      render: Function;
      wrapHeaderText: boolean;
    }[];
  }[];
};

const TableBase = memo<TableBaseProps>(function TableBase({
  columns,
  filter,
  data,
  fetchData,
  loading,
  sorting,
  pagination = false,
  autoPagination,
  currentPage,
  pageCount: count,
  pageSize = 10,
  renderRowSubComponent,
  getRowProps = () => ({}),
  getCellProps = () => ({}),
  dataVersion,
  hasCheckboxSelect,
  onRowSelect,
  noDataLocizeKey = "generic.noDataAvailableMessage",
  disableScroll,
}) {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setHiddenColumns,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    toggleAllRowsExpanded,
    state: { pageIndex, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: Boolean(fetchData),
      disableSortRemove: true,
      manualPagination: Boolean(fetchData),
      pageCount: count,
      initialState: {
        sortBy: [],
        pageIndex: 0,
        pageSize,
      } as any,
    } as TableOptions<object>,

    useSortBy,
    useExpanded,
    usePagination,
    useSticky,
    useRowSelect,
    (hooks) => {
      // eslint-disable-next-line no-unused-expressions
      hasCheckboxSelect &&
        hooks.visibleColumns.push((column) => [
          {
            id: "selection",
            // eslint-disable-next-line react/prop-types
            Header: ({
              getToggleAllRowsSelectedProps,
            }: {
              getToggleAllRowsSelectedProps: () => Record<string, any>;
            }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // eslint-disable-next-line react/prop-types
            Cell: ({ row }: { row: any }) => (
              <IndeterminateCheckbox
                {
                  // eslint-disable-next-line react/prop-types
                  ...row.getToggleRowSelectedProps()
                }
              />
            ),
          },
          ...column,
        ]);
    }
  ) as unknown as ReactUseTable;

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize, sortBy, dataVersion });
    }
  }, [fetchData, pageIndex, pageSize, sortBy, dataVersion]);

  useEffect(() => {
    gotoPage(0);
  }, [filter, gotoPage]);

  useEffect(() => {
    const hiddenColumns = columns.reduce<string[]>((acc, column) => {
      if (!column.isVisible && column.id) {
        acc.push(column.id);
      }
      return acc;
    }, []);
    setHiddenColumns(hiddenColumns);
  }, [columns, setHiddenColumns]);

  useEffect(() => {
    if (hasCheckboxSelect && onRowSelect) {
      onRowSelect(selectedFlatRows.map((d) => d.original));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  //Add top scroll that matches bottom scroll
  const topScroll = document.getElementById("topScrollContainer");
  const bottomScroll = document.getElementById("bottomScrollContainer");

  useEffect(() => {
    let scrolling = false;
    const handleTopScroll = (_: Event) => {
      if (topScroll && bottomScroll) {
        if (!scrolling) {
          scrolling = true;
          bottomScroll.scrollLeft = topScroll.scrollLeft;
        } else {
          scrolling = false;
        }
      }
    };
    const handleBottomScroll = (_: Event) => {
      if (topScroll && bottomScroll) {
        if (!scrolling) {
          scrolling = true;
          topScroll.scrollLeft = bottomScroll.scrollLeft;
        } else {
          scrolling = false;
        }
      }
    };
    if (topScroll && bottomScroll) {
      topScroll.onscroll = handleTopScroll;
      bottomScroll.onscroll = handleBottomScroll;
    }
    return () => {
      // cleanup
      topScroll?.removeEventListener("scroll", handleTopScroll);
      bottomScroll?.removeEventListener("scroll", handleBottomScroll);
    };
  }, [bottomScroll, topScroll]);

  const topScrollBar = document.getElementById("topScrollBar");
  const adTable = document.getElementById("adTable");
  const scrollWidth = adTable ? adTable.scrollWidth : undefined;

  useEffect(() => {
    // HACK - add setTimeout(..., 0) so the scroll width retrieved is up to date
    setTimeout(() => {
      if (topScrollBar && adTable && scrollWidth) {
        topScrollBar.setAttribute("style", `width:${adTable.scrollWidth}px`);
      }
    }, 0);
  }, [adTable, topScrollBar, scrollWidth]);

  // Render the UI for your table
  return (
    <ScrollStyles>
      {!disableScroll && (
        <TopScrollContainer id="topScrollContainer">
          <TopScrollBar id="topScrollBar" />
        </TopScrollContainer>
      )}
      <ScrollContainer id="bottomScrollContainer" disableScroll={disableScroll}>
        <table {...getTableProps()} className="table sticky" id="adTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                {headerGroup.headers.map((column) => (
                  <Header
                    key={column}
                    align={column.align}
                    width={column.customWidth}
                    maxWidth={column.maxWidth}
                    divideRight={column.divideRight}
                    whiteSpace={column.whiteSpace}
                    {...column.getHeaderProps(
                      sorting &&
                        column.getSortByToggleProps(
                          column.tooltip
                            ? {
                                title: column.tooltip,
                              }
                            : undefined
                        )
                    )}
                  >
                    {column.isSorted &&
                      (column.isSortedDesc ? <DownIcon /> : <UpIcon />)}
                    {headerWrapper(
                      column.render("Header"),
                      column.wrapHeaderText
                    )}
                  </Header>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {loading ? (
              <NoDataRow>
                <NoData>
                  <CircularProgress />
                </NoData>
              </NoDataRow>
            ) : page.length ? (
              page.map((row, i) => {
                prepareRow(row as unknown as Row);
                const disconnected = row.original.disconnected;

                if (disconnected) {
                  return (
                    <tr {...row.getRowProps(getRowProps(row))}>
                      <DisconnectedStoreRow
                        {...{
                          row,
                          getCellProps,
                        }}
                      />
                    </tr>
                  );
                }
                return (
                  <React.Fragment key={i}>
                    <tr {...row.getRowProps(getRowProps(row))}>
                      {row.cells.map((cell) => (
                        <CustomCell
                          {...{
                            align: cell.column.align,
                            width: cell.column.customWidth,
                            divideRight: cell.column.divideRight,
                            toggleAllRowsExpanded,
                            ...cell.getCellProps(getCellProps(cell)),
                          }}
                        >
                          {cell.render("Cell")}
                        </CustomCell>
                      ))}
                    </tr>
                    {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                    {row.isExpanded && renderRowSubComponent
                      ? renderRowSubComponent({ row })
                      : null}
                  </React.Fragment>
                );
              })
            ) : (
              <NoDataRow>
                <NoData>
                  <TypographyNoWrap align="left">
                    {t(noDataLocizeKey)}
                  </TypographyNoWrap>
                </NoData>
              </NoDataRow>
            )}
          </tbody>
        </table>
      </ScrollContainer>
      {pagination && pageCount > 1 && (
        <Pagination
          {...{
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            pageIndex,
            currentPage, // For auto pagination
            autoPagination,
          }}
        />
      )}
    </ScrollStyles>
  );
});

type TableProps = TableBaseProps & {
  tdHeight?: number | string;
};

const Table = memo<TableProps>(function Table(props) {
  return (
    <Styles tdHeight={props.tdHeight}>
      <TableBase {...props} />
    </Styles>
  );
});

export default Table;
