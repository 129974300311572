import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import Medium from "~/components/typography/medium";
import RaisedButton from "~/components/buttons/raisedButton";
import { baseUrl } from "../../configs";
import { connectOtto } from "~/store/connections/connectOtto.redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import useOttoAuthcodeAuth from "~/hooks/useOttoAuthcodeAuth";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const AccountNameWrapper = styled(TextField)`
  margin-top: 0;
`;

interface OttoConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const OttoConnectPage: React.FC<OttoConnectPageProps> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [shopName, setShopName] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isAdded, setIsAdded] = useState(false);

  const onAuthError = useCallback((error: string) => {
    setError(t("connectWizard.connectionError"));
    console.error(error);
  }, []);

  const onAuthCode = useCallback(
    async (code: string) => {
      try {
        await dispatch(connectOtto(code, shopName));
        setIsAdded(true);
        onSuccess();
      } catch (error) {
        const errorMessage = (error as Error).message || "Unknown Error";
        onAuthError(errorMessage);
      }
    },
    [dispatch, shopName]
  );

  const triggerSignin = useOttoAuthcodeAuth(onAuthCode, onAuthError);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <GridWrapper id="otto-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Medium color="textPrimary" align="center">
            {t("connectWizard.ottoLinkTitle")}
          </Medium>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <LogoWrapper src={`${baseUrl}/static/icons/otto.jpg`} />
        </Grid>
        {!isAdded && (
          <>
            <Grid container item xs={12} justifyContent="center">
              <Box my={1}>
                <Medium color="textPrimary" align="center">
                  {t("connectWizard.ottoInstallInstructions")}
                </Medium>
              </Box>
              <Medium color="textPrimary" align="center">
                {process.env.OTTO_INSTALLATION_LINK && (
                  <a href={process.env.OTTO_INSTALLATION_LINK} target="_blank">
                    {t("connectWizard.ottoInstallationLinkText")}
                  </a>
                )}
              </Medium>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <AccountNameWrapper
                fullWidth
                label={t("connectWizard.shopNameTitle")}
                margin="normal"
                required
                onChange={(e) => {
                  setShopName(e.target.value);
                }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              item
              xs={12}
            >
              <Grid item>
                <RaisedButton color="primary" onClick={handleSignin}>
                  <StyledWrapper>
                    <StyledChildren>
                      {t("connectWizard.connectButton")}
                    </StyledChildren>
                  </StyledWrapper>
                </RaisedButton>
              </Grid>
            </Grid>
          </>
        )}
        {error && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <ErrorMessage color="error">{error}</ErrorMessage>
          </Grid>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default OttoConnectPage;
