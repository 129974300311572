import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

interface ConnectYahooJapanArgs {
  sellerId: string;
  publicKey: string;
  shopName: string;
  authCode: string;
}

interface ConnectYahooJapanResponse {
  errMsg?: string;
  code?: number;
  store?: {
    mid: string;
    marketplaceType: string;
    marketplaceSubtype: string;
    countryCode: string;
    shopName: string;
  };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    connectYahooJapan: build.mutation<
      ConnectYahooJapanResponse,
      ConnectYahooJapanArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/auth/yahooJapan/connect`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler("Yahoo Japan connect", true),
    }),
  }),
});

export const { useConnectYahooJapanMutation } = extendedApiSlice;
