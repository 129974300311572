import { CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import ConfirmDialog from "~/components/dialogs/confirmDialog";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import InputIcon from "@material-ui/icons/Input";
import { LinkCell } from "./linkCell";
import LinkIcon from "@material-ui/icons/Link";
import { get } from "lodash";
import { isFilteredStoreSupported } from "~/utils/marketplaceUtils";
import { useConnectBrandFilterMutation } from "~/store/connections/connectBrandFilter.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ReviewContainer = styled(Grid)`
  min-width: 150px;
`;

const Divider = styled.div`
  height: 50%;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.border.dark};
`;

const SecondRowGridContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
`;

const iconStyles = css`
  fill: ${({ theme }) => theme.palette.grey["500"]};
  width: 1rem;
  height: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;
const StyledEditIcon = styled(EditIcon)`
  ${iconStyles}
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  ${iconStyles}
`;
const StyledLinkIcon = styled(LinkIcon)`
  ${iconStyles}
  fill: ${({ theme }) => theme.palette.primary.main};
`;

const StyledFilterStoreIcon = styled(InputIcon)`
  ${iconStyles}
`;

const FlexGridItem = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
`;

export interface CustomGroupCellProps {
  cell: {
    value: {
      groupId: number;
      groupName: string;
      groupSize: number;
      groupLink?: string;
      groupType: string;
      filteredStoresCount?: number;
    };
  };
  onDelete: ({
    groupId,
    groupName,
  }: {
    groupId: number;
    groupName: string;
  }) => void;
  onEdit: ({
    groupId,
    groupName,
  }: {
    groupId: number;
    groupName: string;
  }) => void;
  report?: boolean;
  isCampaignGroupCell?: boolean;
}

const CustomGroupCell: React.FC<CustomGroupCellProps> = ({
  cell,
  onEdit,
  onDelete,
  report,
  isCampaignGroupCell,
}) => {
  const {
    groupId,
    groupName,
    groupSize,
    groupLink,
    groupType,
    filteredStoresCount = 0,
  } = cell.value;
  const { t } = useTranslation();
  const [showStoreConfirm, setShowStoreConfirm] = useState(false);
  const [connectBrandFilter, { isLoading }] = useConnectBrandFilterMutation();

  const user = useTypedSelector((state) => state.user);

  const currentStore = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  // TODO: Ideally, the campaign group filtered store creation button should create a standalone filtered advertising
  // store, currently that's not how it works. Disabling the filter store creation for campaign group cell for now
  // until we implement the proper behavior.
  const isFilterStoreAvailable =
    !isCampaignGroupCell &&
    currentStore?.marketplace &&
    !user?.roleSettings?.hideStoreConnectionWizard &&
    isFilteredStoreSupported(currentStore.marketplace) &&
    filteredStoresCount === 0;

  const handleFilterStoreCreation = async () => {
    if (!currentStore) {
      return;
    }
    const { merchantId, marketplace, marketplaceSubtype, marketplaceCountry } =
      currentStore;

    await connectBrandFilter({
      groupId,
      successMessage: t("connectWizard.brandFilteredLinkSuccess"),
      parentStoreParams: {
        mid: merchantId,
        marketplaceType: marketplace,
        marketplaceSubtype: marketplaceSubtype || marketplace,
        marketplaceCountry,
      },
    });
  };

  return (
    <>
      <ReviewContainer container>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <LinkCell
              {...{
                cell: {
                  value: {
                    value: groupName,
                    link:
                      report || groupSize == 0 || !groupLink ? null : groupLink,
                  },
                },
              }}
            />
          </Grid>
          <SecondRowGridContainer item container xs={12}>
            <FlexGridItem item style={{ width: "5rem" }}>
              <Typography
                align="left"
                variant="subtitle1"
                color="textSecondary"
                noWrap
              >
                {`${groupSize} ${groupType}`}
              </Typography>
            </FlexGridItem>
            {!report && (
              <>
                <Divider />
                <FlexGridItem item>
                  {onDelete && (
                    <Tooltip title="Delete">
                      <StyledDeleteIcon
                        onClick={() => onDelete({ groupId, groupName })}
                      />
                    </Tooltip>
                  )}
                </FlexGridItem>
                <Divider />
                <FlexGridItem item>
                  {onEdit && (
                    <Tooltip title="Edit">
                      <StyledEditIcon
                        onClick={() => onEdit({ groupId, groupName })}
                      />
                    </Tooltip>
                  )}
                </FlexGridItem>
                {isFilterStoreAvailable && (
                  <>
                    <Divider />
                    <FlexGridItem item>
                      <Tooltip
                        title={t("customGroups.filterStoreCreation.icon")}
                      >
                        {isLoading ? (
                          <CircularProgress
                            size={14}
                            style={{ marginLeft: "6px" }}
                          />
                        ) : (
                          <StyledFilterStoreIcon
                            onClick={() => setShowStoreConfirm((prev) => !prev)}
                          />
                        )}
                      </Tooltip>
                    </FlexGridItem>
                    <ConfirmDialog
                      open={showStoreConfirm}
                      title={t("filteredStoresPopup.title")}
                      onClose={() => setShowStoreConfirm(false)}
                      onConfirm={() => {
                        handleFilterStoreCreation();
                        setShowStoreConfirm(false);
                      }}
                      content={
                        <>
                          <Typography variant="body1">
                            {t("filteredStoresPopup.body")}
                          </Typography>
                        </>
                      }
                    />
                  </>
                )}
                {filteredStoresCount > 0 && (
                  <>
                    <Divider />
                    <FlexGridItem item>
                      <Tooltip title={t("customGroups.syncIconTooltip")}>
                        <StyledLinkIcon />
                      </Tooltip>
                    </FlexGridItem>
                  </>
                )}
              </>
            )}
          </SecondRowGridContainer>
        </Grid>
      </ReviewContainer>
    </>
  );
};

export default CustomGroupCell;
