import { useCallback, useEffect, useRef, useState } from "react";
import {
  useGetPersistentTableSettingsQuery,
  useUpsertPersistentTableSettingsMutation,
} from "~/store/mystore/persistentTableSettings.redux";

import { Column } from "~/components/adTable/columnSelect";
import { PERSISTENT_TABLE_IDS } from "~/utils/persistentTableIdsEnum";
import debounce from "lodash/debounce";

interface UsePersistentColumnsParams {
  tableId: (typeof PERSISTENT_TABLE_IDS)[keyof typeof PERSISTENT_TABLE_IDS];
  initialColumns: Column[];
  // are we currently in a report as opposed to a live view?
  report: boolean;
}

/**
 * A hook to manage persistent column settings for tables
 *
 * This hook handles:
 * - Loading saved column preferences from the backend
 * - Saving column visibility changes to the backend
 * - Managing local column state
 *
 * @param {Object} params - The hook parameters
 * @param {PERSISTENT_TABLE_IDS} params.tableId - The table ID from PERSISTENT_TABLE_IDS enum
 * @param {Column[]} params.initialColumns - Initial array of column configurations
 *
 * @returns {Object} Hook return object
 * @returns {boolean} .isFetching - Whether the initial column preferences are being fetched
 * @returns {Error} .fetchError - Any error that occurred during fetching
 * @returns {boolean} .isUpdating - Whether column updates are being saved
 * @returns {Column[]} .myColumns - The current column configurations
 * @returns {Function} .setMyColumns - Function to update column configurations
 */
export function usePersistentTableColumns({
  tableId,
  initialColumns,
  report,
}: UsePersistentColumnsParams): {
  isFetching: boolean;
  isUpdating: boolean;
  fetchError: Error | null;
  myColumns: Column[];
  setMyColumns: (columns: Column[]) => void;
} {
  if (report) {
    return {
      isFetching: false,
      isUpdating: false,
      fetchError: null,
      myColumns: initialColumns,
      setMyColumns: () => {},
    };
  }

  // Fetch persistent columns from the mongo collection with caching disabled
  const {
    data: getResponse,
    isLoading: isFetching,
    error: fetchError,
  } = useGetPersistentTableSettingsQuery({
    tableId,
  });

  const [myColumns, setMyColumns] = useState<Column[]>(initialColumns);

  // Mutation to update columns
  const [
    upsertColumns,
    { isLoading: isUpdating, error: updateError, isSuccess },
  ] = useUpsertPersistentTableSettingsMutation();

  // Effect to update the columns state with the persistent columns from the mongo collection.
  //The column's isVisible value is set to the value in the mongo collection.
  useEffect(() => {
    if (!isFetching && getResponse?.data?.columns) {
      const persistentColumns = getResponse.data.columns;
      const updatedColumns = myColumns.map((column) => {
        const persistentColumn = persistentColumns.find(
          (pCol) => pCol.id === column.id
        );
        return {
          ...column,
          isVisible: persistentColumn
            ? persistentColumn.isVisible
            : column.isVisible,
        };
      });

      if (JSON.stringify(updatedColumns) !== JSON.stringify(myColumns)) {
        setMyColumns(updatedColumns);
      }
    }
  }, [getResponse, isFetching, setMyColumns]);

  // Debounced function to update persistent storage when the columns change in the UI
  const debouncedUpdatePersistentColumns = useCallback(
    debounce(async (columnsToPersist) => {
      try {
        await upsertColumns({
          tableId,
          columns: columnsToPersist,
        }).unwrap();
      } catch (e) {
        console.error("Failed to persist columns:", e);
      }
    }, 300),
    [upsertColumns, tableId]
  );

  const initialRender = useRef(true);

  // Effect to trigger debounced update when columns change in the UI
  useEffect(() => {
    // we don't want to persist the columns on the initial render as they are the default columns
    // and it will override the columns in the mongo collection
    if (initialRender.current) {
      initialRender.current = false;

      if (JSON.stringify(myColumns) === JSON.stringify(initialColumns)) {
        return;
      }
    }
    const columnsToPersist = myColumns.map(({ id, isVisible }) => ({
      id,
      isVisible: isVisible ?? false,
    }));
    debouncedUpdatePersistentColumns(columnsToPersist);
  }, [myColumns, debouncedUpdatePersistentColumns]);

  return {
    isFetching,
    isUpdating,
    fetchError: fetchError as Error,
    myColumns,
    setMyColumns,
  };
}
