import React, { useEffect, useMemo } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import { hashAccessToken } from "~/utils/userUtils";

export default function (
  onCode: (code: string, state: string) => void,
  onError: (e: string) => void
) {
  const state = useMemo(() => hashAccessToken(), []);

  const childWindow = React.useRef<Window | null>(null);

  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener = (event: MessageEvent<any>) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "meta"
      ) {
        const { params } = event.data;
        closeChildWindow();

        if (params.error) {
          onError(params.error);
          return;
        }

        if (params.state) {
          if (params.state !== state) {
            onError(
              "Invalid state response from Meta - please contact support."
            );
            return;
          }

          if (params.code) {
            onCode(params.code, params.state);
            return;
          }
        }

        onError("Invalid response from Meta");
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError, state]);

  const triggerSignIn = () => {
    if (!childWindow.current || childWindow.current.closed) {
      if (state) {
        childWindow.current = window.open(
          generateAuthUrl(state),
          "authPopup-mm-meta",
          "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
        );
      }
    }

    if (childWindow.current) {
      childWindow.current.focus();
    }
  };

  return triggerSignIn;
}

const generateAuthUrl = (state: string): string => {
  const host = "https://www.facebook.com/v21.0/dialog/oauth";

  const clientId = process.env.META_APP_ID;
  const redirectUri =
    process.env.META_APP_REDIRECT_URI ||
    "https://mm.merchantspring.io/callback/meta";

  if (!clientId) {
    throw new Error("Error authorising Meta connection");
  }

  const url = new URL(host);
  const urlParams = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    redirect_uri: redirectUri,
    state,
    scope: "ads_read,business_management",
    return_scopes: "true",
  });

  url.search = urlParams.toString();
  return url.toString();
};
