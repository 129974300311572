import {
  Box,
  Divider,
  MuiThemeProvider,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import MarketplaceOverview from "~/modules/widgets/partner/marketplaceOverview";
import PageBlock from "~/components/containers/pageBlock";
import { RouteComponentProps } from "react-router-dom";
import cookies from "browser-cookies";
import { fetchFilteredStores } from "~/store/overview/customTags.redux";
import { hasOnlyDemoStores } from "~/utils/marketplaceUtils";
import { jwtInterceptor } from "~/utils/apiUtils/jwt.interceptor";
import theme from "~/theming/omnivoreTheme";
import { useCustomTheme } from "~/hooks/useCustomTheme";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.palette.grey["400"]};
`;

const PrimaryText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const RectangularWidgetPage = memo<
  RouteComponentProps<{ partner: string }>
>(function RectangularWidgetPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useTypedSelector((state) => state.user);
  const userCheck = user && user._id;

  const partnerName = props.match.params.partner || "unknown";

  const THEME_OVERRIDES: Record<string, Theme> = {
    omnivore: theme,
    default: useCustomTheme(),
  };
  const themeToUse = THEME_OVERRIDES[partnerName] || THEME_OVERRIDES["default"];

  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );

  const [showingDemoData, setShowingDemoData] = useState(true);

  useEffect(() => {
    dispatch(fetchFilteredStores({}));
  }, [userCheck]);

  useEffect(() => {
    if (userCheck) {
      const isShowingDemoData = hasOnlyDemoStores(filteredStores);
      setShowingDemoData(isShowingDemoData);
    } else {
      setShowingDemoData(true);
    }

    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, [userCheck, filteredStores]);

  // This is available after a user logs in to our application -
  // this is separate from the "token" value earlier, which is
  // basically a one-use token that the user can exchange for this one.
  const loggedInToken = cookies.get("accessToken");
  useEffect(() => {
    if (loggedInToken && userCheck) {
      jwtInterceptor(null, location.pathname);
    }
  }, [userCheck, loggedInToken, location.pathname]);

  return (
    <MuiThemeProvider theme={themeToUse}>
      <ThemeProvider theme={themeToUse}>
        <PageBlock disableGutters>
          <Box>
            <MarketplaceOverview
              currentCurrency="AUD"
              showingDemoData={showingDemoData}
              partnerName={partnerName}
            />
          </Box>
          <StyledDivider />
          <Box p={1} fontStyle="italic">
            <Typography variant="body2" align="right">
              <PrimaryText>{t(`partnerWidget.${partnerName}`)}</PrimaryText>
              &nbsp;{t("partnerWidget.poweredBy")}
            </Typography>
          </Box>
        </PageBlock>
      </ThemeProvider>
    </MuiThemeProvider>
  );
});

export default RectangularWidgetPage;
