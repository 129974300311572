import { Box, Grid, useTheme } from "@material-ui/core";
import { Filter, Range } from "~/typedef/store";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  computeTotalValue,
  getCategory,
} from "../../profitLossTable/categoryUtils";
import {
  fetchProfitabilityCategories,
  useProfitabilityChartQuery,
} from "@store/mystore/profitability.redux";
import moment, { DurationInputArg2 } from "moment-timezone";

import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import GenericTotalMoney from "~/components/totals/genericTotalMoney";
import LoadingIndicator from "@components/loadingIndicator/loadingIndicator";
import MultiBarLineChart from "~/components/charts/multiBarLineChart/multiBarLineChart";
import Panel from "@components/panel/panel";
import get from "lodash/get";
import { roundFractionalDigits } from "~/utils/currencyUtils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const LABELS = {
  SALES: "productSalesLabel",
  ADVERTISING: "advertisingLabel",
  SHIPPING: "fulfilmentAndShippingLabel",
  REFUNDS: "refundedSalesLabel",
};

/** Group data into buckets/intervals, where each bucket
is a single bar on the chart */
const formatChartData = (
  range: Range,
  events:
    | [
        {
          label: string;
          value: number;
          date: string;
          storeId?: number | undefined;
        }
      ]
    | undefined,
  categories: any[],
  timezone: string
) => {
  const { fromDate, interval, toDate } = range;
  const formatCurrencyValue = (value: number) => {
    if (isNaN(value)) return "-";
    // defend against weird accidental string values
    if (typeof value === "number") return roundFractionalDigits(value, 2);
    return value;
  };

  const startDate = moment.unix(fromDate).tz(timezone);
  const endDate = moment.unix(toDate).tz(timezone);
  const data = [];
  if (events && categories) {
    while (startDate.isBefore(endDate)) {
      const bucketEvents = events.filter((event) =>
        startDate.isSame(moment(event.date).tz(timezone), interval)
      );

      const revenueCategories = categories.filter(
        (category: { type: string }) => category.type === "revenue"
      );

      const expensesCategories = categories.filter(
        (category: { type: string }) => category.type === "expense"
      );

      const totalRevenue = computeTotalValue(
        {
          type: "revenue",
          label: "incomeLabel",
          children: revenueCategories.reduce((acc: any[], category: any) => {
            return [...acc, ...category.children];
          }, []),
        },
        bucketEvents
      );
      const totalExpenses = computeTotalValue(
        {
          type: "expense",
          label: "expenseLabel",
          children: expensesCategories.reduce((acc: any[], category: any) => {
            return [...acc, ...category.children];
          }, []),
        },
        bucketEvents
      );

      const salesCategory = categories.reduce(getCategory(LABELS.SALES), []);
      const totalSales = computeTotalValue(salesCategory, bucketEvents);

      const shippingCategory = categories.reduce(
        getCategory(LABELS.SHIPPING),
        []
      );
      const totalShipping = computeTotalValue(shippingCategory, bucketEvents);

      const advertisingCategory = categories.reduce(
        getCategory(LABELS.ADVERTISING),
        []
      );
      const totalAdvertising = computeTotalValue(
        advertisingCategory,
        bucketEvents
      );

      const refundsCategory = categories.reduce(
        getCategory(LABELS.REFUNDS),
        []
      );
      const totalRefunds = computeTotalValue(
        refundsCategory,
        bucketEvents,
        true
      );

      const intervalData = {
        startTime: startDate.clone().startOf(interval).unix(),
        endTime: startDate.clone().endOf(interval).unix(),
        sales: formatCurrencyValue(totalSales),
        shipping: formatCurrencyValue(totalShipping),
        advertising: formatCurrencyValue(totalAdvertising),
        refunds: formatCurrencyValue(totalRefunds),
        profit: formatCurrencyValue(totalRevenue - totalExpenses),
      };
      data.push(intervalData);
      startDate.add(1, interval as DurationInputArg2);
    }
  }

  if (moment.normalizeUnits(interval as any) === "week" && data.length) {
    data[0].startTime = fromDate;
    data[data.length - 1].endTime = toDate;
  }

  return data;
};

interface ProfitabilityChartProps {
  mid?: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  timezone: string;
  report?: boolean;
  includeTax: boolean;
  currentFilter: Filter;
}

const ProfitabilityChart = memo<ProfitabilityChartProps>(
  function ProfitabilityChart({
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    timezone,
    report,
    includeTax,
    currentFilter,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [totalProfit, setTotalProfit] = useState(0);

    const categoriesData = useTypedSelector((state) =>
      get(state.profitability, "categories.data")
    );

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const { chartData, chartDataLoading, chartTotalsData } =
      useProfitabilityChartQuery(
        {
          mid,
          currentRange,
          filter: currentFilter,
          currency: currentCurrency,
          includeTax,
        },
        {
          selectFromResult: ({ data, isFetching }) => ({
            chartData: data?.events?.events,
            chartDataLoading: isFetching,
            chartTotalsData: data?.events?.totalWithSku,
          }),
        }
      );

    const formattedChartData = useMemo(() => {
      return formatChartData(currentRange, chartData, categoriesData, timezone);
    }, [chartData, categoriesData]);

    useEffect(() => {
      dispatch(
        fetchProfitabilityCategories({
          mid,
          includeTax,
        })
      );
    }, [mid, includeTax]);

    useEffect(() => {
      if (categoriesData && categoriesData.length && chartData) {
        const revenueCategories = categoriesData.filter(
          (category: { type: string }) => category.type === "revenue"
        );

        const expensesCategories = categoriesData.filter(
          (category: { type: string }) => category.type === "expense"
        );

        const totalRevenue = computeTotalValue(
          {
            type: "revenue",
            label: "incomeLabel",
            children: revenueCategories.reduce((acc: any[], category: any) => {
              return [...acc, ...category.children];
            }, []),
          },
          chartData
        );
        const totalExpenses = computeTotalValue(
          {
            type: "expense",
            label: "expenseLabel",
            children: expensesCategories.reduce((acc: any[], category: any) => {
              return [...acc, ...category.children];
            }, []),
          },
          chartData
        );

        setTotalProfit(totalRevenue - totalExpenses);
      }
    }, [categoriesData, chartData]);

    return (
      <Panel
        id="product-profitability-chart"
        title={t("myStoresWidget.productProfit.chartTitle")}
        content={
          chartDataLoading ? (
            <Box
              p={2}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={9} lg={10}>
                  <MultiBarLineChart
                    title={t(CHART_TITLES[currentPeriod])}
                    isLoading={chartDataLoading}
                    currentPeriod={currentPeriod}
                    currencyRates={currencyRates}
                    currentCurrency={currentCurrency}
                    chartData={formattedChartData}
                    bars={[
                      {
                        key: "profit",
                        colour: theme.palette.success.main,
                        negativeColour: theme.palette.error.main,
                        axis: "1",
                        toFixed: 2,
                        isCurrency: true,
                      },
                    ]}
                    lines={[
                      {
                        key: "sales",
                        colour: theme.palette.chart.darkBlue,
                        axis: "1",
                        toFixed: 2,
                        isCurrency: true,
                      },
                      {
                        key: "shipping",
                        colour: theme.palette.chart.lightBlue,
                        axis: "1",
                        toFixed: 2,
                        isCurrency: true,
                      },
                      {
                        key: "advertising",
                        colour: theme.palette.chart.orange,
                        axis: "1",
                        toFixed: 2,
                        isCurrency: true,
                      },
                      {
                        key: "refunds",
                        colour: theme.palette.chart.red,
                        axis: "1",
                        toFixed: 1,
                        isCurrency: true,
                      },
                    ]}
                    xKey="startTime"
                    report={report}
                    timezone={currentRange.timezone}
                    interval={currentRange.interval}
                  />
                </Grid>
                <Grid container item xs={12} md={3} lg={2} alignItems="center">
                  <Box width="100%" pl={report ? 2 : 0} pb={report ? 2 : 0}>
                    <GenericTotalMoney
                      current={totalProfit}
                      currency={currentCurrency}
                      currentCurrency={currentCurrency}
                      title={t(`profitability.totalProfit`)}
                      rounded
                    />
                  </Box>
                  {chartTotalsData && (
                    <Box width="100%" pl={report ? 2 : 0} pb={report ? 2 : 0}>
                      <GenericTotalMoney
                        current={chartTotalsData}
                        currency={currentCurrency}
                        currentCurrency={currentCurrency}
                        title={t(`profitability.productProfit`)}
                        tooltip={t(`profitability.productProfitTooltip`)}
                        rounded
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default ProfitabilityChart;
