import { DATETIME_PERIODS, INTERVAL } from "../utils/dateTimeUtils";
import { TAG_TYPES, api } from "../apiSlice";
import { setError, setInfoMessage } from "../globalToast.redux";

import { Dispatch } from "redux";
import { ProcessedWidgetOption } from "~/pages/reports/reportConfigForm";
import { RawDraftContentState } from "draft-js";
import { ReportType } from "~/pages/reports/createReport";
import { Store } from "~/typedef/store";
import { TFunction } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import isEmpty from "lodash/isEmpty";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";

export const FETCH_CLIENT_REPORT_CONFIG = "FETCH_CLIENT_REPORT_CONFIG";
export const FETCH_CLIENT_REPORT_CONFIG_FETCHING =
  "FETCH_CLIENT_REPORT_CONFIG_FETCHING";

export const SET_CLIENT_REPORT_CONFIG = "SET_CLIENT_REPORT_CONFIG";
export const SET_CLIENT_REPORT_CONFIG_FETCHING =
  "SET_CLIENT_REPORT_CONFIG_FETCHING";

export const DELETE_CLIENT_REPORT_CONFIG = "DELETE_CLIENT_REPORT_CONFIG";
export const DELETE_CLIENT_REPORT_CONFIG_FETCHING =
  "DELETE_CLIENT_REPORT_CONFIG_FETCHING";

export const SEND_CLIENT_REPORT_VIA_EMAIL = "SEND_CLIENT_REPORT_VIA_EMAIL";
export const SEND_CLIENT_VIA_EMAIL_IN_PROGRESS =
  "SEND_CLIENT_VIA_EMAIL_IN_PROGRESS";

export const SCHEDULE_CLIENT_REPORT = "SCHEDULE_CLIENT_REPORT";
export const SCHEDULE_CLIENT_REPORT_IN_PROGRESS =
  "SCHEDULE_CLIENT_REPORT_IN_PROGRESS";

export const INITIAL_STATE = "INITIAL_STATE";

export const DELETE_REPORT_IN_PROGRESS = "DELETE_REPORT_IN_PROGRESS";
export const DELETE_REPORT_SUCCESSFUL = "DELETE_REPORT_SUCCESSFUL";

interface ClientReportConfig {
  reportType: string;
  name: string;
  period: string;
  comparisonPeriod: string;
  fromDate: number;
  toDate: number;
  priorFromDate?: number;
  priorToDate?: number;
  timezone?: string;
  interval: INTERVAL;
  pageSize: number;
  frequency: string | number; //d'/'w'/'m'/'y' or if empty then it is not recurring
  reportingDelay?: number; // in days
  currency: string;
  widgets: ProcessedWidgetOption[]; // ordered array of widgets
  notes?: { [key: string]: RawDraftContentState };
  selectedTags?: string[];
  includeTax: boolean;
  vendorRevenueType: "orderedRevenue" | "shippedRevenue";
  conditionalFormatting: boolean;
}

export type ClientReportConfigTemplate = Omit<
  ClientReportConfig,
  "fromDate" | "toDate" | "interval" | "notes"
> &
  Partial<Pick<ClientReportConfig, "fromDate" | "toDate" | "interval">> & {
    timezone: string;
  };

interface SentEmailResponseType {
  _id: string;
  storeName: string;
  reportName: string;
  reportType: ReportType;
  type: string;
  reportPeriod: string;
  reportUrl: string;
  createdAt: string;
  userId: string;
}

export interface ScheduledReportEditType {
  frequency?: string | number;
  reportingDelay?: number;
  stores?: Store[];
  period?: DATETIME_PERIODS | string;
  reportDay?: string;
  reportName?: string;
  reportType: ReportType;
  reportUrl?: string;
  storeName?: string;
  type?: string;
  userId?: string;
  reportId?: string;
  selectedTags: string[];
  timezone?: string;
  reportRecipients: string[];
}

export interface ClientReportState {
  _id?: string;
  fetching: boolean;
  sending: boolean;
  deleting: boolean;
  emailSent: boolean;
  reportConfig?: ClientReportConfig;
  success?: boolean;
  error?: string;
  sentReports?: SentEmailResponseType;
}

export interface FetchEmailListParamsType {
  userId?: string;
  pageIndex?: number;
  pageSize?: number;
  sortKey?: string;
  sortOrder?: string;
}

const initState: ClientReportState = {
  fetching: false,
  sending: false,
  deleting: false,
  emailSent: false,
};

type FetchReportFetchingAction = {
  type: typeof FETCH_CLIENT_REPORT_CONFIG_FETCHING;
};
type FetchReportAction = {
  type: typeof FETCH_CLIENT_REPORT_CONFIG;
  payload: { reportConfig: ClientReportConfig };
};

type SetReportFetchingAction = {
  type: typeof SET_CLIENT_REPORT_CONFIG_FETCHING;
};
type SetReportAction = {
  type: typeof SET_CLIENT_REPORT_CONFIG;
};

type DeleteReportFetchingAction = {
  type: typeof DELETE_CLIENT_REPORT_CONFIG_FETCHING;
};
type DeleteReportAction = {
  type: typeof DELETE_CLIENT_REPORT_CONFIG;
};
type SendingReportViaEmailInProgress = {
  type: typeof SEND_CLIENT_VIA_EMAIL_IN_PROGRESS;
};
type SendingReportViaEmailSuccessful = {
  type: typeof SEND_CLIENT_REPORT_VIA_EMAIL;
};
type ScheduleReportInProgress = {
  type: typeof SCHEDULE_CLIENT_REPORT_IN_PROGRESS;
};
type ScheduleReportSuccessful = {
  type: typeof SCHEDULE_CLIENT_REPORT;
};

type DeleteReportInProgress = {
  type: typeof DELETE_REPORT_IN_PROGRESS;
};
type DeleteReportSuccessful = {
  type: typeof DELETE_REPORT_SUCCESSFUL;
};
type InitialState = {
  type: typeof INITIAL_STATE;
};

type ClientReportAction =
  | FetchReportFetchingAction
  | FetchReportAction
  | SetReportFetchingAction
  | SetReportAction
  | DeleteReportFetchingAction
  | DeleteReportAction
  | SendingReportViaEmailInProgress
  | SendingReportViaEmailSuccessful
  | InitialState
  | ScheduleReportInProgress
  | ScheduleReportSuccessful
  | DeleteReportInProgress
  | DeleteReportSuccessful;

export const clientReport = (
  state: ClientReportState = initState,
  action: ClientReportAction
): ClientReportState => {
  switch (action.type) {
    case FETCH_CLIENT_REPORT_CONFIG_FETCHING:
      return {
        ...state,
        fetching: true,
        success: false,
      };

    case FETCH_CLIENT_REPORT_CONFIG:
      return {
        ...action.payload,
        fetching: false,
        success: false,
        sending: false,
        deleting: false,
        emailSent: state.emailSent,
      };

    case SET_CLIENT_REPORT_CONFIG_FETCHING:
      return {
        ...state,
        sending: true,
        success: false,
        deleting: false,
        emailSent: false,
      };

    case SET_CLIENT_REPORT_CONFIG:
      return {
        ...state,
        sending: false,
        success: true,
        deleting: false,
        emailSent: false,
      };

    case DELETE_CLIENT_REPORT_CONFIG_FETCHING:
      return {
        ...state,
        deleting: true,
        success: false,
      };

    case DELETE_CLIENT_REPORT_CONFIG:
      return {
        ...state,
        deleting: false,
        success: true,
      };
    case SEND_CLIENT_VIA_EMAIL_IN_PROGRESS:
      return {
        ...state,
        deleting: false,
        success: false,
        sending: true,
      };
    case SEND_CLIENT_REPORT_VIA_EMAIL:
      return {
        ...state,
        deleting: false,
        success: true,
        sending: false,
        emailSent: true,
      };
    case SCHEDULE_CLIENT_REPORT_IN_PROGRESS:
      return {
        ...state,
        deleting: false,
        success: false,
        sending: true,
      };
    case SCHEDULE_CLIENT_REPORT:
      return {
        ...state,
        deleting: false,
        success: true,
        sending: false,
        emailSent: true,
      };

    case DELETE_REPORT_IN_PROGRESS:
      return {
        ...state,
        deleting: true,
        success: false,
      };
    case DELETE_REPORT_SUCCESSFUL:
      return {
        ...state,
        deleting: false,
        success: true,
      };
    case INITIAL_STATE:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export interface FetchClientReportArgs {
  userId: string;
  reportType: ReportType;
  stores: Store[];
  selectedTags?: string[];
}

export interface ClientReportViaEmailArgs {
  userId: string;
  stores: Store[];
  payload: {
    reportRecipients: string[];
    reportId: string;
    reportUrl: string;
    type: string;
    reportPeriod: string;
    reportName: string;
    reportType: ReportType;
    selectedTags: string;
  };
}
export const fetchClientReportConfig =
  ({ userId, reportType, stores, selectedTags }: FetchClientReportArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CLIENT_REPORT_CONFIG_FETCHING });
    const body: FetchClientReportArgs = {
      userId,
      reportType,
      stores,
      selectedTags,
    };
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/client-report-service/api/public-report/find`,
        body
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_CLIENT_REPORT_CONFIG,
          payload: data,
        });
      } else if (isHttpResponseValid(status) && isEmpty(data)) {
        return dispatch({
          type: FETCH_CLIENT_REPORT_CONFIG,
          payload: { reportConfig: null },
        });
      } else {
        return setError(dispatch, data.errMsg, status, "public-report/find");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status"),
        "public-report/find"
      );
    }
  };

export interface FetchClientReportByIdArgs {
  reportId: string;
}
export const fetchClientReportConfigById =
  ({ reportId }: FetchClientReportByIdArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CLIENT_REPORT_CONFIG_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/client-report-service-external/api/public-report/find`,
        { reportId }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: FETCH_CLIENT_REPORT_CONFIG,
          payload: data,
        });
      } else if (isHttpResponseValid(status) && data === null) {
        return dispatch({
          type: FETCH_CLIENT_REPORT_CONFIG,
          payload: { reportConfig: null },
        });
      } else {
        return setError(dispatch, data.errMsg, status, "report/findById");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status"),
        "report/findById"
      );
    }
  };

export interface SetClientReportArgs {
  userId: string;
  preparedBy: string;
  stores: Store[];
  originalDomain: string | null;
  agency?: string;
  authSource: string | null;
  reportConfig: ClientReportConfig;
  t: TFunction<"translation">;
}
export const saveClientReportConfig =
  ({ t, ...body }: SetClientReportArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SET_CLIENT_REPORT_CONFIG_FETCHING });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/client-report-service/api/report`,
        body
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("clientReport.reportSavedSuccessMessage"));
        return dispatch({
          type: SET_CLIENT_REPORT_CONFIG,
        });
      } else {
        return setError(dispatch, data.errMsg, status, "saveReport");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status"),
        "saveReport"
      );
    }
  };

interface SetClientReportTemplateArgs {
  stores: Store[];
  forceSave?: boolean;
  reportConfig: ClientReportConfigTemplate;
  successMsg: string;
}

interface SetClientReportTemplateResponse {
  status: string;
  message?: string;
}

export interface DeleteReportTemplateArgs {
  reportId: string;
}

export interface ReportTemplateRows {
  _id: string;
  userId: string;
  organisationId: string;
  marketplaceType: string;
  stores: Store[];
  reportConfig: ClientReportConfigTemplate;
  preparedBy: string;
  createdAt: string;
}
interface GetReportTemplateByOrgResponse {
  reportRows: ReportTemplateRows[];
  count: number;
}

interface GetReportTemplateByOrgRequest {
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortOrder: string;
}

interface GetScheduledReportsRequest {
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortOrder: string;
  search: string | null;
}

export interface SentReport {
  _id: string;
  stores: Store[];
  reportId: string;
  type: string;
  reportPeriod: string;
  reportUrl: string;
  reportName: string;
  reportType: string;
  reportRecipients: string | string[];
  createdAt: string;
  userId: string;
  scheduledReportId: string;
}

export interface ScheduledReport {
  _id: string;
  stores: Store[];
  selectedTags: null;
  reportId: string;
  storeName: null;
  marketplace: null;
  countryCode: null;
  type: string;
  period: string;
  frequency: string;
  reportingDelay: number;
  timezone: string;
  reportDay: string;
  reportUrl: string;
  reportName: string;
  reportType: string;
  createdAt: string;
  reportRecipients: string[];
  userId: string;
  latestReportId: string;
  latestReportUrl: string;
}

interface GetScheduledReportsResponse {
  sentReports: {
    reportRows: ScheduledReport[];
    count: number;
  };
}

interface GetSentReportsRequest {
  pageSize: number;
  pageIndex: number;
  sortKey: string;
  sortOrder: string;
  search: string | null;
}

interface GetSentReportsResponse {
  sentReports: {
    reportRows: SentReport[];
    count: number;
  };
}

interface GetReportTemplateByIdResponse {
  template?: ReportTemplateRows;
}

interface GetReportTemplateByIdArgs {
  templateId: string | null;
}

interface DeleteScheduledReportArgs {
  reportId: string;
  successMessage: string;
}

interface DeleteScheduledReportResponse {}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getScheduledReports: build.query<
      GetScheduledReportsResponse,
      GetScheduledReportsRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/report/scheduledReports`,
          method: "GET",
          params,
        };
      },
      providesTags: [TAG_TYPES.ScheduledReport],
      onQueryStarted: globalQueryErrorHandler("getScheduledReports"),
    }),
    getSentReports: build.query<GetSentReportsResponse, GetSentReportsRequest>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/report/fetchSentEmails`,
          method: "GET",
          params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("getSentReports"),
    }),
    deleteScheduledReport: build.mutation<
      DeleteScheduledReportResponse,
      DeleteScheduledReportArgs
    >({
      query: ({ successMessage, reportId }) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/report/deleteReport`,
          method: "DELETE",
          data: { reportId },
        };
      },
      invalidatesTags: [TAG_TYPES.ScheduledReport],
      onQueryStarted: globalQueryErrorHandler(
        "deleteScheduledReport",
        true,
        (_data, dispatch, { successMessage }: DeleteScheduledReportArgs) => {
          setInfoMessage(dispatch, successMessage, "deleteScheduledReport");
        }
      ),
    }),
    saveReportConfigTempate: build.mutation<
      SetClientReportTemplateResponse,
      SetClientReportTemplateArgs
    >({
      query: ({ successMsg, ...params }) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/reportTemplate/saveTemplate`,
          method: "POST",
          data: params,
        };
      },
      invalidatesTags: [TAG_TYPES.ReportTemplate],
      onQueryStarted: globalQueryErrorHandler(
        "saveReportTemplate",
        true,
        (_data, dispatch, { successMsg }: SetClientReportTemplateArgs) => {
          setInfoMessage(dispatch, successMsg, "saveReportTemplate");
        }
      ),
    }),
    deleteReportTempate: build.mutation<{}, DeleteReportTemplateArgs>({
      query: (params) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/reportTemplate/deleteTemplate`,
          method: "DELETE",
          data: params,
        };
      },
      invalidatesTags: [TAG_TYPES.ReportTemplate],
      onQueryStarted: globalQueryErrorHandler("deleteReportTemplate"),
    }),
    getReportTemplateByOrg: build.query<
      GetReportTemplateByOrgResponse,
      GetReportTemplateByOrgRequest
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/reportTemplate/getTemplates`,
          method: "GET",
          params,
        };
      },
      providesTags: [TAG_TYPES.ReportTemplate],
      onQueryStarted: globalQueryErrorHandler("getReportTemplate"),
    }),
    getReportTemplateById: build.query<
      GetReportTemplateByIdResponse,
      GetReportTemplateByIdArgs
    >({
      query: ({ templateId }) => {
        return {
          url: `${baseUrl}/api/client-report-service/api/reportTemplate/template`,
          method: "GET",
          params: { reportId: templateId },
        };
      },
      providesTags: [TAG_TYPES.ReportTemplate],
      onQueryStarted: globalQueryErrorHandler("getReportTemplate", true),
    }),
  }),
});

export interface DeleteClientReportArgs {
  userId: string;
  reportId: string;
  t: TFunction<"translation">;
}
export const deleteClientReportConfig =
  ({ userId, reportId, t }: DeleteClientReportArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_CLIENT_REPORT_CONFIG_FETCHING });
    try {
      const { status, data } = await axios.get(
        `${baseUrl}/api/client-report-service/api/report/delete`,
        {
          params: { userId, reportId },
        }
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("clientReport.reportDeletedSuccessMessage"));
        return dispatch({
          type: DELETE_CLIENT_REPORT_CONFIG,
        });
      } else {
        return setError(dispatch, data.errMsg, status, "report/delete");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status"),
        "report/delete"
      );
    }
  };

export const sendClientReportViaEmail =
  ({ userId, stores, payload }: ClientReportViaEmailArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SEND_CLIENT_VIA_EMAIL_IN_PROGRESS });
    try {
      const body = { userId, stores, payload };
      const { status } = await axios.post(
        `${baseUrl}/api/client-report-service/api/report/sendEmail`,
        body
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: SEND_CLIENT_REPORT_VIA_EMAIL,
        });
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export const setInitialState = () => async (dispatch: Dispatch) => {
  dispatch({ type: INITIAL_STATE });
};

export interface ClientReportScheduledArgs {
  userId: string;
  stores: Store[];
  payload: {
    reportRecipients: string[];
    reportId: string;
    reportUrl: string;
    type: string;
    period: string;
    frequency: string | number;
    reportingDelay?: number;
    timezone: string;
    reportDay: string;
    reportName: string;
    reportType: ReportType;
  };
}
export const scheduleClientReport =
  ({ userId, stores, payload }: ClientReportScheduledArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SCHEDULE_CLIENT_REPORT_IN_PROGRESS });
    try {
      const body = { userId, stores, payload };
      const { status } = await axios.post(
        `${baseUrl}/api/client-report-service/api/report/scheduleReport`,
        body
      );

      if (isHttpResponseValid(status)) {
        return dispatch({
          type: SCHEDULE_CLIENT_REPORT,
        });
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data.errMsg"),
        get(err, "response.status")
      );
    }
  };

export interface EditReportArgs {
  t: TFunction<"translation">;
  reportId: string | undefined | null;
  stores: Store[];
  editedReportConfig: ClientReportConfig;
  editedScheduledReport: ScheduledReportEditType;
}

export const editScheduledReport =
  ({
    t,
    reportId,
    stores,
    editedReportConfig,
    editedScheduledReport,
  }: EditReportArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_REPORT_IN_PROGRESS });
    try {
      const { status, data } = await axios.put(
        `${baseUrl}/api/client-report-service/api/report/editReport`,
        {
          data: {
            reportId,
            stores,
            editedReportConfig,
            editedScheduledReport,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("scheduledReports.editSuccessMessage"));
        return dispatch({
          type: DELETE_REPORT_SUCCESSFUL,
          payload: data,
        });
      } else {
        return setError(dispatch, data.errMsg, status, "report/editReport");
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "report/editReport"
      );
    }
  };

interface EditReportRecipientsArgs {
  t: TFunction<"translation">;
  reportId: ScheduledReportEditType["reportId"];
  reportRecipients: ScheduledReportEditType["reportRecipients"];
}

export const editScheduledReportRecipients =
  ({ t, reportId, reportRecipients }: EditReportRecipientsArgs) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: SET_CLIENT_REPORT_CONFIG_FETCHING });
    try {
      const { status, data } = await axios.put(
        `${baseUrl}/api/client-report-service/api/report/editReportRecipients`,
        {
          reportId,
          reportRecipients,
        }
      );

      if (isHttpResponseValid(status) && data) {
        setInfoMessage(dispatch, t("scheduledReports.editSuccessMessage"));
        return dispatch({
          type: SET_CLIENT_REPORT_CONFIG,
        });
      } else {
        return setError(
          dispatch,
          data.errMsg,
          status,
          "report/editReportRecipients"
        );
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status"),
        "report/editReportRecipients"
      );
    }
  };

export const {
  useSaveReportConfigTempateMutation,
  useGetReportTemplateByOrgQuery,
  useDeleteReportTempateMutation,
  useGetScheduledReportsQuery,
  useGetSentReportsQuery,
  useGetReportTemplateByIdQuery,
  useDeleteScheduledReportMutation,
} = extendedApiSlice;
