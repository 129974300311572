import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import React from "react";
import SalesPerformanceAgainstForecastWidget from "../widgets/forecasting/salesPerformanceAgainstForecast";
import { get } from "lodash";
import { memo } from "react";
import moment from "moment";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface SalesPerformanceAgainstForecastParams {
  marketplaceType: string;
  mid: string;
}

const SalesPerformanceAgainstForecast =
  memo<SalesPerformanceAgainstForecastParams>(
    function SalesPerformanceAgainstForecast({ mid, marketplaceType }) {
      const includeTax = useTypedSelector((state) =>
        Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
      );
      const currentCurrency = useTypedSelector((state) =>
        get(
          state,
          "persistentAppSettings.setting.data.currentCurrency",
          DEFAULT_CURRENCY
        )
      );
      const currentPeriod = useTypedSelector(
        (state) =>
          get(state, "persistentAppSettings.setting.data.currentPeriod") ||
          DATETIME_PERIODS.LAST30
      );
      const currentCompare = useTypedSelector(
        (state) =>
          get(state, "persistentAppSettings.setting.data.currentCompare") ||
          COMPARISON_PERIOD.THISYEAR
      );
      const selectedTimezone = useTypedSelector(
        (state) =>
          get(state, "persistentAppSettings.setting.data.timezone") ||
          moment.tz.guess()
      );
      const currentRange = useTypedSelector(
        (state) =>
          get(state, "persistentAppSettings.setting.data.currentRange") ||
          getDatesFromPeriod(
            currentPeriod,
            currentCompare || COMPARISON_PERIOD.THISYEAR,
            selectedTimezone
          )
      );

      const currentFilter = useTypedSelector((state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter")
      );

      const userInfo = useTypedSelector((state) => state.user);

      const currencyRates = useTypedSelector(
        (state) => state.globalVar.currencyRates
      );

      const vendorRevenueType = useTypedSelector(
        (state) =>
          state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
          "orderedRevenue"
      );

      return (
        <SalesPerformanceAgainstForecastWidget
          currentCurrency={currentCurrency}
          currentFilter={currentFilter}
          currentPeriod={currentPeriod}
          marketplaceType={marketplaceType}
          includeTax={includeTax}
          mid={mid}
          currentRange={currentRange}
          userInfo={userInfo}
          currencyRates={currencyRates}
          vendorRevenueType={vendorRevenueType}
        />
      );
    }
  );

export default SalesPerformanceAgainstForecast;
