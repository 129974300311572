import { Box, Grid, MenuItem, Typography } from "@material-ui/core";
import {
  DATETIME_PERIODS,
  INTERVAL,
  RESTRICTED_CHART_INTERVALS,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";

import { OptionText } from "../../components/select/filterSelect";
import ToolbarSelect from "../select/toolbarSelect";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IntervalInputProps {
  chartInterval: INTERVAL;
  setChartInterval: (i: INTERVAL) => void;
  startDate: Date;
  endDate: Date;
  currentPeriod: DATETIME_PERIODS;
}

interface StyleProps {
  $width?: number | null;
}
const IntervalMenuItem = styled(MenuItem)`
  width: ${({ $width }: StyleProps) => $width}px;
`;
const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

function isFirstDayOfMonth(momentDate: Moment) {
  return momentDate.date() === 1;
}

function isLastDayOfMonth(momentDate: Moment) {
  return momentDate.date() === momentDate.daysInMonth(); // Compare the day of the month to the last day of the month
}

const IntervalInput = memo<IntervalInputProps>(function IntervalInput({
  chartInterval,
  setChartInterval,
  startDate,
  endDate,
  currentPeriod,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const selectedTimeRange = moment.duration(
    moment(endDate).diff(moment(startDate)),
    "millisecond"
  );
  const excludeDailyIntervals = selectedTimeRange.asDays() > 60;
  const excludeHourlyIntervals = selectedTimeRange.asDays() > 7;

  // allow 1 day of leeway on each side to account for
  // timezone discrepancies
  const isFirstOfMonth =
    isFirstDayOfMonth(moment(startDate)) ||
    isFirstDayOfMonth(moment(startDate).add(1, "d")) ||
    isFirstDayOfMonth(moment(startDate).subtract(1, "d"));
  const isLastOfMonth =
    isLastDayOfMonth(moment(endDate)) ||
    isLastDayOfMonth(moment(endDate).add(1, "d")) ||
    isLastDayOfMonth(moment(endDate).subtract(1, "d"));
  const excludeMonthlyIntervals = !(isFirstOfMonth && isLastOfMonth);

  const options = Object.entries(INTERVAL)
    .filter(([_k, v]) => {
      if (excludeDailyIntervals && v === INTERVAL.DAYS) {
        return false;
      }
      if (excludeHourlyIntervals && v === INTERVAL.HOURS) {
        return false;
      }
      if (
        excludeMonthlyIntervals &&
        RESTRICTED_CHART_INTERVALS.includes(v) &&
        // allow monthly intervals for "Last 12 Months"
        DATETIME_PERIODS.LAST12 !== currentPeriod
      ) {
        return false;
      }
      if ([INTERVAL.QUARTERS, INTERVAL.YEARS].includes(v)) {
        return false;
      }
      return true;
    })
    .map(([k, v]) => ({
      label: t(`interval.${k}`),
      value: v,
    }));

  useEffect(() => {
    const currentInterval = options.find(
      (option) => option.value === chartInterval
    );
    if (!currentInterval) {
      setChartInterval(options[options.length - 1].value);
    }
  }, [chartInterval, options]);

  const renderOptions = () =>
    options.map((o) => (
      <IntervalMenuItem
        key={o.value}
        $width={menuWidth}
        onClick={() => {
          setChartInterval(o.value);
          setAnchorEl(null);
        }}
      >
        <Typography variant="body2">{o.label}</Typography>
      </IntervalMenuItem>
    ));

  return (
    <Box>
      <ToolbarSelect
        id="report-interval-select"
        title={t("createReport.selectInterval")}
        renderOptions={renderOptions}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setMenuWidth={setMenuWidth}
        tooltip={t("intervalSelect.tooltip")}
        fullWidth
        rightAlign={true}
        displayComponent={
          <FlexGrid item>
            <OptionText variant="body2" noWrap $condensed>
              {options.find((option) => option.value === chartInterval)
                ?.label || ""}
            </OptionText>
          </FlexGrid>
        }
      ></ToolbarSelect>
    </Box>
  );
});

export default IntervalInput;
