import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";

import MarketplaceOverview from "~/modules/widgets/overview/marketplaceOverview";
import React from "react";
import get from "lodash/get";
import { memo } from "react";
import moment from "moment-timezone";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const MarketPlaceOverview = memo(function MarketPlaceOverview() {
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(currentPeriod, currentCompare, selectedTimezone)
  );
  const currentFilter = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentFilter") ||
      DEFAULT_FILTER
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const vendorRevenueType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorRevenueType ??
      "orderedRevenue"
  );

  const filteredStores = useTypedSelector(
    (state) => state?.mystore?.filteredStores?.stores
  );
  const PAGE_SIZE = 5;

  return (
    <MarketplaceOverview
      {...{
        currentCurrency,
        currentPeriod,
        currentRange,
        pageSize: PAGE_SIZE,
        includeTax,
        currentFilter,
        filteredStores,
        vendorRevenueType,
      }}
    />
  );
});

export default MarketPlaceOverview;
