import { Grid, Switch, Typography } from "@material-ui/core";
import React, { memo, useCallback, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import AlertCard from "~/components/alert/alertCard";
import CaseConfigTemplateDialog from "~/modules/widgets/inventoryManagement/caseConfigTemplateDialog";
import CaseConfigUploadDialog from "~/modules/widgets/inventoryManagement/caseConfigUploadDialog";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import InventoryReplenishmentTable from "~/modules/widgets/inventoryManagement/inventoryReplenishmentTable";
import InventoryStatus from "~/modules/widgets/inventoryManagement/inventoryStatus";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import RaisedButton from "~/components/buttons/raisedButton";
import ReplenishmentSetting from "~/modules/widgets/inventoryManagement/replenishmentSetting";
import SearchFilter from "~/components/adTable/searchFilter";
import get from "lodash/get";
import moment from "moment-timezone";
import styled from "styled-components";
import { uploadCaseConfig } from "~/store/mystore/inventoryReplenishment.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const StyledButton = styled(RaisedButton)`
  min-width: 100px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
`;

const InventoryReplenishment = memo(function InventoryReplenishment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [templateDialogState, setTemplateDialogState] = useState(false);
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const user = useTypedSelector((state) => get(state, "user"));
  const [searchText, setSearchText] = useState<string>("");
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );
  const caseConfigUploading = useTypedSelector(
    (state) => state?.inventoryReplenishment?.caseConfigUpload?.uploading
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const [showActiveProducts, setShowActiveProducts] = useState(true);

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const dispatchUploadCaseConfig = useCallback(
    (file: File) => {
      dispatch(uploadCaseConfig(store?.merchantId, file, t));
    },
    [t, store?.merchantId]
  );

  // It is not an amazon seller store
  // It is an amazon seller store but in Mexico
  if (store.marketplace !== "amazon" || store.marketplaceCountry === "MEX") {
    return (
      <PageBlock>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AlertCard isOpen={true} type="error">
              {t("inventoryReplenishment.unsupportedStores")}
            </AlertCard>
          </Grid>
        </Grid>
      </PageBlock>
    );
  }

  const settingActions = (
    <>
      <Grid item>
        <StyledButton
          variant="outlined"
          color="info"
          onClick={() => setTemplateDialogState(true)}
        >
          {t("inventoryReplenishment.downloadTemplateButton")}
        </StyledButton>
      </Grid>
      <Grid item>
        <StyledButton
          variant="contained"
          color="info"
          onClick={() => setUploadDialogOpen(true)}
        >
          {t("inventoryCaseConfig.uploadButtonLabel")}
        </StyledButton>
      </Grid>
    </>
  );

  const handleProductToggle = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setShowActiveProducts(checked);
  };

  const tableActions = (
    <>
      <StyledDiv>
        <Typography variant="body2">
          {t(
            showActiveProducts
              ? `replenishmentSetting.activeProductsToggle`
              : `replenishmentSetting.allProductsToggle`
          )}
        </Typography>
        <Switch
          color="secondary"
          checked={showActiveProducts}
          onChange={handleProductToggle}
        />
      </StyledDiv>
      <SearchFilter setSearchText={setSearchText} />
      <DownloadCsv
        {...{
          reportType: "inventoryReplenishment",
          path: "/api/amazon/inventory/replenishment",
          mid: store.merchantId,
          params: {
            storeName: store.storeName,
            marketplaceType: store.marketplace,
            marketplaceSubtype: store.marketplaceSubtype || "",
            countryCode: store.marketplaceCountry,
            searchText,
            currentCurrency,
            timezone: selectedTimezone,
            exchangeRate: getExchangeRate(
              currencyRates,
              getCurrencyByCountryCode[store.marketplaceCountry],
              currentCurrency
            ),
            activeProducts: showActiveProducts,
          },
        }}
      />
    </>
  );

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <InventoryStatus
            marketplaceCountry={store.marketplaceCountry}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || ""}
            mid={store.merchantId}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <ReplenishmentSetting
            marketplaceCountry={store.marketplaceCountry}
            marketplaceType={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype || ""}
            mid={store.merchantId}
            extraActions={settingActions}
          />
        </Grid>
        <Grid item xs={12}>
          <InventoryReplenishmentTable
            {...{
              user,
              mid: store.merchantId,
              marketplaceType: store.marketplace,
              marketplaceSubtype: store.marketplaceSubtype || "",
              countryCode: store.marketplaceCountry,
              currentCurrency,
              actions: tableActions,
              searchText,
              selectedTimezone,
              activeProducts: showActiveProducts,
            }}
          />
          <CaseConfigTemplateDialog
            mid={store.merchantId}
            open={templateDialogState}
            onClose={() => setTemplateDialogState(false)}
          />
          <CaseConfigUploadDialog
            open={uploadDialogOpen}
            onClose={() => {
              setUploadDialogOpen(false);
            }}
            onConfirm={dispatchUploadCaseConfig}
            loading={caseConfigUploading}
            warningLabel={t("inventoryCaseConfig.uploadWarning")}
          />
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default InventoryReplenishment;
