import React, { memo } from "react";

import PageBlock from "~/components/containers/sideNavPageBlock";

// Placeholder for the QueryLibrary component
const QueryLibrary = memo(function QueryLibrary() {
  return <PageBlock>Query Library - Coming Soon</PageBlock>;
});

export default QueryLibrary;
