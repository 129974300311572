import {
  AmazonVendorState,
  Filter,
  InventoryHealth,
  InventoryHealthTotals,
  InventoryHealthTrend,
  MarketplaceFilters,
  OrderedRevenue,
  OrderedRevenueTotals,
  OrderedRevenueTrend,
  OrderedRevenueTrendComparison,
  PurchaseOrder,
  PurchaseOrderStatus,
  Range,
  ShippedRevenue,
  ShippedRevenueTotals,
  ShippedRevenueTrend,
  ShippedUnitsSummary,
  SourcingShare,
  SourcingShareTrend,
} from "~/typedef/store";

import { Dispatch } from "redux";
import { PaginationArgs } from "~/typedef/pagination";
import { User } from "~/typedef/user";
import { api } from "../apiSlice";
import axios from "axios";
import { baseUrl } from "~/configs";
import get from "lodash/get";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";
import { isHttpResponseValid } from "../utils/httpsResponseCodes";
import { setError } from "../globalToast.redux";
import { shouldUseCache } from "../utils/shouldUseCache";

export const FETCH_SOURCING_SHARE = "FETCH_SOURCING_SHARE";
export const FETCH_SOURCING_SHARE_FETCHING = "FETCH_SOURCING_SHARE_FETCHING";

export const FETCH_INVENTORY_HEALTH = "FETCH_INVENTORY_HEALTH";
export const FETCH_INVENTORY_HEALTH_FETCHING =
  "FETCH_INVENTORY_HEALTH_FETCHING";

export const FETCH_ORDERED_TOTALS = "FETCH_ORDERED_TOTALS";
export const FETCH_ORDERED_TOTALS_FETCHING = "FETCH_ORDERED_TOTALS_FETCHING";

export const FETCH_ORDERED_TREND = "FETCH_ORDERED_TREND";
export const FETCH_ORDERED_TREND_FETCHING = "FETCH_ORDERED_TREND_FETCHING";
export const FETCH_SOURCING_TREND = "FETCH_SOURCING_TREND";
export const FETCH_SOURCING_TREND_FETCHING = "FETCH_SOURCING_TREND_FETCHING";
export const FETCH_SHIPPED_UNITS_SUMMARY = "FETCH_SHIPPED_UNITS_SUMMARY";
export const FETCH_SHIPPED_UNITS_SUMMARY_FETCHING =
  "FETCH_SHIPPED_UNITS_SUMMARY_FETCHING";

export const FETCH_INVENTORY_HEALTH_SUMMARY = "FETCH_INVENTORY_HEALTH_SUMMARY";
export const FETCH_INVENTORY_HEALTH_SUMMARY_FETCHING =
  "FETCH_INVENTORY_HEALTH_SUMMARY_FETCHING";
export const FETCH_INVENTORY_HEALTH_TREND = "FETCH_INVENTORY_HEALTH_TREND";
export const FETCH_INVENTORY_HEALTH_TREND_FETCHING =
  "FETCH_INVENTORY_HEALTH_TREND_FETCHING";

export const FETCH_ORDERED_PRODUCT_CHART_START =
  "FETCH_ORDERED_PRODUCT_CHART_START";
export const FETCH_ORDERED_PRODUCT_CHART_SUCCESS =
  "FETCH_ORDERED_PRODUCT_CHART_SUCCESS";

const initState: AmazonVendorState = {
  orderedRevenue: {
    chart: { fetching: false },
    totals: { fetching: false },
  },
  sourcingShare: {
    chart: { fetching: false },
    summary: { fetching: false },
    products: { fetching: false },
  },
  inventoryHealth: {
    chart: { fetching: false },
    totals: { fetching: false },
    products: { fetching: false },
  },
  productOrderedRevenue: {
    chart: { fetching: false },
  },
};

type FetchOrderedRevenueTotalsFetchingAction = {
  type: typeof FETCH_ORDERED_TOTALS_FETCHING;
};
type FetchOrderedRevenueTotalsAction = {
  type: typeof FETCH_ORDERED_TOTALS;
  payload: {
    data: OrderedRevenueTotals;
    currency: string;
    params: FetchOrderedRevenueTotalsArgs;
  };
};

type FetchSourcingShareFetchingAction = {
  type: typeof FETCH_SOURCING_SHARE_FETCHING;
};
type FetchSourcingShareAction = {
  type: typeof FETCH_SOURCING_SHARE;
  payload: {
    data: SourcingShare[];
    currency: string;
    count: number;
    params: FetchSourcingShareArgs;
  };
};

type FetchInventoryHealthFetchingAction = {
  type: typeof FETCH_INVENTORY_HEALTH_FETCHING;
};
type FetchInventoryHealthAction = {
  type: typeof FETCH_INVENTORY_HEALTH;
  payload: {
    data: InventoryHealth[];
    currency: string;
    lastUpdated: string;
    count: number;
    params: FetchInventoryHealthArgs;
  };
};

type FetchOrderedTrendFetchingAction = {
  type: typeof FETCH_ORDERED_TREND_FETCHING;
};
type FetchOrderedTrendAction = {
  type: typeof FETCH_ORDERED_TREND;
  payload: {
    data: OrderedRevenueTrendComparison[];
    count: number;
    params: FetchOrderedRevenueTrendArgs;
  };
};

type FetchSourcingTrendFetchingAction = {
  type: typeof FETCH_SOURCING_TREND_FETCHING;
};
type FetchSourcingTrendAction = {
  type: typeof FETCH_SOURCING_TREND;
  payload: {
    data: SourcingShareTrend[];
    currency: string;
    lastReportDate?: string;
    lastUpdatedAt?: string;
    params: FetchSourcingShareTrendArgs;
  };
};

type FetchShippedUnitsSummaryFetchingAction = {
  type: typeof FETCH_SHIPPED_UNITS_SUMMARY_FETCHING;
};
type FetchShippedUnitsSummary = {
  type: typeof FETCH_SHIPPED_UNITS_SUMMARY;
  payload: {
    data: ShippedUnitsSummary;
    currency: string;
    lastReportDate?: string;
    lastUpdatedAt?: string;
    params: FetchShippedRevenueTrendArgs;
  };
};

type FetchInventoryHealthSummaryFetchingAction = {
  type: typeof FETCH_INVENTORY_HEALTH_SUMMARY_FETCHING;
};
type FetchInventoryHealthSummary = {
  type: typeof FETCH_INVENTORY_HEALTH_SUMMARY;
  payload: {
    data: InventoryHealthTotals;
    currency: string;
    lastReportDate?: string;
    lastUpdatedAt?: string;
    params: FetchInventoryHealthSummaryArgs;
  };
};

type FetchInventoryTrendFetchingAction = {
  type: typeof FETCH_INVENTORY_HEALTH_TREND_FETCHING;
};
type FetchInventoryTrendAction = {
  type: typeof FETCH_INVENTORY_HEALTH_TREND;
  payload: {
    data: InventoryHealthTrend;
    count: number;
    params: FetchInventoryHealthTrendArgs;
  };
};

type FetchOrderedProductChartFetchingAction = {
  type: typeof FETCH_ORDERED_PRODUCT_CHART_START;
};
type FetchOrderedProductChartSuccessAction = {
  type: typeof FETCH_ORDERED_PRODUCT_CHART_SUCCESS;
  payload: {
    data: OrderedRevenueTrend[];
    currency: string;
  };
};

type VendorAction =
  | FetchOrderedTrendFetchingAction
  | FetchOrderedTrendAction
  | FetchOrderedRevenueTotalsFetchingAction
  | FetchOrderedRevenueTotalsAction
  | FetchShippedUnitsSummaryFetchingAction
  | FetchShippedUnitsSummary
  | FetchSourcingTrendFetchingAction
  | FetchSourcingTrendAction
  | FetchSourcingShareAction
  | FetchSourcingShareFetchingAction
  | FetchInventoryHealthSummary
  | FetchInventoryHealthSummaryFetchingAction
  | FetchInventoryHealthAction
  | FetchInventoryHealthFetchingAction
  | FetchInventoryTrendFetchingAction
  | FetchInventoryTrendAction
  | FetchOrderedProductChartFetchingAction
  | FetchOrderedProductChartSuccessAction;

export const vendor = (
  state: AmazonVendorState = initState,
  action: VendorAction
): AmazonVendorState => {
  switch (action.type) {
    case FETCH_ORDERED_TOTALS_FETCHING:
      return {
        ...state,
        orderedRevenue: {
          ...state.orderedRevenue,
          totals: {
            ...state.orderedRevenue.totals,
            fetching: true,
          },
        },
      };

    case FETCH_ORDERED_TOTALS:
      return {
        ...state,
        orderedRevenue: {
          ...state.orderedRevenue,
          totals: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_ORDERED_TREND_FETCHING:
      return {
        ...state,
        orderedRevenue: {
          ...state.orderedRevenue,
          chart: {
            ...state.orderedRevenue.chart,
            fetching: true,
          },
        },
      };

    case FETCH_ORDERED_TREND:
      return {
        ...state,
        orderedRevenue: {
          ...state.orderedRevenue,
          chart: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_SOURCING_TREND_FETCHING:
      return {
        ...state,
        sourcingShare: {
          ...state.sourcingShare,
          chart: {
            ...state.sourcingShare.chart,
            fetching: true,
          },
        },
      };

    case FETCH_SOURCING_TREND:
      return {
        ...state,
        sourcingShare: {
          ...state.sourcingShare,
          chart: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_SOURCING_SHARE_FETCHING:
      return {
        ...state,
        sourcingShare: {
          ...state.sourcingShare,
          products: {
            ...state.sourcingShare.products,
            fetching: true,
          },
        },
      };

    case FETCH_SOURCING_SHARE:
      return {
        ...state,
        sourcingShare: {
          ...state.sourcingShare,
          products: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_SHIPPED_UNITS_SUMMARY_FETCHING:
      return {
        ...state,
        sourcingShare: {
          ...state.sourcingShare,
          summary: {
            ...state.sourcingShare.summary,
            fetching: true,
          },
        },
      };

    case FETCH_SHIPPED_UNITS_SUMMARY:
      return {
        ...state,
        sourcingShare: {
          ...state.sourcingShare,
          summary: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_INVENTORY_HEALTH_SUMMARY_FETCHING:
      return {
        ...state,
        inventoryHealth: {
          ...state.inventoryHealth,
          totals: {
            ...state.inventoryHealth.totals,
            fetching: true,
          },
        },
      };

    case FETCH_INVENTORY_HEALTH_FETCHING:
      return {
        ...state,
        inventoryHealth: {
          ...state.inventoryHealth,
          products: {
            ...state.inventoryHealth.products,
            fetching: true,
          },
        },
      };

    case FETCH_INVENTORY_HEALTH_SUMMARY:
      return {
        ...state,
        inventoryHealth: {
          ...state.inventoryHealth,
          totals: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_INVENTORY_HEALTH:
      return {
        ...state,
        inventoryHealth: {
          ...state.inventoryHealth,
          products: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_INVENTORY_HEALTH_TREND_FETCHING:
      return {
        ...state,
        inventoryHealth: {
          ...state.inventoryHealth,
          chart: {
            ...state.inventoryHealth.chart,
            fetching: true,
          },
        },
      };

    case FETCH_INVENTORY_HEALTH_TREND:
      return {
        ...state,
        inventoryHealth: {
          ...state.inventoryHealth,
          chart: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    case FETCH_ORDERED_PRODUCT_CHART_START:
      return {
        ...state,
        productOrderedRevenue: {
          ...state.productOrderedRevenue,
          chart: {
            ...(state.productOrderedRevenue?.chart
              ? state.productOrderedRevenue.chart
              : {}),
            fetching: true,
          },
        },
      };

    case FETCH_ORDERED_PRODUCT_CHART_SUCCESS:
      return {
        ...state,
        productOrderedRevenue: {
          ...state.productOrderedRevenue,
          chart: {
            ...action.payload,
            fetching: false,
          },
        },
      };

    default:
      return state;
  }
};

export interface FetchOrderedRevenueTotalsArgs {
  user: User;
  mid: string;
  currentRange: Range;
}

export const fetchOrderedTotals =
  (
    params: FetchOrderedRevenueTotalsArgs,
    oldParams?: FetchOrderedRevenueTotalsArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_ORDERED_TOTALS_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/orderedRevenueSummary`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_ORDERED_TOTALS,
            payload: {
              data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchOrderedRevenueTrendArgs {
  user: User;
  mid: string;
  currentRange: Range;
}

export const fetchOrderedTrend =
  (
    params: FetchOrderedRevenueTrendArgs,
    oldParams?: FetchOrderedRevenueTrendArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_ORDERED_TREND_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/orderedRevenueChartData`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          const { revenueChartData, ...rest } = data;
          return dispatch({
            type: FETCH_ORDERED_TREND,
            payload: {
              data: revenueChartData || [],
              ...rest,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchSourcingShareTrendArgs {
  user: User;
  mid: string;
  currentRange: Range;
}

export const fetchSourcingTrend =
  (
    params: FetchSourcingShareTrendArgs,
    oldParams?: FetchSourcingShareTrendArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_SOURCING_TREND_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/sourcingShareTrend`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_SOURCING_TREND,
            payload: {
              ...data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchShippedUnitsTrendArgs {
  user: User;
  mid: string;
  currentRange: Range;
  currentFilter: Filter;
}
export const fetchShippedUnitsSummary =
  (
    params: FetchShippedUnitsTrendArgs,
    oldParams?: FetchShippedUnitsTrendArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_SHIPPED_UNITS_SUMMARY_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/sourcingShareSummary`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_SHIPPED_UNITS_SUMMARY,
            payload: {
              ...data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchSourcingShareArgs {
  user: User;
  mid: string;
  currentRange: Range;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
}
export const fetchSourcingShare =
  (params: FetchSourcingShareArgs, oldParams?: FetchSourcingShareArgs) =>
  async (dispatch: Dispatch) => {
    const {
      user,
      currentRange,
      mid,
      searchText,
      sortKey = "lost_cogs",
      sortOrder = "desc",
      pageIndex,
      pageSize,
    } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_SOURCING_SHARE_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/sourcingShare`,
          {
            userId: user._id,
            mid,
            ...currentRange,
            searchText,
            sortKey,
            sortOrder,
            pageIndex,
            pageSize,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_SOURCING_SHARE,
            payload: {
              ...data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchInventoryHealthSummaryArgs {
  user: User;
  mid: string;
  currentRange: Range;
}

export const fetchInventoryHealthTotals =
  (
    params: FetchInventoryHealthSummaryArgs,
    oldParams?: FetchInventoryHealthSummaryArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_INVENTORY_HEALTH_SUMMARY_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/inventoryHealthSummary`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_INVENTORY_HEALTH_SUMMARY,
            payload: {
              data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchInventoryHealthArgs {
  user: User;
  mid: string;
  currentRange: Range;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
}
export const fetchInventoryHealth =
  (params: FetchInventoryHealthArgs, oldParams?: FetchInventoryHealthArgs) =>
  async (dispatch: Dispatch) => {
    const {
      user,
      mid,
      currentRange,
      searchText,
      sortKey = "sellable_inventory_units",
      sortOrder = "desc",
      pageIndex,
      pageSize,
    } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_INVENTORY_HEALTH_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/inventoryHealth`,
          {
            userId: user._id,
            mid,
            ...currentRange,
            searchText,
            sortKey,
            sortOrder,
            pageIndex,
            pageSize,
          }
        );
        if (isHttpResponseValid(status) && data) {
          return dispatch({
            type: FETCH_INVENTORY_HEALTH,
            payload: {
              ...data,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchInventoryHealthTrendArgs {
  user: User;
  mid: string;
  currentRange: Range;
}

export const fetchInventoryTrend =
  (
    params: FetchInventoryHealthTrendArgs,
    oldParams?: FetchInventoryHealthTrendArgs
  ) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid } = params;
    if (!shouldUseCache(params, oldParams)) {
      dispatch({ type: FETCH_INVENTORY_HEALTH_TREND_FETCHING });
      try {
        const { status, data } = await axios.post(
          `${baseUrl}/api/generic-mws-service/api/vendor/inventoryHealthTrend`,
          {
            mid,
            ...currentRange,
          }
        );

        if (isHttpResponseValid(status) && data) {
          const { revenueChartData, ...rest } = data;
          return dispatch({
            type: FETCH_INVENTORY_HEALTH_TREND,
            payload: {
              data: revenueChartData || [],
              ...rest,
              params,
            },
          });
        } else {
          return setError(dispatch, data, status);
        }
      } catch (err) {
        return setError(
          dispatch,
          get(err, "response.data"),
          get(err, "response.status")
        );
      }
    }
  };

export interface FetchOrderedProductRevenueChartArgs {
  user: User;
  mid: string;
  productId: string;
  currentRange: Range;
}

export const fetchOrderedProductChart =
  (params: FetchOrderedProductRevenueChartArgs) =>
  async (dispatch: Dispatch) => {
    const { currentRange, mid, productId } = params;
    dispatch({ type: FETCH_ORDERED_PRODUCT_CHART_START });
    try {
      const { status, data } = await axios.post(
        `${baseUrl}/api/generic-mws-service/api/vendor/orderedRevenueProductChartData`,
        {
          mid,
          productId,
          ...currentRange,
        }
      );

      if (isHttpResponseValid(status) && data) {
        const { revenueChartData, ...rest } = data;
        return dispatch({
          type: FETCH_ORDERED_PRODUCT_CHART_SUCCESS,
          payload: {
            data: revenueChartData || [],
            ...rest,
            params,
          },
        });
      } else {
        return setError(dispatch, data, status);
      }
    } catch (err) {
      return setError(
        dispatch,
        get(err, "response.data"),
        get(err, "response.status")
      );
    }
  };

// mid and marketplaceFilters are required for single store
interface FetchShippedRevenueTrendArgs {
  mid?: string;
  marketplaceFilters?: MarketplaceFilters;
  currentFilter?: Filter;
  currentRange: Range;
}

interface FetchShippedRevenueTrendResponse {
  lastReportDate: string;
  lastUpdatedAt: string;
  revenueChartData: ShippedRevenueTrend;
  currency: string;
}

// mid and marketplaceFilters are required for single store
interface FetchShippedRevenueTotalsArgs {
  mid?: string;
  marketplaceFilters?: MarketplaceFilters;
  currentFilter?: Filter;
  currentRange: Range;
}

interface FetchPurchaseOrderStatusArgs {
  mid: string;
  currentRange: Range;
  includeTax?: boolean;
}

interface FetchPurchaseOrdersArgs {
  mid: string;
  marketplaceType: string;
  countryCode: string;
  currentRange: Range;
  includeTax?: boolean;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
}

interface FetchPurchaseOrdersResponse {
  rows: PurchaseOrder[];
  count: number;
}

interface FetchOrderedRevenueArgs {
  mid: string;
  currentRange: Range;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
}

interface FetchOrderedRevenueResponse {
  data: OrderedRevenue[];
  currency: string;
  count: number;
  lastReportDate?: string | null;
  lastUpdatedAt?: string | null;
}

interface FetchShippedRevenueArgs {
  mid: string;
  currentRange: Range;
  searchText?: string;
  sortKey: string;
  sortOrder: string;
  pageIndex: number;
  pageSize: number;
}

interface FetchShippedRevenueResponse {
  sourcing: {
    data: ShippedRevenue[];
    count: number;
  };
  manufacturing: {
    data: ShippedRevenue[];
    count: number;
  };
  currency: string;
  lastReportDate?: string | null;
  lastUpdatedAt?: string | null;
}

export interface ShippedRevenueByStores {
  marketplaceType: string;
  marketplaceCountry: string;
  storeName: string;
  mid: string;
  shippedRevenue: number;
  shippedCogs: number;
  shippedUnits: number;
  avgCogs: number;
  shippedRevenueManufacturing: number;
  shippedCogsManufacturing: number;
  shippedUnitsManufacturing: number;
  avgCogsManufacturing: number;
}

export interface FetchShippedRevenueByStoresResponse {
  shippedRevenueByStores: {
    current: ShippedRevenueByStores;
    prior: ShippedRevenueByStores | null;
  }[];
  count: number;
}

interface FetchShippedRevenueByStoresArgs {
  currentRange: Range;
  paginationParams: PaginationArgs;
  currentFilter: Filter;
}

interface RetailAnalyticsByProductArgs {
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  sellerId?: string;
  countryCode: string;
  currentRange: Range;
  pageIndex: number;
  pageSize: number;
  searchText?: string;
  includeNoInventory: boolean;
  includeTax: boolean;
  groupId?: string | null;
}

interface RetailAnalyticsByProductResponse {
  rows: {
    current: {
      storeId: string;
      productId: string;
      id: string;
      imageUrl: string;
      title: string;
      brand: string;
      fulfillmentChannel: string;
      sku: string;
      productSku: string;
      quantity: number;
      linkUrl: string;
      price: string;
      shippedCogs: number;
      shippedCogsManufacturing: number;
      shippedUnits: number;
      orderedRevenue: number;
      glanceViews: number;
      orderedUnits: number;
      retailPrice: string;
      unitsPerView: number;
      adSpend: number;
      adSales: number;
      acos: number;
      tacos: number;
      shippedUnitsManufacturing: number;
    };
    prior: {
      productId: string;
      priorShippedCogs: number;
      priorOrderedUnits: number;
      priorShippedCogsManufacturing: number;
      priorShippedUnits: number;
      priorOrderedRevenue: number;
      priorGlanceViews: number;
      priorPrice: number;
      priorUnitsPerView: number;
      priorShippedUnitsManufacturing: number;
    };
  }[];
  count: number;
}

interface RetailAnalyticsByProductChartArgs {
  mid: string;
  productId: string;
  currentRange: Range;
}

export interface RetailAnalyticsByProductChartData {
  startTime: number;
  endTime: number;
  ordered: number;
  retailPrice: number;
}

interface RetailAnalyticsByProductChartResponse {
  currency: string;
  retailAnalyticChartData: RetailAnalyticsByProductChartData[];
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    shippedRevenueTotals: build.query<
      ShippedRevenueTotals,
      FetchShippedRevenueTotalsArgs
    >({
      query: (params) => {
        const { currentFilter, currentRange, mid, marketplaceFilters } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/shippedRevenueSummary`,
          method: "POST",
          data: {
            mid,
            currentRange,
            currentFilter,
            ...(marketplaceFilters && { marketplaceFilters }),
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("ShippedRevenue/Summary"),
    }),

    shippedTrend: build.query<
      FetchShippedRevenueTrendResponse,
      FetchShippedRevenueTrendArgs
    >({
      query: (params) => {
        const { currentFilter, currentRange, mid, marketplaceFilters } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/shippedRevenueChartData`,
          method: "POST",
          data: {
            mid,
            currentFilter,
            currentRange,
            ...(marketplaceFilters && { marketplaceFilters }),
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler("ShippedRevenue/Trend"),
    }),

    purchaseOrderStatus: build.query<
      PurchaseOrderStatus,
      FetchPurchaseOrderStatusArgs
    >({
      query: (params) => {
        const { mid, includeTax, currentRange } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/postatus`,
          method: "POST",
          data: {
            mid,
            includeTax,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    purchaseOrders: build.query<
      FetchPurchaseOrdersResponse,
      FetchPurchaseOrdersArgs
    >({
      query: (params) => {
        const {
          currentRange,
          mid,
          marketplaceType,
          countryCode,
          includeTax,
          searchText,
          sortKey = "dateReceived",
          sortOrder = "desc",
          pageIndex,
          pageSize,
        } = params;

        return {
          url: `${baseUrl}/api/amazon-mws-service/api/vendor/purchaseOrders`,
          method: "POST",
          data: {
            mid,
            marketplaceType,
            countryCode,
            ...currentRange,
            includeTax,
            searchText,
            sortKey,
            sortOrder,
            pageIndex,
            pageSize,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
      transformResponse: ({ rows, count }: FetchPurchaseOrdersResponse) => ({
        rows,
        count,
      }),
    }),

    orderedRevenue: build.query<
      FetchOrderedRevenueResponse,
      FetchOrderedRevenueArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/orderedRevenue`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    shippedRevenue: build.query<
      FetchShippedRevenueResponse,
      FetchShippedRevenueArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/shippedRevenue`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    shippedRevenueByStores: build.query<
      FetchShippedRevenueByStoresResponse,
      FetchShippedRevenueByStoresArgs
    >({
      query: (params) => {
        const { currentRange, paginationParams, currentFilter } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/shippedRevenueByStores`,
          method: "POST",
          data: {
            currentRange,
            paginationParams,
            currentFilter,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(
        "ShippedRevenue/shippedRevenueByStores"
      ),
    }),

    retailAnalyticsByProduct: build.query<
      RetailAnalyticsByProductResponse,
      RetailAnalyticsByProductArgs
    >({
      query: (params) => {
        const { currentRange, ...otherParams } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/retailAnalyticsByProduct`,
          method: "POST",
          data: {
            ...otherParams,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),

    retailAnalyticsByProductChart: build.query<
      RetailAnalyticsByProductChartResponse,
      RetailAnalyticsByProductChartArgs
    >({
      query: (params) => {
        const { currentRange, mid, productId } = params;
        return {
          url: `${baseUrl}/api/generic-mws-service/api/vendor/retailAnalyticsByProductChart`,
          method: "POST",
          data: {
            mid,
            productId,
            ...currentRange,
          },
        };
      },
      onQueryStarted: globalQueryErrorHandler(),
    }),
  }),
});

export const {
  useShippedTrendQuery,
  useShippedRevenueTotalsQuery,
  usePurchaseOrderStatusQuery,
  usePurchaseOrdersQuery,
  useOrderedRevenueQuery,
  useShippedRevenueQuery,
  useShippedRevenueByStoresQuery,
  useRetailAnalyticsByProductQuery,
  useRetailAnalyticsByProductChartQuery,
} = extendedApiSlice;
