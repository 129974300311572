import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Cell,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import React, { memo } from "react";

import PieChartTooltip from "./pieChartTooltip";
import styled from "styled-components";
import { useLayoutProps } from "../chartUtils/chartComponents";

const SquareIcon = styled.div<{ $color?: string }>`
  width: 10px;
  height: 10px;
  background-color: ${({ $color }) => $color};
  margin-right: 4px;
  margin-top: 6px;
  flex-shrink: 0;
`;

const CustomLegendContainer = styled.div<{ $condensed?: boolean }>`
  display: flex;
  min-width: 50%;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: ${({ $condensed }) => ($condensed ? "1rem" : "2rem")};
  padding-bottom: ${({ $condensed }) => ($condensed ? "1rem" : "2rem")};
`;

export interface PieChartData {
  name: string;
  value: number;
  color: string;
  tooltipContent?: React.ReactNode;
  legendContent?: React.ReactNode;
}

interface CustomLegendProps {
  data: PieChartData[];
  legendHeader?: React.ReactElement;
  condensed?: boolean;
}

const CustomLegend: React.FC<CustomLegendProps> = ({
  data,
  legendHeader,
  condensed,
}) => {
  return (
    <CustomLegendContainer $condensed={condensed}>
      {legendHeader && <Box mb={2}>{legendHeader}</Box>}
      {data.map((e, index) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          key={index}
        >
          <SquareIcon $color={e.color} />
          {e.legendContent ? (
            e.legendContent
          ) : (
            <Typography variant="body2" noWrap>
              {e.name}
            </Typography>
          )}
        </Box>
      ))}
    </CustomLegendContainer>
  );
};

const XS_SIZE = 150;
const SM_SIZE = 250;
const MD_SIZE = 300;

interface PieChartProps {
  data: {
    name: string;
    value: number;
    color: string;
    tooltipContent?: React.ReactNode;
    legendContent?: React.ReactNode;
  }[];
  title?: string;
  placeholderWhenEmpty?: string;
  legendHeader?: React.ReactElement;
  report?: boolean;
  condensed?: boolean;
  flexDirection?: string;
}

const PieChart = memo<PieChartProps>(function PieChart({
  data,
  title,
  placeholderWhenEmpty = "N/A.",
  legendHeader,
  report,
  condensed,
  flexDirection = "row",
}) {
  const pieChartData = data;
  const totalValue = React.useMemo(
    () => data.reduce((acc, curr) => acc + curr.value, 0),
    [data]
  );

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const height = condensed ? XS_SIZE : md ? MD_SIZE : sm ? SM_SIZE : XS_SIZE;
  const { margin } = useLayoutProps("pieChart", report || condensed);

  return (
    <Grid container>
      {title && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6" align="center">
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection={flexDirection}
          alignItems="center"
          justifyContent="center"
        >
          {totalValue > 0 ? (
            <ResponsiveContainer width={"100%"} height={height}>
              <RechartsPieChart margin={margin}>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  labelLine={false}
                  isAnimationActive={false}
                >
                  {pieChartData.map((e, index) => (
                    <Cell key={`pieChart-cell-${index}`} fill={e.color} />
                  ))}
                </Pie>
                <Tooltip
                  content={({ active, payload }) => (
                    <PieChartTooltip active={active} payload={payload} />
                  )}
                />
              </RechartsPieChart>
            </ResponsiveContainer>
          ) : (
            <Typography>{placeholderWhenEmpty}</Typography>
          )}
          <CustomLegend
            data={pieChartData}
            legendHeader={legendHeader}
            condensed={condensed}
          />
        </Box>
      </Grid>
    </Grid>
  );
});

export default PieChart;
