import { Chip, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import isEmail from "~/utils/validateEmail";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const EmailChip = styled(Chip)`
  line-height: 16px;
  vertical-align: baseline;
  margin: 2px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) =>
    theme.darkModeEnabled ? theme.palette.grey[600] : theme.palette.grey[100]};
  background-image: none;
  padding-left: 5px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
`;

interface ReportEmailFieldProps {
  emailToBeSent: string[];
  setEmailToBeSent: (emailToBeSent: string[]) => void;
  emailError: boolean;
  setEmailError: (emailError: boolean) => void;
  fullWidth?: boolean;
}

const ReportEmailField = ({
  emailToBeSent,
  setEmailToBeSent,
  emailError,
  setEmailError,
  fullWidth,
}: ReportEmailFieldProps) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState("");

  const onChangeOfEmail = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>
  ) => {
    const value = (e.target as HTMLInputElement).value?.trim()?.toLowerCase();
    const isValidEmail = isEmail(value);
    if (isValidEmail) {
      const newValue = [...emailToBeSent, value];
      if (newValue.length > 15) {
        setEmailError(true);
        setErrorText(t("createReport.EmailLengthError"));
      } else {
        setEmailToBeSent(newValue);
        setEmailError(false);
        setErrorText("");
      }
    } else {
      setEmailError(true);
      setErrorText(t("createReport.EnterValidEmail"));
    }
  };

  const deleteEmail = (email: string[]) => {
    const filteredValue = emailToBeSent.filter(
      (e) => e !== (email as unknown as string)
    );
    setEmailToBeSent(filteredValue);
  };

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {t("createReport.RecipientsEmail")}:
      </Typography>
      <Grid item xs={fullWidth ? 12 : 9}>
        <Autocomplete
          multiple
          id="multi-email"
          filterSelectedOptions={true}
          options={[]}
          value={emailToBeSent}
          disableClearable
          freeSolo
          renderTags={(emailToBeSent) =>
            emailToBeSent.map((email, index) => (
              <EmailChip
                key={index}
                variant="outlined"
                onDelete={() => deleteEmail(email)}
                label={email}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={emailError}
              helperText={errorText}
              onChange={() => {
                setEmailError(false);
                setErrorText("");
              }}
            />
          )}
          onChange={onChangeOfEmail}
        />
      </Grid>
    </>
  );
};

export default ReportEmailField;
