import { Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import AlertCard from "~/components/alert/alertCard";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Medium from "~/components/typography/medium";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import { baseUrl } from "../../configs";
import styled from "styled-components";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useValidateInstacartAuthMutation } from "~/store/connections/connectInstacart.redux";

const StyledContainer = styled(Grid)`
  align-self: stretch;
  padding: 2rem;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const LogoWrapper = styled.img`
  width: 128px;
  height: 128px;
`;

const ErrorMessage = styled(Typography)`
  min-height: 1.5em;
`;

const InstacartAdvertisingAuthCodePage: React.FC = () => {
  const { t } = useTranslation();

  const queryParams = useQueryParams();
  const authCode = queryParams.get("code");

  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const [validateInstacartAuth] = useValidateInstacartAuthMutation({
    fixedCacheKey: "instacartAuth",
  });

  const onAuthError = useCallback((error: string) => {
    setError(t(error));
  }, []);

  useEffect(() => {
    if (authCode && authCode?.trim() !== "") {
      validateInstacartAuth({
        authCode,
      })
        .unwrap()
        .then(({ code, errMsg }) => {
          if (errMsg) {
            onAuthError(errMsg);
          } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
            onAuthError("connectWizard.connectionError");
          } else {
            setShowSuccess(true);
          }
        })
        .catch((error: { message: string }) => {
          const errorMessage = error.message || "connectWizard.connectionError";
          onAuthError(errorMessage);
        });
    } else {
      const errorMessage = "connectWizard.connectionError";
      onAuthError(errorMessage);
    }
  }, [authCode]);

  return (
    <StyledContainer container item xs={12}>
      <GridWrapper id="instacart-connect-wrapper">
        <Grid container spacing={5} alignItems="center" justifyContent="center">
          <Grid item xs={10}>
            <Medium align="center">
              {t("connectWizard.instacartLinkTitle")}
            </Medium>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <LogoWrapper src={`${baseUrl}/static/icons/instacart.jpg`} />
          </Grid>
          {showSuccess ? (
            <Grid item xs={12}>
              <AlertCard isOpen={true} type={"success"}>
                <Medium variant="body1">
                  {t("connectWizard.congratulationsBanner")}
                </Medium>
              </AlertCard>
            </Grid>
          ) : error ? (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <ErrorMessage color="error">{error}</ErrorMessage>
            </Grid>
          ) : (
            <LoadingIndicator />
          )}
        </Grid>
      </GridWrapper>
    </StyledContainer>
  );
};

export default InstacartAdvertisingAuthCodePage;
