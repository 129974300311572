import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
} from "~/store/utils/dateTimeUtils";
import CustomGroupCell, {
  CustomGroupCellProps,
} from "~/components/table/cells/customGroupCell";
import React, { ReactChild, memo, useCallback, useMemo, useState } from "react";
import {
  formatCurrency,
  formatCurrencyRounded,
  getCurrencyByCountryCode,
  intFormatterRounded,
} from "~/utils/currencyUtils";
import {
  useCustomGroupsQuery,
  useCustomVendorGroupMetricsQuery,
} from "~/store/customGroups/reducer.redux";

import ACOSCell from "~/components/table/cells/acosCell";
import { CUSTOM_GROUP_TABLE_PAGE_SIZE } from "~/modules/widgets/customGroups/customGroups";
import { ChangeCell } from "~/components/table/cells/changeCell";
import { ChartOption } from "~/modules/widgets/customGroups/customGroupsChart";
import { CustomGroup } from "@typedef/customGroups";
import CustomGroupsTable from "~/modules/widgets/customGroups/customGroupsTable";
import CustomVendorGroupsChart from "./customVendorGroupsChart";
import { Grid } from "@material-ui/core";
import { PaginationArgs } from "~/typedef/pagination";
import PercentageAndGrowthCell from "~/components/table/cells/percentageAndGrowthCell";
import { Range } from "@typedef/store";
import TACOSCell from "~/components/table/cells/tacosCell";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueAndTextCell } from "~/components/table/cells/valueAndTextCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { getPercentageDifference } from "~/utils/salesUtils";
import { isEmpty } from "lodash";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

interface CustomVendorGroupsProps {
  userInfo: User;
  selectedGroups: CustomGroup[];
  actions?: ReactChild;
  chartType: ChartOption;
  mid: string;
  countryCode: string;
  marketplace: string;
  shopName: string;
  report?: boolean;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  conditionalFormatting?: boolean;
  timezone: string;
  includeTax: boolean;
  isSourcingView: boolean;
  currentCompare: COMPARISON_PERIOD;
  pageSize?: number;
}

const CustomVendorGroups = memo<CustomVendorGroupsProps>(
  function CustomVendorGroups({
    userInfo,
    actions,
    selectedGroups,
    chartType,
    mid,
    countryCode,
    marketplace,
    shopName,
    report,
    currentPeriod,
    currentRange,
    currentCurrency,
    conditionalFormatting,
    timezone,
    includeTax,
    isSourcingView,
    currentCompare,
    pageSize = CUSTOM_GROUP_TABLE_PAGE_SIZE,
  }) {
    const { t } = useTranslation();
    const currentUrl = window.location.href;

    const { customGroups } = useCustomGroupsQuery(
      {
        mid,
        userId: userInfo?._id,
      },
      {
        skip: !userInfo?._id,
        selectFromResult: ({ data }) => ({
          customGroups: data?.groups || [],
        }),
      }
    );

    /** Selectors */
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const [searchText, setSearchText] = useState("");
    const [markedForDelete, setMarkedForDelete] = useState<{
      groupId: number;
      groupName: string;
    } | null>(null);
    const [markedForEdit, setMarkedForEdit] = useState<{
      groupId: number;
      groupName: string;
    } | null>(null);
    const [openModal, setOpenModal] = useState(false);

    const homeCurrency = getCurrencyByCountryCode[countryCode];

    const [paginationParams, setPaginationParams] = useState<
      Omit<PaginationArgs, "sortKey" | "sortOrder">
    >({
      pageSize,
      pageIndex: 0,
    });

    const {
      customGroupsMetrics,
      customGroupsMetricsRowCount,
      customGroupsMetricsFetching,
      paginatedGroups,
    } = useCustomVendorGroupMetricsQuery(
      {
        mid,
        currentPeriod,
        currentRange,
        includeTax,
        selectedGroups,
        ...paginationParams,
        searchText,
      },
      {
        skip: isEmpty(selectedGroups),
        selectFromResult: ({ data, isFetching }) => {
          const customGroupsMetrics = data?.data || [];
          const customGroupsMetricsRowCount = data?.count || 0;
          const customGroupsMetricsFetching = isFetching;
          const paginatedGroups =
            selectedGroups?.filter((selectedGroup) =>
              customGroupsMetrics?.find(
                (customGroupLine) =>
                  customGroupLine.groupId === selectedGroup.groupId
              )
            ) || [];
          return {
            customGroupsMetrics,
            customGroupsMetricsRowCount,
            customGroupsMetricsFetching,
            paginatedGroups,
          };
        },
      }
    );

    /** Actions */
    const fetchData = useCallback(
      ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
        setPaginationParams({
          pageIndex,
          pageSize,
        });
      },
      []
    );

    const onMarkDeleted = ({
      groupId,
      groupName,
    }: {
      groupId: number;
      groupName: string;
    }) => {
      setMarkedForDelete({ groupId, groupName });
    };

    const onMarkEdit = ({
      groupId,
      groupName,
    }: {
      groupId: number;
      groupName: string;
    }) => {
      setMarkedForEdit({ groupId, groupName });
      setOpenModal(true);
    };

    const generateColumns = () => {
      return [
        {
          Header: t("customVendorGroups.productGroupColumn"),
          accessor: (row: {
            groupId: number;
            groupName: number;
            productCount: number;
          }) => ({
            groupId: row.groupId,
            groupName: row.groupName,
            groupSize: row.productCount,
            groupLink: marketplaceLink(
              marketplace,
              mid,
              currentUrl.includes("grouprevenue")
                ? "productrevenue"
                : "salesbyproduct",
              {
                groupId: row.groupId,
              }
            ),
            groupType: t(
              row.productCount > 1
                ? "customGroups.products"
                : "customGroups.product"
            ),
          }),
          Cell: (props: CustomGroupCellProps) => (
            <CustomGroupCell
              {...props}
              report={report}
              onDelete={onMarkDeleted}
              onEdit={onMarkEdit}
            />
          ),
          isVisible: true,
          sticky: "left",
        },
        {
          Header: t("customVendorGroups.glanceViewsColumn"),
          accessor: (row: {
            glanceViews: number;
            priorGlanceViews: number;
          }) => {
            return {
              value:
                row.glanceViews !== undefined
                  ? numberWithCommas(row.glanceViews.toFixed(0))
                  : "-",
              growth: row.priorGlanceViews
                ? getPercentageDifference(row.glanceViews, row.priorGlanceViews)
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "glance_views",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.orderedRevenueColumn"),
          id: "ordered_revenue",
          accessor: (row: {
            orderedRevenue: number;
            priorOrderedRevenue: number;
          }) => {
            return {
              value:
                row.orderedRevenue !== undefined
                  ? formatCurrencyRounded(
                      row.orderedRevenue,
                      currencyRates,
                      homeCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorOrderedRevenue
                ? getPercentageDifference(
                    row.orderedRevenue,
                    row.priorOrderedRevenue
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          Cell: ValueAndGrowthCell,
          isVisible: true,
          align: "right",
        },
        {
          Header: t("customVendorGroups.unitsColumn"),
          accessor: (row: {
            orderedUnits: number;
            priorOrderedUnits: number;
          }) => {
            return {
              value:
                row.orderedUnits !== undefined
                  ? numberWithCommas(row.orderedUnits.toFixed(0))
                  : "-",
              growth: row.priorOrderedUnits
                ? getPercentageDifference(
                    row.orderedUnits,
                    row.priorOrderedUnits
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "ordered_units",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.unitsPerViewColumn"),
          accessor: (row: {
            unitsPerView: number;
            priorUnitsPerView: number;
          }) => {
            return {
              value:
                row.unitsPerView !== undefined
                  ? row.unitsPerView.toFixed(1)
                  : "-",
              growth: row.priorUnitsPerView
                ? (row.unitsPerView - row.priorUnitsPerView).toFixed(1)
                : "N/A",
            };
          },
          id: "units_per_view",
          Cell: PercentageAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.priceColumn"),
          accessor: (row: { price: number; priorPrice: number }) => {
            return {
              value: row.price
                ? formatCurrency(
                    row.price,
                    currencyRates,
                    homeCurrency,
                    currentCurrency
                  )
                : "-",
              growth:
                row.priorPrice !== undefined
                  ? getPercentageDifference(row.price, row.priorPrice)
                  : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "price",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
          divideRight: true,
        },
        {
          Header: t("customVendorGroups.shippedCogsColumn", {
            type: isSourcingView ? "(SRC)" : "(MFG)",
          }),
          wrapHeaderText: Boolean,
          accessor: (row: {
            shippedCogs: number;
            priorShippedCogs: number;
            shippedCogsManufacturing: number;
            priorShippedCogsManufacturing: number;
          }) => {
            const shippedCogs = isSourcingView
              ? row.shippedCogs
              : row.shippedCogsManufacturing;
            const priorShippedCogs = isSourcingView
              ? row.priorShippedCogs
              : row.priorShippedCogsManufacturing;
            return {
              value:
                shippedCogs === 0.0
                  ? "-"
                  : shippedCogs !== undefined
                  ? formatCurrencyRounded(
                      shippedCogs,
                      currencyRates,
                      homeCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: priorShippedCogs
                ? getPercentageDifference(shippedCogs, priorShippedCogs)
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "shipped_cogs",
          Cell: ValueAndGrowthCell,
          align: "center",
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.deltaColumn"),
          id: "delta",
          disableSortBy: true,
          accessor: (row: {
            shippedCogs: number;
            priorShippedCogs: number;
            shippedCogsManufacturing: number;
            priorShippedCogsManufacturing: number;
          }) => {
            const shippedCogs = isSourcingView
              ? row.shippedCogs
              : row.shippedCogsManufacturing;
            const priorShippedCogs = isSourcingView
              ? row.priorShippedCogs
              : row.priorShippedCogsManufacturing;
            const delta = Math.abs(shippedCogs - priorShippedCogs);
            return {
              value:
                shippedCogs && priorShippedCogs
                  ? formatCurrencyRounded(
                      delta,
                      currencyRates,
                      homeCurrency,
                      currentCurrency
                    )
                  : "-",
              conditionalFormatting,
              positiveGrowth: shippedCogs > priorShippedCogs,
            };
          },
          align: "center",
          Cell: ChangeCell,
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.shippedUnitsColumn"),
          accessor: (row: {
            shippedUnits: number;
            priorShippedUnits: number;
            shippedUnitsManufacturing: number;
            priorShippedUnitsManufacturing: number;
          }) => {
            const toggledShippedUnits = isSourcingView
              ? row.shippedUnits
              : row.shippedUnitsManufacturing;
            const toggledPriorShippedUnits = isSourcingView
              ? row.priorShippedUnits
              : row.priorShippedUnitsManufacturing;
            return {
              value:
                toggledShippedUnits === 0.0
                  ? "-"
                  : toggledShippedUnits !== undefined
                  ? intFormatterRounded.format(toggledShippedUnits)
                  : "-",
              growth: toggledPriorShippedUnits
                ? getPercentageDifference(
                    toggledShippedUnits,
                    toggledPriorShippedUnits
                  )
                : "N/A",
              conditionalFormatting: conditionalFormatting,
            };
          },
          id: "shipped_units",
          Cell: ValueAndGrowthCell,
          align: "right",
          hiddenDown: report ? "xl" : "sm",
          divideRight: true,
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.salesColumn"),
          id: "attributedSales",
          accessor: (row: { attributedSales?: string }) => ({
            value: row.attributedSales
              ? formatCurrency(
                  row.attributedSales,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
            text: "",
          }),
          align: "right",
          Cell: ValueAndTextCell,
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.spendColumn"),
          id: "cost",
          accessor: (row: { cost?: string }) =>
            row.cost
              ? formatCurrency(
                  row.cost,
                  currencyRates,
                  homeCurrency,
                  currentCurrency
                )
              : "-",
          align: "right",
          Cell: ValueCell,
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.acosColumn"),
          id: "acos",
          accessor: (row: { acos?: number }) => ({
            value: row.acos ? `${row.acos.toFixed(2)}%` : "-",
          }),
          align: "right",
          Cell: ACOSCell,
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
        {
          Header: t("customVendorGroups.tacosColumn"),
          id: "tacos",
          accessor: (row: { tacos?: number }) => ({
            value: row.tacos ? `${row.tacos.toFixed(1)}%` : "-",
          }),
          align: "center",
          Cell: TACOSCell,
          hiddenDown: report ? "xl" : "sm",
          isVisible: true,
        },
      ];
    };

    const columns = useMemo(
      () => generateColumns(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mid, currentRange, currentPeriod, currentCurrency, isSourcingView]
    );

    const tableActions = useMemo(() => {
      return <></>;
    }, []);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomVendorGroupsChart
            mid={mid}
            currentPeriod={currentPeriod}
            currentCompare={currentCompare}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            customGroups={customGroups}
            selectedGroups={
              selectedGroups.length > paginatedGroups.length
                ? paginatedGroups
                : selectedGroups
            }
            actions={actions}
            chartType={chartType}
            conditionalFormatting={conditionalFormatting}
            timezone={timezone}
            includeTax={includeTax}
            report={report}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomGroupsTable
            marketplace={marketplace}
            mid={mid}
            countryCode={countryCode}
            currentPeriod={currentPeriod}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            selectedGroups={selectedGroups}
            customGroupsMetrics={customGroupsMetrics}
            customGroupsMetricsRowCount={customGroupsMetricsRowCount}
            customGroupsMetricsFetching={customGroupsMetricsFetching}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchData={fetchData}
            report={report}
            shopName={shopName}
            openModal={openModal}
            setOpenModal={setOpenModal}
            setMarkedForDelete={setMarkedForDelete}
            setMarkedForEdit={setMarkedForEdit}
            markedForEdit={markedForEdit}
            markedForDelete={markedForDelete}
            tableColumns={columns}
            customTableActions={tableActions}
            customReportTitle={t(
              "customVendorGroups.productGroupsTable.mainTitle"
            )}
            reportType="vendorGroupRevenue"
            reportPath="/api/generic/vendor/groupRevenue"
            pageSize={pageSize}
          />
        </Grid>
      </Grid>
    );
  }
);

export default CustomVendorGroups;
