import {
  Box,
  ButtonBase,
  Grid,
  GridItemsAlignment,
  Hidden,
  Menu,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { MouseEventHandler, ReactNode, memo } from "react";

import { InlineIconButton } from "~/icons/inlineIconButton";
import StyledArrowDropDown from "../toolbars/toolbarComponents/styledArrowDropdown";
import styled from "styled-components";

interface ToolbarSelectBaseProps {
  $maxWidth?: number;
  $minWidth?: number;
}
export const ToolbarSelectBase = styled(ButtonBase)<ToolbarSelectBaseProps>`
  width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  min-width: ${({ $minWidth }) => ($minWidth ? `${$minWidth}px` : "100px")};
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "none")};
  &:disabled {
    background-color: ${({ theme }) => theme.palette.disabled.light};
    box-shadow: ${({ theme }) => theme.shadows[0]};
    cursor: not-allowed;
  }
`;

interface RightAlignedGridProps {
  $fullWidth?: boolean;
  $rightAlign?: boolean;
  $centerAlign?: boolean;
  $maxWidth?: number;
}

const RightAlignedGrid = styled(Grid)<RightAlignedGridProps>`
  display: flex;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  margin-left: ${({ $rightAlign }) => ($rightAlign ? "auto" : 0)};
  ${({ $centerAlign }) => $centerAlign && "margin: auto"};
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : "none")};
`;

interface ToolbarSelectProps {
  id: string;
  title: string;
  setMenuWidth: (width: number | null) => void;
  renderOptions: () => ReactNode;
  displayComponent: any;
  anchorEl?: any;
  setAnchorEl: (anchorEl: any) => void;
  fullWidth?: boolean;
  minWidth?: number;
  maxWidth?: number;
  tooltip?: string;
  alignItems?: string;
  disabled?: boolean;
  rightAlign?: boolean;
  centerAlign?: boolean;
}

export const ToolbarSelect = memo<ToolbarSelectProps>(function ToolbarSelect({
  id,
  title,
  setMenuWidth,
  renderOptions,
  displayComponent,
  anchorEl,
  setAnchorEl,
  maxWidth,
  minWidth,
  fullWidth,
  tooltip,
  alignItems,
  disabled,
  rightAlign,
  centerAlign,
}) {
  React.useEffect(() => {
    setMenuWidth(Boolean(anchorEl) ? anchorEl.offsetWidth : null);
  }, [anchorEl]);

  const openMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <RightAlignedGrid
      container
      item
      xs={12}
      direction="column"
      wrap="nowrap"
      $fullWidth={fullWidth}
      $rightAlign={rightAlign}
      $centerAlign={centerAlign}
      $maxWidth={maxWidth}
      alignItems={alignItems as GridItemsAlignment | undefined}
    >
      <Hidden smDown>
        <Box display="flex" width="100%" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {title}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} id="panel-card-tooltip">
              <InlineIconButton />
            </Tooltip>
          )}
        </Box>
      </Hidden>
      <ToolbarSelectBase
        $minWidth={minWidth}
        $maxWidth={maxWidth}
        onClick={openMenu}
        id={id}
        disabled={disabled}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="nowrap"
        >
          {displayComponent}
          <StyledArrowDropDown />
        </Box>
      </ToolbarSelectBase>
      <Menu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        marginThreshold={1}
        onClose={() => setAnchorEl(null)}
      >
        {renderOptions()}
      </Menu>
    </RightAlignedGrid>
  );
});

export default ToolbarSelect;
