import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import DonutChart, { Variant } from "~/components/charts/donutChart/donutChart";
import ProgressBar, {
  ProgressVariant,
} from "~/components/charts/progressBar/progressBar";
import React, { memo, useEffect } from "react";
import { StoreState, WithFetching } from "~/typedef/store";
import { useDispatch, useSelector } from "react-redux";

import DataDate from "~/components/dataDate/dataDate";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import Panel from "~/components/panel/panel";
import { fetchListingQualitySummary } from "~/store/mystore/listingQuality.redux";
import { get } from "lodash";
import { isNumber } from "~/utils/typeUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { roundFractionalDigits } from "~/utils/currencyUtils";
import styled from "styled-components";
import { useMarketplace } from "~/utils/navigationUtils";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Value = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.chartSummary.fontWeight};
  font-size: ${(props) => props.theme.typography.chartSummary.fontSize};
`;

// This type is copied over from mm-walmart-service and could potentially be out of date.
export interface ListingQualitySummary {
  _id: string; // actually a mongodb ObjectId;
  mid: string;
  listingQualityScore: number;
  contentDiscoveryScore: number;
  offerScore: number;
  reviewRatingScore: number;
  titleAverage: number;
  descriptionAverage: number;
  imagesAverage: number;
  savedAt: string; // Should be something like '2021-08-03T02:37:31.047Z'
}

const ListingQualityInformation: React.FC<{
  title: string;
  value: number | "-";
}> = ({ title, value }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="space-around"
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Value>
          {isNumber(value) ? roundFractionalDigits(value, 1) : value}
        </Value>
      </Grid>
    </Grid>
  );
};

interface ListingQualityStatusProps {
  showFooterLink?: boolean;
}

export const ListingQualityStatus = memo(
  ({ showFooterLink = false }: ListingQualityStatusProps) => {
    const theme = useTheme();
    const gt1050 = useMediaQuery("(min-width:1050px)");
    const xs =
      theme.breakpoints &&
      theme.breakpoints.down &&
      useMediaQuery(theme.breakpoints.down("xs"));
    const useMedium = !gt1050 && !xs;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Typed as Partial<> because it is undefined while fetching = true,
    // and also it would presumably be undefined if data wasn't available,
    // and we lack any graphql guarantees about the type of the data we recieve
    const {
      listingQualityScore,
      offerScore,
      reviewRatingScore,
      contentDiscoveryScore,
      titleAverage,
      descriptionAverage,
      imagesAverage,
      savedAt,
      fetching,
    }: WithFetching<Partial<ListingQualitySummary>> = useTypedSelector(
      (state: StoreState) => state.listingQualityGeneric?.summary
    );

    const store = useTypedSelector((state: StoreState) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const marketplace = useMarketplace();

    const footerLink = showFooterLink
      ? {
          url: marketplaceLink(
            marketplace,
            store?.merchantId || "",
            "listingquality"
          ),
          label: t("generic.viewAllLink"),
        }
      : undefined;

    useEffect(() => {
      if (store) {
        dispatch(
          fetchListingQualitySummary(store.merchantId, store.marketplaceCountry)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.merchantId, store?.marketplaceCountry]);

    return (
      <Panel
        id="widget-walmart-listing-quality-status"
        title={t("myStoresWidget.listingQuality.walmart.status.title")}
        tooltip={t("myStoresWidget.listingQuality.walmart.status.tooltip")}
        footerLink={footerLink}
        content={
          <Box p={2}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={4}>
                {fetching ? (
                  <LoadingIndicator />
                ) : isNumber(listingQualityScore) ? (
                  <DonutChart
                    title={t(
                      "myStoresWidget.listingQuality.walmart.status.listingQualityScore"
                    )}
                    variant={Variant.Percent}
                    percent={listingQualityScore}
                    {...(useMedium ? { height: 130 } : {})}
                  />
                ) : (
                  <Typography align="center" gutterBottom>
                    {t("generic.noDataAvailableMessage")}
                  </Typography>
                )}
              </Grid>

              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  {!fetching &&
                    (isNumber(offerScore) ? (
                      <Box px={2}>
                        <ProgressBar
                          percent={offerScore}
                          title={t(
                            "myStoresWidget.listingQuality.walmart.offerScore"
                          )}
                          primaryColor={theme.palette.chart.progressBlue}
                          variant={ProgressVariant.Percent}
                        />
                      </Box>
                    ) : (
                      <Typography align="center" gutterBottom>
                        {t("generic.noDataAvailableMessage")}
                      </Typography>
                    ))}
                </Grid>
                <Grid item xs={12}>
                  {!fetching &&
                    (isNumber(contentDiscoveryScore) ? (
                      <Box px={2}>
                        <ProgressBar
                          percent={contentDiscoveryScore}
                          title={t(
                            "myStoresWidget.listingQuality.walmart.contentDiscoveryScore"
                          )}
                          primaryColor={theme.palette.chart.darkGray}
                          variant={ProgressVariant.Percent}
                        />
                      </Box>
                    ) : (
                      <Typography align="center" gutterBottom>
                        {t("generic.noDataAvailableMessage")}
                      </Typography>
                    ))}
                </Grid>
                <Grid item xs={12}>
                  {!fetching &&
                    (isNumber(reviewRatingScore) ? (
                      <Box px={2}>
                        <ProgressBar
                          percent={reviewRatingScore}
                          title={t(
                            "myStoresWidget.listingQuality.walmart.reviewRatingScore"
                          )}
                          primaryColor={theme.palette.primary.main}
                          variant={ProgressVariant.Percent}
                        />
                      </Box>
                    ) : (
                      <Typography align="center" gutterBottom>
                        {t("generic.noDataAvailableMessage")}
                      </Typography>
                    ))}
                </Grid>
              </Grid>
              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  {!fetching && (
                    <Box px={2}>
                      <ListingQualityInformation
                        title={t(
                          "myStoresWidget.listingQuality.walmart.imagesAverage"
                        )}
                        value={imagesAverage ?? "-"}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!fetching && (
                    <Box px={2}>
                      <ListingQualityInformation
                        title={t(
                          "myStoresWidget.listingQuality.walmart.titleAverage"
                        )}
                        value={titleAverage ?? "-"}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!fetching && (
                    <Box px={2}>
                      <ListingQualityInformation
                        title={t(
                          "myStoresWidget.listingQuality.walmart.descriptionAverage"
                        )}
                        value={descriptionAverage ?? "-"}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
              {savedAt && (
                <Grid item xs={12}>
                  <DataDate savedAt={savedAt}></DataDate>
                </Grid>
              )}
            </Grid>
          </Box>
        }
      />
    );
  }
);
