import React, { memo, useCallback, useState } from "react";

import { Box, Grid, Switch, Typography } from "@material-ui/core";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import RetailAnalyticsByProduct from "~/modules/overview/retailAnalyticsByProduct";
import get from "lodash/get";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { upsertPersistentAppSettings } from "~/store/persistentAppSettings.redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ProductRevenue = memo(function SalesByProductPage() {
  const dispatch = useDispatch();
  const userInfo = useTypedSelector((state) => state.user);
  const { t } = useTranslation();
  const currentViewPreference = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.viewPreference") ||
      "sourcing"
  );
  const [isSourcingView, setIsSourcingView] = useState(
    currentViewPreference === "sourcing"
  );
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const updateViewPreference = useCallback((isSourcingView: boolean) => {
    dispatch(
      upsertPersistentAppSettings({
        organisationId: userInfo.organisationId,
        userId: userInfo._id,
        settings: {
          viewPreference: isSourcingView ? "sourcing" : "manufacturing",
        },
      })
    );
  }, []);

  return store ? (
    <PageBlock>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="subtitle1" color="textPrimary" noWrap>
            {isSourcingView
              ? t("retailAnalytics.sourcingView")
              : t("retailAnalytics.manufacturingView")}
          </Typography>
          <Box pr={1}>
            <Switch
              {...{
                size: "small",
                checked: isSourcingView,
                onClick: () => {
                  updateViewPreference(!isSourcingView);
                  setIsSourcingView(!isSourcingView);
                },
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={4}>
        <GridWithErrorBoundary item xs={12}>
          <RetailAnalyticsByProduct
            mid={store.merchantId}
            marketplace={store.marketplace}
            marketplaceSubtype={store.marketplaceSubtype ?? store.marketplace}
            countryCode={store.marketplaceCountry}
            sellerId={store.sourceSystemId}
            isSourcingView={isSourcingView}
          />
        </GridWithErrorBoundary>
      </Grid>
    </PageBlock>
  ) : (
    <LoadingIndicator />
  );
});

export default ProductRevenue;
