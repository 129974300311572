import { Box, Grid, useTheme } from "@material-ui/core";
import { DATETIME_PERIODS, INTERVAL } from "~/store/utils/dateTimeUtils";
import { FORECAST_TYPE, REFERENCE_LEVEL } from "~/components/forecasting/types";
import { Filter, Range } from "~/typedef/store";
import { FooterLink, Panel } from "~/components/panel/panel";
import MultiBarLineChart, {
  MultiBarLineChartProps,
} from "~/components/charts/multiBarLineChart/multiBarLineChart";
import React, { ReactChild, memo, useMemo } from "react";

import AchievementText from "~/components/totals/achievementText";
import AvgOrderValue from "~/components/totals/avgOrderValue";
import BarChartNoData from "~/components/placeholders/barChartNoData";
import { CHART_TITLES } from "~/components/charts/chartUtils/chartUtils";
import GenericTotal from "~/components/totals/genericTotal";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import TotalOrders from "~/components/totals/totalOrders";
import TotalSales from "~/components/totals/totalSales";
import { User } from "~/typedef/user";
import { getConvertedValue } from "~/utils/currencyUtils";
import moment from "moment-timezone";
import { useForecastingValuesQuery } from "~/store/mystore/forecasting.redux";
import { useSalesPerformanceQuery } from "@store/overview/salesPerformance.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

export const CHART_TYPE = {
  Sales: "sales",
  Orders: "orders",
  AvgOrderValue: "avgOrderValue",
  UnitsSold: "unitsSold",
  OrderItems: "orderItems",
};

export const CHART_TYPE_LEGENDS_MAPPING = {
  [CHART_TYPE.Sales]: "chartLegends.sales",
  [CHART_TYPE.Orders]: "chartLegends.orders",
  [CHART_TYPE.AvgOrderValue]: "chartLegends.avgOrderValue",
  [CHART_TYPE.UnitsSold]: "chartLegends.unitsSold",
  [CHART_TYPE.OrderItems]: "chartLegends.orderItems",
};

export interface ChartOption {
  value: (typeof CHART_TYPE)[keyof typeof CHART_TYPE];
  label: string;
}

interface SalesPerformanceProps {
  userInfo: User;
  mid?: string;
  marketplaceType?: string;
  marketplaceSubtype?: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  currentFilter?: Filter;
  footerLink?: FooterLink;
  actions?: ReactChild;
  chartType: ChartOption;
  report?: boolean;
  timezone: string;
  includeTax: boolean;
  conditionalFormatting?: boolean;
  isLineChart?: boolean;
  isForecast?: boolean;
  customTitle?: string;
  showSalesTarget?: boolean;
  vendorRevenueType?: "orderedRevenue" | "shippedRevenue";
}

const SalesPerformanceLineChart = memo<SalesPerformanceProps>(
  function SalesPerformanceLineChart({
    userInfo,
    mid,
    marketplaceType,
    marketplaceSubtype,
    currentPeriod,
    currentRange,
    currentCurrency,
    currentFilter,
    footerLink,
    timezone,
    actions,
    chartType,
    report,
    includeTax,
    conditionalFormatting,
    isLineChart,
    customTitle,
    isForecast,
    showSalesTarget = true,
    vendorRevenueType = "orderedRevenue",
  }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isVendorStore = useVendorStore(mid);
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );

    const { interval } = currentRange;

    const showForecastingData = useMemo(() => {
      // Show if:
      // chart type is sales
      // mid is defined (single store view)
      return (
        chartType?.value === "sales" &&
        mid &&
        interval !== INTERVAL.HOURS &&
        showSalesTarget
      );
    }, [userInfo, chartType, interval, showSalesTarget]);

    const isOrderItemsChart = useMemo(
      () => chartType.value === CHART_TYPE.OrderItems,
      [chartType.value]
    );
    const {
      salesPerformance,
      loading,
      hasData,
      summaryComparisonSubtitle,
      summaryCurrentSubtitle,
    } = useSalesPerformanceQuery(
      {
        mid,
        filter: currentFilter,
        currentPeriod,
        currentRange,
        includeTax,
        currency: currentCurrency,
        includeVendor: true,
        vendorRevenueType,
        // Only pass if true, else undefined
        // to allow RTK Query to cache efficiently
        ...(isOrderItemsChart === true ? { isOrderItemsChart } : {}),
      },
      {
        selectFromResult: ({ data, isFetching }) => {
          const salesPerformance = data;

          const hasChartData =
            salesPerformance?.chartData &&
            salesPerformance?.chartData.some(
              (data) => data.current.sales !== 0
            );
          const hasCurrentSales =
            salesPerformance?.current?.sales &&
            salesPerformance?.current?.sales !== 0;
          const hasPriorSales =
            salesPerformance?.prior?.sales &&
            salesPerformance?.prior?.sales !== 0;
          const hasData = hasChartData || hasCurrentSales || hasPriorSales;

          const summaryComparisonSubtitle =
            data?.summaryPriorRange &&
            data.summaryPriorRange.toDate !== currentRange.priorToDate
              ? `${moment
                  .unix(data.summaryPriorRange.fromDate)
                  .tz(currentRange.timezone)
                  .format("D MMM YY")} - ${moment
                  .unix(data.summaryPriorRange.toDate)
                  .tz(currentRange.timezone)
                  .format("D MMM YY")}`
              : undefined;

          const summaryCurrentSubtitle =
            data?.summaryCurrentRange &&
            data.summaryCurrentRange.toDate !== currentRange.toDate
              ? `${moment
                  .unix(data.summaryCurrentRange.fromDate)
                  .tz(currentRange.timezone)
                  .format("D MMM YY")} - ${moment
                  .unix(data.summaryCurrentRange.toDate)
                  .tz(currentRange.timezone)
                  .format("D MMM YY")}`
              : undefined;

          return {
            salesPerformance,
            loading: isFetching,
            hasData,
            summaryComparisonSubtitle,
            summaryCurrentSubtitle,
          };
        },
      }
    );

    const { forecastData, forecastDataTimezone, forecastDataLoading } =
      useForecastingValuesQuery(
        {
          user: {
            _id: userInfo._id,
            organisationId: userInfo.organisationId,
          },
          mid: mid || "",
          currentRange: {
            ...currentRange,
            timezone:
              currentRange.interval === INTERVAL.WEEKS
                ? "UTC" // since orders caggs use utc timezone
                : currentRange.timezone,
          },
          respectTimezone: true,
          includeInterval: true,
        },
        {
          skip: !showForecastingData,
          selectFromResult: ({ data, isFetching }) => ({
            forecastData: data?.data || { forecastValue: null, totals: null },
            forecastDataTimezone: data?.dataTimezone || currentRange.timezone,
            forecastDataLoading: isFetching,
          }),
        }
      );

    const isTotalsNull =
      forecastData.totals?.forecast.budget == null &&
      forecastData.totals?.forecast.forecast == null &&
      forecastData.totals?.adSpend.target == null;

    // Format data for chart display
    const formattedSalesPerformanceChartData = useMemo(() => {
      const type = chartType?.value || "sales";

      return (
        salesPerformance?.chartData?.map((unitData) => {
          const current =
            (type === CHART_TYPE.Sales || type === CHART_TYPE.AvgOrderValue) &&
            Number.isFinite(unitData.current?.[type])
              ? getConvertedValue(
                  currencyRates,
                  salesPerformance.currency,
                  currentCurrency,
                  unitData.current?.[type]
                )
              : unitData.current?.[type];
          const prior =
            (type === CHART_TYPE.Sales || type === CHART_TYPE.AvgOrderValue) &&
            Number.isFinite(unitData.prior?.[type])
              ? getConvertedValue(
                  currencyRates,
                  salesPerformance.currency,
                  currentCurrency,
                  unitData.prior?.[type]
                )
              : unitData.prior?.[type];

          const chartForecastData: {
            forecastTarget?: number | null;
            budgetTarget?: number | null;
          } = {
            forecastTarget: null,
            budgetTarget: null,
          };
          if (showForecastingData && unitData?.current?.startTime) {
            const forecastTimestamp = moment
              .unix(unitData.current.startTime)
              .tz(forecastDataTimezone)
              .startOf("day")
              .unix();

            if (isForecast) {
              const forecastTarget =
                forecastData?.forecastValue?.[forecastTimestamp]?.[
                  FORECAST_TYPE.FORECAST
                ]?.[REFERENCE_LEVEL.TARGET];

              if (Number.isFinite(forecastTarget?.amount)) {
                chartForecastData.forecastTarget = getConvertedValue(
                  currencyRates,
                  forecastTarget?.currency,
                  currentCurrency,
                  forecastTarget?.amount
                );
              }
            } else {
              const budgetTarget =
                forecastData?.forecastValue?.[forecastTimestamp]?.[
                  FORECAST_TYPE.BUDGET
                ]?.[REFERENCE_LEVEL.TARGET];

              if (Number.isFinite(budgetTarget?.amount)) {
                chartForecastData.budgetTarget = getConvertedValue(
                  currencyRates,
                  budgetTarget?.currency,
                  currentCurrency,
                  budgetTarget?.amount
                );
              }
            }
          }

          return {
            startTime: unitData?.current?.startTime,
            endTime: unitData?.current?.endTime,
            current,
            prior,
            ...chartForecastData,
          };
        }) || []
      );
    }, [
      currencyRates,
      currentCurrency,
      salesPerformance,
      forecastData,
      chartType,
      showForecastingData,
      isForecast,
    ]);

    const salesChartProps = useMemo(() => {
      const currentAndPrior = [
        {
          key: "current",
          colour: theme.palette.primary.main,
          toFixed: 2,
          isCurrency: [CHART_TYPE.Sales, CHART_TYPE.AvgOrderValue].includes(
            chartType?.value
          ),
          axis: "1" as const,
        },
        {
          key: "prior",
          colour: theme.palette.secondary.main,
          toFixed: 2,
          isCurrency: [CHART_TYPE.Sales, CHART_TYPE.AvgOrderValue].includes(
            chartType?.value
          ),
          axis: "1" as const,
        },
      ];

      if (showForecastingData) {
        isForecast
          ? currentAndPrior.push({
              key: "forecastTarget",
              colour: theme.palette.success.dark,
              toFixed: 2,
              isCurrency: true,
              axis: "1" as const,
            })
          : currentAndPrior.push({
              key: "budgetTarget",
              colour: theme.palette.info.light,
              toFixed: 2,
              isCurrency: true,
              axis: "1" as const,
            });
      }

      const chartProps: MultiBarLineChartProps = {
        title: customTitle || t(CHART_TITLES[currentPeriod]),
        currentPeriod,
        currentCurrency,
        currencyRates,
        chartData: formattedSalesPerformanceChartData,
        isLoading: false, // We have a loading check in our content
        lines: [],
        bars: [],
        xKey: "startTime",
        timezone,
        interval,
        tooltipPercentageChange: [
          {
            dataKey1: "current",
            dataKey2: "prior",
            toFixedDigits: 0,
          },
        ],
        tooltipAttainment: [],
      };

      if (showForecastingData && chartProps && chartProps.tooltipAttainment) {
        chartProps.tooltipAttainment.push({
          currentValueKey: "current",
          thresholdValueKey: isForecast ? "forecastTarget" : "budgetTarget",
          lowThreshold: 100,
          labelKey: "chartKeys.achievement",
        });
      }
      if (isLineChart) {
        chartProps.lines.push(...currentAndPrior);
      } else {
        chartProps.bars.push(...currentAndPrior);
      }

      return chartProps;
    }, [isLineChart, theme, t, formattedSalesPerformanceChartData, isForecast]);
    return (
      <Panel
        id="widget-sales-performance"
        title={t(
          isVendorStore
            ? vendorRevenueType == "orderedRevenue"
              ? "chartTitle.orderedRevenueTrend"
              : "chartTitle.ShippedRevenueTrend"
            : "dashboardWidget.salesPerformance.mainTitle"
        )}
        tooltip={
          report
            ? undefined
            : `${t("dashboardWidget.salesPerformance.mainTooltip")} ${
                marketplaceType && marketplaceType === "ebay"
                  ? t("dashboardWidget.salesPerformance.ebayAdditionalTooltip")
                  : ""
              }`
        }
        footerLink={footerLink}
        actions={actions}
        content={
          loading || (showForecastingData && forecastDataLoading) ? (
            <PanelLoading />
          ) : !salesPerformance || !hasData ? (
            <BarChartNoData {...{ currentPeriod: currentPeriod, report }} />
          ) : (
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={9} lg={10}>
                  <MultiBarLineChart {...salesChartProps} />
                </Grid>

                <Grid container item xs={12} md={3} lg={2} alignItems="center">
                  {chartType?.value === CHART_TYPE.Orders ? (
                    <TotalOrders
                      current={salesPerformance.current.orders}
                      prior={salesPerformance.prior.orders}
                      currentPeriod={currentPeriod}
                      comparisonSubtitle={summaryComparisonSubtitle}
                      currentSubtitle={summaryCurrentSubtitle}
                    />
                  ) : chartType?.value === CHART_TYPE.AvgOrderValue ? (
                    <AvgOrderValue
                      current={salesPerformance.current.avgOrderValue}
                      prior={salesPerformance.prior.avgOrderValue}
                      currency={salesPerformance?.currency}
                      currentCurrency={currentCurrency}
                      currentPeriod={currentPeriod}
                      comparisonSubtitle={summaryComparisonSubtitle}
                      currentSubtitle={summaryCurrentSubtitle}
                    />
                  ) : chartType?.value === CHART_TYPE.Sales ? (
                    <TotalSales
                      current={salesPerformance.current.sales}
                      prior={salesPerformance.prior.sales}
                      isVendorStore={isVendorStore}
                      currency={salesPerformance?.currency}
                      currentCurrency={currentCurrency}
                      currentPeriod={currentPeriod}
                      conditionalFormatting={conditionalFormatting}
                      comparisonSubtitle={summaryComparisonSubtitle}
                      currentSubtitle={summaryCurrentSubtitle}
                      vendorRevenueType={vendorRevenueType}
                    />
                  ) : (
                    <GenericTotal
                      number={salesPerformance.current[chartType?.value]}
                      comparison={{
                        prior: salesPerformance.prior[chartType?.value],
                      }}
                      title={t(`genericTotal.${chartType?.value}`)}
                      comparisonSubtitle={summaryComparisonSubtitle}
                      currentSubtitle={summaryCurrentSubtitle}
                    />
                  )}
                  {showForecastingData &&
                    forecastData &&
                    forecastData.totals &&
                    !isTotalsNull && (
                      <Box pt={1} width="100%">
                        <AchievementText
                          {...{
                            title: isForecast
                              ? t("chartKeys.forecastTarget")
                              : t("chartKeys.budgetTarget"),
                            currency: forecastData.totals.currency,
                            currentCurrency,
                            thresholdValue: isForecast
                              ? Number(forecastData.totals.forecast.forecast)
                              : Number(forecastData.totals.forecast.budget),
                            currentValue: salesPerformance.current["sales"],
                            lowThreshold: 100,
                          }}
                        />
                      </Box>
                    )}
                </Grid>
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default SalesPerformanceLineChart;
