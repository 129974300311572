import { Container } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const PageBlockBase = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  max-width: none;
`;

const PageBlock: React.FC = (props) => (
  <PageBlockBase {...props}>{props.children ?? <></>}</PageBlockBase>
);

export default PageBlock;
